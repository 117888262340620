import React, { useEffect, useState } from "react";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";
import Tabs, { TabPane } from "rc-tabs";
import PageTitle from "../../../../components/layout/PageTitle";
import confirmedpayments from "../../../../../../assets/img/icons/confirmedpayments.svg";
import redannulation from "../../../../../../assets/img/icons/redannulation.svg";

import Table from "./components/Table";
import { renderDatePicker } from "../../../../components/Utils/renderField/renderField";
import { Field, Form } from "react-final-form";
import { RenderCurrency } from "../../../../components/Utils/renderField/renderReadField";

import "./styles.css";

const ListFloatingPayments = (props) => {
  const {
    cashRegisterHistoryList,
    confirmedFloatingPayments,
    dataHistory,
    loader,
    page,
    onSortChange,
    confirmed_payment,
    annulation_payment,
    get_total_floating_payment,
    total,
    changeDateStart,
    changeDateEnd,
    setPage,
  } = props;

  const [status, setStatus] = useState("floating_payments");

  useEffect(() => {
    if (status === "floating_payments") {
      cashRegisterHistoryList(page);
    }

    if (status === "confirmed_payments") {
      confirmedFloatingPayments(page);
    }

    get_total_floating_payment();
  }, [status]);

  return (
    <React.Fragment>
      <div>
        <PageTitle
          title={"Pago Flotante"}
          colTitle="col-lg-4 col-xl-3"
          showTotal={true}
          total={total}
        />
        <div>
          <div className="p-0 cash-container">
            <LoadMask loading={loader} dark blur>
              <Tabs
                onTabClick={(e) => {
                  setPage(1);
                  setStatus(e);
                }}
                tabBarStyle={{ background: "transparent" }}
                defaultActiveKey={"floating_payments"}
              >
                <TabPane tab="Pagos Flotantes" key="floating_payments">
                  <div
                    className="card bg-card-cofaqui"
                    style={{ borderTopLeftRadius: "0" }}
                  >
                    <Table
                      dataHistory={dataHistory}
                      loader={loader}
                      page={page}
                      listar={cashRegisterHistoryList}
                      onSortChange={onSortChange}
                      action={{
                        action: confirmed_payment,
                        title: "confirmar",
                        className:
                          "px-2 py-0 actions-btn btn-confirmed-payment",
                        src: confirmedpayments,
                        alt: "confirmed_payments",
                      }}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Pagos Confirmados" key="confirmed_payments">
                  <div
                    className="card bg-card-cofaqui"
                    style={{ borderTopLeftRadius: "0" }}
                  >
                    <div className="d-flex flex-column">
                      {/* FILTOS DE FECHA */}
                      <Form
                        onSubmit={() => {}}
                        render={({ handleSubmit }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <div className="filters-main">
                                <div className="form-group has-feedback card-star-date">
                                  <label
                                    className="text-16 font-weight-bold"
                                    htmlFor="dateStart"
                                  >
                                    Fecha Inical
                                  </label>
                                  <Field
                                    className="float"
                                    name="dateStart"
                                    other={{ clearIcon: null }}
                                    maxDate={new Date()}
                                    component={renderDatePicker}
                                    customAction={changeDateStart}
                                  />
                                </div>

                                <div className="form-group has-feedback card-end-date">
                                  <label
                                    className="text-16 font-weight-bold"
                                    htmlFor="dateEnd"
                                  >
                                    Fecha Final
                                  </label>
                                  <Field
                                    className="float"
                                    name="dateEnd"
                                    maxDate={new Date()}
                                    other={{ clearIcon: null }}
                                    component={renderDatePicker}
                                    customAction={changeDateEnd}
                                  />
                                </div>
                              </div>
                            </form>
                          );
                        }}
                      />
                      {/* FILTOS DE FECHA */}
                      <div className="ml-4 mr-4 mb-2">
                        <label className="font-weight-bold">
                          Saldo confirmado:
                        </label>
                        &nbsp;&nbsp;
                        <RenderCurrency
                          className="text-right"
                          value={
                            status === "confirmed_payments" &&
                            dataHistory.total
                              ? dataHistory.total
                              : 0
                          }
                        />
                      </div>
                    </div>
                    <Table
                      dataHistory={dataHistory}
                      loader={loader}
                      page={page}
                      listar={confirmedFloatingPayments}
                      fecha={{title: "Fecha pago confirmado", name: "confirm_floating_payment_date"}}
                      onSortChange={onSortChange}
                      action={{
                        action: annulation_payment,
                        title: "anular",
                        className: "px-2 py-0 actions-btn btn-annulation",
                        src: redannulation,
                        alt: "redannulation",
                      }}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </LoadMask>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListFloatingPayments;
