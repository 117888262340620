import React, {useState} from 'react';
import ProfileForm from "./ProfileForm";


const Profile = ({update, me, loader}) => {
  const [avatar, setAvatar] = useState(null)

  const customUpdate = (data) => {
    update({...data, avatar: null}, [{"file": avatar, "name": "avatar"}]);
  };

  return (
    <ProfileForm
      data={me}
      loader={loader}
      setAvatar={setAvatar}
      onSubmit={customUpdate}
    />
  );
}

export default Profile
