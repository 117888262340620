import bag from "../../../../../assets/img/icons/bag.svg";
import greenBag from "../../../../../assets/img/icons/baggreen.svg";
import dollar from "../../../../../assets/img/icons/dollar.svg";
import wallet from "../../../../../assets/img/icons/wallet.svg";
import doc from "../../../../../assets/img/icons/docs.svg";
import userIcon from "../../../../../assets/img/icons/member.svg";
import HomeIcon from "../../../../../assets/img/icons/home.svg";
import bookIcon from "../../../../../assets/img/icons/book.svg";
import logoCOFAQUI from "../../../../../assets/img/icons/logo.svg";
import spentIcon from "../../../../../assets/img/icons/spent.svg";
import creditCard from "../../../../../assets/img/icons/creditcard.svg";
import product from "../../../../../assets/img/icons/product.svg";
import greenStamp from "../../../../../assets/img/icons/greenstamp.svg";
import floatingPayment from "../../../../../assets/img/icons/floating_payment.svg";
import bank from "../../../../../assets/img/icons/bank.svg";
import management from "../../../../../assets/img/icons/management.svg";

export {
  bag,
  greenBag,
  dollar,
  wallet,
  doc,
  userIcon,
  HomeIcon,
  bookIcon,
  logoCOFAQUI,
  spentIcon,
  creditCard,
  product,
  greenStamp,
  floatingPayment,
  bank,
  management,
}
