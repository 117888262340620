import React, {useEffect, useState} from 'react'
import moment from "moment";
import FiltersBinnacle from "./Filters";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";
import {api} from "../../../../../utility/api";
import ModalDetail from "./ModalDetail";
import {addIndexToArray} from "../../../../../utility/constants";


const List = (props) => {
  const {data, page, loader, listar, onSortChange, changeDateStart, changeDateEnd, changeUser, dateStart, dateEnd} = props;
  const [defOptionUser, setOptionUser] = useState([])
  const [details, setDetails] = useState({})
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    props.resetValueUser()
    getUser().then((opts) => {
      setOptionUser(opts)
    })
    listar(page)
  }, [])

  const getUser = (search = "") => {
    return api.get("user", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) {
        _options = resp.results
        _options.push({id: null, full_name: 'Todos'})
      }
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }
  const openModalAction = (data) => {
    setDetails(data)
    setOpenModal(true)
  }
  const closeModalAction = () => {
    setDetails({})
    setOpenModal(false)
  }

  return (
    <div>
      <PageTitle
        title="Bitácora"
        customContainer={"w-100 flex-wrap justify-content-between mb-3 pt-4"}
      />
      <ModalDetail
        info={details}
        openModal={openModal}
        closeModal={closeModalAction}
      />
      <div className="card bg-card-cofaqui br-20px">
        <div>
          <FiltersBinnacle
            getUser={getUser}
            defaultOp={defOptionUser}
            data={{dateStart, dateEnd}}
            changeDateEnd={changeDateEnd}
            changeDateStart={changeDateStart}
            changeUserSelected={changeUser}
          />
        </div>
        <div className="col-12 px-1">
          <div className="mb-4">
            <div className="px-4 pt-4">
              <Grid
                hover
                data={data}
                page={page}
                loading={loader}
                onPageChange={listar}
                onSortChange={onSortChange}
                bodyContainerClass = "bc-professions-custom"
                headerContainerClass = "hc-professions-custom"
                tableContainerClass = "professions-container-table-sm"
                tableHeaderClass = "table-header-professions"
              >
                <TableHeaderColumn
                  width="200px"
                  dataField="order"
                  dataAlign="center"
                >
                  No.
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="created"
                  dataAlign="center"
                  width='200px'
                  dataFormat={(cell) => {
                    let dateStr = ""
                    if (cell) {
                      dateStr = moment(cell).format("DD/MM/YYYY HH:mm")
                    }
                    return <span>{dateStr}</span>
                  }}
                >
                  Fecha y Hora
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="user"
                  dataAlign="center"
                  width='200px'
                  dataFormat={(cell) => {
                    let full_name = ""
                    if (cell && typeof (cell) === "object") {
                      full_name = `${cell.first_name} ${cell.last_name}`
                    }
                    return <div>{full_name}</div>
                  }}
                >
                  Usuario
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="action"
                  width='200px'
                >
                  Acción
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataField="id"
                  dataAlign="center"
                  width='100px'
                  dataFormat={standardActions({
                    details: (data) => openModalAction(data)
                  })}
                >
                  Detalle
                </TableHeaderColumn>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default List
