import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/administration/professions";
import Professions from "./Professions";

const ms2p = (state) => {
  return {
    ...state.professions,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Professions);
