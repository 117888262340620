import React, {Fragment, useState, useEffect} from "react"
import ItemTree from "./components/ItemTree";
import Modal from "react-responsive-modal";
import ExpenseForm from "./CategoryForm";
import Swal from "sweetalert2";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";


const ExpenseCategory = (props) => {
  const [typeAction, setTypeAction] = useState()
  const [item, setLocalItem] = useState(null)
  const {setOpenModal, openCategories, openModal, dataCategories, loader, listCategories, loaderForm} = props

  useEffect(() => {
    listCategories(1);
  }, [])


  const selectItem = (item, option) => {
    setLocalItem(item)
    setTypeAction(option)
    if (Number(option) === 3) {
      Swal.fire({
        title: "¿Desea eliminar la categoría?",
        text: "¡No podrá revertir esta acción!",
        showCancelButton: true,
        confirmButtonText: "¡Sí, borrar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          props.deleteRecord(item.value);
        }
      });
    }
  }

  const onSubmit = (data) => {
    let finalData = {name: data.name, parent_id: null, is_insurance: data.is_insurance}

    if (typeAction === 1) {
      // create
      if (item) {
        finalData["parent_id"] = item.value
      }
      props.create(finalData)
    } else if (typeAction === 2) {
      // edit
      props.edit(item.value, finalData)
    }
  }

  const closeModal = () => setOpenModal(false);

  const renderModal = () => {
    let titleEdit = "Editar Categoría"
    let titleCreate = "Agregar Categoría"
    let titleDelete = "Eliminar Categoría"
    if (item && item.parent) {
      titleEdit = "Editar Subcategoría"
    } else if (item) {
      titleCreate = "Agregar Subcategoría"
      titleDelete = "Eliminar Subcategoría"
    }
    return (
      <Modal
        center
        open={openModal}
        styles={{modal: {maxWidth: "90%", width: "550px"}}}
        onClose={closeModal}
      >
        <div className="w-100">
          {
            (typeAction === 1) && (
              <Fragment>
                <h4 className="text-center">{titleCreate}</h4>
                {item &&
                <h5 className="text-center weight-600">de: {item.title}</h5>
                }
              </Fragment>
            )
          }
          {
            (typeAction === 2) && (
              <Fragment>
                <h4 className="text-center">{titleEdit}</h4>
              </Fragment>
            )
          }
          {
            (typeAction === 3) && (
              <Fragment>
                <h4 className="text-center">{titleDelete}</h4>
                {item &&
                <h4 className="text-center weight-600">de: {item.title}</h4>
                }
              </Fragment>
            )
          }
          <LoadMask loading={loaderForm} light>
            <ExpenseForm
              loader={loaderForm}
              onSubmit={onSubmit}
              closeModal={closeModal}
              initialValues={typeAction === 2 ? item : {}}
            />
          </LoadMask>
        </div>
      </Modal>
    )
  }


  return (
    <div className="w-100 pt-4">
      {renderModal()}
      <h3 className="weight-600 ml-lg-3 ml-md-2 ml-sm-0 ">
        Categorías Contables
      </h3>
      <div className="bg-card-cofaqui card col-lg-7 col-md-8 col-sm-12 py-5 px-sm-0 px-lg-5 px-md-3 br-20px">
        <LoadMask loading={loader} dark blur>
          <div className="w-100 d-flex justify-content-end mb-sm-4 mb-md-2 mb-lg-2">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                selectItem(null, 1);
                setOpenModal(true);
              }}
              className="btn btn-create col-lg-3 col-md-4 col-sm-12">
              Crear categoría
            </button>
          </div>
          <div className="w-100 my-2 p-3 bg-tree container-tree">
            {
              dataCategories.map((item, index) => {
                return (
                  <ItemTree
                    key={index}
                    item={item}
                    selectItem={selectItem}
                    setOpenModal={setOpenModal}
                    openCategories={openCategories}
                  />
                )
              })
            }
          </div>
        </LoadMask>
      </div>
    </div>
  );
}

export default ExpenseCategory;
