import React from 'react';
import {Form, Field} from "react-final-form";
import {
  renderField,
} from "../../../../components/Utils/renderField";
import AddMembers from "./AddMembers"
import {fieldRequired} from "../../../../../utility/validation";
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";


const CommissionCreate = (
  {
    data,
    onSubmit,
    redirectTo,
    initialValues,
    promiseAction,
    actionBntAdd,
    actionDelete,
    defaultOption,
    setValueSelect,
    valueSelect,
  }) => {

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{submitting: true}}
        render={({handleSubmit}) =>
          <form onSubmit={handleSubmit}>
            <div className={"form-validate pt-1 pb-2 px-0 shadow-none col-lg-4 col-md-6 col-sm-12"}>
              <div className="d-flex flex-column flex-md-row">
                <div className="form-group has-feedback flex-1 mx-4">
                  <label className="text-16 font-weight-bold" htmlFor="name">Nombre de comisión</label>
                  <Field
                    name="name"
                    typeInput='text'
                    component={renderField}
                    validate={fieldRequired}
                    placeholder='Informatica'
                  />
                </div>
              </div>
            </div>
            <div>
              <AddMembers
                data={data}
                actionBntAdd={actionBntAdd}
                defaultOption={defaultOption}
                valueSelect={valueSelect}
                setValueSelect={setValueSelect}
                promiseAction={promiseAction}
                columnsToShow={[
                  {
                    title: 'No.',
                    tableHeaderProps: {
                      isKey: true,
                      width: '200px',
                      dataField: "id",
                      dataAlign: 'center',
                    }
                  },
                  {
                    title: 'Nombre',
                    tableHeaderProps: {
                      dataField: 'full_name',
                      dataAlign: 'center',
                    }
                  },
                  {
                    title: 'Profesión',
                    tableHeaderProps: {
                      dataField: 'profession',
                      dataAlign: 'center',
                    }
                  },
                  {
                    title: 'Estado',
                    tableHeaderProps: {
                      dataField: 'id',
                      dataAlign: 'center'
                    }
                  },
                  {
                    title: 'Acciones',
                    tableHeaderProps: {
                      dataField: 'id',
                      dataAlign: 'center',
                      dataFormat: standardActions({eliminarSinConfirmacion: actionDelete})
                    }
                  }
                ]}
                classNameContainerSearch="d-lg-flex d-md-flex d-sm-block flex-wrap col-xl-7 col-lg-7 col-md-7 col-sm-12"
              />
            </div>
            <BtnSaveAndCancel
              renderBtnSubmit={true}
              renderLinkCancel={true}
              redirectToLink={redirectTo}
            />
          </form>
        }/>
    </div>
  )
}

export default CommissionCreate;
