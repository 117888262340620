import React from "react";

const CustomCard = ({ children, customStyle={}, customClass, title }) => {
  return (
    <div className={`card ${customClass}`} style={customStyle}>
      <div className="card-body">
        {title && (
          <h5 className="card-title">
            {title}
          </h5>
        )}
        {children}
      </div>
    </div>
  );
}

export default CustomCard;