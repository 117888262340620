import {handleActions} from "redux-actions";
import {createReducer} from "../../baseReducer/baseReducer";
import {api} from 'api';
import {NotificationManager} from "react-notifications";

// ------------------------------------
// Constants
// ------------------------------------
const DATA_EXPENSE_CATEGORIES = "DATA_EXPENSE_CATEGORIES";
const OPEN_MODAL_EXPENSE_CATEGORIES = "OPEN_MODAL_EXPENSE_CATEGORIES";
const LOADER_MODAL_EXPENSE_CATEGORIES = "LOADER_MODAL_EXPENSE_CATEGORIES";

const baseReducer = createReducer(
  "expenseCategory",
  "expense_category",
  "expenseCategoryForm",
  "/expense-categories",
);
const {setLoader} = baseReducer.actions;

const setDataCategories = (dataCategories) => ({
  type: DATA_EXPENSE_CATEGORIES,
  dataCategories
})

const setModalValue = (openModal) => ({
  type: OPEN_MODAL_EXPENSE_CATEGORIES,
  openModal,
})

const setLoaderForm = (loaderForm) => ({
  type: LOADER_MODAL_EXPENSE_CATEGORIES,
  loaderForm,
})


// ------------------------------------
// Actions
// ------------------------------------
const create = data => (dispatch) => {
  dispatch(setLoaderForm(true));
  api.post('expense_category', data).then(() => {
    NotificationManager.success('Categoría creado', 'Éxito', 3000);
    dispatch(listCategories());
  }).catch(() => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la creación", "ERROR", 0);
    }
  }).finally(() => {
    dispatch(setLoaderForm(false));
    dispatch(setOpenModal(false))
  });
};

const edit = (id, data) => (dispatch) => {
  dispatch(setLoaderForm(true));
  api.put(`expense_category/${id}`, data).then(() => {
    NotificationManager.success("Categoría actualizada", "Éxito", 3000);
    dispatch(listCategories());
  }).catch(() => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la edición", "ERROR", 0);
    }
  }).finally(() => {
    dispatch(setLoaderForm(false));
    dispatch(setOpenModal(false))
  });
};

const deleteRecord = id => (dispatch) => {
  dispatch(setLoader(true));
  api.eliminar(`expense_category/${id}`).then(() => {
    dispatch(listCategories())
  }).catch(() => {
    NotificationManager.success('Error en la transacción', 'Éxito', 3000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const listCategories = (page = 1) => (dispatch) => {
  const params = {page};
  dispatch(setLoader(true));
  api.get('expense_category', params).then((response) => {
    dispatch(setDataCategories(response));
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const openCategories = (value) => (dispatch, getStore) => {
  const data = getStore().expenseCategory.dataCategories
  data.forEach(category => {
    if (value == category.value) {
      category.seleccionado = !category.seleccionado
    } else {
      category.children.forEach(sub => {
        if (value == sub.value) {
          sub.seleccionado = !sub.seleccionado
        } else {
          sub.children.forEach(sub1 => {
            if (value == sub1.value) {
              sub1.seleccionado = !sub1.seleccionado
            } else {
              sub1.children.forEach(sub12 => {
                if (value == sub12.value) {
                  sub12.seleccionado = !sub12.seleccionado
                } else {
                  sub12.children.forEach(sub2 => {
                    if (value == sub12.value) {
                      sub12.seleccionado = !sub12.seleccionado
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
  })
  dispatch(setDataCategories(data))
}

const setOpenModal = (openModal) => (dispatch) => {
  dispatch(setModalValue(openModal))
}

export const actions = {
  ...baseReducer.actions,
  edit,
  create,
  deleteRecord,
  setOpenModal,
  listCategories,
  openCategories,
}

const reducers = {
  ...baseReducer.reducers,
  [DATA_EXPENSE_CATEGORIES]: (state, {dataCategories}) => {
    return {
      ...state,
      dataCategories,
    }
  },
  [OPEN_MODAL_EXPENSE_CATEGORIES]: (state, {openModal}) => {
    return {
      ...state,
      openModal,
    };
  },
  [LOADER_MODAL_EXPENSE_CATEGORIES]: (state, {loaderForm}) => {
    return {
      ...state,
      loaderForm,
    };
  },
}

const initialState = {
  ...baseReducer.initialState,
  dataCategories: [],
  openModal: false,
  loaderForm: false,
}

export default handleActions(reducers, initialState);
