import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Select from "react-select";
import DatePicker from "react-date-picker";
import plus from "../../../../../assets/img/icons/circleandplus.svg";
import {
  customStylesDefault,
  DropdownIndicator,
  IndicatorSeparator,
} from "../../Utils/renderField/renderField";
import { RenderCurrency } from "../../Utils/renderField/renderReadField";
import iconReturn from "../../../../../assets/img/icons/iconreturn.svg";
import moment from "moment";

const PageTitle = ({
  title,
  withLink,
  withModal,
  returnTo,
  withReturn,
  withSearch,
  withFilter,
  withStartDateFilter,
  withEndDateFilter,
  searchAction,
  filterAction,
  filterOption,
  returnActionBtn,
  filterLabel = "",
  placeholderFilter = "Seleccionar",
  linkLabel,
  linkTo = "/",
  colTitle = "col-lg-2",
  customContainer = "w-100 flex-wrap justify-content-between mb-3 pt-5",
  showTotal,
  total = 0,
  months,
  setStartDate,
  setEndDate,
  resetPage,
  removeCols="col-md-4 col-sm-12",
  // startDate,
  // endDate,
  ...rest
}) => {
  const [value, setOpen] = useState(null);

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  return (
    <div className={customContainer}>
      <div className={`text-sm-left ${colTitle} ${removeCols} mt-3`}>
        <h1 className="page-title mb-0">{title}</h1>
        {
          withReturn &&
          <Link to={returnTo} className="ml-2 text-14">
            <img src={iconReturn} alt="Regresar" className="ml-3"/>
          </Link>
        }
        {
          returnActionBtn &&
          <button type='button' className="ml-2 text-14 border-none bg-transparent" onClick={returnActionBtn}>
            <img src={iconReturn} alt="Regresar" className="ml-3"/>
          </button>
        }
      </div>
      {withSearch && (
        <div className="text-sm-left col-lg-4 col-md-4 col-sm-12 px-0 mt-3">
          <input
            type="text"
            onChange={(event) => {
              searchAction(event.target.value);
            }}
            placeholder={"Buscar"}
            className="form-control search-input"
          />
          <i className="material-icons position-absolute icon-search">search</i>
        </div>
      )}
      {withFilter && (
        <div className="text-sm-left col-lg-3 col-md-4 col-sm-12 px-0 d-flex flex-row justify-content-start align-items-center px-0 mt-3">
          <label className="mb-0 text-16">
            &nbsp;&nbsp;{filterLabel}&nbsp;&nbsp;
          </label>
          <Select
            value={value}
            styles={customStylesDefault}
            components={{ DropdownIndicator, IndicatorSeparator }}
            isMulti={false}
            isDisabled={false}
            options={filterOption}
            placeholder={placeholderFilter}
            isClearable={false}
            backspaceRemovesValue={false}
            isSearchable={true}
            onChange={(e) => {
              setOpen(e);
              filterAction && filterAction(e);
            }}
            className={classNames("react-select-container col-9 px-0")}
          />
        </div>
      )}
      {withLink && (
        <div className="text-sm-left col-lg-2 col-md-4 col-sm-12 mt-3">
          <Link
            to={linkTo}
            className="btn w-100 btn-create text-white py-2 d-flex justify-content-center"
          >
            <img src={plus} alt="" />
            <span className="align-self-center">&nbsp;&nbsp;{linkLabel}</span>
          </Link>
        </div>
      )}
      {withModal && (
        <div className="text-sm-left col-lg-4 col-md-12 col-sm-12 mt-3">
          <button
            className="btn w-100 btn-create text-white py-2 d-flex justify-content-center"
            onClick={rest.customAction}
          >
            <img src={plus} alt="" />
            <span className="align-self-center">&nbsp;&nbsp;{rest.btnModalText}</span>
          </button>
        </div>
      )}
      {showTotal && (
        <div className="text-sm-left col-lg-2 col-md-4 col-sm-12 mt-3">
          <div className="btn w-100 card-total text-white py-2 d-flex justify-content-center">
            <span className="align-self-center font-weight-bold">
              Saldo flotante:&nbsp;&nbsp;
              <RenderCurrency className="text-right" value={total} />
            </span>
          </div>
        </div>
      )}
      {withStartDateFilter && (
        <div className="text-sm-left col-lg-4 col-md-12 col-sm-12 px-0 d-flex flex-row justify-content-start align-items-center px-0 mt-3">
          <label
            className="d-block"
            htmlFor="startDate"
          >
            Fecha de inicio
          </label>
          <DatePicker
            selected={selectedStartDate}
            onChange={(date) => {
              let finalValueDate = date;
              if (finalValueDate == null) finalValueDate = "NaN";
              else finalValueDate = moment(date).format("YYYY-MM-DD");
              setSelectedStartDate(date);
              setStartDate(finalValueDate);
              resetPage(1);
            }}
            // dateFormat="MM/yyyy"
            format={"dd/MM/yyyy"}
            showMonthYearPicker
            className="form-control"
            dayPlaceholder={"dd"}
            monthPlaceholder={"mm"}
            yearPlaceholder={"yyyy"}
            value={selectedStartDate}
          />
        </div>
      )}

      {withEndDateFilter && (
        <div className="ml-1 text-sm-left col-lg-4 col-md-12 col-sm-12 px-0 d-flex flex-row justify-content-start align-items-center px-0 mt-3">
          <label 
            className="d-block"
            htmlFor="endDate"
          >
            Fecha final
          </label>
          <DatePicker
            selected={selectedEndDate}
            onChange={(date) => {
              let finalValueDate = date;
              if (finalValueDate == null) finalValueDate = "NaN";
              else finalValueDate = moment(date).format("YYYY-MM-DD");
              setSelectedEndDate(date);
              setEndDate(finalValueDate);
              resetPage(1);
            }}
            // dateFormat="MM/yyyy"
            format={"dd/MM/yyyy"}
            showMonthYearPicker
            className="form-control"
            dayPlaceholder={"dd"}
            monthPlaceholder={"mm"}
            yearPlaceholder={"yyyy"}
            value={selectedEndDate}
          />
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default PageTitle;
