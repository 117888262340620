import {handleActions} from "redux-actions";
import {createReducer} from "../baseReducer/baseReducer";
import {push} from "react-router-redux";
// eslint-disable-next-line
import {api} from "api";
import {NotificationManager} from "react-notifications";

// ------------------------------------
// Constants
// ------------------------------------

const ROLE_LIST = "ROLE_SELECT_LIST";
const PAGE = "ROLE_SELECT_PAGE";
const ORDERING = "ROLE_SELECT_ORDERING";
const SEARCH = "ROLE_SELECT_SEARCH";
const IS_MEMBER = "THE_USER_IS_MEMBER";
const TEMPORARY_PASSWORD = "TEMPORARY_PASSWORD";
const FORM_STEP = "STAMP_FORM_STEP";
const OPEN_MODAL = "OPEN_MODAL_USER";
const SHOW_MODAL_SUCCESS_USER = "SHOW_MODAL_SUCCESS_USER__";
const NUMBER_FORM_STEPS = "NUMBER_FORM_STEPS";

export const constants = {
  ROLE_LIST,
  PAGE,
  ORDERING,
  SEARCH,
  IS_MEMBER,
  TEMPORARY_PASSWORD,
  OPEN_MODAL,
  FORM_STEP,
  NUMBER_FORM_STEPS,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

const setRoleSelectList = (data) => ({
  type: ROLE_LIST,
  data,
});

const setIsMember = (isMember) => ({
  type: IS_MEMBER,
  isMember,
});

const setRoleSelectPage = (page) => ({
  type: PAGE,
  page,
});

const setTemporaryPassword = (password) => ({
  type: TEMPORARY_PASSWORD,
  password,
});

const setOpenModal = (value) => ({
  type: OPEN_MODAL,
  value,
});

const setFormStep = (value) => ({
  type: FORM_STEP,
  value,
});

const setShowModalSuc = (showModalSuc) => ({
  type: SHOW_MODAL_SUCCESS_USER,
  showModalSuc,
});

const setNumberFormSteps = data => ({
  type: NUMBER_FORM_STEPS,
  data,
});

const baseReducer = createReducer(
  "user",
  "user",
  "UserForm",
  "/users",
);

const {setLoader} = baseReducer.actions;

// ------------------------------------
// Actions
// ------------------------------------

const createUser = (data, withSpouse, isMember) => (dispatch, getStore) => {
  dispatch(setLoader(true));
  const forms = getStore()["form"]
  let finalData = data
  if (isMember) {
    let form1 = forms.MemberStep1.values;
    let form2 = forms.MemberStep2.values;
    let step3 = forms.MemberStep3.values;
    finalData = {...form1, ...form2, spouse: step3, beneficiaries: data.customers}
    if (!withSpouse) delete finalData.spouse
  }
  api.post("user/custom_create", finalData).then(() => {
    dispatch(setShowModalSuc(true))
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la creación", "ERROR");
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const updateUser = (id, data, withSpouse) => (dispatch, getStore) => {
  dispatch(setLoader(true));
  const forms = getStore()["form"]
  let form1 = forms.MemberStep1.values;
  let form2 = forms.MemberStep2.values;
  let step3 = forms.MemberStep3.values;
  let dataEnd = {...form1, ...form2, spouse: step3, beneficiaries: data.customers}
  if (!withSpouse) {
    delete dataEnd.spouse
  }
  api.put(`user/${id}`, dataEnd).then(() => {
    dispatch(setShowModalSuc(true))
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error al actualizar", "ERROR");
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const updateSingleUser = (id, data) => (dispatch) => {
  dispatch(setLoader(true));
  api.put(`user/${id}`, {...data}).then(() => {
    dispatch(setShowModalSuc(true))
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error al actualizar", "ERROR");
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const getRoles = (page = 1) => (dispatch) => {
  dispatch(setLoader(true));
  api.get("role/get_roles_for_select", {page})
    .then((response) => {
      dispatch(setRoleSelectList(response));
      dispatch(setRoleSelectPage(page));
    }
    ).catch((error) => {
      dispatch(setLoader(false));
      const err = error.body.detail
      NotificationManager.error(err, "Error", 4000);
    }).finally(() => {
      dispatch(setLoader(false));
    });
};

const generateTemporaryPassword = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.put(`user/${id}/generate_temporary_password`).then((response) => {
    NotificationManager.success("Contraseña temporal generada", "Éxito", 3000);
    dispatch(setTemporaryPassword(response.password))
    dispatch(setOpenModal(true))
    dispatch(push("/users"));
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error al generar la contraseña temporal", "ERROR");
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const changeFormStep = (value) => (dispatch) => {
  dispatch(setFormStep(value))
}

const changeIsMember = (newValue) => (dispatch) => {
  dispatch(setIsMember(newValue))
}

const closeModal = (value) => (dispatch) => {
  dispatch(setOpenModal(value))
}

const closeModalSuc = () => (dispatch) => {
  dispatch(setShowModalSuc(false))
  dispatch(push("/users"))
}

const updateNumberFormSteps = number => (dispatch) => {
  dispatch(setNumberFormSteps(number));
};


export const actions = {
  ...baseReducer.actions,
  changeIsMember,
  closeModalSuc,
  updateUser,
  createUser,
  getRoles,
  generateTemporaryPassword,
  closeModal,
  changeFormStep,
  updateSingleUser,
  updateNumberFormSteps,
}

const reducers = {
  ...baseReducer.reducers,
  [ROLE_LIST]: (state, {data}) => {
    return {
      ...state,
      roleSelectList: data,
    };
  },
  [PAGE]: (state, {page}) => {
    return {
      ...state,
      page,
    };
  },
  [IS_MEMBER]: (state, {isMember}) => {
    return {
      ...state,
      isMember,
    };
  },
  [TEMPORARY_PASSWORD]: (state, {password}) => {
    return {
      ...state,
      temporaryPassword: password,
    };
  },
  [OPEN_MODAL]: (state, {value}) => {
    return {
      ...state,
      openModal: value,
    };
  }, [SHOW_MODAL_SUCCESS_USER]: (state, {showModalSuc}) => {
    return {
      ...state,
      showModalSuc,
    };
  },
  [FORM_STEP]: (state, {value}) => {
    return {
      ...state,
      formStep: value,
    };
  },
  [NUMBER_FORM_STEPS]: (state, { data }) => {
    return {
      ...state,
      numberFormSteps: data,
    };
  },
}

const initialState = {
  ...baseReducer.initialState,
  roleSelectList: [],
  page: 1,
  isMember: false,
  temporaryPassword: "",
  openModal: false,
  showModalSuc: false,
  formStep: 1,
  numberFormSteps: 3,
}

export default handleActions(reducers, initialState);
