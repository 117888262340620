import TransactionForm from "./Form"
import PettyCashTransactionForm from "./PettyCashTransactionForm"
import {api} from "../../../../utility/api"
import React, {useEffect, useState} from "react"
import LoadMask from "../../../components/Utils/LoadMask/LoadMask"
import TitlePageCreate from "../../../components/layout/TitleCreate"
import {NotificationManager} from "react-notifications";
import Swal from "sweetalert2";
import {PR_APPROVED, PURCHASE_REQUEST_STATUS} from "../../../../utility/constants";


const CreateCashRegister = (props) => {
  const [cashierOpt, setCashier] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [dataProducts, setDataProducts] = useState([])
  const [itemSelected, setItemSelected] = useState(null)
  const [defaultOptionProd, setDefaultOptionProd] = useState([])
  const [defaultOptionMembers, setDefaultOptionMembers] = useState([])
  const [professionMember, setProfessionMember] = useState(null)
  const [initialData, setInitialData] = useState({transaction_date: new Date()})
  const [lockBtnAdd, setLockBtnAdd] = useState(false)

  const {
    form: {CashRegister},
    loader, createTrans,
    me, cashRegister,
    validateCurrentCash, dateOpeningCR,
    typeCashRegister, openCategories,
    categories, getCategories,
    createCashOutflow, getOriginOrDestinationBanks,
    originBanksList, destinationBanksList,
  } = props

  useEffect(() => {
    if (me && me.id) {
      setCashier([{label: me.full_name, value: me.id}])
      setInitialData({cashier_id: me.id, ...initialData})
    }
  }, [me])

  useEffect(() => {
    if (!cashRegister) {
      validateCurrentCash()
    }
  }, [cashRegister])

  useEffect(() => {
    // set default values
    setDefaultOptionMembers([])
    setDataProducts([])
    setItemSelected(null)
    loadMembers().then((_data) => {
      setDefaultOptionMembers(_data)
    })
  }, [])

  useEffect(() => {
    if (professionMember) {
      loadProducts().then((_data) => {
        setDefaultOptionProd(_data)
      })
    }
  }, [professionMember])

  const selectMember = (itemSel) => {
    setProfessionMember(itemSel.profession_id)
    // reset default values
    setTotalAmount(0)
    setDataProducts([])
    setItemSelected(null)
  }

  const loadProducts = (search = "") => {
    const params = {search, profession_id: professionMember}
    return api.get("product/products_and_stamps", params).then((resp) => {
      let _options = [];
      if (resp && resp.length) _options = resp
      return _options
    }).catch((err) => {
      if (err && err.details) {
        NotificationManager.error(err.details, "Error", 8000)
      }
      console.log("Error to load products", err)
    }).finally(() => {
    });
  }

  const loadMembers = (search = "") => {
    return api.get("member", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch((err) => {
      console.log("Error to load members", err)
    }).finally(() => {
    });
  }

  function getSumAmount(accumulated, item) {
    let quantity = item.quantity ? parseInt(item.quantity) : 0
    return parseFloat(accumulated) + (parseFloat(item.amount) * quantity);
  }

  function addOrderToItem(arrayProd) {
    for (const arrayProdKey in arrayProd) {
      arrayProd[arrayProdKey].order = parseInt(arrayProdKey) + 1
    }
  }

  function _finalAddProd(code, rest) {
    let dataCopy = dataProducts
    if(rest) dataCopy = []
    if (dataCopy.find(element => (element.id === itemSelected.id && element.is_stamp === itemSelected.is_stamp)) === undefined) {
      dataCopy.push({...itemSelected, quantity: 1, code})
      addOrderToItem(dataCopy)
      setDataProducts(dataCopy)
      setItemSelected(null)
    }
    setTotalAmount(dataCopy.reduce(getSumAmount, 0))
  }

  const validateProduct = () => {
    Swal.fire({
      title: "Producto bajo aprobación",
      icon: "warning",
      text: "Este es un producto bajo aprobación, por lo que debe ingresar el código de la solicitud de compra",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      allowEscapeKey: false,
      cancelButtonText: "<span class=\"material-icons float-left lh-18px ml-2\">close</span>&nbsp;Cancelar&nbsp;&nbsp;",
      confirmButtonText: "<span class=\"material-icons float-left lh-18px ml-2\">done</span>&nbsp;Validar&nbsp;&nbsp;&nbsp;&nbsp;",
      showLoaderOnConfirm: true,
      focusCancel: false,
      focusConfirm: false,
      reverseButtons: true,
      background: "#FFFFFF",
      customClass: {
        cancelButton: "btn btn-gray px-3 br-20px",
        confirmButton: "btn btn-success-important px-3 br-20px",
        input: "form-control col-10 mx-auto",
        validationMessage: "text-center text-14",
      },
      preConfirm: (inputValue) => {
        const params = {
          purchase_request_code: inputValue,
          member_id: CashRegister.values.member_id,
        }
        return api.get("purchase_request/status_by_code", params)
          .then(response => {
            if (response && response.purchase_request_status !== PR_APPROVED) {
              const status = PURCHASE_REQUEST_STATUS.find(ele => ele.value == response.purchase_request_status).label
              Swal.showValidationMessage(`El producto: ${itemSelected.name}, no ha sido aprobado. Estado actual: ${status}`)
            } else {
              setItemSelected({...itemSelected, purchase_request_code: response.purchase_code})
              return {res: response, code: inputValue}
            }
          })
          .catch(error => {
            console.log("error en la validación", error)
            if (error && error.body && error.body.details) {
              Swal.showValidationMessage(error.body.details)
            } else if (error && error.details) {
              Swal.showValidationMessage(error.details)
            } else {
              Swal.showValidationMessage("Error al validar el estado de la solicitud!")
            }
          })
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setTotalAmount(0)
        setDataProducts([])
        setLockBtnAdd(true)
        _finalAddProd(result.value.code, true)
      }
      else {
        setItemSelected(null)
      }
    })
  }

  const AddProduct = () => {
    if (itemSelected) {
      if (itemSelected.under_approved == true) {
        validateProduct()
      } else {
        _finalAddProd()
      }
    }
  }

  const changeQuantityItem = (row, newQuantity) => {
    if (row) {
      let dataCopy = dataProducts
      const index = dataCopy.indexOf(row)
      if (dataCopy.indexOf(row) !== -1) {
        dataCopy[index].quantity = newQuantity
        setDataProducts(dataCopy)
      }
      setTotalAmount(dataCopy.reduce(getSumAmount, 0))
    }
  }

  const ActionDeleteItem = (idItem) => {
    const result = dataProducts.filter(item => item.order !== idItem);
    const prod = dataProducts.find(item => item.order === idItem);
    if (prod && prod.under_approved) {
      setLockBtnAdd(false)
    }
    addOrderToItem(result)
    setDataProducts(result)
    setTotalAmount(result.reduce(getSumAmount, 0))
  }

  const actionSubmit = (data) => {
    let errors = []
    for (const dataKey in dataProducts) {
      if (dataProducts[dataKey].quantity === "" || dataProducts[dataKey].quantity == 0) {
        errors.push(dataProducts[dataKey].id)
      }
    }
    if (errors.length) {
      NotificationManager.error(`Debe ingresar la cantidad a vender de los elementos: [${errors}]`, "Error", 9000)
    } else if (dataProducts.length === 0) {
      NotificationManager.error("Debe ingresar al menos un producto", "Error", 4000)
    } else {
      createTrans({...data, cash_register_id: cashRegister, amount: totalAmount, cashier: me.id, products: dataProducts})
    }
  }

  const dischargeActionSubmit = (data) => {
    createCashOutflow({...data, cash_register: cashRegister, amount: totalAmount, cashier: me.id})
  }


  return (
    <div className="pt-5">
      <TitlePageCreate
        redirectTo="/my-cash-register"
        title={`Caja (${(me && me.full_name) ? me.full_name : ""}) - Registro de ${typeCashRegister === 5 ? "ingreso" : "egreso"}`}
      />
      <LoadMask dark blur loading={loader}>
        {
          typeCashRegister === 5 && (
            <TransactionForm
              cashier={cashierOpt}
              total={totalAmount}
              lockBtnAdd={lockBtnAdd}
              onSubmit={actionSubmit}
              actionBntAdd={AddProduct}
              loadMembers={loadMembers}
              itemSelected={itemSelected}
              setItemSel={setItemSelected}
              dataProducts={dataProducts}
              loadProducts={loadProducts}
              initialData={initialData}
              setMember={selectMember}
              minDate={dateOpeningCR}
              actionDelete={ActionDeleteItem}
              defaultOptionProd={defaultOptionProd}
              changeQuantityItem={changeQuantityItem}
              defaultOptionMembers={defaultOptionMembers}
              getOriginOrDestinationBanks={getOriginOrDestinationBanks}
              originBanksList={originBanksList}
              destinationBanksList={destinationBanksList}
            />
          )
        }
        {
          typeCashRegister === 1 && (
            <PettyCashTransactionForm
              cashier={cashierOpt}
              total={totalAmount}
              onSubmit={dischargeActionSubmit}
              actionBntAdd={AddProduct}
              loadMembers={loadMembers}
              itemSelected={itemSelected}
              setItemSel={setItemSelected}
              dataProducts={dataProducts}
              loadProducts={loadProducts}
              initialData={initialData}
              setMember={selectMember}
              minDate={dateOpeningCR}
              actionDelete={ActionDeleteItem}
              defaultOptionProd={defaultOptionProd}
              changeQuantityItem={changeQuantityItem}
              defaultOptionMembers={defaultOptionMembers}
              openCategories={openCategories}
              categories={categories}
              getCategories={getCategories}
            />
          )
        }
      </LoadMask>
    </div>
  )
}

export default CreateCashRegister
