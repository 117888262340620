import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
// import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import { addIndexToArray } from "../../../../utility/constants";

const MEMBER_INVENTORY_LIST = "MEMBER_INVENTORY_LIST";
const LOADER = "MEMBER_INVENTORY_LOADER";
const PAGE = "MEMBER_INVENTORY_PAGE";
const ORDERING = "MEMBER_INVENTORY_ORDERING";
const SEARCH = "MEMBER_INVENTORY_SEARCH";
const ID_MEMBER_INVENTORY = "ID_MEMBER_INVENTORY";
const HAS_CUSTOM_CONTENT = "HAS_CUSTOM_CONTENT";
const CUSTOM_CONTENT = "CUSTOM_CONTENT";
const ID_DOCUMENT = "ID_DOCUMENT";

export const constants = {
  MEMBER_INVENTORY_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
  ID_MEMBER_INVENTORY,
  HAS_CUSTOM_CONTENT,
  CUSTOM_CONTENT,
  ID_DOCUMENT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setMemberInventoryList = inventoryDocuments => ({
  type: MEMBER_INVENTORY_LIST,
  inventoryDocuments,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

// eslint-disable-next-line
const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

// eslint-disable-next-line
const setSearch = search => ({
  type: SEARCH,
  search,
});

export const setIdMemberInventory = idInventory => ({
  type: ID_MEMBER_INVENTORY,
  idInventory,
});

export const setHasCustomContent = hasCustomContent => ({
  type: HAS_CUSTOM_CONTENT,
  hasCustomContent,
});

export const setCustomContent = customContent => ({
  type: CUSTOM_CONTENT,
  customContent,
});

export const setIdDocument = idDocument => ({
  type: ID_DOCUMENT,
  idDocument,
});

// ------------------------------------
// Actions
// ------------------------------------

const listMemberInventory = (page = 1) => (
  dispatch,
  getStore
) => {
  const params = { page };
  const { idInventory } = getStore().inventoryDocumens;
  dispatch(setLoader(true));
  api.get(`inventory_documents/${idInventory}/get_inventory_documents`, params).then(response => {
    if (response.results) response.results = addIndexToArray(response.results, page);
    dispatch(setMemberInventoryList(response));
    dispatch(setPage(page));
  }).catch(() => {
    NotificationManager.error(
      "Error al obtener la lista de documentos",
      "ERROR",
      5000
    );
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const assignMemberInventory = (idInventory) => (
  dispatch,
//   getStore
) => {
  dispatch(setIdMemberInventory(idInventory));
};

const printPDF = archive => () => {
  // if (archive.base64) {
  //   const dataURI = `data:application/pdf;base64,${archive}`;
  //   const win = window.open();
  //   win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
  // } else {
  fetch(archive.url).then(function (response) {
    response.blob().then(function (blob) {
      var reader = new FileReader();
      reader.onload = function () {
        // printJS({
        //   printable: reader.result.substring(28),
        //   type: "pdf",
        //   base64: true,
        // });
        const dataURI = `data:application/pdf;base64,${reader.result.substring(28)}`;
        const win = window.open();
        if (win) {
          win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
        }
      }
      reader.readAsDataURL(blob);
    });
  });
  // }
};

const generatePDF = (id, customContent=null) => (
  dispatch,
) => {
  const data = { id };
  if (customContent) data.custom_content = customContent;

  dispatch(setLoader(true));
  api.post("inventory_documents/generate_pdf", data).then(response => {
    if (!response.has_custom_content) {
      setTimeout(() => {
        printPDF(response)();
      }, 500);
    } else {
      dispatch(setCustomContent(response.custom_content_keys));
      dispatch(setHasCustomContent(true));
      dispatch(setIdDocument(id));
    }
  }).catch(() => {
    NotificationManager.error(
      "Error al generar el PDF",
      "ERROR",
      5000
    );
  }).finally(() => {
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 500);
  });
};

const closeCustomContentModalAction = () => (
  dispatch,
) => {
  dispatch(setHasCustomContent(false));
};

const openCustomContentModalAction = () => (
  dispatch,
) => {
  dispatch(setHasCustomContent(true));
};

export const actions = {
  listMemberInventory,
  assignMemberInventory,
  generatePDF,
  closeCustomContentModalAction,
  openCustomContentModalAction,
}

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [MEMBER_INVENTORY_LIST]: (state, { inventoryDocuments }) => {
    return {
      ...state,
      inventoryDocuments,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [ID_MEMBER_INVENTORY]: (state, { idInventory }) => {
    return {
      ...state,
      idInventory,
    };
  },
  [HAS_CUSTOM_CONTENT]: (state, { hasCustomContent }) => {
    return {
      ...state,
      hasCustomContent,
    };
  },
  [CUSTOM_CONTENT]: (state, { customContent }) => {
    return {
      ...state,
      customContent,
    };
  },
  [ID_DOCUMENT]: (state, { idDocument }) => {
    return {
      ...state,
      idDocument,
    };
  },
};

export const initialState = {
  loader: false,
  inventoryDocuments: {
    count: 0,
    results: [],
  },
  page: 1,
  ordering: "",
  search: "",
  idInventory: null,
  hasCustomContent: false,
  customContent: [],
  idDocument: null,
};

export default handleActions(reducers, initialState);