import React, { useEffect } from "react";
import PettyCashEntryForm from "./PettyCashEntryForm";
import PageTitle from "../../../components/layout/PageTitle";

import "./pettyCash.css";

const PettyCashEntry = (props) => {
  const {
    loader,
    crear,
    me,
    cashRegister,
    validateCurrentCash,
    dateOpeningCR,
    typeCashRegister,
    openCategories,
    categories,
    getCategories,
    createCashOutflow,
    createPettyCashIncome,
  } = props;

  useEffect(() => {
    validateCurrentCash();
  }, []);

  const onSubmit = (data) => {
    createPettyCashIncome({...data, cash_register: cashRegister});
  };
        
  return (
    <div className="">
      <PageTitle
        title={`Caja (${me && me.full_name})`}
        colTitle="col-lg-4 col-xl-3"
      />
      <PettyCashEntryForm
        loader={loader}
        crear={crear}
        me={me}
        validateCurrentCash={validateCurrentCash}
        dateOpeningCR={dateOpeningCR}
        typeCashRegister={typeCashRegister}
        openCategories={openCategories}
        categories={categories}
        getCategories={getCategories}
        createCashOutflow={createCashOutflow}
        createPettyCashIncome={onSubmit}
      />
    </div>
  );
}

export default PettyCashEntry;