import React, {Component} from "react";
import LoadMask from "../LoadMask/LoadMask";
import {BootstrapTable} from "react-bootstrap-table";


export default class Grid extends Component {

  static defaultProps = {
    loading: false
  };

  render() {
    const {
      loading,
      data,
      page,
      remote = true,
      expandableRow,
      expandComponent,
      onlyOneExpanding=false,
      cellEditProp,
      afterSave,
      onPageChange,
      onSortChange,
      pagination,
      expanding,
      onExpand,
      trClassName,
      bodyContainerClass = "bc-custom",
      headerContainerClass = "hc-custom",
      tableContainerClass = "container-table-sm",
      tableHeaderClass = "table-header-cofaqui",
      ...other
    } = this.props;

    const renderShowsTotal = (start, to, total) => {
      return (
        <div
          className="col-sm-12 col-lg-6 d-flex justify-content-lg-start justify-content-sm-center justify-content-center px-0">
          <div className="text-16 table-info-result mb-lg-0 mb-md-0 mb-sm-3"> Mostrando {to} de {total} resultados</div>
        </div>
      );
    }
    const options = {
      page,
      sizePerPage: 10,
      paginationSize: 3,
      onExpand: onExpand,
      dataSize: data.count,
      expandRowBgColor: 'rgb(238,235,235)',
      expandBodyClass: 'not-hover-div',
      onlyOneExpanding: onlyOneExpanding,
      expandBy: 'row',  // Currently, available value is row and column, default is row
      sizePerPageDropDown: () => <div className="d-none"></div>,
      withFirstAndLast: true,
      // alwaysShowAllBtns: true,
      paginationShowsTotal: renderShowsTotal,
      noDataText: loading ? "Cargando..." : "No hay datos",
      onPageChange: onPageChange ? onPageChange : () => {
      },
      onSortChange: onSortChange ? onSortChange : () => {
      },
      lastPage: <i className="material-icons last-page-size">last_page</i>,
      firstPage: <i className="material-icons last-page-size">first_page</i>,
      nextPage: <i className="material-icons last-page-size">keyboard_arrow_right</i>,
      prePage: <i className="material-icons last-page-size">keyboard_arrow_left</i>,
    };

    function rowClassNameFormat(row, rowIdx) {
      // return rowIdx % 2 === 0 ? 'td-column-even' : 'td-column-odd';
      return "td-column-even";
    }

    const paginar = !(pagination === false);

    return (
      <div>
        <LoadMask loading={loading} dark blur>
          <BootstrapTable
            expandableRow={expandableRow}
            tableContainerClass={tableContainerClass}
            bodyContainerClass={bodyContainerClass}
            headerContainerClass={headerContainerClass}
            expandComponent={expandComponent}
            tableHeaderClass={tableHeaderClass}
            trClassName={trClassName ? trClassName : rowClassNameFormat}
            cellEdit={cellEditProp}
            data={loading ? [] : data.results}
            afterSaveCell={afterSave}
            remote={remote}
            pagination={paginar}
            fetchInfo={{dataTotalSize: data.count}}
            options={options}
            {...other}
          />
        </LoadMask>
      </div>
    )
  }
}
