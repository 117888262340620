import React from "react";
import CustomCard from "../CustomCard";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import ReduxConnection from "../../../../../components/ReduxFormConn/FinalFormConnection";
import { Form } from "react-final-form";
import BtnSaveAndCancel from "../../../../../components/layout/BtnSaveAndCancel";
import validate from "../validations";

import { NotificationManager } from "react-notifications";

// import { Field } from "react-final-form";

const values = {
  required_forms: [
    {
      name: null,
      type_form: null,
      archive: null,
    }
  ],
};

const buttonProps = (form, prevStep) => {
  if (form.type == "edit") {
    return {
      renderLinkCancel: true,
      redirectToLink: "/stamps",
      renderBtnSubmit: true,
    };
  } else {
    return {
      renderBtnNext: true,
      renderBtnPrev: true,
      actionBntPrev: prevStep,
    };
  }
};


const Forms = ({
  // form: {
  //   mutators: { push },
  // },
  type,
  // updateNumberDocuments,
  // numberDocuments,
  nextStep,
  prevStep,
  step,
  form,
  // edit,
  initialValues,
  createOrUpdateStamp,
  match,
}) => {

  // ########################################
  // Este el paso que esta entre el
  // paso 2 y el paso 3
  // Para CAYCEQ y TQB
  // ########################################

  // const [document, setDocument] = useState([])

  // useEffect(() => {
  //   if (type === "create" && numberDocuments < 1) {
  //     push("documents", {
  //       name: null,
  //       type: null,
  //     });
  //     updateNumberDocuments(1);
  //   }
  // }, []);

  const addDocument = (push) => {
    // setDocument([...document, { id: document.length + 1 }]);
    push("required_forms", {
      name: null,
      type_form: null,
      archive: null,
    });
  };

  const removeDocument = (id, fields, documents) => {
    // setDocument(document.filter((item) => item.id !== id));
    if (documents.length === 1) {
      return NotificationManager.error(
        "Debe tener al menos un documento",
        "Error",
        3000
      );
    }
    fields.remove(id);
  };

  return (
    <Form
      onSubmit={
        type == "edit" ? (values) => {
          const { id } = match.params;
          createOrUpdateStamp(id, values);
        } :
          nextStep
      }
      initialValues={
        type == "edit" ?
          initialValues :
          values
      }
      validate={(values) => validate(values, 3)}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form: { mutators: { push }, change } ,
        values: { documents },
        values
      }) => (
        <form onSubmit={handleSubmit} className={`${step === 3 && form.step == "create" || (form.type == "edit" && form.step == 3) ? "d-block" : "d-none"}`}>
          <ReduxConnection form="stamps_step_2_3" />
          <div className="w-100 p-3 p-lg-5 stamps-form">
            <h5
              style={{
                color: "#4B4D58",
                fontWeight: "500",
              }}
            >
              Agregar documentos
            </h5>
            <FieldArray
              name="required_forms"
            >
              {
                ({ fields }) => (
                  fields.map((name, index) => (
                    <CustomCard
                      key={name}
                      id={index}
                      name={name}
                      removeDocument={() => removeDocument(index, fields, documents)}
                      withArchive={true}
                      otherTypes={true}
                      activateTitle={true}
                      change={change}
                    />
                  ))
                )
              }
            </FieldArray>
            <div className="row col-12 justify-content-center mt-4">
              <button
                type="button"
                className="btn btn-add-document"
                onClick={() => addDocument(push)}
              >
                <i className="icon-plus">
                  <img src={require("assets/img/add_circle_green.svg")} alt="icono-mas" />
                </i>
                <span
                  style={{
                    color: "#0A4725",
                  }}
                >
                  &nbsp;
                  Añadir documento
                </span>
              </button>
            </div>
          </div>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          <BtnSaveAndCancel
            // renderBtnNext={true}
            // renderBtnPrev={true}
            // actionBntPrev={prevStep}
            {
            ...buttonProps(form, prevStep)
            }
          />
        </form>
      )}
    />
  );
}

export default Forms;
