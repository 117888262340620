import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";

const ROLE_DATA = "EXAMPLE_DATA";
const ROLE_LIST = "ROLE_LIST";
const LOADER = "ROLE_LOADER";
const PAGE = "ROLE_PAGE";
const ORDERING = "ROLE_ORDERING";
const SEARCH = "ROLE_SEARCH";

export const constants = {
  ROLE_DATA,
  ROLE_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setRoleData = data => ({
  type: ROLE_DATA,
  data,
});

export const setRoleList = data => ({
  type: ROLE_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

const setSearch = search => ({
  type: SEARCH,
  search,
});

// ------------------------------------
// Actions
// ------------------------------------

const createRole = (data) => (dispatch) => {
  dispatch(setLoader(true));
  api.post("role", { data })
    .then(() => {
      dispatch(push("/permissions-roles"));
      NotificationManager.success("Rol creado exitosamente", "Success", 3000);
    }
    ).catch((error) => {
      if (error && error.detail) {
        NotificationManager.error(error.detail, "Error", 4000);
      } else {
        NotificationManager.error("Error al crear el rol", "Error", 4000);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
};

const getRoles = (page = 1) => (dispatch, getStore) => {
  const resource = getStore().permissionsAndRoles;
  const params = { page };

  if (resource.ordering) {
    params.ordering = resource.ordering;
  }
  if (resource.search) {
    params.search = resource.search;
  }

  dispatch(setLoader(true));
  api.get("role", params)
    .then((response) => {
      dispatch(setRoleList(response));
      dispatch(setPage(page));
      // NotificationManager.success("Información cargada exitosamente", "Success", 3000);
    }
    ).catch((error) => {
      dispatch(setLoader(false));
      const err = error.body.detail
      NotificationManager.error(err, "Error", 4000);
    }).finally(() => {
      dispatch(setLoader(false));
    });
};

const getRole = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`role/${id}`)
    .then((response) => {
      dispatch(setRoleData(response));
      // NotificationManager.success("Example loaded", "Success", 3000);
    }
    ).catch((error) => {
      const err = error.body.detail
      NotificationManager.error(err, "Error", 4000);
    }).finally(() => {
      dispatch(setLoader(false));
    });
};

const updateRole = (id, data) => (dispatch) => {
  dispatch(setLoader(true));
  api.put(`role/${id}`, { data })
    .then(() => {
      dispatch(push("/permissions-roles"));
      NotificationManager.success("Rol actualizado exitosamente", "Success", 3000);
    }
    ).catch((error) => {
      if (error && error.detail) {
        NotificationManager.error(error.detail, "Error", 4000);
      } else {
        NotificationManager.error("Error al actualizar el rol", "Error", 4000);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
};

const deleteRole = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.eliminar(`role/${id}`)
    .then(() => {
      dispatch(getRoles());
      NotificationManager.success("Rol eliminado exitosamente", "Success", 3000);
    }
    ).catch((error) => {
      if (error && error.detail) {
        NotificationManager.error(error.detail, "Error", 4000);
      } else {
        NotificationManager.error("Error al eliminar el Rol", "Error", 4000);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
};

const searchRole = (search) => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getRoles());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
  const sort = getStore().permissionsAndRoles.ordering;
  if (ordering === sort) {
    dispatch(setOrdering(`-${ordering}`));
  } else {
    dispatch(setOrdering(ordering));
  }
  dispatch(getRoles());
};

export const actions = {
  createRole,
  getRoles,
  getRole,
  updateRole,
  deleteRole,
  onSortChange,
  searchRole,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [ROLE_DATA]: (state, { data }) => {
    return {
      ...state,
      roleData: data,
    };
  },
  [ROLE_LIST]: (state, { data }) => {
    return {
      ...state,
      roleList: data,
    };
  },
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  }
};

export const initialState = {
  loader: false,
  roleData: {},
  roleList: {
    results: [],
    count: 0,
  },
  page: 1,
  ordering: "",
  search: "",
};

export default handleActions(reducers, initialState);
