import React, { Fragment, useEffect } from "react";
//Components
import PageTitle from "../../components/layout/PageTitle";
import LoadMask from "../../components/Utils/LoadMask/LoadMask";
import {
  ResumenAgremiados,
  EgresosIngresos,
  ResumenDepartamentos,
  TimbresVendidos,
  GastosSeguros,
  TopTransacciones,
} from "./Components";

import "./stylesDashboard.css";

const CardDashboard = ({ children, is_loading = false, customClassName }) => {
  return (
    <div className={`bg-card-cofaqui-light br-10px p-2 ${customClassName}`}>
      <LoadMask
        loading={is_loading}
        styleCustom={{
          height: "100%",
          backgroundColor: "#FAFDF3",
          borderRadius: "10px",
        }}
        type="TailSpin"
        light
        radius
      >
        {children}
      </LoadMask>
    </div>
  );
};

const Dashboard = (props) => {
  //State
  const {
    is_loader_status_members,
    status_members,
    is_loader_members_departments,
    members_departments,
    icome_expenses,
    age_icome_expenses_select,
    is_loader_icome_expenses,
    stamps_sold,
    is_loader_stamps_sold,
    stamps_sold_month_select,
    stamps_sold_year_select,
    is_loader_expenses_insurance,
    expenses_insurance,
    expenses_insurance_year_select,
    expenses_insurance_month_select,
    top_transactions,
    is_loader_top_transactions,
    top_transactions_month_select,
    top_transactions_year_select,
  } = props;

  //Actions
  const {
    getMembersStatus,
    getMembersDepartments,
    getIcomeExpenses,
    getIcomeExpensesByYear,
    getStampsSold,
    getStampsSoldByYear,
    getStampsSoldByMonth,
    getExpencesInsurances,
    getExpencesInsurancesByYear,
    getExpencesInsurancesByMonth,
    getTopTransactions,
    getTopTransactionsByYear,
    getTopTransactionsByMonth,
  } = props;

  // Cuando se monta el componente
  useEffect(() => {
    getMembersStatus();
    getMembersDepartments();
    getIcomeExpenses(age_icome_expenses_select);
    getStampsSold(stamps_sold_year_select, stamps_sold_month_select);
    getExpencesInsurances(
      expenses_insurance_year_select,
      expenses_insurance_month_select
    );
    getTopTransactions(
      top_transactions_year_select,
      top_transactions_month_select
    );
  }, []);

  return (
    <Fragment>
      <PageTitle
        title="Dashboard"
        subtitle="My Dashboard"
        customContainer="w-100 flex-wrap justify-content-between mb-3 pt-4"
      />
      <div className="p-4 br-20px parent-dashboard bg-card-cofaqui">
        <CardDashboard 
          is_loading={is_loader_status_members}
          customClassName="char-container"
        >
          <ResumenAgremiados datos={status_members} />
        </CardDashboard>

        <CardDashboard
          customClassName="char-container" 
          is_loading={is_loader_icome_expenses}
        >
          <EgresosIngresos
            datos={icome_expenses}
            age_icome_expenses_select={age_icome_expenses_select}
            getIcomeExpensesByYear={getIcomeExpensesByYear}
          />
        </CardDashboard>

        <CardDashboard 
          is_loading={is_loader_members_departments}
          customClassName="char-container" 
        >
          <ResumenDepartamentos datos={members_departments} />
        </CardDashboard>

        <CardDashboard 
          is_loading={is_loader_stamps_sold}
          customClassName="char-container" 
        >
          <TimbresVendidos
            datos={stamps_sold}
            stamps_sold_month_select={stamps_sold_month_select}
            stamps_sold_year_select={stamps_sold_year_select}
            getStampsSoldByMonth={getStampsSoldByMonth}
            getStampsSoldByYear={getStampsSoldByYear}
          />
        </CardDashboard>

        <CardDashboard 
          is_loading={is_loader_expenses_insurance}
          customClassName="char-container" 
        >
          <GastosSeguros
            datos={expenses_insurance}
            expenses_insurance_year_select={expenses_insurance_year_select}
            expenses_insurance_month_select={expenses_insurance_month_select}
            getExpencesInsurancesByYear={getExpencesInsurancesByYear}
            getExpencesInsurancesByMonth={getExpencesInsurancesByMonth}
          />
        </CardDashboard>

        <CardDashboard 
          is_loading={is_loader_top_transactions}
          
        >
          <TopTransacciones
            datos={top_transactions}
            top_transactions_month_select={top_transactions_month_select}
            top_transactions_year_select={top_transactions_year_select}
            getTopTransactionsByYear={getTopTransactionsByYear}
            getTopTransactionsByMonth={getTopTransactionsByMonth}
          />
        </CardDashboard>
      </div>
    </Fragment>
  );
};

export default Dashboard;
