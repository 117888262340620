import React from 'react'
import { connect } from 'react-redux'
import ListMastersBeneficiary from './ListMastersBeneficiary'
import { actions } from '../../../../redux/modules/beneficiary_masters'

const mapStateToProps = (state) => ({
  ...state.masterReducer
})

const mapDispatchToProps = {
  ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(ListMastersBeneficiary)