import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import ConfirmationModal from "./ConfirmationModal"
import TransactionTable from "../List/TransactionTable"
import TransactionTablePettyCash from "../List/TransactionTablePettyCash"

const ClosingCashReg = (props) => {
  const {
    data, loader, 
    page, transactionList, 
    onPageChange, onSortChange, 
    annulation, closingCashRegister, 
    typeCashRegister
  } = props
  const [openModal, setOpenModal] = useState(false)

  const toggleBtnModal = () => setOpenModal(!openModal)
  const openModalAction = () => setOpenModal(true)

  useEffect(() => {
    transactionList(1)
  }, [])

  return (
    <div className="pt-5">
      <ConfirmationModal
        openModal={openModal}
        item={{name: "name"}}
        onSubmit={closingCashRegister}
        closeModalAction={toggleBtnModal}
      />
      <div className="text-left w-100 mt-3 d-flex flex-wrap mb-2">
        <h1 className="page-title mb-0 pl-3">Detalle de cierre de caja</h1>
        <span className="align-self-center ml-lg-4 ml-md-3 ml-sm-auto font-weight-bolder">
          IMPORTANTE: Por favor revise el detalle de las transacciones con las que se cerrará la caja ya que esta acción es irreversible.
        </span>
      </div>
      {/* ----------------------------------------------------*/}
      {/* ----------------  TABLE TRANSACTIONS  --------------*/}
      {/* ----------------------------------------------------*/}
      <div className="card bg-card-cofaqui br-20px">
        <div className="px-4 pt-4">
          {
            typeCashRegister === 1 ?
              <TransactionTablePettyCash
                data={data}
                page={page}
                loader={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                annulationAction={annulation}
                getTransactionList={transactionList}
              />
              :
              <TransactionTable
                data={data}
                page={page}
                loader={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                annulationAction={annulation}
              />
          }
        </div>
      </div>
      <div className="w-100 pt-5">
        <center>
          <h5 className="text-center font-weight-bold" style={{color: "#4B4D58"}}>
            ¿Está seguro que desea realizar el cierre de esta caja?
          </h5>
        </center>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            onClick={openModalAction}
            className="btn btn-red col-lg-2 col-md-3 col-sm-12 px-0"
          >
            <span className="material-icons" style={{fontSize: "20px", weight: 200}}>done</span>
            &nbsp;&nbsp;Si, confirmar
          </button>
          <div className="">
            &nbsp;&nbsp;
          </div>
          <Link to="/my-cash-register" className="btn btn-gray col-lg-2 col-md-3 col-sm-12 px-0">
            <span className="material-icons" style={{fontSize: "20px", weight: 200}}>close</span>
            &nbsp;&nbsp;No, cancelar
          </Link>
        </div>
      </div>
    </div>
  )
}


export default ClosingCashReg
