import {connect} from "react-redux"
import List from "./List"
import {combineActions} from "../../../../../redux/modules/administration/adminCashRegister"

const ms2p = (state)=>{
  return {
    ...state.adminCashRegister,
  }
}

const md2p = {...combineActions}

export default connect(ms2p, md2p)(List)
