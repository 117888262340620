import React, { useState, useEffect } from "react";
import { simpleRenderField, selectField, SimpleRenderFieldCheck } from "../../../../../components/Utils/renderField";
import { renderFilePicker } from "../../../../../components/Utils/renderField/renderField";
import { Field } from "react-final-form";
import Editor from "../Editor";
import ReduxConnection from "../../../../../components/ReduxFormConn/FinalFormConnection";
import { Form } from "react-final-form";
import BtnSaveAndCancel from "../../../../../components/layout/BtnSaveAndCancel";
import validate from "../validations";
import { formValueSelector } from "redux-form";
import { SHEET_FORMAT, EDITOR_VARIABLE } from "../../../../../../utility/constants";
import { connect } from "react-redux";

let Step3 = ({
  setDocument,
  document,
  // photo,
  createOrUpdateStamp,
  prevStep,
  step,
  form,
  icon,
  is_print_stamp,
  edit,
  initialValues = {},
  match,
}) => {
  const [srcFile, setSrcFile] = useState("");

  const [documentOrientation, setDocumentOrientation] = useState(1);

  const [docTitle, setDocTitle] = useState("")

  const [deleteBackground, setDeleteBackground] = useState(false);

  const [customContent, setCustomContent] = useState(false);

  useEffect(() => {
    initialValues.background = "";
  }, []);

  useEffect(() => {
    if (initialValues.background) {
      setSrcFile(initialValues.background);
    }
  }, [initialValues.background]);

  useEffect(() => {
    if (initialValues.document_orientation) {
      setDocumentOrientation(initialValues.document_orientation)
    }
  }, [initialValues.document_orientation])

  useEffect(() => {
    if (initialValues.title) {
      setDocTitle(initialValues.title)
    }
  }, [initialValues.title])

  const buttonProps = () => {
    if (edit || form.type === "create") {
      return {
        renderLinkCancel: true,
        redirectToLink: "/stamps",
      };
    } else {
      return {
        renderBtnPrev: true,
        actionBntPrev: prevStep,
      };
    }
  };

  return (
    <Form
      onSubmit={
        form.type == "edit" ?
          (values) => {
            const { id } = match.params;
            createOrUpdateStamp(id, values);
          } :
          (values) => {
            const { id } = match.params;
            createOrUpdateStamp(id, values)
          }
      }
      initialValues={initialValues}
      validate={(values) => validate(values, 3)}
      render={({ handleSubmit, form: { change }, values: { background } }) => (
        <form onSubmit={handleSubmit} className={`${step === 4 || ((form.type == "edit" || form.type == "create") && form.step == 4) ? "d-block" : "d-none"}`}>
          <ReduxConnection form="stamps_step_3" />
          <div className="row justify-content-between stamps-form p-3 p-lg-4">
            <div className="col- col-sm-12 col-md-12 col-lg-6 col-lx-6">
              <div className="title-format-sheet">
                <div className="mb-0 mb-md-3 col">
                  <label htmlFor="name">Título</label>
                  <Field
                    name="title"
                    placeholder="Certificado XYZ"
                    component={simpleRenderField}
                    typeInput="text"
                    customClass="w-100"
                    parse={
                      (value) => {
                        setDocTitle(value)
                        return value
                      }
                    }
                  />
                </div>
                <div className="col">
                  <label htmlFor="name">Formato de hoja</label>
                  <Field
                    options={SHEET_FORMAT}
                    name="document_orientation"
                    placeholder="Vertical"
                    component={selectField}
                    typeInput="text"
                    customClass="w-100"
                    parse={value => {
                      setDocumentOrientation(value);
                      return value;
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                {
                  EDITOR_VARIABLE.map((ele, index) => {
                    return (
                      <button
                        type="button"
                        key={index}
                        style={{
                          minWidth: "128px",
                          backgroundColor: "transparent",
                        }}
                        className="mt-3 pointer border-caffe text-14 border px-2 py-1 br-10px text-center"
                        onClick={() => {
                          let content = document
                          if (content && content.length > 2) {
                            let initialTag = content.lastIndexOf("</")
                            content = content.substring(0, initialTag) + `<span> {{${ele.value}}} </span>` + content.substring(initialTag, content.length)
                          } else {
                            content = `<span>{{${ele.value}}}</span>`
                          }
                          change("description", content)
                        }}
                        disabled={ele.value === "contenido_personalizado" && customContent}
                      >
                        {ele.label}
                      </button>
                    )
                  })
                }
              </div>
              <div className="mt-3">
                <label htmlFor="editor">Descripción</label>
                <Field
                  name="description"
                  setDocument={setDocument}
                  component={Editor}
                  setCustomContent={setCustomContent}
                />
                {/* <Editor /> */}
              </div>
              <div className="background-signatures mt-3">
                <div className="back-document mr-0 mr-lg-4">
                  <label htmlFor="name">Fondo</label>
                  <Field
                    name="background"
                    className="icon-picker"
                    component={renderFilePicker}
                    photo={initialValues.background}
                    typeInput="file"
                    deleteBackground={deleteBackground}
                    setDeleteBackground={setDeleteBackground}
                    parse={value => {
                      setSrcFile(value);
                      return value;
                    }}
                  />
                </div>
                <div className="field-form signatures mb-0 mb-sm-3">
                  {
                    (background && background !== null) && (
                      <div className="row">
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            change("background", null);
                            setSrcFile(null);
                            setDeleteBackground(true);
                          }}
                        >
                          Eliminar fondo
                        </button>
                      </div>
                    )
                  }
                  <div className="row check-documents">
                    <Field
                      name="print_stamp"
                      value={true}
                      withPharagraph={true}
                      component={SimpleRenderFieldCheck}
                      typeInput="checkbox"
                      customClass="forms-field"
                    />
                    <label htmlFor="uno" className="forms-field-label">
                      ¿Debe llevar impreso el timbre?
                    </label>
                  </div>
                  <div className="row check-documents">
                    <Field
                      name="print_signature"
                      value={true}
                      withPharagraph={true}
                      component={SimpleRenderFieldCheck}
                      typeInput="checkbox"
                      customClass="forms-field"
                    />
                    <label htmlFor="dos" className="forms-field-label">
                      ¿Debe llevar firmas electronicas?
                    </label>
                  </div>
                  <div className="row justify-content-start">
                    <span
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Nota: debe generar un documento para completar la creación del timbre.
                    </span>
                    <span
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Si desea generar más documentos puede hacerlo desde la sección correspondiente.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${documentOrientation == 1 ? "col- col-sm-12 col-md-12 col-lg-5 col-lx-5" : "col- col-sm-12 col-md-12 col-lg-6 col-lx-6"}`}
              style={{
                overflow: "auto",
                maxHeight: "690px",
              }}
            >
              <label htmlFor="preview">Vista previa</label>
              <div
                className="container-fluid"
                style={{
                  background: "#FAFDF3",
                  border: "1.5px solid #B0A196",
                  borderRadius: "20px",
                  height: `${documentOrientation === 1 ? "638px" : "561px"}`,
                  width: `${documentOrientation === 1 ? "561px" : "638px"}`,
                  backgroundImage: `url(${srcFile})`,
                  wordWrap: "break-word",
                  position: "relative",
                }}
              >
                <div className="row justify-content-center mt-2">
                  <img
                    src={require("assets/img/logo.png")}
                    style={{
                      width: "100px",
                      height: "100px",
                      zIndex: "1",
                    }}
                    alt="logo"
                  />
                </div>
                {is_print_stamp && (
                  <img
                    src={icon}
                    style={{
                      width: "100px",
                      height: "100px",
                      zIndex: "1",
                      position: "absolute",
                      top: "9px",
                      left: "12px",
                      borderRadius: "5px"
                    }}
                    alt="timbre"
                  />
                )}
                <div
                  className="mt-3"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    textAlignLast: "center",
                  }}
                >
                  {docTitle}
                </div>
                <div
                  style={{
                    padding: "10px",
                  }}
                  dangerouslySetInnerHTML={{ __html: document }}
                />
              </div>
            </div>
          </div>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          <BtnSaveAndCancel
            renderBtnSubmit={true}
            {
            ...buttonProps()
            }
          />
        </form>
      )}
    />
  );
}

const selectForm1 = formValueSelector("stamps_step_1")

const selectForm3 = formValueSelector("stamps_step_3")

Step3 = connect((state) => {
  const icon = selectForm1(state, "icon")
  const is_print_stamp = selectForm3(state, "print_stamp")
  return {
    icon,
    is_print_stamp
  }
})(Step3)


export default Step3;