const TEXT = "Este campo es requerido";

const validate = (values, requiredDoc=[]) => {
  const errors = {};

  if (!values.type_of_discharge) {
    errors.name = TEXT;
  }
  if (requiredDoc.includes(values.type_of_discharge) && !values.no_document) {
    errors.no_document = "Este campo es requerido"
  }
  if (!values.cash_outflows) {
    errors.cash_outflows = TEXT;
  }

  return errors;
}

export default validate;