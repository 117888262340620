import React, { useState } from "react";
import { renderField } from "../../Utils/renderField";
import { Form, Field } from "react-final-form";
import eyeblock from "../../../../../assets/img/icons/eyeblock.png";

const required = value => (value ? undefined : "Este campo es requerido");

const LoginForm = ({
  onSubmit,
}) => {

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        values
      }) => (
        <form onSubmit={handleSubmit} className="login-formulate w-100">
          <div className="mb-2">
            <label htmlFor="dpi">DPI</label>
            <Field
              name="dpi"
              component={renderField}
              typeInput="text"
              customClass="dpi-field"
              placeholder="    Ingresa DPI"
              maxLength={19}
              validate={required}
            />
          </div>
          <div className="input-password">
            <label htmlFor="password">Contraseña</label>
            {
              values && values.password && values.password.length > 0 ? (
                <i className="icon-eye">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowPassword(!showPassword)}
                    onKeyDown={() => setShowPassword(!showPassword)}
                  >
                    <img
                      src={eyeblock}
                      className="eye"
                      alt="icono-ocultar"
                    />
                  </div>
                </i>
              ) : null
            }
            <Field
              name="password"
              component={renderField}
              typeInput="password"
              customClass="password-field"
              placeholder="    Ingresa contraseña"
              showPassword={showPassword}
              maxLength={32}
              validate={required}
            />
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn login-button">Iniciar sesión</button>
          </div>
          <br />
        </form>
      )}
    />
  );
};

export default LoginForm;
