import React from 'react';
import {Field, Form} from 'react-final-form';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {GENDER_OPTION} from "../../../../utility/constants";
import {renderField, renderFilePicker, renderNumber, selectField} from '../../Utils/renderField/renderField';
import {fieldSelectRequired} from "../../../../utility/validation";


const ProfileForm = ({onSubmit, data, setAvatar, loader}) => {

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({handleSubmit}) =>
        <form action="" onSubmit={handleSubmit} className="py-4">
          <LoadMask light loading={loader}>
            <h2>PERFIL</h2>
            <div
              className="mb-4 card card-small bg-card-cofaqui px-lg-5 px-md-5 px-sm-0 py-4 col-lg-8 col-md-10 col-sm-12">
              {/*<div className="border-bottom card-header"><h6 className="m-0">{me.first_name} {me.last_name}</h6></div>*/}
              <div className="pt-3 d-flex flex-column flex-md-row">
                <div className="form-group has-feedback flex-1 mx-3">
                  <label htmlFor="avatar">Avatar</label>
                  <Field
                    photo={data && data.avatar ? data.avatar : null}
                    name="avatar"
                    accept={'image/*'}
                    setFile={setAvatar}
                    component={renderFilePicker}
                  />
                </div>
                <div className="d-flex flex-column flex-1 mx-3">
                  <div className="form-group has-feedback">
                    <label htmlFor="first_name">Nombre</label>
                    <Field
                      type="text"
                      name="first_name"
                      placeholder="Nombre"
                      component={renderField}
                      className="form-control"/>
                  </div>

                  <div className="form-group has-feedback">
                    <label htmlFor="last_name">Apellido</label>
                    <Field name="last_name" placeholder="Nombre" component={renderField} type="text"
                           className="form-control"/>
                  </div>
                </div>
              </div>
              <div className="pt-3 d-flex flex-column flex-md-row">
                <div className="form-group has-feedback flex-1 mx-3">
                  <div className="form-group has-feedback">
                    <label htmlFor="phone">Teléfono</label>
                    <Field
                      name="phone"
                      placeholder="Teléfono"
                      numberFormat={"########"}
                      component={renderNumber}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group has-feedback">
                    <label className="text-16" htmlFor="gender">Género</label>
                    <Field
                      name={'gender'}
                      options={GENDER_OPTION}
                      component={selectField}
                      validate={fieldSelectRequired}
                    />
                  </div>
                </div>
                <div className="form-group has-feedback flex-1 mx-3">
                  <div className="form-group has-feedback">
                    <label htmlFor="address">Dirección</label>
                    <Field name="address" placeholder="Dirección" component={renderField} type="text"
                           className="form-control"/>
                  </div>
                </div>
              </div>
              <div className="d-flex px-2">
                <button className="btn btn-primary mx-auto mb-3 col-lg-3 col-md-4 col-sm-12">Guardar</button>
              </div>
            </div>
          </LoadMask>
        </form>
      }/>
  );
};

export default ProfileForm
