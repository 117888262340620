import {connect} from "react-redux";
import List from './List'
import {actions} from '../../../../../redux/modules/myManagements/purchaseRequest'


const ms2p = (state) => {
  return {
    ...state.purchaseRequest,
  }
}

const md2p = {...actions}

export default connect(ms2p, md2p)(List)
