import React, {useEffect} from 'react'
import PageTitle from "../../../../components/layout/PageTitle";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";


const ProductList = (pros) => {
  const {data, page, listar, loader, onSortChange, setItemValues} = pros;
  useEffect(()=>{
    listar(1)
    setItemValues({})
  }, [])

  return (
    <div>
      <PageTitle
        withLink={true}
        title={"Productos"}
        linkTo="/new-product"
        linkLabel={"Nuevo producto"}
      />
      <div>
        <div className="card bg-card-cofaqui br-20px py-5 px-4">
          <Grid
            hover
            data={data}
            page={page}
            loading={loader}
            onPageChange={listar}
            onSortChange={onSortChange}
            bodyContainerClass = "bc-professions-custom"
            headerContainerClass = "hc-professions-custom"
            tableContainerClass = "professions-container-table-sm"
            tableHeaderClass = "table-header-professions"
          >
            <TableHeaderColumn
              dataField="id"
              width="100px"
              thStyle={{paddingLeft: "55px"}}
              tdStyle={{paddingLeft: "55px"}}
            >
              No.
            </TableHeaderColumn>
            <TableHeaderColumn
              isKey
              dataField="name"
              dataAlign="center"
              width='150'
            >
              Nombre
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="amount"
              width='125'
              dataFormat={(cell)=>{
                return <span>Q. {cell}</span>
              }}
            >
              Precio
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="profession"
              width='150'
            >
              Profesión
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataAlign="center"
              width="100px"
              dataFormat={standardActions({
                editar: "product",
              })}
            >
              Acciones
            </TableHeaderColumn>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default ProductList
