import React, { useEffect, useState } from "react";
import Grid from "../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import PageTitle from "../../../components/layout/PageTitle";
import { standardActions } from "../../../components/Utils/Grid/StandardActions";
import { Modal } from "react-responsive-modal";

import "react-responsive-modal/styles.css";
import "./modal.css";

const ListMembers = (props) => {
  const {
    data, loader, onPageChange, onSortChange, setMemberData, setMemberDataMaster,
    page, listar, eliminar, searchChange,
    filteringChange, createExcelReport, createPDFReport,
    clearValueMun, generateTemporaryPassword, openModal, closeModal,
    temporaryPassword, me: { permissions },
  } = props;

  const [createMember, setCreateMember] = useState({});

  const { technical_address_management, payment_document_management, member_management } = permissions;

  useEffect(() => {
    listar(page, { "is_active": true, })
    clearValueMun()
    searchChange("")
    if (permissions.role_permissions_management && member_management) {
      setCreateMember({
        withLink: true,
        linkTo: "new-member",
        linkLabel: "Nuevo agremiado",
      })
    }
  }, []);

  const [moreActions, setMoreActions] = useState({});

  useEffect(() => {
    if (technical_address_management) {
      setMoreActions((prevState) => ({
        ...prevState,
        technical_directions: "technical_directions",
      }));
    }
    if (payment_document_management) {
      setMoreActions((prevState) => ({
        ...prevState,
        documents: "documents",
        confirmedPayments: "confirmed-payments",
      }));
    }
    if (member_management) {
      setMoreActions((prevState) => ({
        ...prevState,
        editar: "member",
        eliminar: eliminar,
        generateTemporaryPassword: generateTemporaryPassword,
        masters: "masters",
        setMemberData: setMemberData,
        setMemberDataMaster: setMemberDataMaster,
      }));
    }
  }, [technical_address_management, payment_document_management, member_management]);

  const handleGenerateExcelReport = () => {
    createExcelReport()
  }

  const handleGeneratePDFReport = () => {
    createPDFReport()
  }

  return (
    <div className="">
      <Modal
        classNames={{
          modal: "custom-modal",
          overlay: "custom-overlay",
        }}
        open={openModal}
        onClose={() => closeModal(false)}
        center
      >
        <div className="container-fluid">
          <h3 className="mb-4">Contraseña temporal</h3>
          <div
            className="d-flex flex-row justify-content-center p-2"
            style={{ background: "#E2DDD9", borderRadius: "8px" }}>
            <h4 style={{ marginTop: "3%" }}>{temporaryPassword}</h4>
          </div>
        </div>
      </Modal>
      <PageTitle
        title={"Agremiados"}
        // withLink={true}
        // linkTo={"new-member"}
        // linkLabel={"Nuevo agremiado"}
        {...createMember}
        withFilter
        filterOption={[
          {
            value: 1,
            label: 'Activos',
          },
          {
            value: 2,
            label: 'Inactivos',
          },
          {
            value: 3,
            label: 'Fallecidos',
          },
          {
            value: 4,
            label: 'Suspención temporal',
          },
          {
            value: 5,
            label: 'Miembro temporal',
          }
        ]}
        filterAction={filteringChange}
        withSearch
        searchAction={searchChange}
        // filterLabel={"Filtrar por"}
        // filterOption={[]}
      />
      <div>

        <div className="d-flex justify-content-center w-100 my-2">
          <button onClick={handleGenerateExcelReport} className="mr-2 btn btn-create text-center " type="button"
            disabled={loader}
          >
            ⇓ Reporte Excel
          </button>

          <button onClick={handleGeneratePDFReport} className="btn btn-secondary" type="button"
            disabled={loader}
          >
            ⇓ Reporte PDF
          </button>
        </div>

        <div className="col-12 px-1">
          <div className="mb-4 card card-small bg-card-cofaqui">
            <div className="px-4 pt-4">
              <Grid
                hover
                data={data}
                page={page}
                loading={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}>
                <TableHeaderColumn
                  width="200px"
                  dataAlign="center"
                  dataField="no_collegiate"
                  thStyle={{ paddingRight: "50px" }}
                  tdStyle={{ paddingRight: "50px" }}
                >
                  Colegiado
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="full_name"
                  width="250px"
                >
                  Nombre y Apellido
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="last_payment"
                  width="220px"
                  dataFormat={(cell) => {
                    let label = cell["month"] + " " + cell["year"]
                    let color = cell["color"]
                    const styles = {
                      color,
                      fontSize: "12px",
                      marginTop: "-4px",
                      verticalAlign: "middle",
                    }
                    return (
                      <span>
                        <span className="material-icons" style={styles}>fiber_manual_record</span>
                        <span className="">&nbsp;{label}</span>
                      </span>
                    )
                  }}
                >
                  Vigencia
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="dpi"
                  width="200px"
                >
                  DPI
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="90px"
                  dataField="gender"
                  dataAlign="center"
                  dataFormat={(cell) => {
                    return <span className="text-capitalize">{cell ? cell[0] : ""}</span>
                  }}
                >
                  Género
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="phone"
                  width="150px"
                >
                  Teléfono
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataField="id"
                  dataAlign="center"
                  width="250px"
                  // columnClassName="d-flex justify-content-center"
                  dataFormat={standardActions({
                    ...moreActions,
                  })}
                >
                  Acciones
                </TableHeaderColumn>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListMembers;
