import React from "react"
import { connect } from "react-redux"
import { FormSpy } from "react-final-form"
import { updateFormState } from "../../../redux/modules/finalFormDump";

const FormStateToRedux = ({ form, updateFormState }) => (
  <FormSpy subscription={{ values: true, pristine: true }} onChange={state => {
    setTimeout(() => {
      updateFormState(form, state)
    }, 0)
  }} />
)

const ms2p = (state) => {
  return {
    ...state.form,
  }
};

export default connect(ms2p, { updateFormState })(FormStateToRedux)
