import React from "react"
import Modal from "react-responsive-modal";
import {Form, Field} from "react-final-form";
import {renderTextArea} from "../../../../../components/Utils/renderField";
import {fieldRequired} from "../../../../../../utility/validation";


const ConfirmationModal = ({openModal, closeModalAction, declineAction}) => {
  
  const onSubmit = (values) => {
    declineAction(values)
  }

  return (
    <div>
      <Modal
        center
        open={openModal}
        closeOnEsc={false}
        onClose={closeModalAction}
        closeOnOverlayClick={false}
        styles={{
          modal: {
            width: "40%",
            padding: "2.5%",
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: "#B0A196"
          }
        }}
      >
        <div>
          <Form
            onSubmit={onSubmit}
            render={({handleSubmit, values}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <center>
                    <h4>
                      Motivo de rechazo
                    </h4>
                  </center>
                  <div>
                    <div className="col-12">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                          <Field
                            name="reason_rejection"
                            component={renderTextArea}
                            placeholder="Motivo de rechazo"
                            validate={fieldRequired}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  <div className='d-flex flex-wrap justify-content-between col-lg-9 col-md-9 col-sm-12 mt-4 mr-1 mx-auto'>
                    <button
                      type='submit'
                      // onClick={declineAction}
                      className={"btn btn-success col mr-1"}>
                      Enviar
                    </button>
                    <button
                      type='button'
                      onClick={closeModalAction}
                      className={"btn btn-danger col"}>
                      Cancelar
                    </button>
                  </div>
                </form>
              )
            }}
          />

        </div>

      </Modal>

    </div>
  )
}


export default ConfirmationModal
