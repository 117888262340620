import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import FinalFormFilter from "../../../../components/Utils/FinalFormFilter";
import Filter from "../../../../components/Utils/Filters/Filter";
import { api } from "../../../../../utility/api";
import excelExportPN from "../../../../../../assets/img/icons/excel.png";

import "./list.css";

const ReportStampListSold = ({
  getReport,
  page,
  loader,
  memberReportList,
  updateFiltersStampsSold,
  filtersStampsSold,
  changeStamp,
  resetValueStamp,
  exportReportStampSoldExcel,
  loader_excel_stamp_sold
}) => {

  const [defOptionStamp, setOptionStamp] = useState([])

  useEffect(() => {
    getReport(page);
  }, []);


  useEffect(() => {
    resetValueStamp()
    getStamp().then((opts) => {
      setOptionStamp(opts)
    })
  }, [])


  const getStamp = (search = "") => {
    return api.get("stamp/all_stamp", { search }).then((resp) => {
      let _options = [];
      if (resp) {
        _options = resp
        _options.push({ id: null, name: 'Todos' })
      }
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }


  return (
    <div className="container-fluid">
      <PageTitle
        title={"Timbres Vendidos"}
        colTitle="col-12"
      />

      <div className="card bg-card-cofaqui br-20px py-5 px-4">

        <FinalFormFilter
          getStamp={getStamp}
          defaultOp={defOptionStamp}
          withStartDateFilter
          withEndDateFilter
          withSelectStampFilter
          action={updateFiltersStampsSold}
          initialValues={filtersStampsSold}
          startDateTitle="Fecha de inicio de transacciones"
          endDateTitle="Fecha de fin de transacciones"
          changeStampSelected={changeStamp}
        />


        <div className="align-self-end d-flex mb-3">
          <button
            className="btn btn-green-generic align-self-end mr-3"
            onClick={exportReportStampSoldExcel}
            >
              <img src={excelExportPN} alt="" />
              &nbsp; {loader_excel_stamp_sold ? "Exportando..." : "Exportar"}
          </button>
        </div>

        <Grid
          hover
          data={memberReportList}
          page={page}
          loading={loader}
          onPageChange={getReport}
          bodyContainerClass="bc-professions-custom"
          headerContainerClass="hc-professions-custom"
          tableContainerClass="professions-container-table-sm"
          tableHeaderClass="table-header-professions"
        >

          <TableHeaderColumn
            width="100px"
            dataField="transaction"
            dataAlign="center"
            dataFormat={(cell) => {
              return <span className="text-capitalize">{cell ? cell.no_document : ""}</span>
            }}
            isKey
          >
            No. Recibo
          </TableHeaderColumn>

          <TableHeaderColumn
            width="100px"
            dataField="transaction"
            dataAlign="center"
            dataFormat={(cell) => {
              return <span className="text-capitalize">{cell ? cell.transaction_date : ""}</span>
            }}
          >
            Fecha
          </TableHeaderColumn>

          <TableHeaderColumn
            dataAlign="center"
            width="100"
            dataField="transaction"
            dataFormat={(cell) => {
              return <span className="text-capitalize">{cell ? cell.member ? cell.member.no_collegiate : "" : ""}</span>
            }}
          >
            No. Colegiado
          </TableHeaderColumn>

          <TableHeaderColumn
            dataAlign="center"
            width="175px"
            dataField="transaction"
            dataFormat={(cell) => {
              return <span className="text-capitalize">{cell ? cell.member ? cell.member.full_name : "" : ""}</span>
            }}
          >
            Nombre del Agremiado
          </TableHeaderColumn>

          <TableHeaderColumn
            dataAlign="center"
            width="175"
            dataField="stamp"
            dataFormat={(cell) => {
              return <span className="text-capitalize">{cell ? cell.name : ""}</span>
            }}
          >
            Timbre
          </TableHeaderColumn>

          <TableHeaderColumn
            dataAlign="center"
            width="90px"
            dataField="quantity"
          >
            Cantidad
          </TableHeaderColumn>

          <TableHeaderColumn
            dataAlign="center"
            width="90px"
            dataField="amount"
          >
            Monto
          </TableHeaderColumn>

        </Grid>
      </div>
    </div>
  );
}

export default ReportStampListSold;