import React, { useEffect, useState } from "react";
import {Field, Form} from "react-final-form";
import { TYPE_OF_DISCHARGE } from "../../../../utility/constants";
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel";
import { 
  renderDatePicker,
  // renderField,
  renderNumber,
} from "../../../components/Utils/renderField/renderField";
import { selectField, simpleRenderField, AsyncSelectField } from "../../../components/Utils/renderField/renderField";
import tree from "../../../components/Utils/TreeComponent";

// eslint-disable-next-line
import {api} from "api"

const required = value => (value ? undefined : "Este campo es requerido")

const getBanks = (search, type) => {
  const params = {
    search,
    type,
  }

  return api.get("bank/get_origin_or_destination_banks", { ...params }).then((response) => {
    let _options = []
    if (response.results) {
      _options = response.results
    }
    return _options
    
  }).catch(() => {
    return []
  });
}


const OutgoingCashReceivedForm = (props) => {
  const {
    getCategories,
    categories,
    openCategories,
    me,
    createOutgoingCashReceived,
    getOriginOrDestinationBanks,
    destinationBanksList,
    ...rest
  } = props;

  const [cashier, setCashier] = useState([]);
  const [initialData, setInitialData] = useState({transaction_date: new Date()});

  useEffect(() => {
    if (me && me.id) {
      setCashier([{label: me.full_name, value: me.id}])
      setInitialData({cashier: me.id, type_of_discharge: 20, ...initialData})
    }
  }, [me])

  useEffect(() => {
    getOriginOrDestinationBanks("destination")
    getCategories()
  }, [])
    
  return (
    <div className="ml-4">
      <Form
        onSubmit={createOutgoingCashReceived}
        initialValues={initialData}
        // validate={rest.validate}
        render={({
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="card bg-card-cofaqui p-5 petty-cash-entry-form">
              <div className="row justify-content-between mb-2">
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="transaction_date">Fecha de egreso</label>
                  <Field
                    withTime
                    name="transaction_date"
                    component={renderDatePicker}
                    typeInput="text"
                    minDate={rest.minDate}
                    maxDate={new Date()}
                    validate={required}
                  />
                </div>
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="cashier_id">Usuario</label>
                  <Field
                    name="cashier"
                    disabled={true}
                    component={selectField}
                    // validate={fieldRequired}
                    // options={cashier=[]}
                    options={cashier}
                    validate={required}
                  />
                </div>
              </div>
              <div className="row justify-content-between mb-2">
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="type_of_discharge">Tipo de egreso</label>
                  <Field
                    name="type_of_discharge"
                    options={TYPE_OF_DISCHARGE}
                    // validate={fieldRequired}
                    disabled={true}
                    component={selectField}
                    validate={required}
                  />
                </div>
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="no_document">
                      Banco
                  </label>
                  <Field
                    name="bank_of_destination"
                    defaultOptions={destinationBanksList.results}
                    component={AsyncSelectField}
                    placeholder="Nombre del banco"
                    loadOptions={(values) => getBanks(values, "destination")}
                    valueKey="id"
                    labelKey="name"
                    validate={required}
                  />
                </div>
              </div>
              <div className="row justify-content-between mb-2">
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="total">
                    No. de boleta, deposito o transferencia
                  </label>
                  <Field
                    name="no_document"
                    component={simpleRenderField}
                    placeholder="0-0001"
                    validate={required}
                  />
                </div>
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="type_of_discharge">Rubro contable</label>
                  <Field
                    component={tree}
                    name="expense_category"
                    options={categories}
                    placeholder="Seleccione un categoría"
                    open={openCategories}
                    validate={required}
                  />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="total">
                    No. de cuenta
                  </label>
                  <Field
                    name="account_number"
                    component={simpleRenderField}
                    placeholder="0-0001"
                    validate={required}
                  />
                </div>
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="total">
                    total
                  </label>
                  <Field
                    name="amount"
                    prefix="Q "
                    placeholder="0.00"
                    customDecimalScale={2}
                    acceptNegative={false}
                    component={renderNumber}
                    validate={required}
                  />
                </div>
              </div>
            </div>
            <BtnSaveAndCancel
              renderBtnSubmit
              renderLinkCancel
              redirectToLink="/my-cash-register"
              customContainer="container justify-content-start w-100 mb-3 mt-4 row"
            />
          </form>
        )}
      />
    </div>
  );
};

export default OutgoingCashReceivedForm;