import React from 'react'
import { connect } from 'react-redux'
import ListTechnicalDirections from './ListTechnicalDirections'
import { actions } from '../../../../redux/modules/technical_directions'

const mapStateToProps = (state) => ({
  ...state.technicalDirectionsReducer
})

const mapDispatchToProps = {
  ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTechnicalDirections)