import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/reports/stampsIssued";
import MembersForReportList from "./List";

const ms2p = (state) => {
  return {
    ...state.stampsIssued,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(MembersForReportList);