import TransactionForm from './Form'
import {api} from "../../../../../utility/api"
import React, {useEffect, useState} from 'react'
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask"
import TitlePageCreate from "../../../../components/layout/TitleCreate"


const CreateCashRegister = (props) => {
  const [defaultOption, setDefaultOption] = useState([])
  const [valueSelect, setValueSelect] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [dataUser, setDataUser] = useState([])

  const {item, loader, crear, editar, leer, setItemValues} = props

  useEffect(() => {
    // set default values
    props.getDepartments()
    setIsUpdate(false)
    setDefaultOption([])
    setValueSelect(null)
    setDataUser([])
    setItemValues({})
    const {params} = props.match
    if (params && params.id) {
      setIsUpdate(true)
      leer(params.id)
    } else {
      setItemValues({})
    }
    loadUser().then((data) => {
      setDefaultOption(data)
    })
  }, [])

  useEffect(() => {
    if (isUpdate && item && item.cashiers) {
      setDataUser(item.cashiers)
    }
  }, [item])

  const loadUser = (search = "") => {
    return api.get('user', {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch((err) => {
      console.log("Error to load user", err)
    }).finally(() => {
    });
  }

  const AddUserToTable = () => {
    if (valueSelect) {
      let dataUserCopy = dataUser
      if (dataUserCopy.find(element => element.id === valueSelect.id) === undefined) {
        dataUserCopy.push(valueSelect)
        setDataUser(dataUserCopy)
      }
    }
    setValueSelect(null)
  }

  const ActionDeleteItem = (idItem) => {
    const result = dataUser.filter(user => user.id !== idItem);
    setDataUser(result)
  }

  const actionSubmit = (data) => {
    if (isUpdate) {
      const {params: {id}} = props.match
      editar(id, {...data, cashiers: dataUser})
    } else {
      crear({...data, cashiers: dataUser})
    }
  }

  return (
    <div className="pt-5">
      <TitlePageCreate
        redirectTo="/cash-registers"
        title={isUpdate ? "Editar Caja" : "Crear Caja"}
      />
      <LoadMask dark blur loading={loader}>
        <TransactionForm
          dataDep={props.dataDep}
          dataUser={dataUser}
          onSubmit={actionSubmit}
          promiseAction={loadUser}
          valueSelect={valueSelect}
          actionBntAdd={AddUserToTable}
          defaultOption={defaultOption}
          setValueSelect={setValueSelect}
          actionDelete={ActionDeleteItem}
          initialValues={isUpdate ? item : {}}
        />
      </LoadMask>
    </div>
  )
}

export default CreateCashRegister
