import React from "react";
import {Form, Field} from "react-final-form";
import {renderField, AsyncSelectField} from "../../../../components/Utils/renderField";

// eslint-disable-next-line
import {api} from "api";
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";

const getCountries = (search) => {
  return api.get("country", {search}).then((response) => {
    let _options = [];
    if (response.results) {
      _options = response.results
    }
    return _options;
  }).catch(() => {
    return [];
  });
}

const required = (value) => (value ? undefined : "Este campo es requerido");

const UniversitiesForm = ({
  createOrUpdate,
  data = {},
  countries,
  loader,
  type,
}) => {
  const onSubmit = (values) => {
    if (type === "create") {
      createOrUpdate(values);
    } else {
      createOrUpdate(data.id, values);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({handleSubmit, form, submitting}) => (
        <form
          onSubmit={handleSubmit}
          className="py-5 px-lg-5 px-md-3 px-sm-01 px-xl-5 universities-form d-flex flex-column"
        >
          <LoadMask blur dark loading={loader} >
            <div className="row d-flex flex-row">
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="name"
                >
                Nombre de la universidad
                </label>
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder="Nombre de la universidad"
                  validate={required}
                />
              </div>
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="country"
                >
                País
                </label>
                <Field
                  name="country"
                  defaultSelectValue={data.country}
                  defaultOptions={countries.results}
                  component={AsyncSelectField}
                  type="select"
                  placeholder="País"
                  labelKey="name"
                  valueKey="id"
                  validate={required}
                  loadOptions={getCountries}
                />
              </div>
            </div>
            <div className="mt-4 container-fluid">
              <BtnSaveAndCancel
                renderBtnSubmit={true}
                renderLinkCancel={true}
                redirectToLink={"/universities"}
                subContainer={"px-0 col-lg-10 col-md-10 col-sm-12 row justify-content-center"}
              />
            </div>
          </LoadMask>
        </form>
      )}
    />
  );
};

export default UniversitiesForm;
