export const GENDER_OPTION = [
  { label: "Masculino", value: 0 },
  { label: "Femenino", value: 1 },
]
export const MARITAL_STATUS_OPTION = [
  { label: "Soltero", value: 1 },
  { label: "Casado", value: 5 },
  { label: "Divorciado", value: 10 },
  { label: "Viudo", value: 15 },
  { label: "Concubinato", value: 20 },
]
export const RELATIONSHIP_OPTION = [
  { label: "Padre/Madre", value: 1 },
  { label: "Hermano/Hermana", value: 5 },
  { label: "Hijo/Hija", value: 10 },
  { label: "Primo/Prima", value: 15 },
  { label: "Tío/Tía", value: 20 },
  { label: "Nieto/Nieta", value: 25 },
  { label: "Amigo/Amiga", value: 30 },
  { label: "Otro", value: 35 },
]

export const STATE = [
  { label: "Activo", value: 1 },
  { label: "Inactivo", value: 2 },
  { label: "Fallecido", value: 3 },
  { label: "Suspensión Temporal", value: 4 },
  { label: "Colegiado Temporal", value: 5 },
]

export const EDITOR_VARIABLE = [
  { label: "Nombre completo", value: "nombre" },
  { label: "Dirección", value: "direccion" },
  { label: "No. Colegiado", value: "no_colegiado" },
  { label: "Fecha actual", value: "fecha_actual" },
  { label: "Fecha de colegiado activo", value: "fecha_colegiacion" },
  { label: "Direcciones técnicas", value: "direcciones_tecnicas" },
  { label: "Contenido personalizado", value: "contenido_personalizado" },
  { label: "Tipo de gestión", value: "tipo_gestion" },
]

export const CUSTOM_CONTENT_VARIABLE = [
  { label: "Razón de uso del documento", value: "contenido_personalizado" },
]

// El nombre que se le quiera dar a la(s) vista(s) y
// el nombre exacto de los permisos que se deben tener
// para poder acceder a la(s) vista(s)

export const VIEW_PERMISSIONS = {
  "permissionsRoles": [
    "role_permissions_management",
  ],
  "permissionsUsers": [
    "internal_user_management",
  ],
  "permissionsCreateUsers": [
    "internal_user_management",
    "role_permissions_management",
  ],
  "permissionsUpdateUsers": [
    "internal_user_management",
  ],
  "permissionsMembers": [
    "member_management",
  ],
  "permissionsUniversities": [
    "universities_management",
  ],
  "permissionsProfessions": [
    "professions_management",
  ],
  "permissionsStamps": [
    "electronic_stamp_management",
  ],
  "permissionsDocuments": [
    "document_management",
    "electronic_stamp_management",
  ],
  "permissionsProducts": [
    "product_management",
  ],
  "permissionsExpenseCategories": [
    "expense_categories_management",
  ],
  "permissionsComissions": [
    "comission_management",
  ],
  "permissionsBinnacle": [
    "binnacle_management",
  ],
  "permissionsFloatingPayments": [
    "floating_payments_management",
  ],
  "managements": [
    "is_member",
  ],
  "cashRegister": [
    "cash_boxes_access",
  ],
  "incomeExpenses": [
    "income_expenses",
  ],
  "contributionReport": [
    "contribution_report",
  ],
  "stampsIssueReport": [
    "stamps_issue_report",
  ],
  "stampsSoldReport": [
    "stamps_sold_report",
  ],
  "stampsUsedReport": [
    "stamps_used_report",
  ],
  "documentConsultation": [
    "document_consultation",
  ],
  "paymentsDocumentsForMember": [
    "payment_document_management",
  ],
  "permissionsTechnicalAddresses": [
    "technical_address_management",
  ],
  "permissionsAdministrativeCashRegister": [
    "collections_cashiers",
  ],
  "permissionsForBanks": [
    "banks_management",
  ],
  "permissionsForTypeOfManagements": [
    "type_of_managements",
  ],
  "permissionDashboard": [
    "dashboard",
  ],
}

// Pantallas que pueden ser usadas
// En el select del formulario de crear/actualizar roles
// Permissions equivale al checkbox de permisos que requiere para
// poder usar la pantalla como pantalla de inicio

export const SCREENS = [
  {
    value: "/",
    label: "Agremiados",
    permissions: [
      "member_management",
    ],
    validate: []
  },
  {
    value: "permissions-roles",
    label: "Roles y permisos",
    permissions: [
      "role_permissions_management",
    ],
    validate: []
  },
  {
    value: "users",
    label: "Usuarios",
    permissions: [
      "internal_user_management",
    ],
    validate: []
  },
  {
    value: "stamps",
    label: "Gestión de timbres elec.",
    permissions: [
      "electronic_stamp_management",
    ],
    validate: []
  },
  {
    value: "universities",
    label: "Gestión de universidades",
    permissions: [
      "universities_management",
    ],
    validate: []
  },
  {
    value: "professions",
    label: "Gestión de profesiones",
    permissions: [
      "professions_management",
    ],
    validate: []
  },
  {
    value: "floating_payments",
    label: "Gestión de pagos flotantes",
    permissions: [
      "floating_payments_management",
    ],
    validate: []
  },
  {
    value: "expense-categories",
    label: "Gestión de categorías Contables",
    permissions: [
      "expense_categories_management",
    ],
    validate: []
  },
  {
    value: "commissions",
    label: "Gestión de comisiones",
    permissions: [
      "comission_management",
    ],
    validate: []
  },
  {
    value: "binnacle",
    label: "Bitácora",
    permissions: [
      "binnacle_management",
    ],
    validate: []
  },
  {
    value: "incomeandexpenses-report",
    label: "Reporte de ingresos y egresos",
    permissions: [
      "income_expenses",
    ],
    validate: []
  },
  {
    value: "stamps-issued-report",
    label: "Reporte de timbres emitidos",
    permissions: [
      "stamps_issue_report",
    ],
    validate: []
  },
  {
    value: "stamps-sold-report",
    label: "Reporte de timbres vendidos",
    permissions: [
      "stamps_sold_report",
    ],
    validate: []
  },
  {
    value: "stamps-used-report",
    label: "Reporte de timbres usados",
    permissions: [
      "stamps_issue_report",
    ],
    validate: []
  }, 
  {
    value: "document-consultation-report",
    label: "Consulta de documentos",
    permissions: [
      "document_consultation",
    ],
    validate: []
  },
  {
    value: "contributions-report",
    label: "Reporte de aportaciones",
    permissions: [
      "contribution_report",
    ],
    validate: []
  },
  {
    value: "products",
    label: "Gestión de productos",
    permissions: [
      "product_management",
    ],
  },
  {
    value: "dashboard",
    label: "Dashboard",
    permissions: [
      "dashboard",
    ],
    validate: []
  },
  {
    value: "my-cash-register",
    label: "Mi cajas",
    permissions: [
      "cash_boxes_access",
    ],
    validate: []
  },
  {
    value: "cash-registers",
    label: "Gestión de cajas",
    permissions: [
      "collections_cashiers",
    ],
    validate: []
  },
  {
    value: "banks",
    label: "Gestión de bancos",
    permissions: [
      "banks_management",
    ],
    validate: []
  },
  {
    value: "management",
    label: "Gestiones de agremiados",
    permissions: [
      "type_of_managements",
    ],
    validate: []
  },
  {
    value: "managements",
    label: "Mis gestiones",
    permissions: [],
    validate: []
  },
]

// Validaciones que la contraseña debe cumplir
export const INITIAL_STATE = {
  password: {
    minLength: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasUpperCase: false,
  }
}

// Formatos de hoja

export const SHEET_FORMAT = [
  {
    value: 1,
    label: "Vertical",
  },
  {
    value: 5,
    label: "Horizontal",
  },
]

// Tipos de documento

export const DOCUMENT_TYPE = [
  {
    value: 1,
    label: "PDF",
  },
  {
    value: 5,
    label: "Word",
  },
  {
    value: 10,
    label: "Excel",
  },
]

// Tipo de formulario

export const FORM_TYPE = [
  {
    value: 1,
    label: "TQB",
  },
  {
    value: 5,
    label: "CAYCEQ",
  },
]

// Tipos de timbres

export const STAMP_TYPE = [
  {
    value: 1,
    label: "Bajo aprobación",
  },
  {
    value: 5,
    label: "Bajo demanda",
  },
]

// Tipo de cajas

export const TYPE_CASH_REGISTER = [
  { label: "Caja Chica", value: 1 },
  { label: "Caja Receptora", value: 5 }
]

// Tipo de pago

export const TYPE_OF_INCOME = [
  {label: "Efectivo", value: 1},
  {label: "Deposito", value: 5},
  {label: "Cheque", value: 10},
  {label: "Transferencia", value: 15},
  {label: "POS", value: 25},
]

export const TYPE_OF_INCOME_DIRECT = [
  {label: "Deposito", value: 5},
  {label: "Transferencia electronica", value: 15},
  {label: "VisaLink (EBI Pay)", value: 30},
]

export const TYPE_OF_DISCHARGE = [
  {label: "Efectivo", value: 1},
  {label: "Deposito", value: 5},
  {label: "Cheque", value: 10},
  {label: "Transferencia", value: 15},
  {label: "Banco", value: 20},
]

export const TYPE_OF_DISCHARGE_PETTY_CASH = [
  {label: "Efectivo", value: 1},
  {label: "Deposito", value: 5},
  {label: "Cheque", value: 10},
]

export const TYPE_OF_INCOME_PETTY_CASH = [
  {label: "Efectivo", value: 1},
  {label: "Cheque", value: 10},
  {label: "Banco", value: 20},
]

export function addIndexToArray(arrayData=[], lastIndex) {
  const arrayCopy = arrayData
  for (const arrayProdKey in arrayCopy) {
    let number = parseInt(arrayProdKey)
    if (lastIndex && lastIndex > 1) {
      number = (number + (lastIndex * 10) - 10)
    }
    arrayCopy[arrayProdKey].order = number + 1
  }
  return arrayCopy
}

// PURCHASE REQUEST STATUS
export const PURCHASE_REQUEST_STATUS = [
  {label: "Iniciado", value: 1},
  {label: "En curso", value: 5},
  {label: "Rechazado", value: 10},
  {label: "Cancelado", value: 15},
  {label: "Aprobado", value: 20}
]
export const PR_INITIATED = 1
export const PR_IN_PROGRESS = 5
export const PR_REFUSED = 10
export const PR_CANCELLED = 15
export const PR_APPROVED = 20


export const MONTHS = [
  {label: "Enero", value: 1},
  {label: "Febrero", value: 2},
  {label: "Marzo", value: 3},
  {label: "Abril", value: 4},
  {label: "Mayo", value: 5},
  {label: "Junio", value: 6},
  {label: "Julio", value: 7},
  {label: "Agosto", value: 8},
  {label: "Septiembre", value: 9},
  {label: "Octubre", value: 10},
  {label: "Noviembre", value: 11},
  {label: "Diciembre", value: 12},
]