const selectStyles = {
  placeholder: (styles) => ({
    ...styles,
    color:" #B0A196",
    fontSize: "16px",
    fontWeight: 400,
  }),
  control: (styles, {isDisabled}) => ({
    backgroundColor: isDisabled ? "#E3E3E3" : "#FAFDF3",
    border: "1.5px solid #B0A196 !important",
    borderRadius: "20px !important",
    display: "flex",
    paddingLeft: 10,
    color: isDisabled? "#959595": "#4B4D58",
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  option: (styles, {isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "#959595"
        : isSelected
          ? "#E2DDD9"
          : isFocused
            ? "#EAE8E6"
            : undefined,
      color: "#4B4D58",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#E2DDD9"
            : "EAE8E6"
          : undefined,
      }
    }
  },
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#FAFDF3",
    border: "1.5px solid #B0A196",
    borderRadius: "8px",
    color: "#4B4D58",
    marginTop: 0,
  }),
}

export default selectStyles;
