import React, { Fragment, useEffect, useState } from "react";
import Grid from "../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import PageTitle from "../../../components/layout/PageTitle";
import {standardActions} from "../../../components/Utils/Grid/StandardActions";

const TableList = (props) => {
  const {
    deleteDirection,
    setDataEditDirection,
    loader,
    data,
    page,
    data_item,
    setIsEdit,
    setOpenModal,
  } = props;

  const [modalValidation, setModalValidation] = useState(false);

  useEffect(() => {
    if (data && data.count < 7) {
      setModalValidation(true);
    } else {
      setModalValidation(false);
    }
  }, [data]);

  return (
    <div className="col-12 px-1">
      {data_item && data_item.id ? (
        <Fragment>
          <PageTitle
            colTitle="row col-lg-8 col-md-12 col-sm-12"
            removeCols=""
            // customContainer="row w-100 justify-content-between mb-3 mt-3"
            title={`Direcciones técnicas de ${data_item.full_name}`}
            withReturn={true}
            returnTo={"/"}
            withModal={modalValidation}
            customAction={() => {
              setIsEdit(false);
              setOpenModal(true);
            }}
            btnModalText="Crear dirección técnica"
          />
          <div className="mb-4 card card-small bg-card-cofaqui">
            <div className="px-4 py-4">
              <Grid
                hover
                page={page || 1}
                data={
                  data || {
                    results: [],
                    count: 0,
                  }
                }
                loading={loader || false}
                // onPageChange={false}
                // onSortChange={onSortChange || false}
                bodyContainerClass = "bc-professions-custom"
                headerContainerClass = "hc-professions-custom"
                tableContainerClass = "professions-container-table-sm"
                tableHeaderClass = "table-header-professions"
              >
                <TableHeaderColumn
                  dataField="name_institution"
                  dataAlign="center"
                  width="200px"
                  dataFormat={(cell, row) => {
                    return <span>{cell}</span>;
                  }}
                >
                  Nombre del establecimiento
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="direction_institution"
                  dataAlign="center"
                  width="200px"
                >
                  Direccion del establecimiento
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="type_institution"
                  dataAlign="center"
                  width="200px"
                >
                  Tipo del establecimiento
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="date_aprobation"
                  width="200px"
                  dataFormat={(cell, row) => {
                    return <span>{cell}</span>;
                  }}
                >
                  Fecha de aprobación
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="no_stamp"
                  dataAlign="center"
                  width={"200px"}
                >
                  No. Timbre
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataField="id"
                  dataAlign="center"
                  width={"100px"}
                  dataFormat={standardActions({
                    eliminar: deleteDirection,
                    editarConAction: setDataEditDirection,
                  })}
                >
                  Acciones
                </TableHeaderColumn>
              </Grid>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="col-12 d-flex justify-content-center align-items-center mt-5">
          <h3 className="text-center">No hay datos para mostrar</h3>
        </div>
      )}
    </div>
  );
};

export default TableList;
