// eslint-disable-next-line
import {api} from "api";
import moment from "moment";
import {handleActions} from "redux-actions";
import {addIndexToArray} from "../../../../utility/constants";
import {createReducer} from "../../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const baseReducer = createReducer(
  "binnacle",
  "binnacle",
  "BinnacleForm",
  "/binnacle",
);
const DATE_START = "DATE_START_FILTER_BINNACLE"
const DATE_END = "DATE_END_FILTER_BINNACLE"
const USER_ID = "USER_ID_FILTER_BINNACLE"

const {setLoader, setData, setPage} = baseReducer.actions;

const setDateStart = dateStart => ({
  type: DATE_START,
  dateStart,
});
const setDateEnd = dateEnd => ({
  type: DATE_END,
  dateEnd,
});
const setUserId = userId => ({
  type: USER_ID,
  userId,
});

const listar = (page = 1) => (dispatch, getStore) => {
  const resource = getStore()["binnacle"];
  const params = {page};
  params.ordering = resource.ordering;
  params.search = resource.search;
  if (resource.userId) {
    params.userId = resource.userId;
  }
  params.dateEnd = resource.dateEnd;
  params.dateStart = resource.dateStart;
  dispatch(setLoader(true));
  api.get("binnacle", params).then((response) => {
    if (response.results) {
      response.results = addIndexToArray(response.results, page)
    }
    dispatch(setData(response));
    if(response && response.page) {
      dispatch(setPage(response.page))
    } else {
      dispatch(setPage(page));
    }
  }).catch(() => {
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const changeDateStart = (date) => (dispatch, getStore) => {
  const resource = getStore()["binnacle"];
  dispatch(setDateStart(date))
  dispatch(listar(resource.page))
}
const changeDateEnd = (date) => (dispatch, getStore) => {
  const resource = getStore()["binnacle"];
  dispatch(setDateEnd(date))
  dispatch(listar(resource.page))
}
const changeUser = ({id}) => (dispatch) => {
  dispatch(setUserId(id))
  dispatch(listar(1))
}
const resetValueUser = () => (dispatch) => {
  const today = moment(new Date()).format("YYYY-MM-DD")
  dispatch(setUserId(null))
  dispatch(setDateStart(today))
  dispatch(setDateEnd(today))
}

export const actions = {
  ...baseReducer.actions,
  changeDateStart,
  resetValueUser,
  changeDateEnd,
  changeUser,
  listar,
}

const reducers = {
  ...baseReducer.reducers,
  [DATE_START]: (state, {dateStart}) => {
    return {
      ...state,
      dateStart,
    }
  },
  [DATE_END]: (state, {dateEnd}) => {
    return {
      ...state,
      dateEnd,
    }

  },
  [USER_ID]: (state, {userId}) => {
    return {
      ...state,
      userId
    }
  },
}

const initialState = {
  ...baseReducer.initialState,
  dateStart: moment(new Date()).format("YYYY-MM-DD"),
  dateEnd: moment(new Date()).format("YYYY-MM-DD"),
  userId: null,
}

export default handleActions(reducers, initialState);
