import "chart.js/auto";
import React, { Fragment } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PieController,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, PieController);

const ResumenAgremiados = ({ datos = [] }) => {

  const values = datos.map((item) => item.value);
  const labels = datos.map((item) => item.label);

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          "#36A2EB",
          "#AD6EDF",
          "#B7B7B7",
          "#FF6384",
          "#FFCE56",
        ],
      },
    ],
  };

  const options = {
    aspectRatio: 2,
    plugins: {
      legend: {
        position: window.innerWidth < 425 ? "bottom" : "right",
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          padding: 20,
          font: {
            size:
              window.innerWidth < 425
                ? 8
                : window.innerWidth < 850
                ? 16
                : window.innerWidth > 1516
                ? 16
                : 10,
          },
        },
      },
    },
  };

  return (
    <div className="w-100">
      <div className="members-by-state">
        <div className="d-flex justify-content-between">
          <p className="title-grafica">Resumen de agremiados por estado</p>
        </div>
        <div style={{ lineHeight: "10px" }}>
          <br />
        </div>
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default ResumenAgremiados;
