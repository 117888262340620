import React from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-date-picker";
import { ErrorMessage } from "@hookform/error-message";

const CreateEditDirection = ({ createDirection, data, loader, updateDirection, is_edit }) => {
  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name_institution: data.name_institution,
      direction_institution: data.direction_institution,
      type_institution: data.type_institution,
      date_aprobation: data.date_aprobation,
      no_stamp: data.no_stamp,
    },
  });
  const onSubmit = (data_submit) => {
    if (is_edit) {
      data_submit['id'] = data.id;
      data_submit['beneficiary'] = data.beneficiary;
      updateDirection(data_submit);
    }
    else {
      createDirection(data_submit);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Nombre del establecimiento</label>
          <input
            type="text"
            className="form-control"
            name="name_institution"
            {...register("name_institution", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name_institution"
            render={({ message }) => (
              <p className="text-danger p-0 m-0 pl-2">{message}</p>
            )}
          />
        </div>
        <div className="form-group">
          <label>Direccion del establecimiento</label>
          <input
            type="text"
            className="form-control"
            name="direction_institution"
            {...register("direction_institution", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="direction_institution"
            render={({ message }) => (
              <p className="text-danger p-0 m-0 pl-2">{message}</p>
            )}
          />
        </div>
        <div className="form-group">
          <label>Tipo del establecimiento</label>
          <input
            type="text"
            className="form-control"
            name="type_institution"
            {...register("type_institution", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="type_institution"
            render={({ message }) => (
              <p className="text-danger p-0 m-0 pl-2">{message}</p>
            )}
          />
        </div>
        <div className="d-flex">
          <div className="form-group flex-grow-1 px-1">
            <label>Fecha de aprobación</label>
            <Controller
              name="date_aprobation"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  format={"dd/MM/yyyy"}
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  className={`form-control`}
                  onChange={onChange}
                  value={value}
                />
              )}
              //validaditos de fecha
              rules={{
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name="date_aprobation"
              render={({ message }) => (
                <p className="text-danger p-0 m-0 pl-2">{message}</p>
              )}
            />
          </div>
          <div className="form-group flex-grow-1 px-1">
            <label>No. de Timbre</label>
            <input
              type="text"
              className="form-control"
              name="no_stamp"
              {...register("no_stamp", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="no_stamp"
              render={({ message }) => (
                <p className="text-danger p-0 m-0 pl-2">{message}</p>
              )}
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-success w-100"
          disabled={loader}
        >
          Guardar
        </button>
      </form>
    </div>
  );
};

export default CreateEditDirection;
