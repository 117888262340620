import React from "react";
import { Link } from "react-router-dom";

import ForgotMyPasswordForm from "./ForgotMyPasswordForm";

import womanscientist from "../../../../../assets/img/womanscientist.png";
import logo from "../../../../../assets/img/logo.png";

import "./forgotMyPassword.css";

const ForgotMyPassword = ({
  forgotMyPassword,
}) => {
  return (
    <div className="h-100">
      {/* <LoadMask loading={false} light> */}
      <div className="container-fluid" style={{ color: "#4B4D58" }}>
        <div className="row d-flex flex-row">
          <div className="d-none d-md-block d-lg-block d-xl-block col-lg-7 col-md-6">
            <div className="row h-100">
              <img src={womanscientist} className="img-fluid" style={{ minHeight: "100%", objectFit: "cover" }} alt="woman-scientist" />
            </div>
          </div>
          <div className="col- col-sm-12 col-md-6 col-lg-5 d-flex flex-row align-items-center justify-content-center forgot-password-form" style={{ minHeight: "100vh" }}>
            <div className="card" style={{ background: "#E2DDD9", borderRadius: "10px", minHeight: "70%", minWidth: "55%", zIndex: 11 }}>
              <div className="d-flex flex-column p-5">
                <img src={logo} className="card-img-top" alt="logo" style={{ height: "60%", width: "60%", alignSelf: "center" }} />
                <div className="card-body d-flex flex-column align-items-center">
                  <h4 className="card-title text-center" style={{ fontWeight: "bold" }}>Olvidaste tu contraseña</h4>
                  <ForgotMyPasswordForm forgotMyPassword={forgotMyPassword} />
                  <span>
                    <Link to="/login">
                      <span style={{ color: "#0A4725" }}>
                        Regresar
                      </span>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </LoadMask> */}
    </div>
  );
}

export default ForgotMyPassword;