import React, { useEffect } from "react";
import Grid from "../../../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import PageTitle from "../../../../../components/layout/PageTitle";
import { standardActions } from "../../../../../components/Utils/Grid/StandardActions";

import "./list.css";

const DocumentsList = ({
  getGeneratedDocuments,
  deleteGeneratedDocument,
  generatedDocuments,
  page,
  loader,
  onSortChange,
  match,
  stampTitle,
}) => {

  const { id } = match.params;

  useEffect(() => {
    getGeneratedDocuments(id);
  }, []);

  return (
    <div className="container-fluid">
      <PageTitle
        title={stampTitle}
        withLink={true}
        linkTo={"generate-new-document"}
        linkLabel={"Nuevo documento"}
        colTitle="col-12"
      />
      <div className="row p-4" style={{
        backgroundColor: "#E2DDD9",
        overflow: "auto",
        borderRadius: "20px",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.25)"
      }}>
        <Grid
          hover
          responsive
          data={generatedDocuments}
          page={page}
          loading={loader}
          onPageChange={getGeneratedDocuments}
          onSortChange={onSortChange}
          tableHeaderClass={"table-header-stamps"}
        //   trClassName={"table-tr-roles"}
        >
          <TableHeaderColumn
            width="60px"
            isKey
            dataField="number_document"
            dataAlign="center"
            dataFormat={(cell, row, enumObject, index) => {
              return index + 1;
            }}
          >
            No
          </TableHeaderColumn>
          <TableHeaderColumn
            width="125px"
            dataAlign="center"
            dataField="title"
          >
            Título del documento
          </TableHeaderColumn>
          <TableHeaderColumn
            width="100px"
            dataAlign="center"
            dataField="document_orientation"
          >
            Formato
          </TableHeaderColumn>
          <TableHeaderColumn
            width="90px"
            dataField="id"
            dataAlign="center"
            dataFormat={standardActions({
              editar: "generated-document",
              eliminar: deleteGeneratedDocument,
            })}
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default DocumentsList;
