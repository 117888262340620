import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import Swal from "sweetalert2";

const PROFESSION_DATA = "PROFESSION_DATA";
const PROFESSION_LIST = "PROFESSION_LIST";
const LOADER = "PROFESSION_LOADER";
const PAGE = "PROFESSION_PAGE";
const ORDERING = "PROFESSION_ORDERING";
const SEARCH = "PROFESSION_SEARCH";

export const constants = {
  PROFESSION_DATA,
  PROFESSION_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setProfessionData = data => ({
  type: PROFESSION_DATA,
  data,
});

export const setProfessionList = data => ({
  type: PROFESSION_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

const setSearch = search => ({
  type: SEARCH,
  search,
});

// ------------------------------------
// Actions
// ------------------------------------

const getProfessions = (page = 1) => (dispatch, getStore) => {
  const search = getStore().professions.search;
  const params = { page };

  if (search) {
    params.search = search;
  }

  dispatch(setLoader(true));

  api.get("/profession", params)
    .then(response => {
      // NotificationManager.success("Informacion cargada exitosamente", "Success", 3000);
      dispatch(setProfessionList(response));
      dispatch(setPage(page));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const getProfession = id => dispatch => {
  dispatch(setLoader(true));

  api.get(`/profession/${id}`)
    .then(response => {
      // NotificationManager.success("Informacion cargada exitosamente", "Success", 3000);
      dispatch(setProfessionData(response));
    })
    .catch((error) => {
      if (error && error.details) {
        NotificationManager.error(error.details, "Error", 3000);
      } else {
        NotificationManager.error("Error al obtener el registro", "Error", 3000);
      }
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const createProfession = data => dispatch => {
  dispatch(setLoader(true));

  api.post("/profession", data)
    .then(response => {
      NotificationManager.success("Profesion creada exitosamente", "Success", 3000);
      dispatch(push("/professions"));
    })
    .catch((error) => {
      if(error && error.details){
        NotificationManager.error(error.details, "ERROR", 3000);
      } else {
        NotificationManager.error("Error en la creación", "ERROR", 3000);
      }
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const updateProfession = (id, data) => dispatch => {
  dispatch(setLoader(true));

  api.put(`/profession/${id}`, data)
    .then(response => {
      NotificationManager.success("Profesion actualizada exitosamente", "Success", 3000);
      dispatch(push("/professions"));
    })
    .catch((error) => {
      if(error && error.details){
        NotificationManager.error(error.details, "ERROR", 3000);
      } else {
        NotificationManager.error("Error al actualizar la profesion", "ERROR", 3000);
      }
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const deleteProfession = id => dispatch => {
  dispatch(setLoader(true));

  api.eliminar(`/profession/${id}`)
    .then(response => {
      NotificationManager.success("Profesion eliminada exitosamente", "Success", 3000);
      dispatch(getProfessions());
    })
    .catch((error) => {
      if(error && error.details){
        NotificationManager.error(error.details, "ERROR", 3000);
      }
      else if(error && error.details_user_validation){
        Swal.fire({
          title: "Error",
          text: error.details_user_validation,
          type: "error",
          confirmButtonText: "Aceptar",
        });
      }
      else {
        NotificationManager.error("Error al eliminar la profesion", "ERROR", 3000);
      }
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const searchProfession = search => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getProfessions());
}

const onSortChange = ordering => (dispatch, getStore) => {
  const sort = getStore().professions.ordering;
  if(sort === ordering){
    dispatch(setOrdering(`-${ordering}`));
  } else {
    dispatch(setOrdering(ordering));
  }
  dispatch(getProfessions());
}

export const actions = {
  getProfessions,
  getProfession,
  createProfession,
  updateProfession,
  deleteProfession,
  searchProfession,
  onSortChange,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [PROFESSION_DATA]: (state, { data }) => {
    return {
      ...state,
      professionData: data,
    };
  },
  [PROFESSION_LIST]: (state, { data }) => {
    return {
      ...state,
      professionList: data,
    };
  },
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
};

export const initialState = {
  loader: false,
  professionData: {},
  professionList: {
    count: 0,
    results: [],
  },
  page: 1,
  ordering: "",
  search: "",
};

export default handleActions(reducers, initialState);
