import React, { Fragment } from "react";
import { ProgressBar } from "react-bootstrap";

const PerfilUsuario = ({
  nombre,
  tipo,
  cantidad,
  avatar,
  cantidad_transacciones,
  transacciones_porcentaje,
  total_transacciones,
}) => {
  return (
    <Fragment>
      <style type="text/css">
        {`
        .bg-warning {
            background-color: #EFD14A !important;
        }
        `}
      </style>
      <div className="d-flex align-items-center perfil-usuario ">
        <div
          style={{
            width: "35px",
            height: "35px",
            backgroundColor: "grey",
            borderRadius: "50%",
          }}
        >
          {avatar && (
            <img
              src={avatar}
              alt="avatar"
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          )}
        </div>
        <div className="d-flex flex-column ml-2 flex-grow-1">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-between ">
              <p className="nombre-colegiado m-0">{nombre}</p>
              <p className="tipo-colegiado m-0 ml-4">{tipo}</p>
            </div>
            <div className="d-flex justify-content-between w-50">
              <div className="cantidad-usuario ">
                <p className="m-0 text-center">Q{cantidad}</p>
              </div>
              <div className="inf-usuario-transacciones d-flex text-end">
                <p className="cantidad-transacciones-porcentaje m-0">
                  {transacciones_porcentaje}%
                </p>
                &nbsp;
                <p className="cantidad-transacciones m-0">
                  ({cantidad_transacciones}/{total_transacciones})
                </p>
              </div>
            </div>
          </div>
          <div className="flex-grow-1" style={{ width: "100%" }}>
            <ProgressBar
              now={cantidad_transacciones}
              min={0}
              max={total_transacciones}
              variant="warning"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PerfilUsuario;
