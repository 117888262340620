import React, {useEffect, useState} from "react";
import MemberForm from "./MemberForm";
import SpouseForm from "./SpouseForm";
import CollegiateForm from "./CollegiateForm";
import BeneficiaryForm from "./BeneficiaryForm";
import StepsCFQ from "../../../components/layout/Steps";
import LoadMask from "../../../components/Utils/LoadMask/LoadMask";
import {api} from "../../../../utility/api";
import ModalSuccess from "./ModalSuccess";
import TitlePageCreate from "../../../components/layout/TitleCreate";

const TITLE_WITH_SPOUSE = [
  {name: "Datos Generales", no: 1},
  {name: "Colegiado", no: 2},
  {name: "Cónyuge", no: 3},
  {name: "Beneficiario", no: 4},
]

const TITLE_WITHOUT_SPOUSE = [
  {name: "Datos Generales", no: 1},
  {name: "Colegiado", no: 2},
  {name: "Beneficiario", no: 3},
]

const Create = (props) => {
  const [step, setStep] = useState(1);
  const [withSpouse, setWithSpouse] = useState(true);
  const [countries, setCountries] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [rolesOption, setRolesOption] = useState([]);
  const [defaultUni, setDefaultUni] = useState([]);
  const [defaultProfession, setDefaultProfession] = useState([]);
  const [defaultProfCol, setDefaultProfCol] = useState([]);

  const {
    item,
    leer,
    create,
    update,
    loader,
    dataDep,
    dataMunResi,
    dataMunBirth,
    setItemValues,
    getDepartments,
    changeCurrentStep,
    changeTotalStep,
    getMunicipalities,
    getCorrelativeNumber,
    showModalSuccess,
    closeModalSuccess,
    correlativeNumber,
    me,
    changeValueSecondaryUser,
    match: {path, url},
  } = props;

  const [MemberStep2, setMemberStep2] = useState({});
  const [redirectAfterUpdate, setRedirectAfterUpdate] = useState("");

  useEffect(() => {
    if (correlativeNumber > 0) {
      setMemberStep2({
        no_collegiate: correlativeNumber,
      });
    }
  }, [correlativeNumber]);

  useEffect(() => {
    // get options values
    getRoles()
    getCountries()
    getDepartments()
    const {params} = props.match
    // clear values form
    const MemberStep1 = {}
    const MemberStep3 = {}
    const MemberStep4 = {customers: [{}]}
    setItemValues({})
    setDefaultProfCol([])
    setDefaultProfession([])
    setItemValues({MemberStep1, MemberStep2, MemberStep3, MemberStep4});
    // if is update
    const { match: { url } } = props;
    if (params && params.id && !url.includes("managements/update-my-information")) {
      setIsUpdate(true)
      leer(params.id)
    } else if (url.includes("managements/update-my-information")) {
      setRedirectAfterUpdate("/managements")
      setIsUpdate(true)
      const {id} = me
      leer(id)
    } else {
      getCorrelativeNumber()
    }
    getUniversities().then((opts) => {
      setDefaultUni(opts)
    })
    getProfession().then((opts) => {
      setDefaultProfession(opts)
    })
    getProfessionPartOfUnion().then((opts) => {
      setDefaultProfCol(opts)
    })
  }, [])

  useEffect(() => {
    const {MemberStep1} = item;
    if (MemberStep1 && MemberStep1.id && isUpdate) {
      getMunicipalities(MemberStep1.birth_department_id, 2)
      getMunicipalities(MemberStep1.department_of_residence_id, 1)
      validateStepSpouse(MemberStep1.marital_status)
    }
  }, [item])

  const changeStep = (currStep) => {
    if ((currStep < step)) {
      setStep(currStep)
      changeCurrentStep(currStep)
    } else {
      const btn = document.getElementById(`btn-step-${step}`);
      if (btn) btn.click()
    }
  }

  const prevStep = () => {
    setStep(step - 1)
    changeCurrentStep(step - 1)
  }

  const nextStep = () => {
    changeCurrentStep(step + 1)
    setStep(step + 1)
  }

  const onSubmit = (data) => {
    if (isUpdate && !url.includes("managements/update-my-information")) {
      update(props.match.params.id, data, withSpouse, redirectAfterUpdate)
    }
    else if (url.includes("managements/update-my-information")) {
      update(me.id, data, withSpouse, redirectAfterUpdate)
    }
    else {
      create(data, withSpouse)
    }
  }
  const percentage = (form, withSpouse) => {
    let total = 0
    if (!withSpouse) {
      return 0
    } else if (form && form.values && form.values.is_beneficiary && form.values.percentage) {
      total = Number(form.values.percentage)
    }
    return total
  }

  const getRoles = () => {
    api.get("role/list_all").then((resp) => {
      setRolesOption(resp)
    }).catch(() => {
    }).finally(() => {
    })
  }

  const getUniversities = (search = "") => {
    return api.get("university/get_all_universities", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }

  const getProfession = (search = "") => {
    return api.get("profession/get_all_professions", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) {
        _options = resp.results
        _options.push({"id": 0, name: "Otro"})
      }
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }

  const getProfessionPartOfUnion = (search = "") => {
    return api.get("profession/get_professions_part_of_union", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }

  const getCountries = () => {
    api.get("country/all_data", {}).then((resp) => {
      setCountries(resp)
    }).catch(() => {
    }).finally(() => {
    })
  }

  const validateStepSpouse = (status) => {
    if (status === 5 || status === 20) {
      setWithSpouse(true)
      changeTotalStep(4)
    } else {
      changeTotalStep(3)
      setWithSpouse(false)
    }
  }

  const {permissions} = me;
  return (
    <div className="pt-5 container-fluid">
      <TitlePageCreate
        redirectTo={url.includes("managements/update-my-information") ? "/managements" : "/"}
        title={withSpouse ? TITLE_WITH_SPOUSE.find(ele => ele.no === step).name : TITLE_WITHOUT_SPOUSE.find(ele => ele.no === step).name}
      />
      <ModalSuccess
        openModal={showModalSuccess}
        actionBtn={closeModalSuccess}
        closeModalAct={closeModalSuccess}
        title={isUpdate ? "Agremiado actualizado" : "Agremiado guardado"}
        msg={isUpdate ? "El agreamiado ha sido actualizado con éxito.": "El nuevo agremiado ha sido guardado con éxito."}
      />
      <LoadMask light loading={loader}>
        <StepsCFQ current={step} clickAction={changeStep} totalSteps={withSpouse ? 4 : 3}/>
        <MemberForm
          show={step === 1}
          dataDep={dataDep}
          onSubmit={nextStep}
          dataMunResi={dataMunResi}
          countriesList={countries}
          permissions={permissions}
          roleSelectList={rolesOption}
          initialValues={item.MemberStep1}
          getMunicipalities={getMunicipalities}
          actionMaritialStatus={validateStepSpouse}
          dataMunBirth={dataMunBirth.length ? dataMunBirth : []}
          path={path}
        />
        <CollegiateForm
          show={step === 2}
          onSubmit={nextStep}
          defaultUni={defaultUni}
          professionList={defaultProfCol}
          values={isUpdate ? item.MemberStep2 : MemberStep2}
          getProfession={getProfessionPartOfUnion}
          changeValueSecondaryUser={changeValueSecondaryUser}
          getUniversities={getUniversities}
          actionBack={prevStep}/>
        <SpouseForm
          onSubmit={nextStep}
          getProfession={getProfession}
          professionList={defaultProfession}
          show={withSpouse ? (step === 3) : false}
          initialValues={isUpdate?item.MemberStep3: {}}
          actionBack={prevStep}/>
        <BeneficiaryForm
          onSubmit={onSubmit}
          actionBack={prevStep}
          initialValue={isUpdate?item.MemberStep4: {}}
          show={withSpouse ? (step === 4) : (step === 3)}
          dataSpouse={withSpouse ? props.MemberStep3 : null}
          percentageSpouse={percentage(props.MemberStep3, withSpouse)}
        />
      </LoadMask>
    </div>
  )
}
export default Create;
