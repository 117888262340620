import {handleActions} from "redux-actions";
import {createReducer} from "../../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
// eslint-disable-next-line
import {api} from "api";
import {push} from "react-router-redux";

// ------------------------------------
// Constants
// ------------------------------------
const STEPS = "STEP_FORM_PRODUCTS";
const CATEGORIES = "CATEGORIES_SELECT_PRODUCTS";
const CURRENT_STEP = "CURRENT_STEP_FORM_PRODUCTS";


const baseReducer = createReducer(
  "products",
  "product",
  "ProductForm",
  "/products",
);
const {setLoader} = baseReducer.actions;
const endpoint = "product";
const resourceList = "/products";

const setSteps = stepsProduct => ({
  type: STEPS,
  stepsProduct,
});

const setCurrentStep = currentStepProduct => ({
  type: CURRENT_STEP,
  currentStepProduct,
});

const setCategories = categories => ({
  type: CATEGORIES,
  categories,
});


// Actions
const create = (data) => (dispatch, getStore) => {
  const forms = getStore()["form"]
  let form1 = forms.ProductsStep1.values;
  let dataEnd = {general_data: {...form1, ...data}}
  dispatch(setLoader(true));
  api.post(endpoint, dataEnd).then(() => {
    NotificationManager.success("Producto creado exitosamente", "Exito", 3000);
    dispatch(push(resourceList))
  }).catch(() => {
    NotificationManager.error("Error en la creación", "ERROR");
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const update = (id, data) => (dispatch, getStore) => {
  const forms = getStore()["form"]
  let form1 = forms.ProductsStep1.values;
  let dataEnd = {data_user: {...form1, ...data}}
  dispatch(setLoader(true));
  api.put(`${endpoint}/${id}`, dataEnd).then(() => {
    NotificationManager.success("Producto actualizado exitosamente", "Exito", 3000);
    dispatch(push(resourceList))
  }).catch(() => {
    NotificationManager.error("Error en la actualización del agremiado", "ERROR");
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const updateOnlyGeneralData = (data) => (dispatch) => {
  dispatch(setLoader(true));
  api.put(`${endpoint}/${data.id}/update_general_data`, data).then(() => {
    NotificationManager.success("Producto actualizado exitosamente", "Exito", 3000);
    dispatch(push(resourceList))
  }).catch(() => {
    NotificationManager.error("Error en la actualización del agremiado", "ERROR");
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const getCategories = (page = 1) => (dispatch) => {
  const params = { page };
  dispatch(setLoader(true));
  api.get("expense_category", params).then(response => {
    dispatch(setCategories(response));
  }).catch(error => {
    console.log("error in redux product", error)
    NotificationManager.error("Error al obtener las categorias", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const openSelectCategories = (value) => (dispatch, getStore) => {
  const dataCat = getStore().products.categories;
  dataCat.forEach(category => {
    if (value == category.value) {
      category.seleccionado = !category.seleccionado
    } else {
      category.children.forEach(sub => {
        if (value == sub.value) {
          sub.seleccionado = !sub.seleccionado
        } else {
          sub.children.forEach(sub1 => {
            if (value == sub1.value) {
              sub1.seleccionado = !sub1.seleccionado
            } else {
              sub1.children.forEach(sub12 => {
                if (value == sub12.value) {
                  sub12.seleccionado = !sub12.seleccionado
                } else {
                  sub12.children.forEach(sub2 => {
                    if (value == sub12.value) {
                      sub12.seleccionado = !sub12.seleccionado
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
  })
  dispatch(setCategories(dataCat))
}

const changeTotalStep = (totalSteps) => (dispatch) => {
  dispatch(setSteps(totalSteps))
}

const changeCurrentStep = (step) => (dispatch) => {
  dispatch(setCurrentStep(step))
}


export const actions = {
  create,
  update,
  getCategories,
  changeTotalStep,
  changeCurrentStep,
  openSelectCategories,
  updateOnlyGeneralData,
  ...baseReducer.actions,
}

const reducers = {
  ...baseReducer.reducers,
  [STEPS]: (state, {stepsProduct}) => {
    return {
      ...state,
      stepsProduct,
    };
  },
  [CURRENT_STEP]: (state, {currentStepProduct}) => {
    return {
      ...state,
      currentStepProduct,
    };
  },
  [CATEGORIES]: (state, { categories }) => {
    return {
      ...state,
      categories,
    };
  },
}
const initialState = {
  ...baseReducer.initialState,
  stepsProduct: 2,
  categories: [],
  currentStepProduct: 1,
}

export default handleActions(reducers, initialState);
