import React from 'react';
import {Field, Form} from 'react-final-form';
import {fieldRequired} from "../../../../../utility/validation";
import {renderField, renderSwitch} from "../../../../components/Utils/renderField";


const ExpenseForm = ({onSubmit, closeModal, initialValues, loader}) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit}) => (
        <form name="ExpenseForm" className="form-validate w-100" onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center flex-wrap">
            <div className="form-group has-feedback col-lg-10 col-md-11 col-sm-12">
              <label className="t-azul" htmlFor="name">Nombre</label>
              <Field
                name="name"
                type="text"
                component={renderField}
                className="form-control"
                validate={fieldRequired}
              />
            </div>
            <div className="form-group has-feedback col-lg-10 col-md-11 col-sm-12 d-flex">
                  <label htmlFor="is_insurance">¿Es un gasto por seguro?</label>
                  <Field
                    name='is_insurance'
                    width={40}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    component={renderSwitch}
                    customClassCon={"switch-step3 ml-3"}
                  />
            </div>
          </div>
          <div className="w-100 mt-4 mb-2">
            <div className="col-12">
              <div
                className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                <button
                  type="button"
                  disabled={loader}
                  onClick={closeModal}
                  className="btn btn-secondary align-self-center col-lg-5 col-md-4 col-sm-12"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={loader}
                  className="btn btn-create m-1 col-lg-5 col-md-4 col-sm-12"
                >
                  <span className="icon-save" style={{width: '20px', height: '20px'}}>&nbsp;</span>
                  <span className="ml-2">Guardar</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  )
};


export default ExpenseForm;
