import TransactionForm from "./Form"
import { api } from "../../../../utility/api"
import React, { useEffect, useState } from "react"
import LoadMask from "../../../components/Utils/LoadMask/LoadMask"
import TitlePageCreate from "../../../components/layout/TitleCreate"
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { PR_APPROVED, PURCHASE_REQUEST_STATUS } from "../../../../utility/constants";


const CreateCashRegister = (props) => {
  const [totalAmount, setTotalAmount] = useState(0)
  const [dataProducts, setDataProducts] = useState([])
  const [itemSelected, setItemSelected] = useState(null)
  const [directPurchases, setDirectPurchases] = useState([])
  const [professionMember, setProfessionMember] = useState(null)
  const [defaultOptionProd, setDefaultOptionProd] = useState([])
  const [defaultOptionMembers, setDefaultOptionMembers] = useState([])
  const [initialData, setInitialData] = useState({ transaction_date: new Date(), direct_purchases: true})
  const [lockBtnAdd, setLockBtnAdd] = useState(false)

  const {
    me,
    loader,
    dateOpeningCR,
    createDirectPurchase,
    getOriginOrDestinationBanks,
    originBanksList,
    destinationBanksList,
    paymentLink,
    paymentLoader,
    createPaymentLink,
  } = props

  useEffect(() => {
    if (me && me.id) {
      setProfessionMember(me.profession_id)
    }
  }, [me])

  useEffect(() => {
    // set default values
    setDefaultOptionMembers([])
    setDataProducts([])
    setItemSelected(null)
    loadDirectPurchases().then(result => {
      setDirectPurchases(result)
      setTimeout(() => {
        if (result.length > 0) {
          setInitialData({...initialData, cash_register_id: result[0].value})
        } else {
          setInitialData({...initialData})
        }
      }, 500)
    })
  }, [])

  useEffect(() => {
    if (professionMember) {
      loadProducts().then((_data) => {
        setDefaultOptionProd(_data)
      })
    }
  }, [professionMember])

  const selectMember = (itemSel) => {
    setProfessionMember(itemSel.profession_id)
    // reset default values
    setTotalAmount(0)
    setDataProducts([])
    setItemSelected(null)
  }

  const loadProducts = (search = "") => {
    const params = { search, profession_id: me.profession_id }
    return api.get("product/products_and_stamps", params).then((resp) => {
      let _options = [];
      if (resp && resp.length) _options = resp
      return _options
    }).catch((err) => {
      if (err && err.details) {
        NotificationManager.error(err.details, "Error", 8000)
      }
      // console.log("Error to load products", err)
    }).finally(() => {
    });
  }

  const loadDirectPurchases = () => {
    return api.get("cash_register/direct_purchases").then((resp) => {
      if (resp && resp.length) {
        return resp.map(item => ({ value: item.id, label: item.name }))
      }
      return []
    }).catch((err) => {
      if (err && err.details) {
        NotificationManager.error(err.details, "Error", 8000)
      }
      if (err && err.body && err.body.details) {
        NotificationManager.error(err.body.details, "Error", 8000)
      }
      return []
    }).finally(() => {
    });
  }

  function getSumAmount(accumulated, item) {
    let quantity = item.quantity ? parseInt(item.quantity) : 0
    return parseFloat(accumulated) + (parseFloat(item.amount) * quantity);
  }

  function addOrderToItem(arrayProd) {
    for (const arrayProdKey in arrayProd) {
      arrayProd[arrayProdKey].order = parseInt(arrayProdKey) + 1
    }
  }

  function _finalAddProd(code, rest) {
    let dataCopy = dataProducts
    if(rest) dataCopy = []
    if (dataCopy.find(element => (element.id === itemSelected.id && element.is_stamp === itemSelected.is_stamp)) === undefined) {
      dataCopy.push({ ...itemSelected, quantity: 1 , code})
      addOrderToItem(dataCopy)
      setDataProducts(dataCopy)
      setItemSelected(null)
    }
    setTotalAmount(dataCopy.reduce(getSumAmount, 0))
  }

  const validateProduct = () => {
    Swal.fire({
      title: "Producto bajo aprobación",
      icon: "warning",
      text: "Este es un producto bajo aprobación, por lo que debe ingresar el código de la solicitud de compra",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      cancelButtonText: "<span class=\"material-icons float-left lh-18px ml-2\">close</span>&nbsp;Cancelar&nbsp;&nbsp;",
      confirmButtonText: "<span class=\"material-icons float-left lh-18px ml-2\">done</span>&nbsp;Validar&nbsp;&nbsp;&nbsp;&nbsp;",
      showLoaderOnConfirm: true,
      focusCancel: false,
      focusConfirm: false,
      reverseButtons: true,
      background: "#FFFFFF",
      customClass: {
        cancelButton: "btn btn-gray px-3 br-20px",
        confirmButton: "btn btn-success-important px-3 br-20px",
        input: "form-control col-10 mx-auto"
      },
      preConfirm: (inputValue) => {
        return api.get("purchase_request/status_by_code", { purchase_request_code: inputValue, member_id: me.id })
          .then(response => {
            if (response && response.purchase_request_status !== PR_APPROVED) {
              const status = PURCHASE_REQUEST_STATUS.find(ele => ele.value == response.purchase_request_status).label
              Swal.showValidationMessage(`El producto: ${itemSelected.name}, no ha sido aprobado. Estado actual: ${status}`)
            } else {
              return {res: response, code: inputValue}
            }
          })
          .catch(error => {
            if (error && error.body && error.body.details) {
              Swal.showValidationMessage(error.body.details)
            } else if (error && error.details) {
              Swal.showValidationMessage(error.details)
            } else {
              Swal.showValidationMessage("Error al validar el estado de la solicitud!")
            }
          })
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setTotalAmount(0)
        setDataProducts([])
        setLockBtnAdd(true)
        _finalAddProd(result.value.code, true)
      }
      else {
        setItemSelected(null)
      }
    })
  }

  const AddProduct = () => {
    if (itemSelected) {
      if (itemSelected.under_approved == true) {
        validateProduct()
      } else {
        _finalAddProd()
      }
    }
  }

  const changeQuantityItem = (row, newQuantity) => {
    if (row) {
      let dataCopy = dataProducts
      const index = dataCopy.indexOf(row)
      if (dataCopy.indexOf(row) !== -1) {
        dataCopy[index].quantity = newQuantity
        setDataProducts(dataCopy)
      }
      setTotalAmount(dataCopy.reduce(getSumAmount, 0))
    }
  }

  const ActionDeleteItem = (idItem) => {
    const result = dataProducts.filter(item => item.order !== idItem);
    const prod = dataProducts.find(item => item.order === idItem);
    if (prod && prod.under_approved) {
      setLockBtnAdd(false)
    }
    addOrderToItem(result)
    setDataProducts(result)
    setTotalAmount(result.reduce(getSumAmount, 0))
  }

  const actionSubmit = (data) => {
    let errors = []
    for (const dataKey in dataProducts) {
      if (dataProducts[dataKey].quantity === "" || dataProducts[dataKey].quantity == 0) {
        errors.push(dataProducts[dataKey].id)
      }
    }
    if (errors.length) {
      NotificationManager.error(`Debe ingresar la cantidad a vender de los elementos: [${errors}]`, "Error", 9000)
    } else if (dataProducts.length === 0) {
      NotificationManager.error("Debe ingresar al menos un producto", "Error", 4000)
    } else {
      createDirectPurchase({ ...data, member_id: me.id, amount: totalAmount, products: dataProducts })
    }
  }

  return (
    <div className="pt-5 mt-md-0">
      <TitlePageCreate
        redirectTo="/managements"
        title={`Compra Directa - (${(me && me.full_name) ? me.full_name : ""})`}
      />
      <LoadMask dark blur loading={loader || paymentLoader}>
        <TransactionForm
          me={me}
          total={totalAmount}
          lockBtnAdd={lockBtnAdd}
          onSubmit={actionSubmit}
          actionBntAdd={AddProduct}
          itemSelected={itemSelected}
          setItemSel={setItemSelected}
          dataProducts={dataProducts}
          loadProducts={loadProducts}
          directPurchases={directPurchases}
          initialData={initialData}
          setMember={selectMember}
          minDate={dateOpeningCR}
          actionDelete={ActionDeleteItem}
          defaultOptionProd={defaultOptionProd}
          changeQuantityItem={changeQuantityItem}
          defaultOptionMembers={defaultOptionMembers}
          getOriginOrDestinationBanks={getOriginOrDestinationBanks}
          originBanksList={originBanksList}
          destinationBanksList={destinationBanksList}
          paymentLink={paymentLink}
          createPaymentLink={createPaymentLink}
        />
      </LoadMask>
    </div>
  )
}

export default CreateCashRegister
