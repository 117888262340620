import { connect } from "react-redux";
import {actions} from "../../../../redux/modules/reports/contributions";
import Contributions from "./Contributions";

const mapStateToProps = (state) => ({
  ...state.contributions,
});

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Contributions);
