import {connect} from "react-redux";
import {actions} from "../../../../../redux/modules/administration/commission";
import CommissionCreate from "./CommissionCreate";


const ms2p = (state) => {
  return {
    ...state.commission,
  }
};

const md2p = {...actions};

export default connect(ms2p, md2p)(CommissionCreate);
