import React from "react"
import {Form, Field} from "react-final-form"
import arrayMutators from "final-form-arrays"
import {FieldArray} from "react-final-form-arrays"
import BreakdownItem from "./BreakdownItem"
import {fieldRequired} from "../../../../../utility/validation"
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel"
import {AsyncSelectField, renderField, renderNumber, renderSwitch} from "../../../../components/Utils/renderField"


const FormStep1 = (
  {
    data,
    show,
    isUpdate,
    onSubmit,
    categories,
    defaultProf,
    searchProfession,
    openSelectCategories,
    permissions,
  }) => {

  const documentManagement = permissions.document_management

  const validateAmount = (amount, items) => {
    let used = 0
    if (amount) {
      if (items && Array.isArray(items)) {
        items.map((item) => {
          used += Number(item.amount)
        })
      }
      return (Number(amount) - used) <= 0
    }
    return true
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        mutators={{...arrayMutators}}
        initialValues={data}
        subscription={{values: true, pristine: true}}
        render={({handleSubmit, form: {mutators: {push}}, values}) =>
          <form onSubmit={handleSubmit} className={show ? "d-block" : "d-none"}>
            <div
              className="d-flex flex-wrap bg-card-cofaqui py-lg-4 py-md-2 py-3 br-20px w-100 shadow">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="form-group has-feedback flex-1 mx-lg-4 mx-md-2 mx-sm-0">
                  <div>
                    <label htmlFor="name">Nombre</label>
                    <Field
                      name={"name"}
                      component={renderField}
                      validate={fieldRequired}
                      placeholder="Registro sanitario"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="form-group has-feedback flex-1 mx-lg-4 mx-md-2 mx-sm-0">
                  <div>
                    <label htmlFor="profession_id">Profesión</label>
                    <Field
                      valueKey={"id"}
                      labelKey={"name"}
                      name="profession_id"
                      validate={fieldRequired}
                      component={AsyncSelectField}
                      defaultOptions={defaultProf}
                      loadOptions={searchProfession}
                      placeholder="Químicos biólogos"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="form-group has-feedback flex-1 mx-lg-4 mx-md- mx-sm-0">
                  <div>
                    <label htmlFor="prefix">Prefijo de código producto</label>
                    <Field
                      name="prefix"
                      component={renderField}
                      validate={fieldRequired}
                      placeholder="QB"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="form-group has-feedback flex-1 mx-lg-4 mx-md-2 mx-sm-0">
                  <div>
                    <label htmlFor="amount">Valor del producto</label>
                    <Field
                      prefix="Q  "
                      name="amount"
                      placeholder="Q  1.00"
                      customDecimalScale={2}
                      component={renderNumber}
                      validate={fieldRequired}
                    />
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {
                    documentManagement && (
                      <div className="form-group has-feedback flex-1 mx-lg-4 mx-md-2 mx-sm-0">
                        <div>
                          <label htmlFor="require_document">Genera Documentos</label>
                          <Field
                            name="require_document"
                            component={renderSwitch}
                          />
                        </div>
                      </div>
                    )
                  }
                  <div className="form-group has-feedback flex-1 mx-lg-4 mx-md-2 mx-sm-0">
                    <div>
                      <label htmlFor="require_document">Pago de colegiado</label>
                      <Field
                        name="is_collegiate_payment"
                        component={renderSwitch}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h3 className="pl-4 font-weight-bold">Desgloce</h3>
            </div>
            <div className="form-validate bg-card-cofaqui p-4 br-20px w-100 shadow">
              <div className="w-100">
                <FieldArray
                  valueKey="id"
                  labelKey="name"
                  name="break_down"
                  isUpdate={isUpdate}
                  categories={categories}
                  items={values.break_down}
                  component={BreakdownItem}
                  openSelectCategories={openSelectCategories}
                  totalAmount={values.amount ? values.amount : 0}
                />
              </div>
              <div className="w-100 mt-5">
                <div className="justify-content-center w-100">
                  <button
                    type="button"
                    title={"Agregar desgloce"}
                    disabled={validateAmount(values.amount, values.break_down)}
                    className="btn btn-add-field mx-auto px-4 mt-2 col-lg-2 col-xl-2 col-md-7 col-sm-12 font-weight-bold"
                    onClick={() => {
                      push("break_down", {})
                    }}
                  >
                    <span className="material-symbols-outlined mr-2">add_circle</span>
                    Añadir desgloce
                  </button>
                </div>
              </div>
            </div>
            {
              documentManagement ? 
                <BtnSaveAndCancel
                  renderBtnNext={!!values.require_document}
                  renderBtnSubmit={!values.require_document}
                  renderLinkCancel={true}
                  redirectToLink={"/products"}
                  customContainer="container justify-content-center w-100 mb-3 mt-5 row"
                />
                :
                <BtnSaveAndCancel
                  renderBtnSubmit={true}
                  renderLinkCancel={true}
                  redirectToLink={"/products"}
                  customContainer="container justify-content-center w-100 mb-3 mt-5 row"
                />
            }
          </form>
        }
      />
    </div>
  )
}

export default FormStep1
