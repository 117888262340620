import List from "./List"
import {connect} from "react-redux"
import {actions} from "../../../../../redux/modules/myManagements/myDocuments"

const ms2p = (state) => {
  return {
    ...state.myDocuments,
  }
}

const md2p = {...actions}

export default connect(ms2p, md2p)(List)
