import React, { useEffect } from "react";
import Grid from "../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../components/layout/PageTitle";
import { Form, Field } from "react-final-form";
import { RenderName } from  "../../../components/Utils/RenderName";
// import {standardActions} from "../../../components/Utils/Grid/StandardActions";

// import "./list.css";

const MyTransactionDetail = ({
  // checkTransaction,
  match: { params: { authorization } },
  data,
  getTransactionData,
  generateReceipt,
  failed,
}) => {

  useEffect(() => {
    if (!failed) {
      getTransactionData(authorization);
    }
  }, []);

  return (
    <div className="container-fluid">
      
      <PageTitle
        title={`${failed ? "Error" : "Detalle de transacción"}`}
        colTitle={"col-12"}
      />
      {
        !failed ? (
          <React.Fragment>
            <div className="card bg-card-cofaqui br-20px py-5 px-4">
              <Form
                onSubmit={() => {}}
                initialValues={data}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="d-flex flex-column mb-4">
                    <div className="col- col-lg-7 col-md-12 col-sm-12">
                      <div className="row justify-content-between">
                        <div>
                          <label
                            className="form-label"
                            htmlFor="authorization"
                          >
                            No. de autorización:&nbsp;
                          </label>
                          <Field
                            name="authorization"
                            component={RenderName}
                          />
                        </div>
                        <div>
                          <label
                            className="form-label"
                            htmlFor="cash_register"
                          >
                            Caja:&nbsp;
                          </label>
                          <Field
                            name="cash_register"
                            component={RenderName}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div>
                          <label
                            className="form-label"
                            htmlFor="member_name"
                          >
                            Agremiado:&nbsp;
                          </label>
                          <Field
                            name="member_name"
                            component={RenderName}
                          />
                        </div>
                        <div>
                          <label
                            className="form-label"
                            htmlFor="amount"
                          >
                            Monto:&nbsp;
                          </label>
                          <Field
                            name="amount"
                            component={RenderName}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              />
              <Grid
                hover
                remote={false}
                loading={false}
                data={data.details}
                page={1}
                bodyContainerClass = "bc-professions-custom"
                headerContainerClass = "hc-professions-custom"
                tableContainerClass = "professions-container-table-sm"
                tableHeaderClass = "table-header-professions"
              //   onPageChange={getUniversities}
              //   onSortChange={onSortChange}
              >
                <TableHeaderColumn
                  width="100px"
                  isKey
                  dataField="order"
                  dataAlign="center"
                >
                  No.
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="205px"
                  dataField="name"
                >
                  Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="200px"
                  dataField="amount"
                >
                  Monto
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="200px"
                  dataField="quantity"
                >
                  Cantidad
                </TableHeaderColumn>
              </Grid>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <button
                type="button"
                style={{
                  background: "#6558F5",
                }}
                onClick={() => generateReceipt(authorization)}
                className="btn btn-primary col-lg-2 col-md-3 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
                  &nbsp;Generar recibo
              </button>
            </div>
          </React.Fragment>
        )
        : (
          <div className="card bg-card-cofaqui br-20px py-5 px-4">
            <p className="text-center">
              No fue posible procesar el pago
            </p>
          </div>
        )

      }
      
    </div>
  );
}

import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/myManagements/ebipay";

const ms2p = (state) => {
  return {
    ...state.ebipay,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(MyTransactionDetail);