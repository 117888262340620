import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import login from "./modules/cuenta/login";
import register from "./modules/cuenta/register";
import profile from "./modules/cuenta/profile";
import usuarios from "./modules/usuarios/usuarios";
import notificaciones from "./modules/notificaciones/notificaciones";
import cashRegister from "./modules/cashRegister"
import adminCashRegister from "./modules/administration/adminCashRegister"
import purchaseRequest from "./modules/myManagements/purchaseRequest";
import myDocuments from "./modules/myManagements/myDocuments";
// import finalFormExample from "./modules/finalFormExample";
import member from "./modules/member";
import user from "./modules/user";
import department from "./modules/department";
import finalFormReducer from "./modules/finalFormDump";
import permissionsAndRoles from "./modules/administration/permissionsAndRoles";
import universities from "./modules/administration/universities";
import professions from "./modules/administration/professions";
import forgotPasswordManagement from "./modules/cuenta/forgotPasswordManagement";
import commission from "./modules/administration/commission";
import expenseCategory from "./modules/administration/expenseCategory";
import stamps from "./modules/stamps";
import binnacle from "./modules/administration/binnacle";
import products from "./modules/administration/products";
import floatingPayments from "./modules/administration/floatingPayments"
import banks from "./modules/administration/banks";
import payments from "./modules/myManagements/myPayments";
import dashboard from "./modules/dashboard";
import managements from "./modules/administration/managements";
import contributions from "./modules/reports/contributions";
import incomeAndExpense from "./modules/reports/incomeAndExpense";
import technicalDirectionsReducer from "./modules/technical_directions";
import masterReducer from "./modules/beneficiary_masters";
import inventoryDocumens from "./modules/myManagements/myDocuments/inventoryDocumens";
import stampsIssued from "./modules/reports/stampsIssued";
import stampsSold from "./modules/reports/stampsSold";
import stampsUsed from "./modules/reports/stampsUsed";
import documentsConsultation from "./modules/reports/documentsConsultation";
import qrScan from "./modules/QRScan";
import ebipay from "./modules/myManagements/ebipay";

export default combineReducers({
  // form: formReducer,
  form: finalFormReducer,
  formRF: formReducer,
  login,
  register,
  profile,
  usuarios,
  routing,
  notificaciones,
  permissionsAndRoles,
  member,
  user,
  department,
  universities,
  professions,
  forgotPasswordManagement,
  commission,
  expenseCategory,
  stamps,
  binnacle,
  products,
  cashRegister,
  adminCashRegister,
  purchaseRequest,
  floatingPayments,
  myDocuments,
  banks,
  payments,
  dashboard,
  managements,
  contributions,
  incomeAndExpense,
  technicalDirectionsReducer,
  masterReducer,
  inventoryDocumens,
  stampsIssued,
  stampsSold,
  stampsUsed,
  documentsConsultation,
  qrScan,
  ebipay
});
