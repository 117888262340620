import React, { useState } from "react";
import { createPortal } from "react-dom";
import { Tooltip } from "react-tooltip";

import "react-tooltip/dist/react-tooltip.css";
// import "./tooltip.css";

const MoreInfo = ({
  anchorId,
  content,
  children,
}) => {

  return (
    <React.Fragment>
      {
        createPortal(
          <Tooltip
            anchorId={anchorId}
            place="bottom"
            variant="info"
            content={content}
            style={{
              zIndex: 1000,
            }}
          />,
          document.getElementById("portal")
        )
      }
      <span
        className="d-flex flex-row align-items-center"
      >
        {children}
        <button
          type="button"
          className="fa fa-info-circle" 
          data-tip 
          data-for={anchorId} 
          id={anchorId}
          onClick={(e) => e.stopPropagation()}
          style={{ 
          // cursor: "pointer",
            margin: "0 10px 0 5px",
            // width: "50px",
            // height: "50px",
            zIndex: 1000,
            // position: "relative",
            background: "transparent",
            border: "none",
          }}

        />
      </span>
    </React.Fragment>
  );
};

export default MoreInfo;