import React, {useEffect, useState} from 'react';
import {api} from "api";
import {Link} from "react-router-dom"
import CommissionForm from "./CommissionForm"
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";
import iconReturn from "../../../../../../assets/img/icons/iconreturn.svg"

const CommissionCreate = (props) => {
  const {
    item,
    match,
    loader,
    setItemValues,
    editCommission,
    readCommission,
    createCommission,
    userInCommission,
    addUserInCommission,
    resetUserInCommission,
    removeUserInCommission,
  } = props;
  const [isUpdate, setIsUpdate] = useState(false)
  const [defaultOption, setDefaultOption] = useState([])
  const [valueSelect, setValueSelect] = useState(null)

  useEffect(() => {
    const {params} = match
    // if is update
    if (params && params.id) {
      setIsUpdate(true)
      setItemValues()
      readCommission(params.id)
    } else {
      resetUserInCommission()
      setItemValues()
    }
    // initialize option async select
    loadOptionMember('').then((opts) => {
      setDefaultOption(opts)
    })
  }, [])

  const loadOptionMember = (search="") => {
    return api.get('member', {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch(() => {
    }).finally(() => {
    });
  }

  const actionSubmit = (data) => {
    if (isUpdate) {
      const {params} = match
      editCommission(params.id, data)
    } else {
      createCommission(data)
    }
  }

  const actionBntAdd = ()=>{
    if(valueSelect !== null){
      addUserInCommission(valueSelect)
      setValueSelect(null)
    }
  }

  return (
    <div className="pt-5">
      <div className="d-flex flex-wrap mb-1">
        <h3 className="weight-600 ml-lg-3 ml-md-2 ml-sm-0">
          {isUpdate ? "Editar comisión" : "Nueva comisión"}
        </h3>
        <Link to="/commissions" className="ml-2">
          <i> <img src={iconReturn} alt="regresar" className="ml-3"/> </i>
        </Link>
      </div>
      <LoadMask dark blur loading={loader}>
      <CommissionForm
        initialValues={item}
        data={userInCommission}
        onSubmit={actionSubmit}
        valueSelect={valueSelect}
        redirectTo={'/commissions'}
        defaultOption={defaultOption}
        setValueSelect={setValueSelect}
        promiseAction={loadOptionMember}
        actionBntAdd={actionBntAdd}
        actionDelete={removeUserInCommission}
      />
      </LoadMask>
    </div>
  )

}

export default CommissionCreate;
