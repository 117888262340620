import React from "react";
import DocumentConsultationForm from "../Form/DocumentConsultationForm";
import ReportForm from "./ReportForm";
import PageTitle from "../../../../components/layout/PageTitle";

const DocumentConsultationReport = ({
  getDocumentConsultationData,
  reportData,
  generatePDF,
}) => {
  return (
    <div className="container-fluid">
      <PageTitle
        title="Consulta de documentos"
        colTitle="col-4"
      />
      <div className="card bg-card-cofaqui p-4">
        <div>
          <h4>Formulario de busqueda</h4>
          <DocumentConsultationForm
            action={getDocumentConsultationData}
          />
        </div>
        {
          (reportData && reportData.id) && (
            <ReportForm
              initialValues={reportData}
              generatePDF={generatePDF}
            />
          )
        }
      </div>
    </div>
  );
};

export default DocumentConsultationReport;