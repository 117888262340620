import React, {useEffect} from "react";
import Grid from "../../../../components/Utils/Grid";
import PageTitle from "../../../../components/layout/PageTitle";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";
import moment from "moment/moment";


const List = (props) => {
  const {listar, page, data, onSortChange, loader, eliminar, setInitialValueRequest} = props

  useEffect(() => {
    listar()
    setInitialValueRequest()
  }, [])


  return (
    <div>
      <div className="col-12 px-1">
        <PageTitle
          colTitle="col-lg-4 d-flex flex-row"
          title={"Solicitudes de compra"}
          withReturn
          returnTo={'/managements'}
          withLink
          linkLabel={"Nueva Solicitud"}
          linkTo={"/managements/new-purchase-request"}
        />
        <div className="mb-4 card card-small bg-card-cofaqui">
          <div className="px-4 py-4">
            <Grid
              hover
              page={page}
              data={data}
              loading={loader}
              onPageChange={listar}
              onSortChange={onSortChange}
            >
              <TableHeaderColumn
                width="200px"
                dataField="order"
                dataAlign="center"
              >
                No.
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="stamp"
                dataAlign="center"
                dataFormat={(cell, row) => {
                  let name = ''
                  if (cell) {
                    name = cell.name
                  } else if (row.product) {
                    name = row.product.name
                  }
                  return (
                    <span>
                      {name}
                    </span>
                  )
                }}
              >
                Timbre o Gestión
              </TableHeaderColumn>
              <TableHeaderColumn
                width="210px"
                dataField="status"
                dataAlign="center"
              >
                Estado
              </TableHeaderColumn>
              <TableHeaderColumn
                width="210px"
                dataField="management_code"
                dataAlign="center"
              >
                Código de la solicitud
              </TableHeaderColumn>
              <TableHeaderColumn
                width={'200px'}
                dataAlign="center"
                dataField="management_start_date"
                dataFormat={(cell, row) => {
                  let dateStr = ""
                  try {
                    dateStr = moment(cell).format('L')
                  } catch (e) {
                    console.log('error in date', e)
                  }
                  return <span>{dateStr}</span>
                }}
              >
                Fecha de inicio
              </TableHeaderColumn>
              <TableHeaderColumn
                isKey
                dataField="id"
                dataAlign="center"
                dataFormat={standardActions({
                  eliminar,
                  editar: 'purchase-request'
                })}
              >
                Acciones
              </TableHeaderColumn>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}


export default List
