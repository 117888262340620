import {connect} from "react-redux";
import {actions} from "../../../../redux/modules/cashRegister";
import { actions as stampActions } from "../../../../redux/modules/stamps";
import { actions as banksActions } from "../../../../redux/modules/administration/banks";
import OutgoingCashReceived from "./OutgoingCashReceived";


const ms2p = (state) => {
  return {
    ...state.cashRegister,
    me: state.login.me,
    categories: state.stamps.categories,
    destinationBanksList: state.banks.destinationBanksList,
  }
};

const md2p = {
  ...actions, 
  openCategories: stampActions.openCategories, 
  getCategories: stampActions.getCategories,
  getOriginOrDestinationBanks: banksActions.getOriginOrDestinationBanks,
};

export default connect(ms2p, md2p)(OutgoingCashReceived);