import React, {useEffect, useState} from "react";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";
import UserForm from "../../../Members/Create/MemberForm";

import {api} from "../../../../../utility/api";
import StepsCFQ from "../../../../components/layout/Steps";
import CollegiateForm from "../../../Members/Create/CollegiateForm";
import SpouseForm from "../../../Members/Create/SpouseForm";
import BeneficiaryForm from "../../../Members/Create/BeneficiaryForm";
import ModalSuccess from "../../../Members/Create/ModalSuccess";
import TitlePageCreate from "../../../../components/layout/TitleCreate";
import {NotificationManager} from "react-notifications";


const UserCreate = (props) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [countries, setCountries] = useState([]);
  const [rolesOption, setRolesOption] = useState([]);
  const [step, setLocalStep] = useState(1);
  const [withSpouse, setWithSpouse] = useState(true);
  const [defaultUni, setDefaultUni] = useState([]);
  const [defaultProfession, setDefaultProfession] = useState([]);
  const [defaultProfCol, setDefaultProfCol] = useState([]);
  const [noCollegiate, setNoCollegiate] = useState(0);

  const {
    me: { permissions },
    item,
    leer,
    loader,
    dataDep,
    editar,
    isMember,
    createUser,
    updateUser,
    dataMunResi,
    dataMunBirth,
    setItemValues,
    getDepartments,
    showModalSuc,
    closeModalSuc,
    clearValueMun,
    changeTotalStep,
    updateSingleUser,
    changeCurrentStep,
    getMunicipalities,
    changeIsMember,
    match: {path},
  } = props;


  useEffect(() => {
    // get options values
    changeIsMember(false)
    clearValueMun();
    getCountries();
    getRoles();
    getDepartments();
    // clear values form
    const MemberStep1 = {}
    const MemberStep2 = {no_collegiate: noCollegiate}
    const MemberStep3 = {}
    const MemberStep4 = {customers: [{}]}
    setItemValues({MemberStep1, MemberStep2, MemberStep3, MemberStep4});
    // if is update
    const {params} = props.match
    if (params && params.id) {
      setIsUpdate(true)
      leer(params.id)
    }
    getUniversities().then((opts) => {
      setDefaultUni(opts)
    })
    getProfession().then((opts) => {
      setDefaultProfession(opts)
    })
    getProfessionPartOfUnion().then((opts) => {
      setDefaultProfCol(opts)
    })
  }, [])

  useEffect(() => {
    const {MemberStep1, MemberStep2} = item;
    if (MemberStep1 && MemberStep1.id && isUpdate) {
      getMunicipalities(MemberStep1.birth_department_id, 2)
      getMunicipalities(MemberStep1.department_of_residence_id, 1)
      validateStepSpouse(MemberStep1.marital_status)
      changeIsMember(!!(MemberStep1.is_member))
      if(MemberStep2 && MemberStep2.no_collegiate){
        setNoCollegiate(MemberStep2.no_collegiate)
      }
    }
  }, [item])

  useEffect(() => {
    if ((isMember && item.MemberStep1 && !item.MemberStep1.is_member) || (isMember && isUpdate === false)) {
      getCurrentNoColl()
    }
  }, [isMember])

  const onSubmit = (data) => {
    if (isMember) {
      nextStep()
    } else {
      if (isUpdate) {
        if (!isMember) {
          updateSingleUser(props.match.params.id, data, false)
        } else {
          updateUser(props.match.params.id, data, withSpouse)
        }
      } else {
        createUser(data, withSpouse)
      }
    }
  }

  const onSubmitBeneficiaries = (data) => {
    if (isUpdate) {
      if (!isMember) {
        editar(props.match.params.id, data)
      } else {
        updateUser(props.match.params.id, data, withSpouse)
      }
    } else {
      createUser(data, withSpouse, true)
    }
  }

  const getRoles = () => {
    api.get("role/list_all").then((resp) => {
      setRolesOption(resp)
    }).catch(() => {
    }).finally(() => {
    })
  }
  const getCountries = () => {
    api.get("country/all_data", {}).then((resp) => {
      setCountries(resp)
    }).catch(() => {
    }).finally(() => {
    })
  }
  const getProfession = (search = "") => {
    const params = {search}
    return api.get("profession/get_all_professions", params).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }
  const getProfessionPartOfUnion = (search = "") => {
    return api.get("profession/get_professions_part_of_union", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }
  const getUniversities = (search = "") => {
    return api.get("university/get_all_universities", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }
  const getCurrentNoColl = () => {
    api.get("index_table/get_correlative_number").then((response) => {
      setNoCollegiate(response.correlative_number)
    }).catch((error) => {
      if (error && error.details) {
        NotificationManager.error(error.details, "ERROR", 5000);
      } else {
        NotificationManager.error("Error al obtener el correlativo del agremiado.", "ERROR", 5000);
      }
    })
  }
  const validateStepSpouse = (status) => {
    if (status === 5 || status === 20) {
      setWithSpouse(true)
      changeTotalStep(4)
    } else {
      changeTotalStep(3)
      setWithSpouse(false)
    }
  }
  const percentage = (form, withSpouse) => {
    let total = 0
    if (!withSpouse) {
      return 0
    } else if (form && form.values && form.values.is_beneficiary && form.values.percentage) {
      total = Number(form.values.percentage)
    }
    return total
  }

  const nextStep = () => {
    changeCurrentStep(step + 1)
    setLocalStep(step + 1)
  }
  const prevStep = () => {
    setLocalStep(step - 1)
    changeCurrentStep(step - 1)
  }
  const changeStep = (currStep) => {
    if ((currStep < step) || isUpdate) {
      setLocalStep(currStep)
      changeCurrentStep(currStep)
    } else {
      const btn = document.getElementById(`btn-step-${step}`);
      if (btn) btn.click()
      changeCurrentStep(step + 1)
    }
  }

  return (
    <div className="pt-5 container-fluid">
      <div className="d-flex flex-row">
        <ModalSuccess
          openModal={showModalSuc}
          actionBtn={closeModalSuc}
          closeModalAct={closeModalSuc}
          title={isUpdate ? "Usuario actualizado" : "Usuario guardado"}
          msg={isUpdate ? "El usuario ha sido actualizado con éxito." : "El nuevo usuario ha sido guardado con éxito."}
        />
        <TitlePageCreate
          redirectTo="/users"
          title={isUpdate ? "Editar Usuario" : "Nuevo Usuario"}
        />
      </div>
      {isMember &&
      <StepsCFQ current={step} clickAction={changeStep} totalSteps={withSpouse ? 4 : 3}/>
      }
      <LoadMask light loading={loader}>
        <UserForm
          show={isMember ? step === 1 : true}
          dataDep={dataDep}
          onSubmit={onSubmit}
          redirectTo={"/users"}
          useButtonSave={!isMember}
          dataMunResi={dataMunResi}
          permissions={permissions}
          countriesList={countries}
          dataMunBirth={dataMunBirth}
          subTitle={"Datos generales"}
          roleSelectList={rolesOption}
          initialValues={item.MemberStep1}
          actionMaritialStatus={validateStepSpouse}
          actionIsMember={changeIsMember}
          getMunicipalities={getMunicipalities}
          path={path}
        />
        {isMember &&
        <React.Fragment>
          <CollegiateForm
            show={step === 2}
            onSubmit={nextStep}
            defaultUni={defaultUni}
            subTitle={"Colegiado"}
            values={{...item.MemberStep2, no_collegiate: noCollegiate}}
            professionList={defaultProfCol}
            getUniversities={getUniversities}
            getProfession={getProfessionPartOfUnion}
            actionBack={prevStep}
          />
          <SpouseForm
            onSubmit={nextStep}
            subTitle={"Cónyuge"}
            getProfession={getProfession}
            initialValues={item.MemberStep3}
            professionList={defaultProfession}
            show={withSpouse ? (step === 3) : false}
            actionBack={prevStep}/>
          <BeneficiaryForm
            actionBack={prevStep}
            subTitle={"Beneficiario"}
            initialValue={item.MemberStep4}
            onSubmit={onSubmitBeneficiaries}
            show={withSpouse ? (step === 4) : (step === 3)}
            dataSpouse={withSpouse ? props.MemberStep3 : null}
            percentageSpouse={percentage(props.MemberStep3, withSpouse)}
          />
        </React.Fragment>
        }
      </LoadMask>
    </div>
  )
}

export default UserCreate
