import {connect} from "react-redux"
import {actions} from "../../../../redux/modules/cashRegister"
import ClosingCashRegister from "./ClosingCashReg"


const ms2p = (state) => {
  return {
    ...state.cashRegister,
  }
}

const md2p = {
  ...actions,
}

export default connect(ms2p, md2p)(ClosingCashRegister)
