import React, { useState, useEffect } from "react";

import { renderField } from "../../Utils/renderField";
import { Form, Field, FormSpy } from "react-final-form";
import { NotificationManager } from "react-notifications";
import PinInput from "react-pin-input";

import eyeblock from "../../../../../assets/img/icons/eyeblock.png";
// import repeatpasswordfailed from "../../../../../assets/img/icons/repeatpasswordfailed.png";

// const quickReveal = (value, index) => {
//   console.log("index:", index);
//   if (!_.isEmpty(value)) { 
//     document.querySelector(".pincode-input-container").children[
//       index - 1
//     ].type = "text";
//     setTimeout(() => {
//       document.querySelector(".pincode-input-container").children[
//         index - 1
//       ].type = "password";
//     }, 500);
//     if (index === 6) {
//       console.log("soy el 6");
//       setTimeout(() => {
//         document.querySelector(".pincode-input-container").children[
//           index
//         ].type = "password";
//       }, 500);
//     }
//   }
// }

var count = () => { };

const countDown = (time, setTime) => {
  if (time > 0) {
    count = setTimeout(() => {
      setTime(prevCount => prevCount - 1);
    }, 1000);
  }
};

const isValidPassword = (validations) => {
  if (validations) {
    const validate = Object.values(validations);
    const isValid = validate.includes(false);
    return isValid === true ? false : true;
  }
  return false;
};

const ChangePasswordForm = ({
  setFormState,
  createOrChange,
  type,
  token,
  resendTemporaryCode,
  passwordValidations,
  getEmail,
  email,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [time, setTime] = useState(0);
  // const [hasError, setHasError] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  useEffect(() => {
    if (type === "change") {
      getEmail(token);
    }
  }, []);

  useEffect(() => {
    setValidPassword(passwordValidations.password);
  }, [passwordValidations]);

  useEffect(() => {
    isValidPassword(validPassword);
  }, [validPassword]);

  useEffect(() => {
    countDown(time, setTime);

    return () => {
      clearTimeout(count);
    }

  }, [time]);

  const onSubmit = (values) => {
    if (isValidPassword(validPassword)) {
      createOrChange({ ...values, token });
    } else {
      NotificationManager.error("La contraseña no cumple con los requisitos mínimos", "ERROR", 4000);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (!values.password) {
          errors.password = "Este campo es requerido";
        }
        if (!values.confirm_password) {
          errors.confirm_password = "Este campo es requerido";
        }
        if ((values.confirm_password !== values.password) && values.confirm_password) {
          // setHasError(!hasError);
          errors.confirm_password = "Las contraseñas no coinciden";
          errors.password = "Las contraseñas no coinciden";
        }
        return errors;
      }}
      render={({ handleSubmit, form, values }) => (
        <React.Fragment>
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => {
              setTimeout(() => {
                setFormState(values);
              }, 0);
            }}
          />
          <form onSubmit={handleSubmit} className="container-fluid mt-4 change-password-form">
            <div className="input-password">
              <label htmlFor="password">Contraseña</label>
              {
                values.password && values.password.length > 0 ? (
                  <i className="icon-eye">
                    <button
                      // role="button"
                      // tabIndex={0}
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none"
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                      onKeyDown={() => setShowPassword(!showPassword)}
                    >
                      <img
                        src={eyeblock}
                        className="eye"
                        alt="icono-ocultar"
                      />
                    </button>
                  </i>
                ) : null
              }
              {/* <div className="row d-flex align-items-center"> */}
              {/* <i style={{ position: "absolute", marginLeft: "-5px" }}>
                <img src={repeatpasswordfailed} className="eye" alt="icono-ocultar" />
              </i> */}
              {/* <div className="container-fluid"> */}
              <Field
                name="password"
                maxLength={32}
                component={renderField}
                typeInput="password"
                customClass="password-field"
                placeholder="    Ingresa contraseña"
                showPassword={showPassword}
              // validate={(value) => validatePassword(value, values.confirm_password)}
              />
              {/* </div> */}
              {/* </div> */}
            </div>
            <div className="input-password mt-4">
              <label htmlFor="password">Repetir contraseña</label>
              {
                values.confirm_password && values.confirm_password.length > 0 ? (
                  <i className="icon-eye">
                    <button
                      // role="button"
                      // tabIndex={0}
                      type="button"
                      style={{
                        background: "transparent",
                        border: "none"
                      }}
                      onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                      onKeyDown={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    >
                      <img
                        src={eyeblock}
                        className="eye"
                        alt="icono-ocultar"
                      />
                    </button>
                  </i>
                ) : null
              }
              {/* <div className="row d-flex align-items-center"> */}
              {/* {
                hasError ? (
                  <i style={{ position: "absolute", marginLeft: "-5px" }}>
                    <img src={repeatpasswordfailed} className="eye" alt="icono-ocultar" />
                  </i>
                ) : null
              } */}
              {/* <div className="container-fluid"> */}
              <Field
                name="confirm_password"
                maxLength={32}
                component={renderField}
                typeInput="password"
                customClass="password-field"
                placeholder="    Ingresa contraseña"
                showPassword={showPasswordConfirm}
              // validate={(value) => validateConfirmPassword(value, values.password)}
              />
              {/* </div> */}
              {/* </div> */}
            </div>
            {
              type == "change" ? (
                <div className="mt-4 d-flex flex-column">
                  <p className="text-center">
                    Por favor ingresa el código de confirmación
                    enviado al correo <span style={{ fontWeight: "bold" }}>{email}</span>
                  </p>
                  <div className="d-flex justify-content-center">
                    <Field
                      name="code"
                      length={6}
                      // initialValue={123456}
                      type="numeric"
                      inputMode="number"
                      inputStyle={{
                        padding: "10px",
                        backgroundColor: "#FAFDF3",
                        border: "1.5px solid #B0A196",
                        borderRadius: "8px",
                      }}
                      onComplete={(value) => {
                        form.change("code", value);
                      }}
                      //   onChange={quickReveal}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      component={PinInput}
                    // parse={value => form.change("code", value)}
                    />
                  </div>
                </div>) : null
            }
            {
              type == "change" ? (
                <div className="d-flex flex-column mt-4">
                  <button
                    className="btn send-code-button"
                    disabled={time > 0}
                    onClick={() => {
                      setTime(60),
                      resendTemporaryCode(token)
                    }}
                  >
                    <span
                      style={{
                        color: "#0A4725",
                        fontWeight: "bold",
                      }}
                    >
                      Reenviar código {
                        time > 0 ? (
                          `(${time}s)`
                        ) : null
                      }
                    </span>
                  </button>
                </div>
              ) : null
            }
            <div className="mt-4 d-flex justify-content-center">
              <button
                type="submit"
                className="btn new-password-button"
                style={{
                  minWidth: "200px",
                }}
              >
                {
                  type == "change" ? "Restablecer" : "Crear contraseña"
                }
              </button>
            </div>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre>  */}
          </form>
        </React.Fragment>
      )}
    />
  );
}

export default ChangePasswordForm;