import React from "react"
import {Link} from "react-router-dom"
import TransactionTable from "./TransactionTable";
import TransactionTablePettyCash from "./TransactionTablePettyCash";
import less from "../../../../../assets/img/icons/circleandless.svg"
import plus from "../../../../../assets/img/icons/circleandplus.svg"
import closingCashReg from "../../../../../assets/img/icons/closingcashreg.svg"
import {RenderCurrency} from "../../../components/Utils/renderField/renderReadField"


const TabCurrentCashRegister = ({
  data, page, 
  loader, onPageChange, 
  onSortChange, annulationAction, 
  cashRegisterId, typeCashRegister,
  getTransactionList, permissions
}) => {

  const closeCashRegisterPermission = permissions.cash_closing
  
  return (
    <div className="col-12 p-0 m-0">
      <div className="mb-4 card bg-card-cofaqui" style={{borderTopLeftRadius: "0"}}>
        {/*------------------------------------------------------*/}
        {/*----------------  BALANCE AND BUTTONS  ---------------*/}
        {/*------------------------------------------------------*/}
        <div className="d-flex flex-wrap pt-4 px-2">
          <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
            <span className="font-weight-bold">
              Saldo inicial: &nbsp;&nbsp;<RenderCurrency value={data.initial_balance? data.initial_balance: 0}/>
            </span>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
            <span className="font-weight-bold">
              Saldo actual: &nbsp;&nbsp;<RenderCurrency value={data.current_balance? data.current_balance: 0}/>
            </span>
          </div>
          {
            typeCashRegister === 1 && (
              <div className="col-lg-2 col-md-12 col-sm-12 mb-2">
                <Link to={"/my-cash-register/petty-cash-entry"} className="btn btn-create w-100">
                  <img src={plus} alt=""/>
                  <span className="align-self-center">
                &nbsp;&nbsp;
                Ingreso
                  </span>
                </Link>
              </div>
            )
          }
          {
            typeCashRegister === 5 && (
              <div className="col-lg-2 col-md-12 col-sm-12 mb-2">
                <Link to={"/my-cash-register/cash-outflow"} className="btn btn-create w-100">
                  <img src={less} alt=""/>
                  <span className="align-self-center">
                &nbsp;&nbsp;
                Egreso
                  </span>
                </Link>
              </div>
            )
          }
          <div className="col-lg-2 col-md-12 col-sm-12 mb-2">
            <Link to={"/my-cash-register/create-transaction"} className="btn btn-create w-100">
              {
                typeCashRegister === 5 ?
                  <img src={plus} alt=""/>
                  :
                  <img src={less} alt=""/>
              }
              <span className="align-self-center">
                    &nbsp;&nbsp;
                {typeCashRegister === 5 ? "Ingreso" : "Egreso"}
              </span>
            </Link>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12">
            <Link to={"/my-cash-register/closing"} className="btn btn-marron w-100">
              <img src={closingCashReg} alt=""/>
              <span className="align-self-center">
              &nbsp;&nbsp;Cierre de caja
              </span>
            </Link>
          </div>
        </div>
        <div className="d-flex flex-wrap pt-4 px-2">
          <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
            <span className="font-weight-bold">
              Saldo Efectivo: &nbsp;&nbsp;
              <RenderCurrency
                value={data.balances ? data.balances.Efectivo : 0}
              />
            </span>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
            <span className="font-weight-bold">
              Saldo Deposito: &nbsp;&nbsp;
              <RenderCurrency
                value={data.balances ? data.balances.Deposito : 0}
              />
            </span>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
            <span className="font-weight-bold">
              Saldo Cheque: &nbsp;&nbsp;
              <RenderCurrency
                value={data.balances ? data.balances.Cheque : 0}
              />
            </span>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
            <span className="font-weight-bold">
              Saldo Transferencia: &nbsp;&nbsp;
              <RenderCurrency
                value={data.balances ? data.balances.Transferencia : 0}
              />
            </span>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
            <span className="font-weight-bold">
              Saldo POS: &nbsp;&nbsp;
              <RenderCurrency
                value={data.balances ? data.balances.POS : 0}
              />
            </span>
          </div>
        </div>
        {/* ----------------------------------------------------*/}
        {/* ----------------  TABLE TRANSACTIONS  --------------*/}
        {/* ----------------------------------------------------*/}
        <div className="px-4 pt-4">
          {
            typeCashRegister === 5 ?
              <TransactionTable
                data={data}
                page={page}
                loader={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                annulationAction={annulationAction}
                getTransactionList={getTransactionList}
              />
              :
              <TransactionTablePettyCash
                data={data}
                page={page}
                loader={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                annulationAction={annulationAction}
                getTransactionList={getTransactionList}
              />
          }
        </div>
      </div>
    </div>
  )
}

export default TabCurrentCashRegister;
