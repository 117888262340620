import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/stamps";
import { actions as userActions } from "../../../../../redux/modules/user";
import Stamps from "./Stamps";

const ms2p = (state) => {
  return {
    ...state.stamps,
    ...state.form,
  };
}

const md2p = { ...actions, formStep: userActions.changeFormStep, updateNumberFormSteps: userActions.updateNumberFormSteps };

export default connect(ms2p, md2p)(Stamps);
