import React, {useEffect} from "react"
import NumberFormat from "react-number-format"
import Grid from "../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table"
import PageTitle from "../../../../components/layout/PageTitle"
import {standardActions} from "../../../../components/Utils/Grid/StandardActions"


const List = (props) => {
  const {data, page, loader, listar, onPageChange, onSortChange, deleteCashRegister} = props;
  
  useEffect(() => {
    listar(page)
  }, [])

  return (
    <div>
      <PageTitle
        withLink
        linkLabel="Nueva Caja"
        title="Administración de Cajas"
        linkTo={"/create-cash-register"}
        colTitle="col-lg-3 col-xl-3"
      />
      <div>
        <div className="col-12 px-1">
          <div className="mb-4 card card-small bg-card-cofaqui">
            <div className="px-4 pt-4">
              <Grid
                hover
                data={data}
                page={page}
                loading={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                bodyContainerClass = "bc-professions-custom"
                headerContainerClass = "hc-professions-custom"
                tableContainerClass = "professions-container-table-sm"
                tableHeaderClass = "table-header-professions"
              >
                <TableHeaderColumn
                  width="200px"
                  dataField="order"
                  dataAlign="center"
                >
                  No.
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="name"
                  dataAlign="center"
                  width="200px"
                >
                  Nombre Caja
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="cashier_main"
                  width="200px"
                  dataFormat={(cell) => {
                    return <span>{cell.full_name}</span>
                  }}
                >
                  Encargado
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="initial_balance"
                  width="200px"
                  dataFormat={(cell) => {
                    return (
                      <NumberFormat
                        disabled
                        prefix={"Q. "}
                        decimalScale={2}
                        fixedDecimalScale
                        value={cell ? cell : 0}
                        thousandSeparator={","}
                        className="bg-transparent border-none text-center"
                      />
                    )
                  }}
                >
                  Saldo
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="250px"
                  dataAlign="center"
                  dataField="type_cash_register"
                >
                  Tipo de caja
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="100px"
                  isKey
                  dataField="id"
                  dataAlign="center"
                  dataFormat={(cell, row) => {
                    if (row.delete_action) {
                      return standardActions({
                        editar: "cash-register",
                        eliminar: deleteCashRegister,
                      }) (cell, row)
                    }
                    return standardActions({
                      editar: "cash-register",
                    }) (cell, row)
                  }}
                >
                  Acciones
                </TableHeaderColumn>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default List
