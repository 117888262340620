import React from "react"
import {Collapse} from "reactstrap"
import {Form, Field} from "react-final-form"
import arrayMutators from "final-form-arrays"
import {FieldArray} from "react-final-form-arrays"
import {NotificationManager} from "react-notifications"
import {RELATIONSHIP_OPTION} from "../../../../utility/constants"
import {renderField, renderNumber, selectField} from "../../../components/Utils/renderField"
import {renderDatePicker, renderFilePicker} from "../../../components/Utils/renderField/renderField"
import {composeValidators, fieldRequired, maxPercentage, minDigits} from "../../../../utility/validation"
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel";
import "./members.css"

const LIMIT_BENEFICIARY = 6

const getRelationship = (value) => {
  const res = RELATIONSHIP_OPTION.find(element => element.value === value)
  return res ? res.label : ""
}
// ITEM COMPONENT
const ItemBeneficiary = ({index, name, fieldsParent, items, percentageAvailable, formErrors}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = () => setIsOpen(!isOpen)
  let borderDanger = ""
  if (formErrors && formErrors.customers) {
    try {
      if (formErrors.customers[index]) {
        borderDanger = "border border-danger"
      }
    } catch (e) {
      console.log("Error")
    }
  }

  return (
    <div className={`card card-item-beneficiary py-3 px-3 mb-3 ${borderDanger}`} style={{ overflow: "auto" }}>
      <div className={`beneficiary-general-container ${isOpen ? "d-none" : ""}`}>
        <div className="beneficiary-general-info" onClick={toggle}>
          <div className="">
            <h5 className="weight-500 mb-0">
              Beneficiario {index + 1}:
            </h5>
          </div>
          <div className="border-right d-flex justify-content-center">
            {fieldsParent.value[index].full_name}
          </div>
          <div className="border-right d-flex justify-content-center">
            {fieldsParent.value[index].relationship ? getRelationship(fieldsParent.value[index].relationship) : ""}
          </div>
          <div className="d-flex justify-content-center">
            {fieldsParent.value[index].percentage}%
          </div>
        </div>
        <div className="beneficiary-buttons">
          <button className="btn-del-item d-flex justify-content-center align-items-center border-none" type="button" onClick={() => fieldsParent.remove(index)}>
            <img src={require("assets/img/dump.png")} alt="Eliminar"/>
          </button>
          <button className="btn-collapse-item d-flex justify-content-center align-items-center" type="button" onClick={toggle}>
            <span className="material-icons">keyboard_arrow_down</span>
          </button>
        </div>
      </div>
      <Collapse isOpen={isOpen} delay={{show: 6, hide: 6}}>
        <div className={`${isOpen ? "" : "d-none"}`}>
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-4 col-lg-9 py-2 pointer" onClick={toggle}>
              <h5 className="weight-500 mb-0">Beneficiario {index + 1}:</h5>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-2 d-flex justify-content-between px-4">
              <div className="col-2">
                <div className="d-flex btn-del-item" onClick={() => fieldsParent.remove(index)}>
                  <img src={require("assets/img/dump.png")} alt="del" className="m-auto"/>
                </div>
              </div>
              <div className="col-2">
                <div className="btn-collapse-item" onClick={toggle}>
                  <span className="material-icons">expand_less</span>
                </div>
              </div>
            </div>
          </div>
          <div className="general-inputs-beneficiary-container">
            <div className="inputs-container-beneficiary">
              <div className="beneficiary-input-item">
                <label htmlFor={`${name}.full_name`}>Nombre completo</label>
                <Field
                  typeInput='text'
                  component={renderField}
                  validate={fieldRequired}
                  name={`${name}.full_name`}
                />
              </div>
              <div className="beneficiary-input-item">
                <label htmlFor={`${name}.relationship`}>Parentesco</label>
                <Field
                  typeInput="text"
                  component={selectField}
                  validate={fieldRequired}
                  options={RELATIONSHIP_OPTION}
                  name={`${name}.relationship`}
                />
              </div>
              <div className="beneficiary-input-item">
                <label htmlFor={`${name}.percentage`}>Porcentaje de beneficio</label>
                <Field
                  suffix={"%"}
                  maxLength={4}
                  maxValue={100}
                  thousandSeparator={""}
                  component={renderNumber}
                  name={`${name}.percentage`}
                  validate={composeValidators(fieldRequired, maxPercentage(percentageAvailable))}
                />
              </div>
            </div>
            <div className="inputs-container-beneficiary">
              <div className="beneficiary-input-item">
                <label htmlFor={`${name}.birth_date`}>Fecha de nacimiento</label>
                <Field
                  maxDate={new Date()}
                  validate={fieldRequired}
                  component={renderDatePicker}
                  name={`${name}.birth_date`}
                />
              </div>
              <div className="beneficiary-input-item">
                <label htmlFor={`${name}.dpi`}>CUI</label>
                <Field
                  maxLength={13}
                  thousandSeparator={""}
                  name={`${name}.dpi`}
                  component={renderNumber}
                  validate={composeValidators(fieldRequired, minDigits(13))}
                />
              </div>
              <div className="null-beneficiary-item" style={{height: "78.33px"}}></div>
            </div>
            <div className="beneficiary-input-item">
              <label htmlFor={`${name}.dpi_img`}>Adjuntar DPI</label>
              <Field
                accept={"image/*"}
                name={`${name}.dpi_img`}
                component={renderFilePicker}
                photo={items ? items.dpi_img : null}
              />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center pt-1 pb-2 px-0">
            <button
              type="button"
              className="btn btn-success col-lg-2 col-md-4 col-sm-12 ml-lg-2 ml-md-4 ml-sm-0">
              <span className="icon-save" style={{width: "20px", height: "20px"}}>&nbsp;</span>
              <span className="ml-2">Guardar</span>
            </button>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

const validateTotalPercentage = (fields, percentageUsed, index) => {
  let total = 100
  if (percentageUsed) total -= percentageUsed;
  fields && fields.value && fields.value.map((field, i) => {
    const percentage = field.percentage;
    if (percentage && i < index) {
      total -= Number(percentage)
    }
  })
  return total;
}

const validateDisableBntAdd = (fields, percentageUsed, dataSpouse) => {
  let total = LIMIT_BENEFICIARY;
  if (dataSpouse && dataSpouse.values && dataSpouse.values.is_beneficiary) {
    total -= 1
  }
  return fields.length === total || validateTotalPercentage(fields, percentageUsed, fields.length) === 0
}


const BeneficiaryForm = ({onSubmit, actionBack, show, initialValue, percentageSpouse, dataSpouse, subTitle}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValue}
    mutators={{...arrayMutators}}
    render={({handleSubmit, form: {mutators: {push}}, values, errors}) => (
      <form onSubmit={handleSubmit} className={show ? "" : "d-none"}>
        <div
          className="container-beneficiary bg-card-cofaqui px-3 py-4 card-shadow">
          {subTitle &&
          <h5 className="roboto weight-600 ml-4">{subTitle}</h5>
          }
          <FieldArray
            name="customers"
          >
            {({fields}) => (
              <div>
                <div className="static-container px-1">
                  {fields.map((name, index) => {
                    return (
                      <ItemBeneficiary
                        key={index}
                        name={name}
                        index={index}
                        formErrors={errors}
                        fieldsParent={fields}
                        items={values.customers[index]}
                        percentageAvailable={validateTotalPercentage(fields, percentageSpouse, index)}
                      />)
                  }
                  )
                  }
                </div>
                <button
                  type="button"
                  title={"Agregar Beneficiario"}
                  disabled={validateDisableBntAdd(fields, percentageSpouse, dataSpouse)}
                  className="btn btn-add-field mx-auto px-4 mt-2"
                  onClick={() => {
                    let withError = false
                    fields && fields.value && fields.value.map((ele, index) => {
                      const newItem = ele
                      delete newItem.dpi_img
                      if (Object.values(newItem).includes(null)) {
                        withError = true
                        NotificationManager.info(`Ingrese todos los datos del beneficiario ${index + 1}`, "ERROR", 3000);
                      }
                    })
                    if (!withError) {
                      push("customers", {full_name: null, dpi: null, relationship: null, percentage: null})
                    }
                  }}
                >
                  <span className="material-symbols-outlined mr-2">add_circle</span>
                  Añadir beneficiario
                </button>
              </div>
            )
            }
          </FieldArray>
        </div>
        {/*--------------------------------------------------------*/}
        {/*-------------------      BUTTONS    --------------------*/}
        {/*--------------------------------------------------------*/}
        <BtnSaveAndCancel
          renderBtnPrev
          renderBtnSubmit
          actionBntPrev={actionBack}
          customContainer="container justify-content-center w-100 mt-4 row"
        />
      </form>
    )}
  />
)

export default BeneficiaryForm;
