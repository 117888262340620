import React, {useEffect} from "react"
import Tabs, {TabPane} from "rc-tabs"
import OpenCashRegister from "./OpeningCashRegoster"
import PageTitle from "../../../components/layout/PageTitle"
import TabCurrentCashRegister from "./TabCurrentCashRegister"
import TabHistoryCashRegister from "./History/TabHistoryCashRegister"
import "./cashRegister.css"
import LoadMask from "../../../components/Utils/LoadMask/LoadMask";


const List = (props) => {
  const {
    data,
    page,
    loader,
    onPageChange,
    onSortChange,
    me,
    me: {permissions},
    ListCRCD,
    detailsData,
    detailsPage,
    setInitialDetails,
    annulation,
    pageHistory,
    dataHistory,
    cashRegister,
    loaderHistory,
    dataCashRegister,
    validateCurrentCash,
    openingCashRegister,
    changeHistoryOrdering,
    cashRegisterHistoryList,
    typeCashRegister,
    transactionList,
    cashRegisterList,
    paginationData,
  } = props;

  useEffect(() => {
    validateCurrentCash()
  }, [])

  function submitAction(data, closeModal) {
    openingCashRegister(data.id, data, closeModal)
  }

  return (
    <div>
      <PageTitle
        title={`Caja (${me && me.full_name})`}
        colTitle="col-lg-4 col-xl-3"
      />
      <div>
        <div className="p-0 cash-container">
          <LoadMask loading={loader || loaderHistory} dark blur>
            <Tabs
              tabBarStyle={{background: "transparent"}}
              defaultActiveKey={cashRegister ? "current_cash_register" : "open"}
            >
              {cashRegister ?
                // If contain an open cash register
                <React.Fragment>
                  <TabPane tab="Caja actual" key="current_cash_register">
                    <TabCurrentCashRegister
                      data={data}
                      page={page}
                      loader={loader}
                      onSortChange={onSortChange}
                      onPageChange={onPageChange}
                      getTransactionList={transactionList}
                      cashRegisterId={cashRegister}
                      annulationAction={annulation}
                      typeCashRegister={typeCashRegister}
                      permissions={permissions}
                    />
                  </TabPane>
                  <TabPane tab="Historial de caja" key="history">
                    <TabHistoryCashRegister
                      data={dataHistory}
                      page={pageHistory}
                      loader={loaderHistory}
                      onSortChange={changeHistoryOrdering}
                      onPageChange={cashRegisterHistoryList}
                      detailsLoader={loader}
                      detailsPage={detailsPage}
                      detailsList={ListCRCD}
                      detailsData={detailsData}
                      setInitialDetails={setInitialDetails}
                      getTransactionList={transactionList}
                      typeCashRegister={typeCashRegister}
                    />
                  </TabPane>
                </React.Fragment> :
                // else open a cash register
                <TabPane tab="Cajas" key="open">
                  <OpenCashRegister
                    action={submitAction}
                    cashRegisters={dataCashRegister && dataCashRegister.results ? dataCashRegister.results : []}
                    cashRegisterList={cashRegisterList}
                    paginationData={paginationData}
                    permissions={permissions}
                  />
                </TabPane>
              }
            </Tabs>
          </LoadMask>
        </div>
      </div>
    </div>
  )
}

export default List
