import React, { useEffect } from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";

import "./list.css";

const RoleList = ({
  getRoles,
  page,
  loader,
  onSortChange,
  roleList,
  deleteRole,
}) => {

  useEffect(() => {
    getRoles(page);
  }, []);

  return (
    <div className="container-fluid">
      <PageTitle
        title={"Roles y Permisos"}
        withLink={true}
        linkTo={"new-role"}
        linkLabel={"Nuevo Rol"}
      />
      <div className="col-12 px-1 p-4 card-small bg-card-cofaqui">
        <Grid
          hover
          responsive
          data={roleList}
          page={page}
          loading={loader}
          onPageChange={getRoles}
          onSortChange={onSortChange}
          bodyContainerClass = "bc-roles-custom"
          headerContainerClass = "hc-roles-custom"
          tableContainerClass = "roles-container-table-sm"
          tableHeaderClass = "table-header-roles"
        >
          <TableHeaderColumn
            width="100px"
            isKey
            dataField="id"
            dataAlign="center"
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="200px"
            dataField="name"
          >
            Nombre del Rol
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="200px"
            dataField="screen"
          >
            Pantalla de inicio
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="200px"
            dataField="permissions"
          >
            Permisos
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="100px"
            dataField="id"
            dataFormat={
              (cell, row) => {
                if (!row.is_superrole) {
                  return standardActions({
                    editar: "role",
                    eliminar: deleteRole,
                  }) (cell, row);
                }
              }
            }
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default RoleList;
