import React, {useState, useEffect} from "react";
import {NavLink} from "react-router-dom";
import {Collapse} from "reactstrap";
import {
  bag,
  greenBag,
  wallet,
  userIcon,
  HomeIcon,
  bookIcon,
  logoCOFAQUI,
  spentIcon,
  creditCard,
  product,
  floatingPayment,
  bank,
  management,
} from "./iconsSidebar"

import aportIcon from "../../../../../assets/img/icons/aport.png";
import iaeIcon from "../../../../../assets/img/icons/iae.png";

import * as allRoutes from "./routesText"

const SideBar = ({toggleOpen, me, location, navToggle, setToggleOpen}) => {
  const [resizeMenu, setResizeMenu] = useState(false);
  const [dropdownAdminOpen, setDropdownAdminOpen] = useState(false);
  const [openDropdownReport, setOpenDropdownReport] = useState(false);
  const [isAdminActive, setIsAdminActive] = useState(false);
  const [isReportActive, setIsReportActive] = useState(false);
  const [mobile, setMobile] = useState(false);

  const toggleAction = () => setResizeMenu(!resizeMenu);
  const toggleCollapseAdmin = () => setDropdownAdminOpen(!dropdownAdminOpen);
  const toggleCollapseReport = () => setOpenDropdownReport(!openDropdownReport);
  const validateActiveLink = (_router) => () => {
    if (_router.includes(location)) return true
  }

  const changeToggle = () => {
    if(mobile) {
      navToggle()
    }
  }

  useEffect(() => {
    actionSwitch(resizeMenu)
  }, [resizeMenu])

  useEffect(() => {
    const pathname = location;
    const {pathsAdmin, pathsReport} = allRoutes
    if (pathsAdmin.includes(pathname)) {
      setIsAdminActive(true)
    } else if (!allRoutes.pathsAdmin.includes(pathname)) {
      setDropdownAdminOpen(false)
      setIsAdminActive(false)
    }
    if (pathsReport.includes(pathname)) {
      setIsReportActive(true)
    } else if (!pathsReport.includes(pathname)) {
      setOpenDropdownReport(false)
      setIsReportActive(false)
    }
  }, [location])

  useEffect(() => {
    const isMobile = (window.innerWidth <= 768)
    if (isMobile) {
      let aside = document.getElementById("aside");
      let containerMain = document.getElementById("con-main");
      containerMain.classList.remove("containerMain");
      aside.classList.remove("w-5v2");
      setMobile(true)
    }
  }, [])
  // general style Mobile-Desktop
  const sizeImgItem = resizeMenu ? "w-30px" : "h-40px";
  const sizeIconArrow = resizeMenu ? "" : "font32";
  const classLabelItem = resizeMenu ? "d-none" : "text-16";
  const classLabelSubItem = resizeMenu ? "d-none" : "text-14";
  const navItemClass = resizeMenu ? "nav-item w-nav-58" : "nav-item w-nav-75";
  // permissions
  const {permissions} = me;
  const internalUserManagement = permissions.internal_user_management;
  const rolePermissionsManagement = permissions.role_permissions_management;
  const member_management = permissions.member_management;
  const universitiesManagement = permissions.universities_management;
  const professionsManagement = permissions.professions_management;
  const stampsManagement = permissions.electronic_stamp_management;
  const productsManagement = permissions.product_management;
  const expenseCategoriesManagement = permissions.expense_categories_management;
  const comissionsManagement = permissions.comission_management;
  const binnacleManagement = permissions.binnacle_management;
  const floatingPaymentsManagement = permissions.floating_payments_management;
  const isMember = permissions.is_member;
  const administrativeCashRegisterPermission = permissions.collections_cashiers;
  const myCashRegistersPermission = permissions.cash_boxes_access;
  const incomeExpensesPermission = permissions.income_expenses;
  const contributionReportPermission = permissions.contribution_report;
  const stampsIssueReportPermission = permissions.stamps_issue_report;
  const stampsSoldReportPermission = permissions.stamps_sold_report;
  const stampsUsedReportPermission = permissions.stamps_issue_report;
  const documentConsultationPermission = permissions.document_consultation;
  const technicalAddressPermission = permissions.technical_address_management;
  const paymentDocumentForMemberPermission = permissions.payment_document_management;
  const banksManagement = permissions.banks_management;
  const typeOfManagements = permissions.type_of_managements;
  const dashboard = permissions.dashboard;

  return (
    <aside id="aside" className={`main-sidebar px-0 col-md-v2 col-lg-new-1 ${toggleOpen ? "" : "open"}`}>
      <div onClick={toggleAction} className="d-sm-none-2 d-md-none d-lg-block  container-switch-menu">
        <img id="tag-switch" src={require("assets/img/switch.png")} alt="switch"/>
      </div>
      <div className="w-100 mr-0 mt-4">
        <div className="d-flex mx-auto mt-3">
          <img
            alt="Logo"
            src={logoCOFAQUI}
            className="mx-auto w-logo"
          />
        </div>
      </div>
      <div className="close-menu d-none" onClick={navToggle}>
        <i className="material-icons" style={{color: "#FFF", fontSize: "30px"}}>close</i>
      </div>
      <div className="nav-wrapper mt-2 pb-4 mb-3 trans-scroll">
        <ul className="nav--no-borders nav">
          {
            dashboard && (
              <li className={navItemClass}>
                <NavLink
                  to="/dashboard"
                  className="nav-link"
                  activeClassName={"active"}
                  onClick={changeToggle}
                >
                  <div className="flex-column mb-1">
                    <img src={HomeIcon} alt={""} className={`item-icon0 ${sizeImgItem}`} color={"red"}/>
                  </div>
                  <span className={classLabelItem}>Dashboard</span>
                </NavLink>
              </li>
            )
          }
          {
            (member_management || technicalAddressPermission || paymentDocumentForMemberPermission) && (
              <li className={navItemClass}>
                <NavLink
                  to="/"
                  className="nav-link"
                  activeClassName={"active"}
                  onClick={changeToggle}
                  isActive={validateActiveLink(allRoutes.memberPath)}
                >
                  <div className="flex-column mb-1">
                    <img src={userIcon} alt={""} className={`item-icon1 ${sizeImgItem}`}/>
                  </div>
                  <span className={classLabelItem}>Agremiados</span>
                </NavLink>
              </li>
            )
          }
          {
            isMember && (
              <li className={navItemClass}>
                <NavLink to="/managements" className="nav-link" activeClassName={"active"}
                onClick={changeToggle} replace>
                  <div className="flex-column mb-lg-2 mb-xl-2 mb-md-2 mb-sm-0">
                    <img src={creditCard} alt={"creditCard"} className={`item-icon3 ${sizeImgItem}`}/>
                  </div>
                  <span className={classLabelItem}>Mis Gestiones</span>
                </NavLink>
              </li>
            )
          }
          {
            myCashRegistersPermission && (
              <li className={navItemClass}>
                <NavLink
                  to="/my-cash-register"
                  className="nav-link"
                  isActive={validateActiveLink(allRoutes.myCashRegister)}
                  activeClassName={"active"}
                  onClick={changeToggle}
                  replace>
                  <div className="flex-column mb-lg-2 mb-xl-2 mb-md-2 mb-sm-0">
                    <img src={wallet} alt={""} className={`item-icon-cash-reg ${sizeImgItem}`}/>
                  </div>
                  <span className={classLabelItem}>Cajas</span>
                </NavLink>
              </li>
            )
          }
          <li className={`${navItemClass} d-flex flex-column justify-content-center ${(dropdownAdminOpen) && "false-Active"}`}>
            {
              (internalUserManagement || rolePermissionsManagement || universitiesManagement || professionsManagement || stampsManagement || productsManagement || expenseCategoriesManagement || comissionsManagement || binnacleManagement || floatingPaymentsManagement || administrativeCashRegisterPermission || incomeExpensesPermission || contributionReportPermission || stampsIssueReportPermission || stampsUsedReportPermission || documentConsultationPermission || banksManagement || typeOfManagements) && (
                <div
                  className={`nav-link pointer ${(isAdminActive && !dropdownAdminOpen) && "active"}`}
                  onClick={toggleCollapseAdmin}>
                  <div className="sub-item-img mb-1">
                    <img
                      alt={""}
                      className={`item-icon5 ${sizeImgItem}`}
                      src={isAdminActive && !dropdownAdminOpen ? greenBag : bag}
                    />
                    <span className={`material-icons arrow-item ${sizeIconArrow} d-lg-block d-md-block d-sm-none-2`}>
                  keyboard_arrow_down
                    </span>
                  </div>
                  <span className={classLabelItem}>Administración</span>
                  <span className={`material-icons arrow-item ${sizeIconArrow} d-lg-none d-md-none d-sm-block`}>
                keyboard_arrow_down
                  </span>
                </div>
              )
            }
            <Collapse isOpen={dropdownAdminOpen} className="px-0 align-content-start scroll-items-admin">
              <ul className="p-0 mx-0" id='collapse-1'>
                {
                  internalUserManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/users"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        isActive={validateActiveLink(allRoutes.userPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        replace>
                        <img
                          alt={"binnacle"}
                          src={require("assets/img/icons/white-user.svg")}
                          className="mr-2 mb-1 item-admin-icon2"
                        />
                        <span className={classLabelSubItem}>Usuarios</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  rolePermissionsManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/permissions-roles"
                        isActive={validateActiveLink(allRoutes.rolesPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle} replace>
                        <img src={require("assets/img/icons/white-permissions.svg")} alt={"binnacle"} className="mr-2 item-admin-icon3"/>
                        <span className={classLabelSubItem}>Roles/Permisos</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  universitiesManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/universities"
                        isActive={validateActiveLink(allRoutes.universityPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle} replace>
                        <img
                          src={require("assets/img/icons/white-universities.svg")}
                          alt={"Univesidad"}
                          className="mr-2 item-icon6"
                        />
                        <span className={classLabelSubItem}>Universidades</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  professionsManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        replace
                        to={"/professions"}
                        isActive={validateActiveLink(allRoutes.professionPath)}
                        activeClassName={"active"}
                        onClick={changeToggle}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                      >
                        <img
                          src={require("assets/img/icons/white-professions.svg")}
                          alt={"Profesion"}
                          className="mr-2 item-icon7"
                        />
                        <span className={classLabelSubItem}>Profesiones</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  comissionsManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        activeClassName={"active"}
                        onClick={changeToggle}
                        to="/commissions"
                        isActive={validateActiveLink(allRoutes.commissionPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                        replace>
                        <img
                          alt={"Comisiones"}
                          className="mr-2 item-icon8"
                          src={require("assets/img/icons/commission.png")}
                        />
                        <span className={classLabelSubItem}>Comisiones</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  expenseCategoriesManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/expense-categories"
                        isActive={validateActiveLink(allRoutes.expenseCat)}
                        activeClassName={"active"}
                        onClick={changeToggle}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                        replace>
                        <img
                          width={22}
                          height={21}
                          src={spentIcon}
                          alt={"Tipo Gasto"}
                          className="mr-2 item-icon9"
                        />
                        <span className={classLabelSubItem}>Cat. Contable</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  productsManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        replace
                        to="/products"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        isActive={validateActiveLink(allRoutes.productPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                      >
                        <img alt="" src={product} className="mr-2 item-icon10" />
                        <span className={classLabelSubItem}>Productos</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  stampsManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        replace
                        to="/stamps"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        isActive={validateActiveLink(allRoutes.stampPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                      >
                        <img
                          height={21}
                          src={require("assets/img/stamp.png")} alt={""} className={"mr-2 item-green-stamp"}/>
                        <span className={classLabelItem}>Timbres</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  administrativeCashRegisterPermission && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/cash-registers"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        isActive={validateActiveLink(allRoutes.adminCashRegister)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                        replace>
                        <img src={wallet} alt={""} className={"mr-2 item-icon-cash-reg"} width="22px"/>
                        <span className={classLabelItem}>Cajas</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  floatingPaymentsManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/floating_payment"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        isActive={validateActiveLink(allRoutes.floatingPayment)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                        replace>
                        <img src={floatingPayment} alt={""} className={"mr-2 item-icon-floating-pay"} width="22px"/>
                        <span className={classLabelSubItem}>Pagos Flotantes</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  banksManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/banks"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        isActive={validateActiveLink(allRoutes.banksPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left  sub-item-container"
                        replace>
                        <img src={bank} alt={"bancos"} className={"mr-2 item-icon-bank"} width="22px"/>
                        <span className={classLabelSubItem}>Bancos</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  typeOfManagements && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/management"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        isActive={validateActiveLink(allRoutes.managementPath)}
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        replace>
                        <img src={management} alt={"gestiones"} className={"mr-2 item-icon-management"} width="22px"/>
                        <span className={classLabelSubItem}>Gestiones</span>
                      </NavLink>
                    </li>
                  )
                }
              </ul>
            </Collapse>
          </li>
          <li className={`${navItemClass} d-flex flex-column justify-content-center ${(openDropdownReport) && "false-Active"}`}>
            {
              (binnacleManagement || contributionReportPermission || incomeExpensesPermission || stampsIssueReportPermission || stampsUsedReportPermission || documentConsultationPermission) && (
                <div
                  className={`nav-link pointer ${(isReportActive && !openDropdownReport) && "active"}`}
                  onClick={toggleCollapseReport}>
                  <div className="sub-item-img mb-1">
                    <img src={bookIcon} alt="" className={`item-icon-report ${sizeImgItem}`} />
                    <span className={`material-icons arrow-item ${sizeIconArrow} d-lg-block d-md-block d-sm-none-2`}>
                  keyboard_arrow_down
                    </span>
                  </div>
                  <span className={classLabelItem}>Reportes</span>
                  <span className={`material-icons arrow-item ${sizeIconArrow} d-lg-none d-md-none d-sm-block`}>
                keyboard_arrow_down
                  </span>
                </div>
              )
            }
            <Collapse isOpen={openDropdownReport} className={"px-0 align-content-start"}>
              <ul className="p-0 mx-0" id='collapse-1'>
                {
                  binnacleManagement && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/binnacle"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        replace>
                        <img src={require("assets/img/binnacle.png")} alt={"binnacle"} className="mr-2 item-admin-icon1" />
                        <span className={classLabelItem}>Bitácora</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  contributionReportPermission && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/contributions-report"
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        replace
                      >
                        <img src={aportIcon} alt="aportaciones" className={"mr-2 item-icon-contribution"} width="22px"/>
                        <span className={classLabelItem}>Aportaciones</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  incomeExpensesPermission && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/incomeandexpenses-report"
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        replace
                      >
                        <img src={iaeIcon} alt="ingresos y egresos" className={"mr-2 item-icon-iae"} width="20px"/>
                        <span className={classLabelItem}>Ing. / Egr.</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  stampsIssueReportPermission && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/stamps-issued-report"
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        replace
                      >
                        <img src={require("assets/img/icons/whitetimbresemitidos.svg")} alt="T. Emitidos" className={"mr-2 item-icon-timbres-emitidos"} width="22px"/>
                        <span className={classLabelItem}>T. Emitidos</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  stampsSoldReportPermission && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/stamps-sold-report"
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        replace
                      >
                        <img src={require("assets/img/icons/dollar.svg")} alt="T. Vendidos" className={"mr-2 item-icon-timbres-emitidos"} width="22px"/>
                        <span className={classLabelItem}>T. Vendidos</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  stampsUsedReportPermission && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/stamps-used-report"
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        replace
                      >
                        <img src={require("assets/img/icons/whitetimbresemitidos.svg")} alt="T. Usados" className={"mr-2 item-icon-timbres-emitidos"} width="22px"/>
                        <span className={classLabelItem}>T. Usados</span>
                      </NavLink>
                    </li>
                  )
                }
                {
                  documentConsultationPermission && (
                    <li className="nav-item w-total-100 mb-2 br-10px sub-item">
                      <NavLink
                        to="/document-consultation-report"
                        className="nav-link pl-2 pr-0 py-2-5 text-left sub-item-container"
                        activeClassName={"active"}
                        onClick={changeToggle}
                        replace
                      >
                        <img src={require("assets/img/icons/whiteconsultadocumentos.svg")} alt="Consulta Doc." className={"mr-2 item-icon-consulta-documentos"} width="22px"/>
                        <span className={classLabelItem}>Consulta Doc.</span>
                      </NavLink>
                    </li>
                  )
                }
              </ul>
            </Collapse>
          </li>
        </ul>
      </div>
    </aside>
  )
}

function actionSwitch(open) {
  let tagSwitch = document.getElementById("tag-switch");
  let aside = document.getElementById("aside");
  let containerMain = document.getElementById("con-main");
  if (open) {
    tagSwitch.classList.add("visible1");
    aside.classList.add("w-5v2")
    containerMain.classList.add("containerMain")
  } else {
    tagSwitch.classList.remove("visible1");
    aside.classList.remove("w-5v2")
    containerMain.classList.remove("containerMain")
  }
}

export default SideBar;
