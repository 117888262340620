import React from "react";
import { connect } from "react-redux";
import {actions} from "../../../redux/modules/dashboard"
import Dashboard from "./Dashboard";

const mapStateToProps = (state) => ({
    ...state.dashboard,
});

const mapDispatchToProps = {
    ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
