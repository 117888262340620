import {connect} from "react-redux";
import {actions} from "../../../../redux/modules/member";
import {actions as actionsDep} from "../../../../redux/modules/department";
import Create from "./Create";


const ms2p = (state) => {
  return {
    ...state.member,
    ...state.form,
    ...state.department,
  }
};

const md2p = {...actions, ...actionsDep};

export default connect(ms2p, md2p)(Create);
