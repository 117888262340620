import React, { useEffect } from "react";
import Select from "react-select";

const selectStyles = {
  placeholder: (styles) => ({
    ...styles,
    color: " #B0A196",
    fontSize: "16px",
    fontWeight: 400,
  }),
  control: (styles, { isDisabled }) => ({
    backgroundColor: isDisabled ? "#E3E3E3" : "#FAFDF3",
    border: "1.5px solid #B0A196 !important",
    borderRadius: "20px !important",
    display: "flex",
    padding: 2.5,
    color: isDisabled ? "#959595" : "#4B4D58",
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      backgroundColor: isDisabled
        ? "#959595"
        : isSelected
          ? "#E2DDD9"
          : isFocused
            ? "#EAE8E6"
            : undefined,
      color: "#4B4D58",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#E2DDD9"
            : "EAE8E6"
          : undefined,
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#FAFDF3",
    border: "1.5px solid #B0A196",
    borderRadius: "8px",
    color: "#4B4D58",
    marginTop: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    display: "inline-flex",
  }),
};

const SelectFilterForm = ({ options, label, onChangeAction, placeholder}) => {
  return (
    <div className="">
      <label>{label}</label>
      &nbsp;
      <Select
        isSearchable={false}
        styles={selectStyles}
        className="select"
        options={options}
        onChange={(option) => onChangeAction(option)}
        placeholder={placeholder || "Selecione una opción"}
        isClearable={false}
      />
    </div>
  );
};

export default SelectFilterForm;
