import React, {useEffect} from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";

import "./list.css";
import Tabs, {TabPane} from "rc-tabs";
import {PendingRequestList} from "../PendingStamp";
import {ApprovalRequestList} from "../ApprovedStamp";
import RejectedRequestList from "../RejectedStamp";

const StampsList = (
  {
    getStamps,
    stampList,
    page,
    loader,
    onSortChange,
    ListApprovalReq,
    ListPendingReq,
    dataApprovedReq,
    pageApprovedReq,
    dataPendingReq,
    pagePendingReq,
    dataRejectedReq,
    pageRejectedReq,
    ListRejectedReq,
    me: { permissions: { document_management, electronic_stamp_management, approved_stamps, rejected_stamps, pending_stamps } },
  }) => {

  useEffect(() => {
    if (electronic_stamp_management) {
      getStamps();
    }
    if (approved_stamps) {
      ListApprovalReq && ListApprovalReq(1)
    }
    if (pending_stamps) {
      ListPendingReq && ListPendingReq(1)
    }
    if (rejected_stamps) {
      ListRejectedReq && ListRejectedReq(1)
    }
  }, []);

  const actions = (row) => {
    const type = row.type;

    const validateActions = {
      editar: "stamp",
    }

    if (type == 1 && document_management) {
      validateActions.generatedDocuments = {
        to: "generated-documents",
        for: "stamps",
      }
      validateActions.requiredDocuments = {
        to: "required-documents",
        for: "stamps",
      }

      return standardActions(validateActions);
    }
    return standardActions(validateActions);
  }

  return (
    <div className="">
      <PageTitle
        title={"Timbres"}
      />
      <div>
        <div className="p-0 cash-container">
          <Tabs
            tabBarStyle={{background: "transparent"}}
            defaultActiveKey={"stamps"}
          >
            {
              electronic_stamp_management && (
                <TabPane tab="Timbres" key="stamp">
                  <div className="card bg-card-cofaqui br-20px p-4">
                    <PageTitle
                      title={""}
                      withLink={true}
                      linkTo={"new-stamp"}
                      linkLabel={"Nuevo timbre"}
                      customContainer="w-100 flex-wrap justify-content-between mb-3 pt-0"
                    />
                    <Grid
                      hover
                      responsive
                      data={stampList}
                      page={page}
                      loading={loader}
                      onPageChange={getStamps}
                      onSortChange={onSortChange}
                    >
                      <TableHeaderColumn
                        isKey
                        dataField="icon"
                        dataAlign="center"
                        width="110px"
                        dataFormat={
                          (cell, row) => {
                            return (
                              <div className="d-flex justify-content-center">
                                <img src={cell} alt={row.name} style={{width: "40px", backgroundColor: row.color}}/>
                              </div>
                            )
                          }
                        }
                      >
                    Imagen
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataAlign="center"
                        dataField="name"
                        width="250px"
                      >
                    Título del timbre
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataAlign="center"
                        dataField="type_name"
                        width="200px"
                      >
                    Tipo de timbre
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataAlign="center"
                        dataField="profession"
                        width="325px"
                      >
                    Profesión
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width="150px"
                        dataFormat={(cell, row) => (
                          actions(row)(cell, row)
                        )}
                      >
                    Acciones
                      </TableHeaderColumn>
                    </Grid>
                  </div>
                </TabPane>
              )
            }
            {
              pending_stamps && (
                <TabPane tab="Pendientes" key="pending">
                  <PendingRequestList
                    data={dataPendingReq}
                    page={pagePendingReq}
                    loader={loader}
                    onChangePage={ListPendingReq}
                  />
                </TabPane>
              )
            }
            {
              approved_stamps && (
                <TabPane tab="Aprobados" key="approved">
                  <ApprovalRequestList
                    data={dataApprovedReq}
                    pageApprovedReq={pageApprovedReq}
                    onChangePage={ListApprovalReq}
                    loader={loader}/>
                </TabPane>
              )
            }
            {
              rejected_stamps && (
                <TabPane tab="Rechazados" key="rejected">
                  <RejectedRequestList
                    data={dataRejectedReq}
                    pageRejectedReq={pageRejectedReq}
                    onChangePage={ListRejectedReq}
                    loader={loader}
                  />
                </TabPane>
              )
            }
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default StampsList;
