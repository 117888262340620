import React, { useEffect, useState } from "react"
import Tabs, {TabPane} from "rc-tabs"
import {Link} from "react-router-dom"
import Async from "react-select/async"
import FormsRequired from "./Tabs/FormsRequired"
import DocumentsRequired from "./Tabs/DocuementsRequired"
import {PR_APPROVED, PR_IN_PROGRESS, PR_REFUSED} from "../../../../../utility/constants"
import {
  customStylesDefault,
  IndicatorSeparator,
  DropdownIndicator,
} from "../../../../components/Utils/renderField/renderField"
import DocsGenerateRequired from "./Tabs/DocsGenerateRequired"
import ConfirmationModal from "./Modal/ConfirmationModal"

const RequestForm = (
  {
    toggleModalAction,
    getDocsAndForms,
    requestApproval,
    isApprovedView,
    addFileToStore,
    setValueSelect,
    defaultOption,
    promiseAction,
    documentsData,
    isDetailView,
    isRejectedView,
    declineAction,
    approvalAction,
    saveProgress,
    valueSelect,
    onChaneProd,
    formsData,
    docsGenerate,
    printAction,
    isUpdate,
    loader,
    stampUnderApproval,
    loadManagements,
    defaultManagements,
    typeOfManagement,
    changeTypeOfManagement,
    addFormsToStore,
    params: { id},
    path,
  }) => {
  const classNameContainer = "d-lg-flex d-md-flex d-sm-block flex-wrap"

  const [requiredForms, setRequiredForms] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const openModalAction = () => {
    setOpenModal(true)
  }

  const closeModalAction = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if (stampUnderApproval === false) {
      changeTypeOfManagement(null)
    }
  }, [stampUnderApproval])

  return (
    <div>
      <ConfirmationModal
        openModal={openModal}
        closeModalAction={closeModalAction}
        declineAction={declineAction}
        id={id}
        // approvalAction={approvalAction}
      />
      <div className="bg-card-cofaqui shadow br-20px py-5 col-lg-6 col-md-10 col-sm-12 ">
        <div className={classNameContainer}>
          <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-1">
            <label className="text-16 font-weight-bold" htmlFor="members">
            Búsqueda de gestiones y/o timbres
            </label>
            <Async
              cacheOptions
              value={valueSelect}
              isDisabled={isUpdate}
              defaultOptions={defaultOption}
              components={{DropdownIndicator, IndicatorSeparator}}
              styles={customStylesDefault}
              getOptionLabel={e => e["name"]}
              getOptionValue={e => e["id"]}
              loadOptions={promiseAction}
              placeholder={"Nombre de gestión o timbre"}
              isClearable={false}
              isSearchable={true}
              onChange={(e) => {
                setValueSelect(e)
                onChaneProd()
              }}
              className={"react-select-container"}
            />
          </div>
          {!isUpdate &&
          <div
            className="d-flex flex-wrap col-lg-3 col-md-5 col-sm-12 mx-lg-4 mx-0 mb-lg-0 mb-md-2 mb-sm-3 px-0">
            <div className="align-self-center w-100 mt-lg-2 mt-md-2 mt-sm-0">
              <button
                type="button"
                disabled={(valueSelect == null || isUpdate)}
                className="btn btn-create w-100 mt-lg-2 mt-md-2 mt-sm-0"
                onClick={getDocsAndForms}>
                Seleccionar
              </button>
            </div>
          </div>
          }
        </div>
        {
          stampUnderApproval && (
            <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-1">
              <label className="text-16 font-weight-bold" htmlFor="members">
                 Tipo de gestión
              </label>
              <Async
                cacheOptions
                value={typeOfManagement}
                isDisabled={isUpdate}
                defaultOptions={defaultManagements}
                components={{DropdownIndicator, IndicatorSeparator}}
                styles={customStylesDefault}
                getOptionLabel={e => e["name"]}
                getOptionValue={e => e["id"]}
                loadOptions={loadManagements}
                placeholder={"Gestión"}
                isClearable={false}
                isSearchable={true}
                onChange={(e) => {
                  changeTypeOfManagement(e)
                }}
                className={"react-select-container"}
              />
            </div>
          )
        }
      </div>
      <div className="p-0 cash-container pt-5 m-0">
        <Tabs
          tabBarStyle={{background: "transparent"}}
          defaultActiveKey={"documents"}
        >
          <TabPane tab="Documentos" key="documents">
            <DocumentsRequired
              page={1}
              loader={loader}
              remote={false}
              isView={isApprovedView || isDetailView}
              addFileToStore={addFileToStore}
              data={documentsData}
              onPageChange={() => {
              }}
              onSortChange={() => {
              }}
              wasApproved={!!(valueSelect && valueSelect.purchase_request_status === PR_APPROVED)}
              setRequiredDocuments={setRequiredDocuments}
              isUpdate={isUpdate}
              path={path}
            />
          </TabPane>
          <TabPane tab="Formularios" key="forms">
            <FormsRequired
              page={1}
              loader={loader}
              remote={false}
              isView={isApprovedView || isDetailView}
              data={formsData}
              onPageChange={() => {
              }}
              onSortChange={() => {
              }}
              toggleModalAction={toggleModalAction}
              addFileToStore={addFormsToStore}
              wasApproved={!!(valueSelect && valueSelect.purchase_request_status === PR_APPROVED)}
              setRequiredForms={setRequiredForms}
              isUpdate={isUpdate}
              path={path}
            />
          </TabPane>
          {valueSelect && valueSelect.purchase_request_status >= PR_APPROVED && docsGenerate.results.length > 0 && (
            <TabPane tab="Documentos Generados" key="documentsGenerate">
              <DocsGenerateRequired
                page={1}
                loader={loader}
                remote={false}
                data={docsGenerate}
                onPageChange={() => {
                }}
                onSortChange={() => {
                }}
                printAction={printAction}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
      <div className="w-100 d-flex flex-wrap justify-content-center pt-5">
        {(isApprovedView || isDetailView || isRejectedView) ?
          <React.Fragment>
            <Link to={"/stamps"} className="btn btn-secondary col-lg-2 col-md-4 col-sm-12 mt-3">
              <span className="material-icons" style={{fontSize: "20px", weight: 200}}>arrow_back_ios</span>
              &nbsp;Atras
            </Link>
            {isDetailView &&
              <React.Fragment>
                <button
                  type="button"
                  disabled={!valueSelect}
                  onClick={openModalAction}
                  className="btn btn-danger col-lg-2 col-md-4 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
                  <span className="ml-2">Rechazar</span>
                </button>
                {(valueSelect && valueSelect.purchase_request) &&
                  <button
                    type="submit"
                    disabled={!valueSelect}
                    onClick={approvalAction}
                    className="btn btn-lila col-lg-2 col-md-4 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
                    <span className="ml-2">Aprobar</span>
                  </button>
                }
              </React.Fragment>
            }
          </React.Fragment> :
          <React.Fragment>
            <Link to={"/managements/purchase-request"} className="btn btn-secondary col-lg-2 col-md-4 col-sm-12 mt-3">
              <span className="material-icons" style={{fontSize: "20px", weight: 200}}>arrow_back_ios</span>
              &nbsp;Atras
            </Link>
            {!(valueSelect && valueSelect.purchase_request_status === PR_APPROVED) &&
              <React.Fragment>
                <button
                  type="button"
                  disabled={!valueSelect}
                  onClick={() => saveProgress(requiredForms, requiredDocuments, isUpdate)}
                  className="btn btn-success col-lg-2 col-md-4 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
                  <span className="icon-save" style={{width: "20px", height: "20px"}}>&nbsp;</span>
                  <span className="ml-2">Guardar</span>
                </button>
                {(valueSelect && valueSelect.purchase_request && valueSelect.purchase_request_status !== PR_IN_PROGRESS) &&
                  <button
                    type="submit"
                    disabled={!valueSelect}
                    onClick={() => requestApproval(requiredForms, requiredDocuments)}
                    className="btn btn-lila col-lg-2 col-md-4 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
                    <span className="ml-2">Solicitar aprobación</span>
                  </button>
                }
              </React.Fragment>
            }
          </React.Fragment>
        }
      </div>
    </div>
  )
}

export default RequestForm
