import React, { useState, useEffect, useRef } from "react";
import "./FileUploader.css"

const defaultProps = {
  baseColor: "gray",
  activeColor: "green",
  overlayColor: "rgba(255,255,255,0.3)",
  opacity: 0
};

function FileUploader(props) {
  const [active, setActive] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const inputRef = useRef(null);

  const onFileChange = (e, file) => {
    if (props.onFileChange) {
      props.onFileChange(e, file);
    }

    file = file || e.target.files[0];
    const pattern = /-*/;
    const imagePattern = /image-*/;

    const reader = new FileReader();
    if (file) {
      const isImage = !!file.type.match(imagePattern);
      if (!file.type.match(pattern)) {
        alert("Formato inválido");
        return;
      }

      setLoaded(false);

      reader.onload = (e) => {
        setImageSrc(reader.result);
        setIsImage(isImage);
        setLoaded(true);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (props.source !== undefined && props.source !== null) {
      setImageSrc(props.source);
      setLoaded(true);
    }
  }, [props.source]);

  useEffect(() => {
    if (props.img !== null && props.img !== undefined) {
      setImageSrc(props.img);
      setLoaded(true);
      setIsImage(true);
    }
  }, [props.img]);

  useEffect(() => {
    if (props.deleteBackground === true && props.setDeleteBackground) {
      setImageSrc("");
      setLoaded(false);
      setIsImage(false);
      setActive(false);
      props.setDeleteBackground(false);
    }
  }, [props.deleteBackground]);

  const onDragEnter = (e) => {
    setActive(true);
  };

  const onDragLeave = (e) => {
    setActive(false);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    setActive(false);
    onFileChange(e, e.dataTransfer.files[0]);
  };

  const getFileObject = () => {
    return inputRef.current.files[0];
  };

  const getFileString = () => {
    return imageSrc;
  };

  let labelClass = `uploader ${loaded && "loaded"}`;
  let borderColor = active ? props.activeColor : props.baseColor;
  let iconColor = active
    ? props.activeColor
    : loaded
      ? props.overlayColor
      : props.baseColor;
  let hideIcon = loaded ? 0 : 1;

  return (
    <label
      className={labelClass}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <img
        src={isImage ? imageSrc : require("assets/img/uploaded.png")}
        className={loaded ? "loaded" : undefined}
        alt=""
      />
      <img
        style={{ color: iconColor, opacity: hideIcon }}
        className="icon icon-upload"
        src={require("assets/img/bg-img-field.png")}
        alt=""
      />
      <p className="texto" style={{ opacity: hideIcon }}>
        Clic aquí o arrastrar para adjuntar.
      </p>
      <input
        disabled={props.disabled}
        type="file"
        accept={props.accept}
        onChange={onFileChange}
        ref={inputRef}
      />
    </label>
  );
}

FileUploader.defaultProps = defaultProps;

export default FileUploader;
