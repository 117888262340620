import React from "react"
import Modal from "react-responsive-modal";

const VisalinkModal = ({openModal, closeModalAction, paymentLink}) => {

  return (
    <div>
      <Modal
        center
        open={openModal}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        styles={{
          modal: {
            width: "600px",
            padding: "2.5%",
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: "#B0A196"
          }
        }}
        // onClose={closeModalAction}
      >
        <div>
          <center>
            <h4 className="font-weight-bold mb-3 text-center">
                Se ha generado un link de pago para tu compra con EBI Pay
            </h4>

          </center>
          <p>
            Porfavor haz clic en el boton de pago, esto te 
            redireccionará a un enlace generado por EBI PAY
            para completar el pago de tu compra.
          </p>
          <div className="d-flex flex-row justify-content-center">
            <button
              style={{
                background: "#6558F5",
              }}
              onClick={() => {
                window.location.href = paymentLink;
              }}
              type="button"
              className="btn col-lg-4 col-md-3 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
              {/* <span className="material-icons" style={{ fontSize: "20px", weight: 200 }}>arrow_back_ios</span> */}
            &nbsp;Pagar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default VisalinkModal