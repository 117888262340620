import React from "react"
import PageTitle from "../../../components/layout/PageTitle"
import docIcon from "../../../../../assets/img/icons/mydocs.svg"
import payment from "../../../../../assets/img/icons/mypayment.svg"
import refreshIcon from "../../../../../assets/img/icons/refreshxl.svg"
import directIcon from "../../../../../assets/img/icons/compra-directa.svg"
import purchaseRequest from "../../../../../assets/img/icons/purchaserequest.svg"
import Swal from "sweetalert2"

import "./myManagements.css"

const Status = ({data}) => {

  let label = " " + data["month"] + " " + data["year"]
  let color = data["color"]

  const styles = {
    color,
    fontSize: "12px",
    marginTop: "-4px",
    verticalAlign: "middle",
  }

  return (
    <span className="ml-2">
      <span className="material-icons" style={styles}>fiber_manual_record</span>
      <span className="">&nbsp;{label}</span>
    </span>
  )
}


const BtnItem = ({redirectTo, srcIcon, text, history, withMX = true, containerClass='', isUserValid=true, validation_errors=[]}) => {
  return (
    <div className={`mt-3 ${containerClass}`}>
      <button
        type="button"
        className="bg-transparent border-none"
        // className={isUserValid ? `bg-transparent border-none ${withMX && 'mx-4'}` : `${withMX && 'mx-4'}  disabledBtnItem`}
        onClick={() => {
          if (isUserValid){
            return history.push(redirectTo)
          }
          else {
            Swal.fire({
              title: '¡Atención!',
              text: `Termina de actualizar tus datos en "Actualizar datos de colegiado"
              para poder acceder a esta sección. ${validation_errors}`,
              icon: 'warning',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#0A4725',
            }).then(
              (result) => {
                if (result.isConfirmed) {
                  return history.push('/managements/update-my-information')
                }
              }
            )
          }
        }}
      >
        <div className='mx-auto'>
          <span className="">
            <img alt="" src={srcIcon}/>
          </span>
        </div>
        <div className="mt-3 w-100 mx-auto px-0">
          <span className="font-weight-bold" style={{color: '#0A4725', fontSize: '25px', lineHeight: '22px'}}>
            {text}
          </span>
        </div>
      </button>
    </div>
  )
}
const List = (props) => {

  const {me, history} = props;

  return (
    <div>
      <PageTitle
        colTitle="col-lg-4"
        removeCols="col-md-6 col-sm-12"
        title={`${me.no_collegiate ? me.no_collegiate : "Cod. colegiado"} - ${me.full_name}`}
      />
      <div className="bg-card-cofaqui shadow p-4" style={{ borderRadius: "20px" }}>
        <div className="row border-dark border-bottom pb-3">
          <div className="col-sm-12 col-md-6 col-lg-5">
            <span>
              Fecha de inscripción: {me.college_date}
            </span>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 font-weight-bold">
            <span>Estado de vigencia: </span>
            <Status
              data={me.status}
            />
          </div>
        </div>
        <div className="my-managements-buttons-container bg-card-cofaqui-light shadow">
          {/* ===================================================================================== */}
          {/* =========================         UPDATE MEMBER DATA        ==========----=========== */}
          {/* ===================================================================================== */}
          <BtnItem
            withMX={false}
            history={history}
            srcIcon={refreshIcon}
            containerClass={'my-managements-button'}
            text={'Actualizar datos de colegiado'}
            redirectTo={'/managements/update-my-information'}
          />
          {/* ===================================================================================== */}
          {/* =========================            MY DOCUMENTS            ======================== */}
          {/* ===================================================================================== */}
          <BtnItem
            history={history}
            srcIcon={docIcon}
            containerClass={'my-managements-button'}
            text={'Mis documentos'}
            redirectTo={'/managements/my-documents'}
          />
          {/* ===================================================================================== */}
          {/* =========================             MY DOCUMENTS           ======================== */}
          {/* ===================================================================================== */}
          <BtnItem
            history={history}
            srcIcon={payment}
            containerClass={'my-managements-button'}
            text={'Mis Pagos'}
            redirectTo={'/managements/my-payments'}
          />
          {/* ===================================================================================== */}
          {/* =========================          MY PURCHASE REQUEST        ======================= */}
          {/* ===================================================================================== */}
          <BtnItem
            history={history}
            srcIcon={purchaseRequest}
            text={'Solicitudes de compra'}
            containerClass={'my-managements-button'}
            isUserValid={me.isUserValid}
            validation_errors={me.validation_errors}
            redirectTo={'/managements/purchase-request'}
          />
          {/* ===================================================================================== */}
          {/* =========================             DIRECT PURCHASE         ======================= */}
          {/* ===================================================================================== */}
          <BtnItem
            history={history}
            srcIcon={directIcon}
            isUserValid={me.isUserValid}
            containerClass={'my-managements-button'}
            validation_errors={me.validation_errors}
            text={'Compra Directa'}
            redirectTo={'/managements/direct-purchases'}
          />
        </div>
      </div>
    </div>
  )
}

export default List
