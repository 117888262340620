import React, {useState, useEffect} from "react";
import _ from "lodash";
import {renderField, SimpleRenderFieldCheck} from "../../../../components/Utils/renderField";
import CustomSelect from "../../../../components/Utils/CustomSelect";
import {SCREENS as screens} from "../../../../../utility/constants";

import {Form, Field, FormSpy} from "react-final-form";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";
import CustomCard from "../../../../components/Utils/CustomCard";
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel";

const required = value => (value ? undefined : "Este campo es requerido");

const requiredSelect = value => {
  if (value) {
    return undefined;
  } else {
    return "Este campo es requerido";
  }
}

const clearSelectedScreen = (checkboxValue, nameOfScreen, formValues, form) => {
  if (!checkboxValue) {
    if (formValues.startup_screen === nameOfScreen) {
      form.change("startup_screen", "");
    }
  }
  return checkboxValue
}

const filteredScreens = (values, screens) => {
  const validateScreens = screens.filter(
    screen => {
      screen.validate = [];
      screen.permissions.map(
        permission => {
          if (values[permission] === true) {
            return screen.validate.push(true);
          } else {
            return screen.validate.push(false);
          }
        }
      )
      if (!screen.validate.includes(false)) {
        return screen;
      }
    }
  )
  return validateScreens;
}

const PermissionsAndRolesForm = ({
  // props
  createOrUpdate,
  // getRole,
  data = {},
  loader,
  type,
}) => {

  const [formState, setFormState] = useState(null)

  const [validHomeScreens, setValidHomeScreens] = useState([])

  const onSubmit = (values) => {
    if (type === "create") {
      createOrUpdate(values);
    } else {
      createOrUpdate(data.id, values);
    }
  }

  useEffect(() => {
    // si formState no esta vacio
    if (!_.isEmpty(formState)) {
      setValidHomeScreens(filteredScreens(formState, screens));
    }
  }, [formState])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({
        handleSubmit,
        form,
        // submitting,
        // pristine,
        values
      }) => (
        <React.Fragment>
          <FormSpy onChange={
            change => {
              setTimeout(() => {
                setFormState(change.values)
              }
              , 0)
            }
          }/>
          <form
            onSubmit={handleSubmit}
            className="py-4 px-lg-4 px-md-3 px-sm-2 permissions-roles-form"
          >
            <LoadMask blur dark loading={loader}>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label htmlFor="name">
                      <span className="title-color">
                        Nombre del rol
                      </span>
                    </label>
                    <Field
                      name="name"
                      component={renderField}
                      typeInput="text"
                      customClass="name-field"
                      placeholder="Invitado"
                      validate={required}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label htmlFor="startup-screen">
                      <span className="title-color">
                        Pantalla de inicio
                      </span>
                    </label>
                    <Field
                      name="startup_screen"
                      component={CustomSelect}
                      options={validHomeScreens}
                      customClass="home-screen-field"
                      placeholder="Dashboard"
                      validate={requiredSelect}
                    />
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="permissions-list">
                  <div className="d-flex flex-column">
                    <CustomCard
                      customStyle={{
                        backgroundColor: "#FAFDF3"
                      }}
                      customClass="cards-checkbox"
                      title="Administración"
                    >
                      <Field
                        name="internal_user_management"
                        value={true}
                        label="Gestión de usuarios (internos)"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "users", values, form)}
                      />
                      <Field
                        name="role_permissions_management"
                        value={true}
                        label="Gestión de roles y permisos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "permissions-roles", values, form)}
                      />
                      <Field
                        name="universities_management"
                        value={true}
                        label="Gestión de universidades"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "universities", values, form)}
                      />
                      <Field
                        name="professions_management"
                        value={true}
                        label="Gestión de profesiones"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "professions", values, form)}
                      />
                      <Field
                        name="product_management"
                        value={true}
                        label="Gestión de productos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "products", values, form)}
                      />
                      <Field
                        name="document_management"
                        value={true}
                        label="Gestión de documentos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                      />
                      <Field
                        name="banks_management"
                        value={true}
                        label="Gestión de bancos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "banks", values, form)}
                      />
                      <h5>Timbres</h5>
                      <div className="container-fluid">
                        <Field
                          name="electronic_stamp_management"
                          value={true}
                          label="Gestión de timbres"
                          component={SimpleRenderFieldCheck}
                          typeInput="checkbox"
                          customClass="modules-field"
                          parse={value => clearSelectedScreen(value, "stamps", values, form)}
                        />
                        <Field
                          name="pending_stamps"
                          value={true}
                          label="Pendientes"
                          component={SimpleRenderFieldCheck}
                          typeInput="checkbox"
                          customClass="modules-field"
                          parse={value => clearSelectedScreen(value, "stamps", values, form)}
                        />
                        <Field
                          name="approved_stamps"
                          value={true}
                          label="Aprobados"
                          component={SimpleRenderFieldCheck}
                          typeInput="checkbox"
                          customClass="modules-field"
                          parse={value => clearSelectedScreen(value, "stamps", values, form)}
                        />
                        <Field
                          name="rejected_stamps"
                          value={true}
                          label="Rechazados"
                          component={SimpleRenderFieldCheck}
                          typeInput="checkbox"
                          customClass="modules-field"
                          parse={value => clearSelectedScreen(value, "stamps", values, form)}
                        />
                      </div>
                      <Field
                        name="collections_cashiers"
                        value={true}
                        label="Mis cajas asignadas"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "cash-registers", values, form)}
                      />
                      <Field
                        name="floating_payments_management"
                        value={true}
                        label="Gestión de pagos flotantes"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "floating_payments", values, form)}
                      />
                      <Field
                        name="expense_categories_management"
                        value={true}
                        label="Gestión de categorías contables"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "expense-categories", values, form)}
                      />
                      <Field
                        name="comission_management"
                        value={true}
                        label="Gestión de comisiones"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "comissions", values, form)}
                      />      
                      <Field
                        name="type_of_managements"
                        value={true}
                        label="Gestiones de timbres"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "management", values, form)}
                      />                
                    </CustomCard>
                  </div>
                  <div className="d-flex flex-column justify-content-between mt-4 mt-md-0">
                    <CustomCard
                      customStyle={{
                        backgroundColor: "#FAFDF3"
                      }}
                      customClass="cards-checkbox"
                      title="Agremiados"
                    >
                      <Field
                        name="member_management"
                        value={true}
                        label="Gestión de agremiados"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "/", values, form)}
                      />
                      <Field
                        name="technical_address_management"
                        value={true}
                        label="Direcciones técnicas"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "/", values, form)}
                      />
                      <Field
                        name="payment_document_management"
                        value={true}
                        label="Gestión de documentos y pagos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "/", values, form)}
                      />
                    </CustomCard>
                    <CustomCard
                      customStyle={{
                        backgroundColor: "#FAFDF3"
                      }}
                      customClass="cards-checkbox mt-4"
                      title="Cajas"
                    >
                      <Field
                        name="cash_boxes_access"
                        value={true}
                        label="Cajas"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "my-cash-register", values, form)}
                      />
                    </CustomCard>
                    <CustomCard
                      customStyle={{
                        backgroundColor: "#FAFDF3"
                      }}
                      customClass="cards-checkbox mt-4"
                      title="Dashboard"
                    >
                      <Field
                        name="dashboard"
                        value={true}
                        label="Dashboard"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "dashboard", values, form)}
                      />
                    </CustomCard>
                    <CustomCard
                      customStyle={{
                        backgroundColor: "#FAFDF3"
                      }}
                      customClass="cards-checkbox mt-4"
                      title="Reportes"
                    >
                      <Field
                        name="binnacle_management"
                        value={true}
                        label="Bitácora"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "binnacle", values, form)}
                      />
                      <Field
                        name="income_expenses"
                        value={true}
                        label="Reporte de ingresos y egresos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "incomeandexpenses-report", values, form)}
                      />
                      <Field
                        name="contribution_report"
                        value={true}
                        label="Reporte de aportaciones"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "contributions-report", values, form)}
                      />
                      <Field
                        name="stamps_issue_report"
                        value={true}
                        label="Reporte de timbres emitidos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "stamps-issued-report", values, form)}
                      />
                      
                      <Field
                        name="stamps_used_report"
                        value={true}
                        label="Reporte de timbres usados"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "stamps-used-report", values, form)}
                      />
                      <Field
                        name="stamps_sold_report"
                        value={true}
                        label="Reporte de timbres vendidos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "stamps-sold-report", values, form)}
                      />
                      <Field
                        name="document_consultation"
                        value={true}
                        label="Consulta de documentos"
                        component={SimpleRenderFieldCheck}
                        typeInput="checkbox"
                        customClass="modules-field"
                        parse={value => clearSelectedScreen(value, "document-consultation-report", values, form)}
                      />
                    </CustomCard>
                  </div>
                </div>
              </div>
              <BtnSaveAndCancel
                renderBtnSubmit
                renderLinkCancel
                redirectToLink="/permissions-roles"
                customContainer="container justify-content-center w-100 mb-3 mt-5 row"
                subContainer="col-lg-12 col-md-12 col-sm-12 row justify-content-center"
              />
            </LoadMask>
          </form>
        </React.Fragment>
      )}
    />
  );
};

export default PermissionsAndRolesForm;
