import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import {addIndexToArray} from "../../../../utility/constants";

const UNIVERSITY_DATA = "UNIVERSITY_DATA";
const UNIVERSITY_LIST = "UNIVERSITY_LIST";
const LOADER = "UNIVERSITY_LOADER";
const PAGE = "UNIVERSITY_PAGE";
const ORDERING = "UNIVERSITY_ORDERING";
const SEARCH = "UNIVERSITY_SEARCH";
const COUNTRY_LIST = "COUNTRY_LIST";

export const constants = {
  UNIVERSITY_DATA,
  UNIVERSITY_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
  COUNTRY_LIST,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setUniversityData = data => ({
  type: UNIVERSITY_DATA,
  data,
});

export const setUniversityList = data => ({
  type: UNIVERSITY_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

const setSearch = search => ({
  type: SEARCH,
  search,
});

const setCountryList = data => ({
  type: COUNTRY_LIST,
  data,
});

// ------------------------------------
// Actions
// ------------------------------------

const getUniversities = (page = 1) => (dispatch, getStore) => {
  const ordering = getStore().universities.ordering;
  const search = getStore().universities.search;
  const params = { page, ordering, search };
  dispatch(setLoader(true));
  api
    .get("/university", params)
    .then(response => {
      if (response.results) {
        response.results = addIndexToArray(response.results, page)
      }
      dispatch(setUniversityList(response));
      dispatch(setPage(page));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
}

const getUniversity = id => dispatch => {
  dispatch(setLoader(true));
  api
    .get(`/university/${id}`)
    .then(response => {
      dispatch(setUniversityData(response));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const createUniversity = data => dispatch => {
  dispatch(setLoader(true));
  api
    .post("/university", data)
    .then(response => {
      NotificationManager.success("Universidad creada exitosamente", "Success", 3000);
      dispatch(push("/universities"));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const updateUniversity = (id, data) => dispatch => {
  dispatch(setLoader(true));
  api
    .put(`/university/${id}`, data)
    .then(response => {
      NotificationManager.success("Universidad editada exitosamente", "Success", 3000);
      dispatch(push("/universities"));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const deleteUniversity = id => dispatch => {
  dispatch(setLoader(true));
  api
    .eliminar(`/university/${id}`)
    .then(response => {
      NotificationManager.success("Universidad eliminada existosamente", "Success", 3000);
      dispatch(getUniversities());
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const getCountries = () => (dispatch, getStore) => {
  const resource = getStore().universities

  const params = {};

  if (resource.search) {
    params.search = resource.search;
  }

  dispatch(setLoader(true));
  api
    .get("/country", params)
    .then(response => {
      dispatch(setCountryList(response));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const searchCountry = (search) => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getCountries());
}

const searchUniversity = (search) => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getUniversities());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
  const sort = getStore().universities.ordering;
  if (ordering === sort) {
    dispatch(setOrdering(`-${ordering}`));
  } else {
    dispatch(setOrdering(ordering));
  }
  dispatch(getUniversities());
};

export const actions = {
  getUniversities,
  getUniversity,
  createUniversity,
  updateUniversity,
  deleteUniversity,
  onSortChange,
  searchUniversity,
  getCountries,
  searchCountry,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [UNIVERSITY_DATA]: (state, { data }) => {
    return {
      ...state,
      universityData: data,
    };
  },
  [UNIVERSITY_LIST]: (state, { data }) => {
    return {
      ...state,
      universityList: data,
    };
  },
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [COUNTRY_LIST]: (state, { data }) => {
    return {
      ...state,
      countries: data,
    };
  }
};

export const initialState = {
  loader: false,
  universityData: {},
  universityList: {
    results: [],
    count: 0,
  },
  page: 1,
  ordering: "",
  search: "",
  countries: {
    count: 0,
    results: []
  },
};

export default handleActions(reducers, initialState);
