import React, { useState, useEffect, Fragment } from "react";
import { Step1, Step2, Step3, Forms } from "./Steps";

const StampsForm = ({
  formStep,
  getProfessionsPartOfUnion,
  professions,
  createOrUpdateStamp,
  stampData = {},
  type,
  setTitle,
  updateNumberFormSteps,
  updateNumberDocuments,
  numberDocuments,
  categories,
  openCategories,
  form,
  initialValues = {},
  match,
  permissions = {}
}) => {
  const [step, setStep] = useState(1);

  const [document, setDocument] = useState("");

  const [typeStamp, setTypeStamp] = useState();

  useEffect(() => {
    formStep(step);
    if (step === 1) {
      setTitle("Datos generales");
    } else if (step === 2) {
      setTitle("Documentos requeridos");
    } else if (step === 3) {
      setTitle("Formularios");
    } else if (step === 4) {
      setTitle("Documentos generados");
    }
  }, [step]);

  useEffect(() => {
    getProfessionsPartOfUnion();
  }, []);

  useEffect(() => {
    if (typeStamp == 1) {
      updateNumberFormSteps(4);
    } else {
      updateNumberFormSteps(1);
    }
  }, [typeStamp]);

  const nextStep = () => {
    setStep(currentStep => currentStep + 1);
  };

  const prevStep = () => {
    setStep(currentStep => currentStep - 1);
  };

  const documentManagement = permissions.document_management;

  return (
    <Fragment>
      <div className="d-flex flex-column">
        <div
          className="p-3 p-lg-5"
          style={{
            overflow: "auto",
          }}
        >
          {
            <Step1
              form={form}
              professions={professions}
              photo={initialValues.icon}
              type={type}
              setTypeStamp={setTypeStamp}
              updateNumberFormSteps={updateNumberFormSteps}
              openCategories={openCategories}
              categories={categories}
              nextStep={nextStep}
              prevStep={prevStep}
              step={step}
              typeStamp={typeStamp}
              createOrUpdateStamp={createOrUpdateStamp}
              edit={
                form && form.type == "edit" ? true : false
              }
              initialValues={initialValues}
              match={match}
              documentManagement={documentManagement}
            />
          }
          {
            <Step2
              form={form}
              type={type}
              typeStamp={typeStamp}
              updateNumberDocuments={updateNumberDocuments}
              numberDocuments={numberDocuments}
              nextStep={nextStep}
              prevStep={prevStep}
              step={step}
              createOrUpdateStamp={createOrUpdateStamp}
              edit={
                form && form.type == "edit" ? true : false
              }
              initialValues={initialValues}
              match={match}
            />
          }
          {
            <Forms
              form={form}
              type={type}
              typeStamp={typeStamp}
              updateNumberDocuments={updateNumberDocuments}
              numberDocuments={numberDocuments}
              nextStep={nextStep}
              prevStep={prevStep}
              step={step}
              createOrUpdateStamp={createOrUpdateStamp}
              edit={
                form && form.type == "edit" ? true : false
              }
              initialValues={initialValues}
              match={match}
            />
          }
          {
            <Step3
              form={form}
              setDocument={setDocument}
              document={document}
              photo={stampData.background}
              createOrUpdateStamp={createOrUpdateStamp}
              prevStep={prevStep}
              step={step}
              edit={
                form && form.type == "edit" ? true : false
              }
              initialValues={initialValues}
              match={match}
            />
          }
        </div>
      </div>
      {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
    </Fragment>
  );
};

export default StampsForm;