import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/administration/banks";
import Banks from "./Banks";

const ms2p = (state) => {
  return {
    ...state.banks,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Banks);
