import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
// eslint-disable-next-line
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import {addIndexToArray} from "../../../../utility/constants";
import moment from "moment";
const MEMBER_REPORT_LIST = "MEMBER_REPORT_LIST";
const LOADER = "MEMBER_REPORT_LOADER";
const PAGE = "MEMBER_REPORT_PAGE";
const ORDERING = "MEMBER_REPORT_ORDERING";
const SEARCH = "MEMBER_REPORT_SEARCH";
const FILTERS_STAMPS_USED = "FILTERS_STAMPS_ISSUED";
const FILTERS_STAMPS_USED_DETAILS = "FILTERS_STAMPS_USED_DETAILS";
const STAMP_ID = "STAMP_ID"
const LOADER_EXCEL_STAMP_USED="LOADER_EXCEL_STAMP_USED"
export const constants = {
  MEMBER_REPORT_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
  FILTERS_STAMPS_USED,
  FILTERS_STAMPS_USED_DETAILS,
  STAMP_ID,
  LOADER_EXCEL_STAMP_USED
};
// ------------------------------------
// Pure Actions
// ------------------------------------
export const setLoader = loader => ({
  type: LOADER,
  loader,
});
export const setMemberReportList = data => ({
  type: MEMBER_REPORT_LIST,
  data,
});
export const setPage = page => ({
  type: PAGE,
  page,
});
const setStampId = stampId => ({
  type: STAMP_ID,
  stampId,
});
// eslint-disable-next-line
const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});
// eslint-disable-next-line
const setSearch = search => ({
  type: SEARCH,
  search,
});
const setFiltersStampsUsed = data => ({
  type: FILTERS_STAMPS_USED,
  data,
});
const setFiltersStampsUsedDetails = data => ({
  type: FILTERS_STAMPS_USED_DETAILS,
  data,
});
export const setLoaderStampUsed = (loader) => ({
  type: LOADER_EXCEL_STAMP_USED,
  loader,
});
// ------------------------------------
// Actions
// ------------------------------------
const getReport = (page = 1) => (
  dispatch,
  getStore,
) => {
  const filter = getStore().stampsUsed.filtersStampsUsed;
  const select_stamp= getStore().stampsUsed.stampId
  const params = { page, ...filter };
  if (select_stamp){
    params.select_stamp = select_stamp;
  }
  dispatch(setLoader(true));
  api.get("/stamps_used/get_stamps_use",params)
    .then((response) => {
      if (response.results) response.results = addIndexToArray(response.results, page);
      dispatch(setMemberReportList(response));
      dispatch(setPage(page));
    })
    .catch(() => {
      NotificationManager.error(
        "Error al obtener a los agremiados",
        "ERROR",
        0,
      );
    })
    .finally(() => {
      dispatch(setLoader(false));
    }
    );
};
const updateFiltersStampsUsed= (values) => (
  dispatch,
) => {
  dispatch(setFiltersStampsUsed(values));
  dispatch(getReport());
};
const updateFiltersStampsUsedDetails = (values, noCollegiate) => (
  dispatch,
) => {
  dispatch(setFiltersStampsUsedDetails(values));
  dispatch(getStampsSold(1, noCollegiate));
};
const changeStamp = ({id}) => (dispatch) => {
  dispatch(setStampId(id))
  //dispatch(getReport(1))
}
const resetValueStamp = () => (dispatch) => {
  dispatch(setStampId(null))
}
export const exportReportStampUsedExcel = () => (dispatch, getStore) => {
  dispatch(setLoaderStampUsed(true));
  let endpoint = "/api/stamps_used/export_to_excel_report_stamp_used/";
  if (
    getStore().stampsUsed.filtersStampsUsed.start_date &&
    getStore().stampsUsed.filtersStampsUsed.end_date
  ) {
    if (
      moment(getStore().stampsUsed.filtersStampsUsed.start_date).isAfter(
        getStore().stampsUsed.filtersStampsUsed.end_date
      )
    ) {
      NotificationManager.error(
        "La fecha inicial debe ser menor a la fecha final",
        "ERROR",
        0
      );
      dispatch(setLoaderStampUsed(false));
      return;
    }
    endpoint += `?start_date=${moment(
      getStore().stampsUsed.filtersStampsUsed.start_date
    ).format("YYYY-MM-DD")}&end_date=${moment(
      getStore().stampsUsed.filtersStampsUsed.end_date
    ).format("YYYY-MM-DD")}`;
  } else if (
    !getStore().stampsUsed.filtersStampsUsed.start_date&&
    !getStore().stampsUsed.filtersStampsUsed.end_date
  ) {
    //pass params empty
    endpoint += "?start_date=&end_date="; //pass params empty
  } else {
    NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
    dispatch(setLoaderStampUsed(false));
    return;
  }
  if (getStore().stampsUsed.stampId) {
    endpoint += `&select_stamp=${getStore().stampsUsed.stampId}`;
  }
  fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Reporte de Timbres Usados.xlsx";
          a.click();
        });
      }
    })
    .catch(() => {
      NotificationManager.error(
        "Ocurrió un error al exportar el reporte",
        "ERROR",
        0
      );
    })
    .finally(() => {
      dispatch(setLoaderStampUsed(false));
    });
};
export const actions = {
  getReport,
  changeStamp,
  resetValueStamp,
  updateFiltersStampsUsed,
  updateFiltersStampsUsedDetails,
  exportReportStampUsedExcel
};
// ------------------------------------
// Reducer
// ------------------------------------
export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [MEMBER_REPORT_LIST]: (state, { data }) => {
    return {
      ...state,
      memberReportList: data,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [FILTERS_STAMPS_USED]: (state, { data }) => {
    return {
      ...state,
      filtersStampsUsed: data,
    };
  },
  [FILTERS_STAMPS_USED_DETAILS]: (state, { data }) => {
    return {
      ...state,
      filtersStampsUsedDetails: data,
    };
  },
  [STAMP_ID]: (state, {stampId}) => {
    return {
      ...state,
      stampId
    }
  },
  [LOADER_EXCEL_STAMP_USED]: (state, { loader }) => {
    return {
      ...state,
      loader_excel_stamp_used: loader,
    };
  },
};
export const initialState = {
  loader: false,
  stampId: null,
  memberReportList: {
    count: 0,
    results: [],
  },
  page: 1,
  ordering: "",
  search: "",
  filtersStampsUsed: {
    start_date: null,
    end_date: null,
  },
  filtersStampsUsedDetails: {
    start_date: null,
    end_date: null,
  },
  loader_excel_stamp_used:false,
};
export default handleActions(reducers, initialState);










