import React, { useEffect } from 'react'
import Select from "react-select";

const selectStyles = {
  placeholder: (styles) => ({
    ...styles,
    color: " #B0A196",
    fontSize: "16px",
    fontWeight: 400,
    padding: 0,
  }),
  control: (styles, { isDisabled }) => ({
    backgroundColor: isDisabled ? "#E3E3E3" : "#FAFDF3",
    border: "1.5px solid #B0A196 !important",
    borderRadius: "20px !important",
    display: "flex",
    paddingLeft: 10,
    color: isDisabled ? "#959595" : "#4B4D58",
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      backgroundColor: isDisabled
        ? "#959595"
        : isSelected
        ? "#E2DDD9"
        : isFocused
        ? "#EAE8E6"
        : undefined,
      color: "#4B4D58",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#E2DDD9"
            : "EAE8E6"
          : undefined,
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#FAFDF3",
    border: "1.5px solid #B0A196",
    borderRadius: "8px",
    color: "#4B4D58",
    marginTop: 0,
    
  }),
};

const SelectDashboard = ({options, label, defaultValue, onChangeAction}) => {
  

  return (
    <div className="d-flex justify-content-between align-items-center">
    <p className="title-grafica-light">{label}</p>
    &nbsp;

    <Select
      isSearchable={false}
      styles={selectStyles}
      className="select"
      options={options}
      defaultValue={defaultValue}
      onChange={(option) => onChangeAction(option)}
    />
  </div>
  )
}

export default SelectDashboard