import React, { Fragment } from "react";
import classNames from "classnames";
import Select from "react-select";

import selectStyles from "./selectStyles";
import "./select.css";

const CustomSelect = ({
  input,
  meta,
  customStyles=selectStyles,
  setSelectValue,
  ...rest
}) => {

  const DropdownIndicator = () => {
    return (
      <div className="d-flex justify-content-between flex-column">
        <span className="material-icons pr-2 icon-select-indicator">expand_less</span>
        <span className="material-icons pr-2 icon-select-indicator">expand_more</span>
      </div>
    )
  };

  const IndicatorSeparator = () => {
    return null;
  };
  
  const invalid = meta.touched && meta.error;

  return (
    <Fragment>
      <Select
        {...input}
        {...rest}
        value={
          rest.options.find(option => option.value === input.value ) || null
        }
        onChange={value => {
          input.onChange(value.value);
          setSelectValue(value.value);
        }}
        components={{ DropdownIndicator, IndicatorSeparator }}
        styles={customStyles}
        className={classNames({ "required": invalid })}
      />
      {
        invalid && <span className="error" style={{
          color: "#dc3545",
          fontSize: "12px",
          position: "absolute",
        }}>{meta.error}</span>
      }
    </Fragment>
  )
}

export default CustomSelect;