import React, {useState} from "react"
import moment from "moment";
import Detail from "./Detail"
import Grid from "../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table"
import {standardActions} from "../../../../components/Utils/Grid/StandardActions"
import {RenderCurrency} from "../../../../components/Utils/renderField/renderReadField"


const TabHistoryCashRegister = (
  {
    data,
    page,
    loader,
    onPageChange,
    onSortChange,
    detailsLoader,
    detailsPage,
    detailsList,
    detailsData,
    setInitialDetails,
    typeCashRegister,
  }) => {
  const [openModal, setOpenModal] = useState(false)
  const [currentItem, setCurrentItem] = useState({})


  function openModalAction() {
    setOpenModal(true)
  }

  function closeModal() {
    setOpenModal(false)
    setCurrentItem({})
    setInitialDetails()
  }

  function viewDetail(dataOfRow) {
    setCurrentItem(dataOfRow)
    detailsList && detailsList(dataOfRow.id, 1)
    openModalAction()
  }

  return (
    <div className="bg-card-cofaqui card mb-4" style={{borderTopLeftRadius: "0"}}>
      <Detail
        item={currentItem}
        page={detailsPage}
        data={detailsData}
        loader={detailsLoader}
        openModal={openModal}
        closeModal={closeModal}
        onPageChange={(page) => detailsList(currentItem.id, page)}
        typeCashRegister={typeCashRegister}
      />
      <div className="px-4 mt-4">
        <h4 className="font-weight-bold">
          Historial de caja
        </h4>
      </div>
      <div className=" ">
        <div className="px-4 pt-2">
          <Grid
            hover
            page={page}
            data={data}
            loading={loader}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            bodyContainerClass = "bc-professions-custom"
            headerContainerClass = "hc-professions-custom"
            tableContainerClass = "professions-container-table-sm"
            tableHeaderClass = "table-header-professions"
          >
            <TableHeaderColumn
              isKey={true}
              dataField='order'
              dataAlign="center"
              width="100px"
            >
              No.
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="date_opening"
              width="200px"
              dataFormat={(cell, row) => {
                let dateStr = ""
                if (cell) {
                  dateStr = moment(cell).format("DD/MM/YYYY  HH:mm")
                }
                return <span>{dateStr}</span>
              }}
            >
              Fecha apertura
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              dataField="date_closing"
              width="200px"
              dataFormat={(cell) => {
                let dateStr = ""
                if (cell) {
                  dateStr = moment(cell).format("DD/MM/YYYY  HH:mm")
                }
                return <span>{dateStr}</span>
              }}
            >
              Fecha cierre
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="250px"
              dataField="opening_balance"
              dataFormat={(cell) => {
                return (
                  <RenderCurrency
                    className="text-right"
                    value={cell ? cell : 0}
                  />
                )
              }}
            >
              Saldo apertura
            </TableHeaderColumn>
            <TableHeaderColumn
              width="250px"
              dataAlign="center"
              dataField="closing_balance"
              dataFormat={(cell) => {
                return (
                  <RenderCurrency
                    className="text-right"
                    value={cell ? cell : 0}
                  />
                )
              }}
            >
              Saldo cierre
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataAlign="center"
              width="100px"
              dataFormat={standardActions({details: viewDetail})}
            >
              Acciones
            </TableHeaderColumn>
          </Grid>
        </div>
      </div>
    </div>
  )
}


export default TabHistoryCashRegister
