import React, { useEffect, useState } from "react";
import {Field, Form} from "react-final-form";
import { TYPE_OF_INCOME_PETTY_CASH } from "../../../../utility/constants";
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel";
import { 
  renderDatePicker,
  renderField,
  renderNumber,
} from "../../../components/Utils/renderField/renderField";
import { selectField, simpleRenderField } from "../../../components/Utils/renderField/renderField";
import tree from "../../../components/Utils/TreeComponent";

const PettyCashEntryForm = (props) => {
  const {
    getCategories,
    categories,
    openCategories,
    me,
    createPettyCashIncome,
    ...rest
  } = props;

  const [cashier, setCashier] = useState([]);
  const [initialData, setInitialData] = useState({transaction_date: new Date()});

  const requiredDoc = [5, 10, 15, 20] // deposito, cheque, transferencia

  useEffect(() => {
    if (me && me.id) {
      setCashier([{label: me.full_name, value: me.id}])
      setInitialData({cashier: me.id, ...initialData})
    }
  }, [me])

  useEffect(() => {
    getCategories()
  }, [])
    
  return (
    <div className="ml-4">
      <Form
        onSubmit={createPettyCashIncome}
        initialValues={initialData}
        // validate={rest.validate}
        render={({
          handleSubmit,
          form: { change },
          values: { type_of_income }
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="card bg-card-cofaqui p-5 petty-cash-entry-form">
              <div className="row justify-content-between mb-2">
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="transaction_date">Fecha de ingreso</label>
                  <Field
                    withTime
                    name="transaction_date"
                    component={renderDatePicker}
                    typeInput="text"
                    minDate={rest.minDate}
                    maxDate={new Date()}
                  />
                </div>
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="cashier_id">Usuario</label>
                  <Field
                    name="cashier"
                    disabled={true}
                    component={selectField}
                    // validate={fieldRequired}
                    // options={cashier=[]}
                    options={cashier}
                  />
                </div>
              </div>
              <div className="row justify-content-between mb-2">
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="type_of_income">Forma de pago</label>
                  <Field
                    name="type_of_income"
                    options={TYPE_OF_INCOME_PETTY_CASH}
                    // validate={fieldRequired}
                    customAction={(item) => {
                      if (item === 1) {
                        change("no_document", null)
                      }
                    }}
                    component={selectField}
                  />
                </div>
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="no_document">
                        No. {type_of_income === 10 ? "Cheque" : type_of_income === 20 ? "Banco" : "Documento"}
                  </label>
                  <Field
                    name="no_document"
                    disabled={!requiredDoc.includes(type_of_income)}
                    component={simpleRenderField}
                  />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="expense_category">Rubro contable</label>
                  <Field
                    component={tree}
                    name="expense_category"
                    options={categories}
                    placeholder="Seleccione un categoría"
                    open={openCategories}
                  />
                </div>
                <div className="col- col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="total">
                    total
                  </label>
                  <Field
                    name="amount"
                    prefix="Q "
                    placeholder="0.00"
                    customDecimalScale={2}
                    acceptNegative={false}
                    component={renderNumber}
                  />
                </div>
              </div>
            </div>
            <BtnSaveAndCancel
              renderBtnSubmit
              renderLinkCancel
              redirectToLink="/my-cash-register"
              customContainer="container justify-content-start w-100 mb-3 mt-4 row"
            />
          </form>
        )}
      />
    </div>
  );
};

export default PettyCashEntryForm;