import React, { useEffect } from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";
import FinalFormFilter from "../../../../components/Utils/FinalFormFilter";

import "./list.css";

const MembersForReportList = ({
  getMemberForReport,
  page,
  loader,
  memberReportList,
  updateFiltersStampsIssued,
  filtersStampsIssued,
}) => {

  useEffect(() => {
    getMemberForReport(page);
  }, []);

  return (
    <div className="container-fluid">
      <PageTitle
        title={"Timbres emitidos"}
        colTitle="col-12"
      />
      <div className="card bg-card-cofaqui br-20px py-5 px-4">
        <FinalFormFilter
          withSearchFilter
          withStartDateFilter
          withEndDateFilter
          withSeparator
          action={updateFiltersStampsIssued}
          initialValues={filtersStampsIssued}
          searchTitle="Buscar por No. Colegiado"
          startDateTitle="Fecha de inicio de transacciones"
          endDateTitle="Fecha de fin de transacciones"
          searchPlaceholder="No. Colegiado"
        />
        <Grid
          hover
          data={memberReportList}
          page={page}
          loading={loader}
          onPageChange={getMemberForReport}
          bodyContainerClass = "bc-professions-custom"
          headerContainerClass = "hc-professions-custom"
          tableContainerClass = "professions-container-table-sm"
          tableHeaderClass = "table-header-professions"
        >
          <TableHeaderColumn
            width="100px"
            dataField="order"
            dataAlign="center"
            isKey
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="no_collegiate"
          >
            No. Colegiado
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="175px"
            dataField="full_name"
          >
            Nombre del agremiado
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="number_stamps_purchased"
          >
            Timbres Comprados
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="number_stamps_assigned"
          >
            Timbres Asignados
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="no_collegiate"
            dataFormat={standardActions({
              ver: "stamps-issued",
              verDetail: "detail",
            })}
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default MembersForReportList;