import React, {useEffect, useState} from "react";

const defaultAvatar = require("assets/img/avatar-placeholder.png");
const homeIcon = require("assets/img/icons/homeNav.svg");
const arrowIcon = require("assets/img/icons/arrowRight.svg");
const arrowBottomIcon = require("assets/img/icons/arrowbottom.svg");
const blackHomeIcon = require("assets/img/icons/blackhome.svg");
const blackArrow = require("assets/img/icons/blackarrow.svg");

import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";

import Navigation from "./Navigation";

import "./navbar.css";

const Navbar = ({logOut, navToggle, user, pathname, member, userStorage, url, itemMyDoc, params}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const [componentLocation, setComponentLocation] = useState("")

  useEffect(() => {
    setComponentLocation(getCurrentLocation())
    validateBreadcrumbsMobile()
  }, [pathname])

  useEffect(() => {
    if(itemMyDoc && itemMyDoc.name && pathname !== "/documents-generated/:memberId/:id"){
      let currLocation = <Navigation
        link1='/managements'
        text1="Mis gestiones"
        text2="Mis documentos"
        level2={"/managements/my-documents"}
        level3={`/managements/my-documents/${itemMyDoc.transaction_id}/documents-available`}
        text3={"Disponibles"}
        level4={url}
        text4={itemMyDoc.name}
      />
      setComponentLocation(currLocation)
    }
  }, [itemMyDoc])

  useEffect(() => {
    setComponentLocation(getCurrentLocation())
  }, [userStorage.isMember, member.currentStepMember, userStorage.formStep, userStorage.numberFormSteps])

  const getCurrentLocation = () => {
    const currentLocation = window.location.href;

    let location = "";

    if (currentLocation.includes("permissions-roles")) {

      location = <Navigation level1={true} text1="Roles y Permisos"/>;

    } else if (currentLocation.includes("new-role")) {

      location = <Navigation level1={true} text1="Roles y permisos" level2={true} text2="Nuevo Rol"/>;

    } else if (currentLocation.includes("role") && currentLocation.includes("edit")) {

      location = <Navigation level1={true} text1="Roles y Permisos" level2={true} text2={"Editar Rol"}/>;

    } else if (pathname === "/") {

      location = <Navigation level1={true} text1="Agremiados"/>;

    } else if (pathname === "/new-member") {
      const text2 = `Nuevo Agremiado (${member.currentStepMember}/${member.stepsMember})`
      location = <Navigation link1={"/"} text1="Agremiados" level2={pathname} text2={text2}/>;
    } else if (pathname === "/member/:id/edit") {
      const text2 = `Editar Agremiado (${member.currentStepMember}/${member.stepsMember})`
      location = <Navigation link1={"/"} text1="Agremiados" level2={url} text2={text2}/>;
    } else if (pathname === "/universities") {

      location = <Navigation level1={true} text1="Universidades"/>;

    } else if (pathname === "/new-university") {

      location = <Navigation link1={"/universities"} text1="Universidades" level2={"/new-university"} text2={"Nueva Universidad"}/>;

    } else if (currentLocation.includes("university") && currentLocation.includes("edit")) {

      location = <Navigation link1={"/universities"} text1="Universidades" level2={"/universities"} text2={"Editar Universidad"}/>;

    } else if (pathname === "/users") {
      location = <Navigation link1={pathname} text1="Usuarios"/>;
    } else if (pathname === "/user/:id/edit") {
      let text2 = "Editar Usuario"
      if (member && userStorage.isMember) {
        text2 = `Editar Usuario (${member.currentStepMember}/${member.stepsMember})`
      }
      location = <Navigation link1={"/users"} text1="Usuarios" level2={url} text2={text2}/>;
    } else if (pathname === "/new-user") {
      let text2 = "Nuevo Usuario"
      if (member && userStorage.isMember) {
        text2 = `Nuevo Usuario (${member.currentStepMember}/${member.stepsMember})`
      }
      location = <Navigation link1={"/users"} text1="Usuarios" level2={pathname} text2={text2}/>;
    } else if (pathname === "/professions") {

      location = <Navigation level1={true} text1="Profesiones"/>;

    } else if (pathname === "/new-profession") {

      location = <Navigation link1={"/professions"} text1="Profesiones" level2={"/new-profession"} text2={"Nueva Profesión"}/>;

    } else if (currentLocation.includes("profession") && currentLocation.includes("edit")) {

      location = <Navigation link1={"/professions"} text1="Profesiones" level2={"/professions"} text2={"Editar Profesión"}/>;

    } else if (pathname === "/commissions") {
      location = <Navigation link1={pathname} text1="Comisiones"/>;
    } else if (pathname === "/commission/:id/edit") {
      location = <Navigation link1={"/commissions"} text1="Comisiones" level2={url} text2={"Editar Comisión"}/>;
    } else if (pathname === "/new-commission") {
      location = <Navigation link1={"/commissions"} text1="Comisiones" level2={pathname} text2={"Nueva Comisión"}/>;
    } else if (pathname === "/expense-categories") {
      location = <Navigation link1={pathname} text1="Categorías Contables"/>;
    } else if (pathname === "/user-profile") {
      location = <Navigation link1={pathname} text1="Perfil"/>;
    } else if (pathname === "/binnacle") {
      location = <Navigation link1={pathname} text1="Bitácora"/>;
    } else if (pathname === "/products") {
      location = <Navigation link1={pathname} text1="Productos"/>;
    } else if (pathname === "/new-product") {
      location = <Navigation link1={"/products"} text1="Productos" level2={url} text2={"Nuevo Producto"}/>;
    } else if (pathname === "/product/:id/edit") {
      location = <Navigation link1={"/products"} text1="Productos" level2={url} text2={"Editar Producto"}/>;
    } else if (pathname === "/managements") {
      location = <Navigation link1={pathname} text1="Mis gestiones" />;
    } else if (pathname === "/managements/update-my-information") {
      location = <Navigation link1={"/managements"} text1="Mis gestiones" level2={url} text2={`Actualizar datos de colegiado (${member.currentStepMember}/${member.stepsMember})`} />;
    } else if (pathname === "/documents") {
      location = <Navigation link1={pathname} text1="Documentos" />;
    } else if(pathname === "/payments"){
      location = <Navigation link1={pathname} text1="Pagos" />
    } else if (pathname === "/cash-registers"){
      location = <Navigation link1={pathname} text1="Cajas" />
    } else if (pathname === "/create-cash-register") {
      location = <Navigation link1="/cash-registers" text1="Cajas" level2={url} text2="Nueva Caja" />
    } else if (pathname === "/cash-register/:id/edit"){
      location = <Navigation link1="/cash-registers" text1="Cajas" level2={url} text2="Editar Caja"/>
    } else if (pathname === "/my-cash-register") {
      location = <Navigation link1={pathname} text1="Caja"/>
    } else if (pathname === "/my-cash-register/cash-outflow") {
      location = <Navigation link1='/my-cash-register' text1="Caja" level2={pathname} text2="Egreso de caja"/>
    } else if (pathname === "/my-cash-register/create-transaction"){
      location = <Navigation link1={"/my-cash-register"} text1={"Caja"} level2={pathname} text2="Nueva Transacción"/>
    } else if (pathname === "/my-cash-register/closing"){
      location = <Navigation link1='/my-cash-register' text1="Caja" level2={pathname} text2="Cierre de caja"/>
    } else if (pathname === "/my-cash-register/petty-cash-entry") {
      location = <Navigation link1='/my-cash-register' text1="Caja" level2={pathname} text2="Ingreso de caja chica"/>
    } else if (pathname === "/managements/purchase-request") {
      location = <Navigation link1='/managements' text1="Mis gestiones" level2={pathname} text2="Solicitud de compra"/>
    } else if (pathname === "/managements/new-purchase-request") {
      location = <Navigation link1='/managements' text1="Mis gestiones" level2={"/managements/purchase-request"} text2="Solicitud de compra" level3={pathname} text3={"Nueva solicitud"}/>
    } else if (pathname === "/managements/purchase-request/:id/edit") {
      location = <Navigation link1='/managements' text1="Mis gestiones" level2={"/managements/purchase-request"} text2="Solicitud de compra" level3={url} text3={"Editar solicitud"}/>
    } else if (pathname === "/managements/my-documents") {
      location = <Navigation link1='/managements' text1="Mis gestiones" level2={pathname} text2="Mis documentos"/>
    } else if (pathname === "/managements/my-documents/:id/documents-available") {
      location = <Navigation link1='/managements' text1="Mis gestiones" level2={"/managements/my-documents"} text2="Mis documentos" level3={url} text3={"Disponibles"} />
    } else if (pathname === "/managements/my-documents/:id/documents-generated") {
      location = <Navigation link1='/managements' text1="Mis gestiones" level2={"/managements/my-documents"} text2="Mis documentos" level3={"/managements/my-documents/:id/documents-available"} text3={"Disponibles"} level4={url} text4={"Producto"}/>
    } else if (pathname === "/managements/direct-purchases") {
      location = <Navigation link1='/managements' text1="Mis gestiones" level2={url} text2="Compra directa" />
    }

    // stamps
    else if (pathname === "/stamps") {
      location = <Navigation link1={pathname} text1="Timbres"/>;
    } else if (pathname === "/new-stamp") {
      location = <Navigation link1={"/stamps"} text1="Timbres" level2={pathname} text2={`Nuevo Timbre (${userStorage.formStep}/${userStorage.numberFormSteps})`}/>;
    } else if (pathname.includes("required-documents")) {
      location = <Navigation link1={"/stamps"} text1="Timbres" level2={pathname} text2={"Documentos requeridos"}/>;
    } else if (pathname.includes("generated-documents")) {
      location = <Navigation link1={"/stamps"} text1="Timbres" level2={pathname} text2={"Generación de documentos"}/>;
    } else if (pathname.includes("stamp") && pathname.includes("edit")) {
      location = <Navigation link1={"/stamps"} text1="Timbres" level2={pathname} text2={"Editar Timbre"}/>;
    } else if (pathname.includes("floating_payment")) {
      location = <Navigation link1={"/floating_payment"} text1="Pagos flotantes"/>;
    }

    // Banks
    else if (pathname === "/banks") {
      location = <Navigation link1={pathname} text1="Bancos"/>;
    } else if (pathname === "/banks/new-bank") {
      location = <Navigation link1={"/banks"} text1="Bancos" level2={pathname} text2="Nuevo Banco"/>;
    } else if (pathname === "/banks/:id/edit") {
      location = <Navigation link1={"/banks"} text1="Bancos" level2={pathname} text2="Editar Banco"/>;
    } 

    // My Payments
    else if (pathname === "/managements/my-payments") {
      location = <Navigation link1={"/managements"} text1="Mis gestiones" level2={pathname} text2="Mis pagos"/>;
    }

    // Documents
    else if (pathname === "/member/:memberId/documents") {
      const { memberId } = params;
      location = <Navigation link1={"/"} text1="Agremiados" level2={`/member/${memberId}/documents`} text2="Mis documentos"/>;
    } 
    else if (pathname === "/available-documents/:memberId/:id") {
      const { memberId, id } = params;
      location = <Navigation link1={"/"} text1="Agremiados" level2={`/member/${memberId}/documents`} text2="Mis documentos" level3={`/available-documents/${memberId}/${id}`} text3="Disponibles"/>;
    }
    else if (pathname === "/documents-generated/:memberId/:id") {
      const { memberId, id } = params;
      location = <Navigation link1={"/"} text1="Agremiados" level2={`/member/${memberId}/documents`} text2="Mis documentos" level3={`/available-documents/${memberId}/${id}`} text3="Disponibles" level4={`/documents-generated/${memberId}/${id}`} text4="Generados"/>;
    }

    // Payments
    else if (pathname === "/member/:memberId/confirmed-payments") {
      const { memberId } = params;
      location = <Navigation link1={"/"} text1="Agremiados" level2={`/member/${memberId}/confirmed-payments`} text2="Pagos del agremiado"/>;
    }

    // Technical Directions
    else if (pathname === "/member/:memberId/technical_directions") {
      const { memberId } = params;
      location = <Navigation link1={"/"} text1="Agremiados" level2={`/member/${memberId}/technical_directions`} text2="Direcciones tecnicas"/>;
    }

    // Masters
    else if (pathname === "/member/:memberId/masters") {
      const { memberId } = params;
      location = <Navigation link1={"/"} text1="Agremiados" level2={`/member/${memberId}/masters`} text2="Maestrias"/>;
    }
    
    // Dashboard
    else if (pathname === "/dashboard") {
      location = <Navigation level1={true} text1="Dashboard"/>;
    }

    // Reports
    else if (pathname === "/contributions-report") {
      location = <Navigation level1={true} text1="Reportes" level2={pathname} text2="Reporte de aportaciones"/>;
    }
    else if (pathname === "/stamps-issued-report") {
      location = <Navigation level1={true} text1="Timbres emitidos"/>;
    } 
    else if (pathname === "/stamps-issued/:noCollegiate/detail") {
      const { noCollegiate } = params;
      location = <Navigation link1={"/stamps-issued-report"} text1="Timbres emitidos" level2={`/stamps-issued/${noCollegiate}/detail`} text2="Detalle"/>;
    }
    else if (pathname === "/stamps-sold-report") {
      location = <Navigation level1={true} text1="Timbres vendidos"/>;
    } 
    else if (pathname === "/stamps-sold/:noCollegiate/detail") {
      const { noCollegiate } = params;
      location = <Navigation link1={"/stamps-sold-report"} text1="Timbres vendidos" level2={`/stamps-sold/${noCollegiate}/detail`} text2="Detalle"/>;
    }
    else if (pathname === "/document-consultation-report") {
      location = <Navigation level1={true} text1="Reporte de consulta de documentos"/>;
    }
    else if (pathname === "/stamps-used-report") {
      location = <Navigation level1={true} text1="Timbres usados"/>;
    }
    else if (pathname === "/stamps-used/:noCollegiate/detail") {
      const { noCollegiate } = params;
      location = <Navigation link1={"/stamps-used-report"} text1="Timbres usados" level2={`/stamps-used/${noCollegiate}/detail`} text2="Detalle"/>;
    }
    // Management
    else if (pathname === "/management") {
      location = <Navigation link1={pathname} text1="Gestiones"/>;
    } else if (pathname === "/new-management") {
      location = <Navigation link1={"/management"} text1="Gestiones" level2={pathname} text2="Nueva Gestión"/>;
    } else if (pathname === "/management/:id/edit") {
      location = <Navigation link1={"/management"} text1="Gestiones" level2={pathname} text2="Editar Gestión"/>;
    } 

    // Inventory documents
    else if (pathname === "/member/:memberId/member-inventory-detail/:id/documents") {
      const { memberId, id } = params;
      location = <Navigation link1={"/"} text1="Agremiados" level2={`/member/${memberId}/documents`} text2="Documentos" level3={`/member/${memberId}/member-inventory-detail/${id}/documents`} text3="Inventario de documentos"/>;
    }
    else if (pathname === "/managements/my-documents/:id/member-inventory-detail") {
      const { id } = params;
      location = <Navigation link1={"/managements"} text1="Mis gestiones" level2={"/managements/my-documents"} text2="Mis documentos" level3={`/managements/my-documents/${id}/member-inventory-detail`} text3="Inventario de documentos"/>;
    }

    // Direct purchase
    else if (pathname === "/managements/direct-purchases/transaction/:authorization") {
      const { authorization } = params;
      location = <Navigation link1={"/managements"} text1="Mis gestiones" level2={`/managements/direct-purchases/transaction/${authorization}`} text2={"Detalle de compra"}/>;
    }
    return location;
  }

  const validateBreadcrumbsMobile = () => {
    if (window.innerWidth <= 850) {
      window.onscroll = function () {
        const positionScrollY = window.scrollY;
        if (positionScrollY >= 45) {
          try {
            const bread = document.getElementById("breadcrumbs-mobile")
            bread.style.display = "none"
          } catch (e) {
            console.log("No encontro el elemeto", e)
          }
        } else {
          try {
            const bread = document.getElementById("breadcrumbs-mobile")
            bread.style.display = "flex"
          } catch (e) {
            console.log("No encontro el elemeto", e)
          }
        }
      };
    }
  }

  return (
    <nav className="w-100 d-flex flex-row justify-content-between p-0 navbar mx-0 navbar-icon">
      {/*----------------------------------------------*/}
      {/*------------     Navigation Bracket    -------*/}
      {/*----------------------------------------------*/}
      <div className="mx-0 d-lg-flex d-md-none d-sm-none-2">
        <i>
          <img src={homeIcon} alt="home icon" className="home-icon" style={{maxHeight: "25px", marginTop: "-0.5%"}}/>
          &nbsp;&nbsp;&nbsp;
          <img src={arrowIcon} alt="arrow icon" className="arrow-icon" style={{maxHeight: "25px", marginTop: "-0.5%"}}/>
        </i>
        <span className="div-breadcrumbs">
          &nbsp;&nbsp;{componentLocation}
        </span>
      </div>
      {/*----------------------------------------------*/}
      {/*------------     Profile Dropdown      -------*/}
      {/*----------------------------------------------*/}
      <ul className="navbar-nav mx-0">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="transparent" className="nav-item-dropdown text-white float-right py-1 px-lg-3 px-md-1 px-sm-0">
            <img
              className="user-avatar rounded-circle mr-3"
              src={(user && user.avatar) ? user.avatar : defaultAvatar}
              alt="User Avatar"/>
            <span className="d-md-inline-block name-user">{user.first_name} &nbsp;&nbsp;&nbsp;</span>
            <div className="d-flex flex-column">
              <img src={arrowBottomIcon} alt=""/>
              <img src={arrowBottomIcon} alt="" className="second-arrow"/>
            </div>
          </DropdownToggle>
          <DropdownMenu className={"dropdown-menu-cofaqui"}>
            <DropdownItem href={"/#/user-profile"} color="white" className={"dropdown-item-cofaqui text-fixed w-100"}>
              Mi perfil {(user && user.permissions) && <span className="text-besh">({user.permissions.name})</span>}
            </DropdownItem>
            <DropdownItem onClick={logOut} href={"/"} color="white" className={"dropdown-item-cofaqui"}>
              Cerrar Sesión
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </ul>
      {/*----------------------------------------------*/}
      {/*------------     Icon Menu Mobile      -------*/}
      {/*----------------------------------------------*/}
      <nav className="nav d-sm-inline d-md-inline d-lg-none">
        <div
          role="button"
          tabIndex={0}
          onKeyDown={navToggle}
          className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
          onClick={navToggle}
        >
          <i className="material-icons"></i>
        </div>
      </nav>
      <div id="breadcrumbs-mobile" className="d-sm-flex d-md-inline d-lg-none col-12 mx-0 mt-3 px-0">
        <span className="">
          <img src={blackHomeIcon} alt="home icon" className="home-icon mt-n1" style={{maxHeight: "25px"}}/>
          &nbsp;
          <img
            src={blackArrow}
            alt="arrow icon"
            className="arrow-icon"
            style={{height: "10px", width: "5px"}}
          />
          &nbsp;
        </span>
        <span className="div-breadcrumbs-mobile">
          {componentLocation}
        </span>
      </div>
    </nav>
  );
}

export default Navbar;
