import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/administration/professions";
import ProfessionList from "./List";

const ms2p = (state) => {
  return {
    ...state.professions,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(ProfessionList);
