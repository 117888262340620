import React from "react"
import {Form, Field, FormSpy} from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Grid from "../../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table";
import {DOCUMENT_TYPE} from "../../../../../../utility/constants";
import UploadButton from "../../../../Administration/Stamps/CreateUpdate/CustomCard/UploadButton";
// import UploadFileBtn from "../Modal/UploadFileBtn";
import LoadMask from "../../../../../components/Utils/LoadMask/LoadMask";

const DocumentsRequired = (
  {
    data,
    page,
    remote,
    loader,
    isView,
    wasApproved,
    onPageChange,
    onSortChange,
    addFileToStore,
    isUpdate,
    setRequiredDocuments,
    path
  }) => {

  return (
    <Form
      onSubmit={() => {}}
      mutators={{
        ...arrayMutators
      }}
      render={({handleSubmit, form, submitting, values}) => (
        <form
          onSubmit={handleSubmit}
        >
          <FormSpy
            subscription={{values: true}}
            onChange={(props) => {
              const {values} = props;
              setTimeout(() => {
                setRequiredDocuments(values.required_documents)
              }, 0)
            }}
          />
          <div className={"col-lg-9 col-md-10 col-sm-12 card bg-card-cofaqui pt-4 pb-5"}
            style={{borderRadius: "0 20px 20px 20px"}}>
            <LoadMask blur dark loading={loader}>
              <FieldArray name="required_documents">
                {({fields}) => (
                  <Grid
                    hover
                    loading={false}
                    remote={remote}
                    page={page}
                    data={data}
                    pagination={false}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                    bodyContainerClass = "bc-professions-custom"
                    headerContainerClass = "hc-professions-custom"
                    tableContainerClass = "professions-container-table-sm"
                    tableHeaderClass = "table-header-professions"
                  >
                    <TableHeaderColumn
                      isKey
                      width='100px'
                      dataField='order'
                      dataAlign="center"
                    >
            No.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width='150px'
                      dataField='name'
                      dataAlign="center"
                    >
            Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width='125px'
                      dataField='type'
                      dataAlign="center"
                      dataFormat={(cell, row) => {
                        return <span>{DOCUMENT_TYPE.find(ele => ele.value === Number(cell)).label}</span>
                      }}
                    >
            Formato
                    </TableHeaderColumn>
                    {
                      path != "/purchase-request/:id/details" && (
                        <TableHeaderColumn
                          width='200px'
                          dataField={"archive"}
                          dataAlign="center"
                          dataFormat={(cell, row) => {
                            return (
                              <div
                                className='m-0 d-flex justify-content-center align-content-center'
                              >
                                {/* Upload new file */}
                                <div 
                                  // className={`${(isUpdate && cell.status != 5) ? "d-none" : ""}`}
                                >
                                  <Field
                                    name={`required_documents[${row.order - 1}]`}
                                    component={UploadButton}
                                    withId={true}
                                    row={row}
                                    changeIcon={true}
                                    customClass="change-icon-uploadbtn"
                                    isUpdate={isUpdate}
                                    subId={row.has_upload_archive ? row.has_upload_archive : null}
                                  />
                                </div>
                              </div>
                            )
                          }}
                        >
                Acciones
                        </TableHeaderColumn>
                      )
                    }
                    {/* current archive */}
                    {
                      isUpdate && (
                        <TableHeaderColumn
                          width='200px'
                          dataField={"current_archive"}
                          dataAlign="center"
                          dataFormat={(cell, row) => {
                            return (
                              <div
                                className='m-0 d-flex justify-content-center align-content-center'
                              >
                                {/* Download current file */}
                                <a 
                                  className={"text-green"}
                                  href={cell.url}
                                  download
                                  onClick={(e) => {
                                    e.preventDefault(); // prevenir redirección
                                    const url = e.currentTarget.getAttribute("href");
                                    const filename = url.substring(url.lastIndexOf("/") + 1);
                                    fetch(url)
                                      .then(res => res.blob())
                                      .then(blob => {
                                        const url = URL.createObjectURL(blob);
                                        const a = document.createElement("a");
                                        a.href = url;
                                        a.download = filename;
                                        document.body.appendChild(a);
                                        a.click();
                                        a.remove();
                                      });
                                  }}
                                >
                                  <i
                                    style={{
                                      transform: isUpdate ? "rotate(0deg)" : "rotate(180deg)",
                                    }}
                                  >
                                    {
                                      isUpdate && (
                                        cell.name
                                      )
                                    }
                                  </i>
                                </a>
                              </div>
                            )
                          }}
                        >
                    Archivo Actual
                        </TableHeaderColumn>
                      )
                    }
                  </Grid>
                )}
              </FieldArray>
            </LoadMask>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </div>
        </form>
      )}
    />
  )
}


export default DocumentsRequired
