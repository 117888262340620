import React, { useState, useMemo, useEffect, Fragment } from "react";
import JoditEditor from "jodit-react";

import "./styles.css";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  height: "281px",
  maxWidth: "100%",
  // enableDragAndDropFileToEditor: true,
  buttons: [
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    //   "font",
    "fontsize",
    "brush",
    //   "paragraph",
    //   "|",
    //   "image",
    //   "table",
    //   "link",
    //   "|",
    "left",
    "center",
    "right",
    "justify",
    //   "|",
    //   "undo",
    //   "redo",
    //   "|",
    //   "hr",
    //   "eraser",
    //   "fullsize",
  ],
  // uploader: { insertImageAsBase64URI: true },
  removeButtons: [
    "source",
    "|",
    //   "bold",
    //   "italic",
    //   "underline",
    "|",
    //   "ul",
    //   "ol",
    "|",
    "font",
    //   "fontsize",
    //   "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    // "left",
    // "center",
    // "right",
    // "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
    "copyformat",
    "source",
  ],
  enter: "div",
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  autofocus: false,
  addNewLine: false,
  saveSelectionOnBlur:false,
  askBeforePasteHTML: false,
  cursorAfterAutofocus: "end",
  askBeforePasteFromWord: false,
  defaultActionOnPaste: "insert_only_text",
  placeholder: "Ingresa texto acá",
  defaultActionOnPasteFromWord: "insert_only_text",
  style: {
    background: "#FAFDF3",
    border: "2px solid #B0A196",
    borderRadius: "0px 0px 20px 20px",
    //   maxHeight: "281px",
    //   color: "rgba(255,255,255,0.5)",
  },
};

const Editor = ({
  input,
  setDocument,
  meta: { touched, error },
  setCustomContent,
  ...props
}) => {
  const invalid = touched && error;

  const [content, setContent] = useState("")
  const newConfig = useMemo(() => config, [props]);

  const handleChange = (content) => {
    // console.log(content);
    if (content.includes("{{contenido_personalizado}}")) setCustomContent(true);
    else setCustomContent(false);

    setContent(content);
    setDocument(content);
    input.onChange(content);
  }

  useEffect(() => {
    if(input.value && input.value !== content){
      setContent(input.value);
    }
  }, [input.value])

  return (
    <Fragment>
      <JoditEditor
        value={content}
        config={newConfig}
        tabIndex={-1} // tabIndex of textarea
        // onBlur={handleChange}
        onChange={handleChange}
      />
      {invalid && <div className="invalid-feedback">{error}</div>}
    </Fragment>
  );
}

export default Editor;
