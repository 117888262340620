import React, { useState, useEffect } from "react";

import { Route } from "react-router-dom";

import { VerifyLogin } from "./common/components/layout";

// eslint-disable-next-line
import { api } from "api";

// const queryString = require("query-string");

const validateToken = (data) => {
  return api
    .post("user/validate_token", data)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return false;
    });
};

const TokenRoute = ({ component: Component, ...rest }) => {

  const [token, setToken] = useState(null);
  const [validToken, setValidToken] = useState(null);

  useEffect(() => {
    if (token === null) {
      const parsed = rest.computedMatch.params;
      if (parsed.token) {
        setToken(parsed.token);
        
        const data = { token: parsed.token };
        
        validateToken(data).then((response) => {
          if (response === "valid") {
            setValidToken(true);
          } else {
            setValidToken(false);
          }
        })
          .catch(() => {
            setValidToken(false);
          });
      }
    }
  }, [token]);

  if(validToken === null || token === null) {
    return (
      <VerifyLogin />
    )
  }

  else if(validToken){
    return (
      <Route 
        {...rest} 
        render={props => (
          <Component {...props} />
        )} 
      />
    );
  }

  else{
    return (
      <React.Fragment>
        <div className="container-fluid invalid-token-container">
          <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
            <h1>Oops!</h1>
            <h2>
              Token invalido o expirado
            </h2>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

export default TokenRoute;