import {api} from "api"
import {push} from "react-router-redux"
import {handleActions} from "redux-actions"
import {NotificationManager} from "react-notifications"
import {createReducer} from "../baseReducer/baseReducer"
import {addIndexToArray} from "../../../utility/constants";
import {printAction} from "../myManagements/myDocuments";

// ------------------------------------
// Constants
// ------------------------------------
const DATA_HISTORY = "DATA_CASH_REGISTER_HISTORY"
const DATA_HISTORY_ORDERING = "DATA_HISTORY_ORDER"
const LOADER_HISTORY = "LOADER_CASH_REGISTER_HISTORY"
const DATA_CASH_REGISTER_2 = "DATA_CASH_REGISTER_DOS"
const CURRENT_CASH_REGISTER = "CURRENT_CASH_REGISTER_ID"
const PAGE_CASH_REGISTER_HISTORY = "PAGE_CASH_REGISTER_HISTORY"
const CASH_REGISTER_CLOSING_DATA = "CASH_REGISTER_CLOSING_DATA"
const CASH_REGISTER_CLOSING_DETAIL_PAGE = "CASH_REGISTER_CLOSING_DETAIL_PAGE"
const DATE_OF_OPENING_CASH_REGISTER = "DATE_OF_OPENING_CASH_REGISTER"
const TYPE_CASH_REGISTER = "TYPE_CASH_REGISTER"
const PAGINATION_DATA = "PAGINATION_DATA"
const CHECK_TRANSACTION_DATA = "CHECK_TRANSACTION"
const PAYMENT_LOADER = "PAYMENT_LOADER"
const PAYMENT_LINK = "PAYMENT_LINK"

const baseReducer = createReducer(
  "cashRegister",
  "transaction",
  "TransactionForm",
  "/my-cash-register",
);
const {setLoader, setData, setPage} = baseReducer.actions;
// ------------------------------------
// Pure action
// ------------------------------------
const initialDataValue = {
  results: [],
  count: 0
}

const setCashRegister = cashRegister => ({
  type: CURRENT_CASH_REGISTER,
  cashRegister,
})

const setDataHistory = dataHistory => ({
  type: DATA_HISTORY,
  dataHistory,
})

const setPageHistory = pageHistory => ({
  type: PAGE_CASH_REGISTER_HISTORY,
  pageHistory,
})

const setLoaderHistory = loaderHistory => ({
  type: LOADER_HISTORY,
  loaderHistory,
})

const setOrderingHistory = orderingHistory => ({
  type: DATA_HISTORY_ORDERING,
  orderingHistory,
})

const setDataCashReg = dataCashRegister => ({
  type: DATA_CASH_REGISTER_2,
  dataCashRegister,
})

const setDetailPage = detailsPage => ({
  type: CASH_REGISTER_CLOSING_DETAIL_PAGE,
  detailsPage,
})

const setDetailsData = detailsData => ({
  type: CASH_REGISTER_CLOSING_DATA,
  detailsData,
})

const setDateOpeningCR = dateOpeningCR => ({
  type: DATE_OF_OPENING_CASH_REGISTER,
  dateOpeningCR,
})

const setTypeCashRegister = typeCashRegister => ({
  type: TYPE_CASH_REGISTER,
  typeCashRegister,
})

const setPaginationData = paginationData => ({
  type: PAGINATION_DATA,
  paginationData,
})

const setCheckTransaction = checkTransactionData => ({
  type: CHECK_TRANSACTION_DATA,
  checkTransactionData,
})

const setPaymentLoader = paymentLoader => ({
  type: PAYMENT_LOADER,
  paymentLoader,
})

const setPaymentLink = paymentLink => ({
  type: PAYMENT_LINK,
  paymentLink,
})

// ------------------------------------
// Actions
// ------------------------------------
const annulation = (id, reason = "") => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const page = getStore()["cashRegister"].page
  api.put(`transaction/${id}/annulation`, {cancellation_description: reason}).then(() => {
    NotificationManager.success("Transaccion anulada", "Exito", 3000)
    dispatch(transactionList(page))
  }).catch((e) => {
    if (e && e.details) {
      NotificationManager.error(e.details, "Error", 300)
    } else {
      NotificationManager.error("Error al anular", "Error", 3000)
    }
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const cashRegisterList = (page) => (dispatch) => {
  dispatch(setLoader(true))
  api.get("/cash_register/assigned", {page}).then((response) => {
    dispatch(setDataCashReg(response))
    dispatch(setPaginationData({
      count: response.count,
      next: response.next,
      previous: response.previous,
      currentPage: page,
    }))
  }).catch((err) => {
    if (err && err.body && err.body.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("Error al listar las cajas", "Error", 3000)
    }
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const transactionList = (page) => (dispatch, getStore) => {
  const resource = getStore()["cashRegister"]
  const params = {page}
  params.ordering = resource.ordering
  params.search = resource.search
  params.cash_register = resource.cashRegister
  if (params.cash_register) {
    dispatch(setLoader(true))
    api.get("transaction", params).then((resp) => {
      resp.results = addIndexToArray(resp.results)
      dispatch(setData(resp))
      dispatch(setPage(page))
    }).catch((err) => {
      // console.log("err", err)
      if (err && err.body && err.body.details) {
        if (err.body.details == "Debe abrir una caja") {
          dispatch(setCashRegister(undefined))
          dispatch(cashRegisterList(1))
        }
        NotificationManager.error(err.body.details, "Error", 4000)
      } else {
        NotificationManager.error("Error al listar las transacciones de la caja actual", "Error", 4000)
      }
    }).finally(() => {
      dispatch(setLoader(false))
    })
  } else {
    dispatch(validateCurrentCash())
  }
}

const closingCashRegister = (data, closeModal) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const id = getStore()["cashRegister"].cashRegister
  api.put(`/cash_register/${id}/closing`, {...data}).then((resp) => {
    dispatch(setCashRegister(undefined))
    dispatch(setDateOpeningCR(null))
    dispatch(setData(initialDataValue))
    dispatch(setDataHistory(initialDataValue))
    closeModal && closeModal()
    NotificationManager.success("Cierre de caja exitoso", "Exito", 4000)
    dispatch(push("/my-cash-register"))
  }).catch((err) => {
    let msg = "Error al realizar el cierre de caja"
    // console.log("error", err)
    if (err && err.details) {
      msg = err.details
    }
    NotificationManager.error(msg, "Error", 9000)
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const openingCashRegister = (id, data, closeModal) => (dispatch) => {
  dispatch(setLoader(true))
  api.put(`/cash_register/${id}/opening`, data).then((response) => {
    dispatch(validateCurrentCash())
    closeModal && closeModal()
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    } else {
      NotificationManager.error("Error al aperturar la caja", "Error", 4000)
    }
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const validateCurrentCash = () => (dispatch) => {
  dispatch(setLoader(true))
  dispatch(setData(initialDataValue))
  dispatch(setDataHistory(initialDataValue))
  api.get("cash_register/check_current_cash_register", {}).then((data) => {
    if (data && data.cash_register_id) {
      dispatch(setTypeCashRegister(data.type_cash_register))
      dispatch(setCashRegister(data.cash_register_id))
      dispatch(setDateOpeningCR(new Date(data.date_opening)))
      dispatch(transactionList(1))
      dispatch(cashRegisterHistoryList(1))
    } else {
      dispatch(setCashRegister(undefined))
      dispatch(cashRegisterList(1))
      dispatch(push("/my-cash-register"))
    }
  }).catch((err) => {
    let message = ""
    if (err && err.body && err.body.details) {
      message = err.body.details
    }
    NotificationManager.error(message, "Error", 4000)
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const cashRegisterHistoryList = (pageHistory) => (dispatch, getStore) => {
  const resource = getStore()["cashRegister"]
  const params = {page: pageHistory}
  params.search = ""
  dispatch(setLoaderHistory(true))
  api.get(`cash_register/${resource.cashRegister}/history`, params).then((resp) => {
    resp.results = addIndexToArray(resp.results)
    dispatch(setDataHistory(resp))
    dispatch(setPageHistory(pageHistory))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("", "Error", 3000)
    }
  }).finally(() => {
    dispatch(setLoaderHistory(false))
  })
}

const ListCRCD = (idDetails, detailsPage) => (dispatch) => {
  // TransactionList of Cash Register Closed details
  const params = {page: detailsPage}
  params.search = ""
  dispatch(setLoader(true))
  api.get(`cash_register/${idDetails}/details`, params).then((resp) => {
    resp.results = addIndexToArray(resp.results)
    dispatch(setDetailsData(resp))
    dispatch(setDetailPage(detailsPage))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("", "Error", 3000)
    }
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const createCashOutflow = (data, closeModal) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  api.post("transaction/create_cash_outflow", data).then((resp) => {
    dispatch(transactionList(1))
    dispatch(cashRegisterHistoryList(1))
    closeModal && closeModal()
    dispatch(push("/my-cash-register"))
    NotificationManager.success("Egreso de caja registrado exitosamente", "Exito", 4000)
  }).catch((err) => {
    let msg = "Error al registrar el egreso de caja"
    if (err && err.details) {
      msg = err.details
    }
    NotificationManager.error(msg, "Error", 9000)
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const createOutgoingCashReceived = (data, closeModal) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  api.post("transaction/create_outgoing_cash_received", data).then((resp) => {
    dispatch(transactionList(1))
    dispatch(cashRegisterHistoryList(1))
    closeModal && closeModal()
    dispatch(push("/my-cash-register"))
    NotificationManager.success("Egreso de caja receptora registrado exitosamente", "Exito", 4000)
  }).catch((err) => {
    let msg = "Error al registrar el egreso de caja receptora"
    if (err && err.details) {
      msg = err.details
    }
    NotificationManager.error(msg, "Error", 9000)
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const createPettyCashIncome = (data, closeModal) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  api.post("transaction/create_petty_cash_income", data).then((resp) => {
    dispatch(transactionList(1))
    dispatch(cashRegisterHistoryList(1))
    closeModal && closeModal()
    dispatch(push("/my-cash-register"))
    NotificationManager.success("Ingreso de caja chica registrado exitosamente", "Exito", 4000)
  }).catch((err) => {
    let msg = "Error al registrar el ingreso de caja chica"
    if (err && err.details) {
      msg = err.details
    }
    NotificationManager.error(msg, "Error", 9000)
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

const createDirectPurchase = data => (dispatch) => {
  dispatch(setLoader(true));
  api.post("transaction", data).then((res) => {
    NotificationManager.success("Compra realizada", "Éxito", 3000);
    dispatch(push("/managements"));
    dispatch(printAction(res.receipt))
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else if (error && error.body && error.body.details) {
      NotificationManager.error(error.body.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la creación", "ERROR", 0);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};
const setInitialDetails = ()=> (dispatch)=> {
  dispatch(setDetailPage(1))
  dispatch(setDetailsData(initialDataValue))
}

const changeHistoryOrdering = (ordering) => (dispatch, getStore) => {
  const {orderingHistory, pageHistory} = getStore()["cashRegister"];
  if (ordering === orderingHistory) {
    dispatch(setOrderingHistory(`-${ordering}`));
  } else {
    dispatch(setOrderingHistory(ordering));
  }
  dispatch(cashRegisterHistoryList(pageHistory));
}

const createTrans = data => (dispatch) => {
  dispatch(setLoader(true));
  api.post("transaction", data).then((res) => {
    NotificationManager.success("Registro creado", "Éxito", 3000);
    dispatch(push("/my-cash-register"));
    dispatch(printAction(res.receipt))
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la creación", "ERROR", 0);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const checkTransaction = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`transaction/${id}/check_transaction`).then((res) => {
    NotificationManager.success("Transacción verificada", "Éxito", 3000);
    dispatch(setCheckTransaction(res));
    // dispatch(push("/my-cash-register"));
  }).catch((error) => {
    // console.log(error)
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la verificación", "ERROR", 0);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const createPaymentLink = (data, action) => (dispatch) => {
  console.log(data)
  dispatch(setPaymentLoader(true));
  api.post("transaction/create_payment_link", {...data}).then((res) => {
    NotificationManager.success("Link de pago creado", "Éxito", 3000);
    dispatch(setPaymentLink(res));
    dispatch(action)
    // dispatch(push("/my-cash-register"));
  }).catch((error) => {
    // console.log(error)
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la creación", "ERROR", 0);
    }
    dispatch(setPaymentLoader(false));
  }).finally(() => {
    // dispatch(setPaymentLoader(false));
  });
};

export const actions = {
  ...baseReducer.actions,
  cashRegisterHistoryList,
  changeHistoryOrdering,
  createDirectPurchase,
  closingCashRegister,
  openingCashRegister,
  validateCurrentCash,
  setInitialDetails,
  cashRegisterList,
  transactionList,
  annulation,
  ListCRCD,
  createTrans,
  createCashOutflow,
  createPettyCashIncome,
  createOutgoingCashReceived,
  checkTransaction,
  createPaymentLink,
}

// ----------------------------------
// Reducer
// ----------------------------------
const reducers = {
  ...baseReducer.reducers,
  [CURRENT_CASH_REGISTER]: (state, {cashRegister}) => {
    return {
      ...state,
      cashRegister
    }
  },
  [DATA_HISTORY]: (state, {dataHistory}) => {
    return {
      ...state,
      dataHistory,
    }
  },
  [DATA_HISTORY_ORDERING]: (state, {orderingHistory}) => {
    return {
      ...state,
      orderingHistory,
    }
  },
  [LOADER_HISTORY]: (state, {loaderHistory}) => {
    return {
      ...state,
      loaderHistory,
    }
  },
  [DATA_CASH_REGISTER_2]: (state, {dataCashRegister}) => {
    return {
      ...state,
      dataCashRegister
    }
  },
  [CASH_REGISTER_CLOSING_DATA]: (state, {detailsData}) => {
    return {
      ...state,
      detailsData,
    }
  },
  [CASH_REGISTER_CLOSING_DETAIL_PAGE]: (state, {detailsPage}) => {
    return {
      ...state,
      detailsPage,
    }
  },
  [DATE_OF_OPENING_CASH_REGISTER]: (state, {dateOpeningCR}) => {
    return {
      ...state,
      dateOpeningCR
    }
  },
  [TYPE_CASH_REGISTER]: (state, {typeCashRegister}) => {
    return {
      ...state,
      typeCashRegister
    }
  },
  [PAGINATION_DATA]: (state, {paginationData}) => {
    return {
      ...state,
      paginationData,
    }
  },
  [CHECK_TRANSACTION_DATA]: (state, {checkTransactionData}) => {
    return {
      ...state,
      checkTransactionData,
    }
  },
  [PAYMENT_LINK]: (state, {paymentLink}) => {
    return {
      ...state,
      paymentLink,
    }
  },
  [PAYMENT_LOADER]: (state, {paymentLoader}) => {
    return {
      ...state,
      paymentLoader,
    }
  }
}

const initialState = {
  ...baseReducer.initialState,
  cashRegister: undefined,
  dateOpeningCR: null,
  detailsPage: 1,
  pageHistory: 1,
  loaderHistory: false,
  orderingHistory: "",
  dataCashRegister: {
    results: [],
    count: 0,
  },
  dataHistory: {
    results: [],
    count: 0,
  },
  detailsData: {
    results: [],
    count: 0,
  },
  typeCashRegister: "",
  paginationData: {
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
  },
  checkTransactionData: {
    details: {
      results: [],
      count: 0,
    }
  },
  paymentLink: "",
  paymentLoader: false,
}

export default handleActions(reducers, initialState);
