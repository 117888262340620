import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
// eslint-disable-next-line
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import {addIndexToArray} from "../../../../utility/constants";

const MEMBER_REPORT_LIST = "MEMBER_REPORT_LIST";
const LOADER = "MEMBER_REPORT_LOADER";
const PAGE = "MEMBER_REPORT_PAGE"; 
const ORDERING = "MEMBER_REPORT_ORDERING";
const SEARCH = "MEMBER_REPORT_SEARCH";
const STAMPS_ISSUED_LIST = "STAMPS_ISSUED_LIST";
const STAMPS_ISSUED_PAGE = "STAMPS_ISSUED_PAGE";
const DETAILS_WITH_STAMPS = "DETAILS_WITH_STAMPS";
const DETAILS_WITH_STAMPS_PAGE = "DETAILS_WITH_STAMPS_PAGE";
const FILTERS_STAMPS_ISSUED = "FILTERS_STAMPS_ISSUED";
const FILTERS_STAMPS_ISSUED_DETAILS = "FILTERS_STAMPS_ISSUED_DETAILS";

export const constants = {
  MEMBER_REPORT_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
  STAMPS_ISSUED_LIST,
  STAMPS_ISSUED_PAGE,
  DETAILS_WITH_STAMPS,
  DETAILS_WITH_STAMPS_PAGE,
  FILTERS_STAMPS_ISSUED,
  FILTERS_STAMPS_ISSUED_DETAILS,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setMemberReportList = data => ({
  type: MEMBER_REPORT_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

// eslint-disable-next-line
const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

// eslint-disable-next-line
const setSearch = search => ({
  type: SEARCH,
  search,
});

const setStampsIssuedList = data => ({
  type: STAMPS_ISSUED_LIST,
  data,
});

const setStampsIssuedPage = page => ({
  type: STAMPS_ISSUED_PAGE,
  page,
});

const setDetailsWithStamps = data => ({
  type: DETAILS_WITH_STAMPS,
  data,
});

const setDetailsWithStampsPage = page => ({
  type: DETAILS_WITH_STAMPS_PAGE,
  page,
});

const setFiltersStampsIssued = data => ({
  type: FILTERS_STAMPS_ISSUED,
  data,
});

const setFiltersStampsIssuedDetails = data => ({
  type: FILTERS_STAMPS_ISSUED_DETAILS,
  data,
});

// ------------------------------------
// Actions
// ------------------------------------

const getMemberForReport = (page = 1) => (
  dispatch,
  getStore,
) => {
  const filter = getStore().stampsIssued.filtersStampsIssued;
  const params = { page, ...filter };
  
  dispatch(setLoader(true));

  api.get("/stamps_issued", params)
    .then((response) => {
      if (response.results) response.results = addIndexToArray(response.results, page);
      dispatch(setMemberReportList(response));
      dispatch(setPage(page));
    })
    .catch(() => {
      NotificationManager.error(
        "Error al obtener a los agremiados",
        "ERROR",
        0,
      );
    })
    .finally(() => {
      dispatch(setLoader(false));
    }
    );
};

const getStampsIssued = (page = 1, noCollegiate) => (
  dispatch,
  getStore,
) => {
  const filter = getStore().stampsIssued.filtersStampsIssuedDetails;
  const params = { page, ...filter };
  dispatch(setLoader(true));
  api.get(`/stamps_issued/${noCollegiate}/get_stamps_issued`, params)
    .then((response) => {
      if (response.results) response.results = addIndexToArray(response.results, page);
      dispatch(setStampsIssuedList(response));
      dispatch(setStampsIssuedPage(page));
    })
    .catch(() => {
      NotificationManager.error(
        "Error al obtener a los timbres emitidos",
        "ERROR",
        0,
      );
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const getDeatailsWithStamps = (page = 1, transactionId) => (
  dispatch,
  getStore,
) => {
  const ordering = getStore().stampsIssued.ordering;
  const search = getStore().stampsIssued.search;
  const params = { page, ordering, search};
  dispatch(setLoader(true));
  api.get(`/stamps_issued/${transactionId}/get_details_with_stamps`, params)
    .then((response) => {
      if (response.results) response.results = addIndexToArray(response.results, page);
      dispatch(setDetailsWithStamps(response));
      dispatch(setDetailsWithStampsPage(page));
    })
    .catch(() => {
      NotificationManager.error(
        "Error al obtener a los detalles",
        "ERROR",
        4000,
      );
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const updateFiltersStampsIssued = (values) => (
  dispatch,
) => {
  dispatch(setFiltersStampsIssued(values));
  dispatch(getMemberForReport());
};

const updateFiltersStampsIssuedDetails = (values, noCollegiate) => (
  dispatch,
) => {
  dispatch(setFiltersStampsIssuedDetails(values));
  dispatch(getStampsIssued(1, noCollegiate));
};

export const actions = {
  getMemberForReport,
  getStampsIssued,
  getDeatailsWithStamps,
  updateFiltersStampsIssued,
  updateFiltersStampsIssuedDetails,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [MEMBER_REPORT_LIST]: (state, { data }) => {
    return {
      ...state,
      memberReportList: data,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [STAMPS_ISSUED_LIST]: (state, { data }) => {
    return {
      ...state,
      stampsIssuedList: data,
    };
  },
  [STAMPS_ISSUED_PAGE]: (state, { page }) => {
    return {
      ...state,
      stampsIssuedPage: page,
    };
  },
  [DETAILS_WITH_STAMPS]: (state, { data }) => {
    return {
      ...state,
      detailsWithStamps: data,
    };
  },
  [DETAILS_WITH_STAMPS_PAGE]: (state, { page }) => {
    return {
      ...state,
      detailsWithStampsPage: page,
    };
  },
  [FILTERS_STAMPS_ISSUED]: (state, { data }) => {
    return {
      ...state,
      filtersStampsIssued: data,
    };
  },
  [FILTERS_STAMPS_ISSUED_DETAILS]: (state, { data }) => {
    return {
      ...state,
      filtersStampsIssuedDetails: data,
    };
  },
};

export const initialState = {
  loader: false,
  memberReportList: {
    count: 0,
    results: [],
  },
  page: 1,
  ordering: "",
  search: "",
  stampsIssuedList: {
    count: 0,
    results: [],
  },
  stampsIssuedPage: 1,
  detailsWithStamps: {
    count: 0,
    results: [],
  },
  detailsWithStampsPage: 1,
  filtersStampsIssued: {
    search: null,
    start_date: null,
    end_date: null,
  },
  filtersStampsIssuedDetails: {
    search: null,
    start_date: null,
    end_date: null,
  },
};

export default handleActions(reducers, initialState);