import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import CustomContentModal from "./CustomContentModal";

const MemberInventoryList = ({
  listMemberInventory,
  page,
  inventoryDocuments,
  loader,
  match: { params: { id } },
  assignMemberInventory,
  generatePDF,
  hasCustomContent,
  customContent,
  idDocument,
  closeCustomContentModalAction,
}) => {
  useEffect(() => {
    closeCustomContentModalAction();
  }, []);
  
  useEffect(() => {
    assignMemberInventory(id);
    // if (idInventory) {
    listMemberInventory(page);
    // }
  }, []);

  return (
    <div className="container-fluid">
      <CustomContentModal
        openModal={hasCustomContent}
        closeModalAction={closeCustomContentModalAction}
        onSubmit={generatePDF}
        customContent={customContent}
        idDocument={idDocument}
      />
      <PageTitle
        title={"Documentos generados"}
        // withLink={true}
        // linkTo={"new-university"}
        // linkLabel={"Nueva Universidad"}
      />
      <div className="card bg-card-cofaqui br-20px py-5 px-4">
        <Grid
          hover
          data={inventoryDocuments}
          page={page}
          loading={loader}
          onPageChange={listMemberInventory}
          bodyContainerClass = "bc-professions-custom"
          headerContainerClass = "hc-professions-custom"
          tableContainerClass = "professions-container-table-sm"
          tableHeaderClass = "table-header-professions"
        >
          <TableHeaderColumn
            width="60px"
            dataField="order"
            dataAlign="center"
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="150px"
            dataField="title"
          >
            Documento
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="150px"
            dataField="signature_required"
          >
            Requiere firma
          </TableHeaderColumn>
          <TableHeaderColumn
            isKey
            dataField="id"
            width="100px"
            dataAlign="center"
            dataFormat={(cell, row) => {
              return (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    title="Imprimir"
                    onClick={() => generatePDF(cell)}
                    className="px-2 py-0 actions-btn btn-print"
                  >
                    <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
                  </button>
                </div>
              )
            }}
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

// Connect to redux

import { connect } from "react-redux";
import {actions} from "../../../../../redux/modules/myManagements/myDocuments/inventoryDocumens";

const ms2p = (state) => {
  return {
    ...state.inventoryDocumens,
  };
}

const md2p = {...actions};

export default connect(ms2p, md2p)(MemberInventoryList);