import React, { useEffect, useState } from "react";

import "./pagination.css";

const calculateNumberOfPages = (total, perPage) => {
  return total < perPage ? 1 : Math.ceil(total / perPage);
};

const Pagination = ({
  paginationData,
  onPageChange,
  perPage=10,
}) => {

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [blockNext, setBlockNext] = useState(false);
  const [blockPrevious, setBlockPrevious] = useState(false);

  useEffect(() => {
    if (paginationData) {
      setNumberOfPages(calculateNumberOfPages(paginationData.count, perPage));
      setCurrentPage(paginationData.currentPage);
    }
  }, [paginationData]);

  useEffect(() => {
    setBlockNext(paginationData.currentPage === numberOfPages);
    setBlockPrevious(paginationData.currentPage === 1);
  }, [currentPage, numberOfPages]);

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(paginationData.currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numberOfPages) {
      handlePageChange(paginationData.currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= numberOfPages; i++) {
      pageNumbers.push(
        <li className={`page-item ${i === currentPage ? "active" : ""}`} key={i}>
          <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
        </li>
      );
    }
    
    return pageNumbers;
  };

  return (
    <nav>
      <ul className="pagination justify-content-center mt-4">
        <li className={`page-item ${ blockPrevious ? "block-pagination" : "" }`}>
          <button 
            className="page-link" aria-label="Previous"
            onClick={handlePreviousPage}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </button>
        </li>
        {
          renderPageNumbers()
        }
        <li className={`page-item ${ blockNext ? "block-pagination" : "" }`}>
          <button 
            className="page-link" 
            href="#" 
            aria-label="Next"
            onClick={handleNextPage}
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination;