import React, {useEffect} from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";

import "./list.css";

const ProfessionList = (
  {
    getProfessions,
    page,
    loader,
    onSortChange,
    professionList,
    deleteProfession,
  }) => {

  useEffect(() => {
    getProfessions(page);
  }, []);

  return (
    <div className="container-fluid">
      <PageTitle
        title={"Profesiones"}
        withLink={true}
        linkTo={"new-profession"}
        linkLabel={"Nueva Profesión"}
      />
      <div className="card bg-card-cofaqui br-20px p-4" >
        <Grid
          hover
          responsive
          data={professionList}
          page={page}
          loading={loader}
          onPageChange={getProfessions}
          onSortChange={onSortChange}
          bodyContainerClass = "bc-professions-custom"
          headerContainerClass = "hc-professions-custom"
          tableContainerClass = "professions-container-table-sm"
          tableHeaderClass = "table-header-professions"
        >
          <TableHeaderColumn
            dataAlign="center"
            width="60px"
            isKey
            dataField="id"
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="175px"
            dataField="name"
          >
            Nombre de la Profesión
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="100px"
            dataField="acronym"
          >
            Siglas
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            width="90px"
            dataAlign="center"
            dataFormat={standardActions({
              editar: "profession",
              eliminar: deleteProfession,
            })}
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default ProfessionList;
