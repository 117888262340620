import React, { useEffect } from "react";
import BanksForm from "./BanksForm";
import TitlePageCreate from "../../../../components/layout/TitleCreate";

import "./banks.css";

const Banks = ({
  createBank,
  updateBank,
  getBank,
  bankData,
  match,
  loader,
}) => {

  const createOrUpdate = match.path.includes("new-bank") ? "createBank" : "updateBank";

  useEffect(() => {
    if (createOrUpdate === "updateBank") {
      const { id } = match.params;
      getBank(id);
    }
  }, []);

  return (
    <div className="pt-5 container-fluid">
      <TitlePageCreate
        redirectTo="/Banks"
        title={createOrUpdate === "createBank" ? "Nuevo Banco" : "Editar Banco"}
      />
      {
        createOrUpdate === "createBank" ?
          <BanksForm
            createOrUpdate={createBank}
            loader={loader}
            type="create"
          />
          :
          <BanksForm
            createOrUpdate={updateBank}
            bankData={bankData}
            loader={loader}
            type="update"
          />
      }
    </div>
  );
}

export default Banks;
