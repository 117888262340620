import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { fieldRequired } from "../../../../../utility/validation";
import {
  AsyncSelectField,
  renderField,
  renderNumber,
  selectField,
  renderSwitch,
} from "../../../../components/Utils/renderField";
import AddMembers from "../../../Administration/Commisions/Create/AddMembers";
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel";
import { TYPE_CASH_REGISTER } from "../../../../../utility/constants";
import { standardActions } from "../../../../components/Utils/Grid/StandardActions";

const FormCashReg = ({
  valueSelect,
  defaultOption,
  promiseAction,
  setValueSelect,
  initialValues,
  actionBntAdd,
  actionDelete,
  onSubmit,
  dataUser,
  dataDep,
}) => {

  const [isValid, setIsValid] = useState("");

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form: { change } }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 card bg-card-cofaqui br-20px pb-4 pt-5 pl-3">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="name">Nombre de caja</label>
                      <Field
                        name={"name"}
                        typeInput="text"
                        component={renderField}
                        validate={fieldRequired}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="type_cash_register">Tipo de caja</label>
                      <Field
                        name={"type_cash_register"}
                        options={TYPE_CASH_REGISTER}
                        component={selectField}
                        validate={fieldRequired}
                        parse={(value) => {
                          if (value === 1) {
                            setIsValid(true);
                            change("virtual_box", false);
                          } else {
                            setIsValid(false);
                          }
                          return value;
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="cashier_main">Encargado</label>
                      <Field
                        name="cashier_main"
                        valueKey="id"
                        labelKey="full_name"
                        validate={fieldRequired}
                        loadOptions={promiseAction}
                        component={AsyncSelectField}
                        defaultOptions={defaultOption}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="initial_balance">Saldo Inicial</label>
                      <Field
                        name="initial_balance"
                        customDecimalScale={2}
                        component={renderNumber}
                        validate={fieldRequired}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="place_of_residence">Sede</label>
                      <Field
                        name="place_of_residence"
                        valueKey={"id"}
                        labelKey={"name"}
                        component={selectField}
                        options={dataDep}
                        placeholder="Seleccione una sede"
                        validate={fieldRequired}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="virtual_box">Caja Virtual</label>
                      <Field name={"virtual_box"} disabled={isValid} component={renderSwitch} />
                    </div>
                  </div>
                </div>
                <div>
                  <AddMembers
                    data={dataUser}
                    promiseAction={promiseAction}
                    defaultOption={defaultOption}
                    setValueSelect={setValueSelect}
                    valueSelect={valueSelect}
                    actionBntAdd={actionBntAdd}
                    classContainerGrid="px-1 pt-2"
                    placeholderSearch="Nombre de usuario"
                    columnsToShow={[
                      {
                        title: "No.",
                        tableHeaderProps: {
                          isKey: true,
                          width: "200px",
                          dataField: "id",
                          dataAlign: "center",
                        },
                      },
                      {
                        title: "Nombre",
                        tableHeaderProps: {
                          dataAlign: "center",
                          dataField: "full_name",
                        },
                      },
                      {
                        title: "Estado",
                        tableHeaderProps: {
                          dataAlign: "center",
                          dataField: "is_active",
                          dataFormat: (cell) => {
                            let status = "Activo";
                            let color = "#199147";
                            let stylesDiv = {
                              background: color,
                            };
                            if (cell == false) {
                              status = "Inactivo";
                              stylesDiv.background = "red";
                            }
                            return (
                              <span className="all-center">
                                <div
                                  style={stylesDiv}
                                  className="circle-status"
                                />
                                &nbsp;{status}
                              </span>
                            );
                          },
                        },
                      },
                      {
                        title: "Acciones",
                        tableHeaderProps: {
                          dataField: "id",
                          dataAlign: "center",
                          dataFormat: standardActions({
                            eliminarSinConfirmacion: actionDelete,
                          }),
                        },
                      },
                    ]}
                    classNameContainer="col-xl-12 col-lg-12 col-md-11 col-sm-12 mt-1"
                    classNameCard="mb-4 py-3 col-12 px-lg-3 px-md-0 px-sm-0 border-cofaqui br-10px"
                    classNameContainerSearch="d-lg-flex d-md-flex d-sm-block flex-wrap col-lg-8 col-md-8 col-sm-12 px-0
                  mb-2"
                  />
                </div>
              </div>
              <BtnSaveAndCancel
                renderBtnSubmit
                renderLinkCancel
                redirectToLink="/cash-registers"
                customContainer="container justify-content-center w-100 mb-3 mt-4 row"
              />
            </form>
          );
        }}
      />
    </div>
  );
};

export default FormCashReg;
