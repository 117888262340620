import {connect} from "react-redux";
import {actions} from "../../../../redux/modules/cashRegister";
import { actions as stampActions } from "../../../../redux/modules/stamps";
import PettyCashEntry from "./PettyCashEntry";


const ms2p = (state) => {
  return {
    ...state.cashRegister,
    me: state.login.me,
    categories: state.stamps.categories,
  }
};

const md2p = {...actions, openCategories: stampActions.openCategories, getCategories: stampActions.getCategories};

export default connect(ms2p, md2p)(PettyCashEntry);