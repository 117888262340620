import React, {useState, Fragment} from "react"
import Modal from "react-responsive-modal"
import {Form, Field} from "react-final-form"
import {fieldRequired} from "../../../../../utility/validation";
import eyeBlock from "../../../../../../assets/img/icons/eyeblock.png";
import {renderField, renderNumber} from "../../../../components/Utils/renderField"

import Pagination from "../../../../components/Utils/Pagination";

import "./cashList.css"

const OpenCashRegister = ({
  cashRegisters, 
  action, 
  cashRegisterList,
  paginationData,
  permissions
}) => {
  const openCashRegisterPermission = permissions.cash_boxes_access

  const [openModal, setOpenModal] = useState(false)
  const [itemSelected, setItemSelected] = useState({})

  function openModalAction(item) {
    if (openCashRegisterPermission) {
      setOpenModal(true)
      setItemSelected(item)
    }
  }

  function closeModal() {
    setOpenModal(false)
    setItemSelected({})
  }

  function submitAction(data){
    action({...data, id: itemSelected.id, name: itemSelected.name}, closeModal)
  }


  return (
    <Fragment>
      {
        openCashRegisterPermission && (
          <ModalOpenCash
            open={openModal}
            item={itemSelected}
            submit={submitAction}
            closeAction={closeModal}
          />
        )
      }
      <div
        className={`bg-card-cofaqui card-style justify-content-center align-items-center py-5 px-2 ${ cashRegisters && cashRegisters.length > 0 ? "cash-list" : "d-flex flex-row" }`}
        style={{borderTopLeftRadius: "0", minHeight: "250px"}}>
      
        {
          Array.isArray(cashRegisters) && cashRegisters.map((item, index) => {
            return (
              <button
                key={index}
                style={{width: "170px", height: "165px"}}
                onClick={(event) => {
                  event.preventDefault()
                  openModalAction(item)
                }}
                className={`card px-2 d-flex justify-content-center align-items-center pointer ${index ? "ml-0" : ""}`}
              >
                <center>
                  <span className="material-symbols-outlined" style={{fontSize: "70px"}}>point_of_sale</span>
                </center>
                <center>
                  <span className="font-weight-bold"> {item.name}</span>
                </center>
              </button>
            )
          })
        }
        {
          (!cashRegisters || (cashRegisters && cashRegisters.length === 0)) &&
            <h4>
              No tienes asignada ninguna caja.
            </h4>
        }
      </div>
      {
        cashRegisters && cashRegisters.length > 0 &&
        (
          <Pagination
            paginationData={paginationData}
            onPageChange={cashRegisterList}
          />
        )
      }
    </Fragment>
  )
}

const ModalOpenCash = ({open, closeAction, item, submit}) => {
  let [showPass, setShowPass] = useState(false)

  return (
    <div>
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        open={open}
        styles={{
          modal: {
            width: "600px",
            padding: "2.5%",
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: "#B0A196"
          }
        }}
        onClose={closeAction}
      >
        <Form
          onSubmit={submit}
          render={({handleSubmit}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <center>
                    <h4 className="font-weight-bold mb-3">
                      Apertura de: {item.name}
                    </h4>
                  </center>
                </div>
                <div className="form-group px-lg-5 px-md-3 px-sm-0">
                  <label htmlFor="initial_balance">Saldo inicial</label>
                  <Field
                    name="initial_balance"
                    prefix="Q "
                    customDecimalScale={2}
                    validate={fieldRequired}
                    component={renderNumber}
                  />
                </div>
                <div className="form-group px-lg-5 px-md-3 px-sm-0">
                  <label htmlFor="password">Contraseña</label>
                  <div className="input-password">
                    <i style={{position: "absolute", marginTop: ".5rem", alignSelf: "flex-end", paddingRight: "10px"}}>
                      <div
                        role="button"
                        onClick={() => setShowPass(!showPass)}
                      >
                        <img
                          src={eyeBlock}
                          className="eye"
                          alt="icono-ocultar"
                        />
                      </div>
                    </i>
                    <Field
                      name="password"
                      typeInput={showPass ? "text" : "password"}
                      customClass="password-field"
                      validate={fieldRequired}
                      component={renderField}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center mt-5">
                  <button
                    type="button"
                    onClick={closeAction}
                    className="btn btn-red col-sm-12 col-lg-5 col-md-5"
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-success col-sm-12 col-lg-5 col-md-5 ml-lg-3 ml-ms-1 ml-sm-0">
                    Aperturar
                  </button>
                </div>
              </form>
            )
          }}
        />
      </Modal>
    </div>
  )
}

export default OpenCashRegister
