import React from 'react'
import moment from "moment"
import Modal from 'react-responsive-modal'
import DetailsViewer from "./DetailsViewer";


const ModalDetail = ({openModal, closeModal, info}) => {

  function validateMark(data) {
    return !!(data['detail_prev'] && data['detail_prev'].length > 2);
  }

  return (
    <Modal
      center
      open={openModal}
      onClose={closeModal}
      classNames={"card bg-card-cofaqui"}
      styles={{
        modal: {
          width: "660px",
          padding: '2.5%',
          maxWidth: "90%",
          borderRadius: "8px",
          background: "#FAFDF3",
          border: "1px solid #B0A196",
        },
        closeIcon: {
          fill: '#B0A196'
        }
      }}
    >
      <label style={{fontSize: '20px', color: '#312F2F'}}>Detalle</label>
      <div className="card bg-card-cofaqui shadow-none border-cofaqui br-20px">
        <div className="p-3 mh-div-details">
          {
            !!(info.id) &&
            <React.Fragment>
              <div className="w-100">
                <label className="col-lg-4 col-md-5 col-sm-12 px-0 text-capitalize">No: </label>
                <span>{info['id']}</span>
              </div>
              <div className="w-100">
                <label className="col-lg-4 col-md-5 col-sm-12 px-0 text-capitalize">Acción: </label>
                <span>{info['action']}</span>
              </div>
              <div className="w-100">
                <label className="col-lg-4 col-md-5 col-sm-12 px-0 text-capitalize">Usuario: </label>
                <span>{info['user']['first_name']} &nbsp;{info['user']['last_name']}</span>
              </div>
              <div className="w-100">
                <label className="col-lg-4 col-md-5 col-sm-12 px-0 text-capitalize">Creado: </label>
                <span>{moment(info['created']).format("DD/MM/YYYY HH:mm")}</span>
              </div>
              <div className="w-100">
                <label className="col-lg-4 col-md-5 col-sm-12 px-0 text-capitalize">Modificado: </label>
                <span>{moment(info['modified']).format("DD/MM/YYYY HH:mm")}</span>
              </div>
              <div className="w-100">
                <div className="d-flex flex-sm-column flex-md-row flex-lg-row">
                  <label className="col-12 px-0 text-capitalize">Antes: </label>
                </div>
                <div className="col-12 border-details">
                  <DetailsViewer dataPost={JSON.parse(info['detail_prev'])}/>
                </div>
                <div className="d-flex flex-sm-column flex-md-row flex-lg-row">
                  <label className="col-12 px-0 text-capitalize flex-1 mt-3">Después: </label>
                </div>
                <div className="col-12 border-details">
                  {info &&
                    <DetailsViewer
                      mark={validateMark(info)}
                      dataPost={JSON.parse(info['detail_post'])}
                      dataPre={JSON.parse(info['detail_prev'])}
                    />
                  }
                </div>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </Modal>
  )
}

export default ModalDetail;
