import React, { useEffect } from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";

import "./list.css";

const BanksList = ({
  getBanks,
  page,
  loader,
  banksList,
  deleteBank,
  onSortChange,
}) => {

  useEffect(() => {
    getBanks(page);
  }, []);

  return (
    <div className="container-fluid">
      <PageTitle
        title={"Bancos"}
        withLink={true}
        linkTo={"banks/new-bank"}
        linkLabel={"Nuevo Banco"}
      />
      <div className="card bg-card-cofaqui br-20px py-5 px-4">
        <Grid
          hover
          data={banksList}
          page={page}
          loading={loader}
          onPageChange={getBanks}
          onSortChange={onSortChange}
          bodyContainerClass = "bc-professions-custom"
          headerContainerClass = "hc-professions-custom"
          tableContainerClass = "professions-container-table-sm"
          tableHeaderClass = "table-header-professions"
        >
          <TableHeaderColumn
            width="60px"
            dataField="order"
            dataAlign="center"
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="150px"
            dataField="name"
          >
            Nombre del Banco
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="150px"
            dataField="account_number"
          >
            No. de Cuenta
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="is_bank_account_of_guild"
            dataFormat={(cell) => {
              return cell ? "Si" : "No";
            }}
          >
            ¿Es cuenta bancaria de COFAQUI?
          </TableHeaderColumn>
          <TableHeaderColumn
            isKey
            dataField="id"
            width="90px"
            dataAlign="center"
            dataFormat={standardActions({
              editar: "banks",
              eliminar: deleteBank,
            })}
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default BanksList;
