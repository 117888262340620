import React from 'react'
import {Field} from 'react-final-form'
import Tree from "../../../../components/Utils/TreeComponent"
import {getSubCategories} from "../../Stamps/CreateUpdate/Steps/Step1"
import {renderField, renderNumber} from "../../../../components/Utils/renderField"
import {fieldRequired, composeValidators, maxAmount} from "../../../../../utility/validation"


const BreakdownItem = ({fields, totalAmount, openSelectCategories, categories, isUpdate}) => {

  const validateAmount = (items, indexExclude) => {
    let used = 0
    if (totalAmount) {
      if (items && Array.isArray(items)) {
        items.map((item, index) => {
          if (item.amount && index != indexExclude) {
            used += Number(item.amount)
          }
        })
      }
      const totalUsed = Number(totalAmount) - used
      if (totalUsed > 0) {
        return totalUsed
      }
    }
    return 0
  }

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div
            className="d-flex flex-lg-row flex-md-column flex-sm-column w-100 align-content-center py-4 shadow br-20px mt-2"
            style={{background: "#FAFDF3"}} key={index}>
            <div
              className="d-flex m-0 flex-lg-row flex-md-column flex-sm-row justify-content-center align-items-lg-center align-items-sm-end align-items-md-end col-sm-12 col-md-1 col-lg-1">
              <span className="font-weight-bold mb-0" style={{fontSize: "30px"}}> {index + 1}</span>
            </div>
            <div
              className="col-lg-4 d-flex flex-lg-row flex-md-column flex-sm-column w-100 justify-content-between align-items-lg-center align-items-sm-start align-items-md-start px-lg-5 px-md-3 px-sm-2">
              <label htmlFor={`${item}.name`} className="d-inline">Descripción </label>
              <Field
                type="text"
                name={`${item}.name`}
                component={renderField}
                validate={fieldRequired}
                placeholder="Aporte a universidad"
                containerClass="col-lg-9 col-md-9 col-sm-12 px-0"
              />
            </div>
            <div
              className="col-lg-4 d-flex flex-lg-row flex-md-column flex-sm-column w-100 justify-content-between align-items-lg-center align-items-sm-start align-items-md-start px-lg-5 px-md-3 px-sm-2">
              <label htmlFor={`${item}.expense_category_id`} className="d-inline">Cat. Contable</label>
              <div className="col-lg-9 coll-md-9 col-sm-12 px-0">
                <Field
                  component={Tree}
                  options={categories}
                  validate={fieldRequired}
                  open={openSelectCategories}
                  getSubCategories={getSubCategories}
                  name={`${item}.expense_category_id`}
                  placeholder="Seleccione una categoría"
                  typeAction={isUpdate ? "edit" : "create"}
                />
              </div>
            </div>
            <div
              className="col-lg-2 d-flex flex-lg-row flex-md-column flex-sm-column w-100 justify-content-between align-items-lg-center align-items-sm-start align-items-md-start">
              <label htmlFor={`${item}.amount`} className="d-inline">Valor </label>
              <Field
                prefix="Q  "
                placeholder="Q. 15.00"
                customDecimalScale={2}
                name={`${item}.amount`}
                validate={composeValidators(fieldRequired, maxAmount(validateAmount(fields.value, index)))}
                component={renderNumber}
                containerClass="col-lg-8 col-md-8 col-sm-12 px-0"
              />
            </div>
            <div className="col-lg-1 d-flex justify-content-center align-items-center mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3">
              <button
                type="button"
                title="Eliminar desgloce"
                className="actions-btn btn-delete-document"
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <img src={require("assets/img/icons/reddump.svg")} alt="Borrar documento"/>
              </button>
            </div>
          </div>
        )
      })
      }
    </div>
  )
}

export default BreakdownItem
