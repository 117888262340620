import React from 'react';
import 'moment/locale/es';
import "./date_picker.css";
import moment from "moment";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';


const DayPicker = (props) => {
  const {input, value, onChange, maxDate, minDate, disabled} = props;
  return (
    <div style={{borderRadius: "1em"}}>
      <DayPickerInput
        {...input}
        classNames={{overlay: "date-picker-custom-overlay"}}
        formatDate={formatDate}
        inputProps={{className: "form-control"}}
        parseDate={parseDate}
        value={new Date(moment(value? value: new Date(), "YYYY-MM-DD"))}
        disabled={disabled}
        dayPickerProps={{
          locale: 'es',
          localeUtils: MomentLocaleUtils,
          modifiers: {
            disabled: {after: maxDate ? maxDate : null, before: minDate ? minDate : null}
          }
        }}
        onDayChange={(val) => {
          const date = moment(val).toDate();
          onChange(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
        }}/>
    </div>
  )
}

export default DayPicker;
