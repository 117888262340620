import React from "react";
import {Form, Field} from "react-final-form";
import { renderField, renderSwitch } from "../../../../components/Utils/renderField";

import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";

const required = (value) => (value ? undefined : "Este campo es requerido");

const BanksForm = ({
  createOrUpdate,
  bankData = {},
  loader,
  type,
}) => {
  const onSubmit = (values) => {
    if (type === "create") {
      createOrUpdate(values);
    } else {
      createOrUpdate(bankData.id, values);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={bankData}
      render={({
        handleSubmit,
        values,
        values: { is_bank_account_of_guild },
      }) => (
        <form
          onSubmit={handleSubmit}
          className="py-5 px-lg-5 px-md-3 px-sm-01 px-xl-5 universities-form d-flex flex-column"
        >
          <LoadMask blur dark loading={loader} >
            <div className="row d-flex flex-row mb-2">
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="name"
                >
                  Nombre del banco
                </label>
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder="Nombre del banco"
                  validate={required}
                />
              </div>
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="is_bank_account_of_guild"
                >
                  ¿Es cuenta bancaria de COFAQUI?
                </label>
                <div className="d-flex flex-row">
                  <Field
                    name="is_bank_account_of_guild"
                    component={renderSwitch}
                  />
                  <span>
                    {values.is_bank_account_of_guild ? "Si" : "No"}
                  </span>
                </div>
              </div>
            </div>
            {
              is_bank_account_of_guild && (
                <div className="row d-flex flex-row">
                  <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                    <label
                      htmlFor="name"
                    >
                       No. de cuenta
                    </label>
                    <Field
                      name="account_number"
                      component={renderField}
                      type="text"
                      placeholder="No. de cuenta"
                      validate={required}
                    />
                  </div>
                </div>
              )
            }
            <div className="mt-4 container-fluid">
              <BtnSaveAndCancel
                renderBtnSubmit={true}
                renderLinkCancel={true}
                redirectToLink={"/banks"}
                subContainer={"px-0 col-lg-10 col-md-10 col-sm-12 row justify-content-center"}
              />
            </div>
          </LoadMask>
        </form>
      )}
    />
  );
};

export default BanksForm;
