import React, { useEffect, useState } from "react"
import {Field, Form} from "react-final-form"
import {fieldRequired} from "../../../../utility/validation"
import {TYPE_OF_INCOME} from "../../../../utility/constants"
import AddMembers from "../../Administration/Commisions/Create/AddMembers"
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel"
import {standardActions} from "../../../components/Utils/Grid/StandardActions"
import {RenderCurrency} from "../../../components/Utils/renderField/renderReadField"
import FinalFormConnection from "../../../components/ReduxFormConn/FinalFormConnection";
import {renderDatePicker, RenderQuantity} from "../../../components/Utils/renderField/renderField"
import {AsyncSelectField, simpleRenderField, selectField} from "../../../components/Utils/renderField"

// eslint-disable-next-line
import {api} from "api"

const getBanks = (search, type) => {
  const params = {
    search,
    type,
  }

  return api.get("bank/get_origin_or_destination_banks", { ...params }).then((response) => {
    let _options = []
    if (response.results) {
      _options = response.results
    }
    return _options
    
  }).catch(() => {
    return []
  });
}

const TransactionForm = (
  {
    defaultOptionMembers,
    changeQuantityItem,
    defaultOptionProd,
    loadMembers,
    setItemSel,
    itemSelected,
    loadProducts,
    initialData,
    actionBntAdd,
    actionDelete,
    dataProducts,
    lockBtnAdd,
    setMember,
    minDate,
    onSubmit,
    cashier,
    total = 0,
    getOriginOrDestinationBanks,
    originBanksList,
    destinationBanksList,
  }) => {

  const requiredDoc = [5, 10, 15] // deposito, cheque, transferencia
  const [removeValue, setRemoveValue] = useState(false)

  const [lastPaymentDate, setLastPaymentDate] = useState(null);
  const [lastPaymentColor, setLastPaymentColor] = useState(null);

  const handleMemberChange = (selectedMember) => {
    if (selectedMember && selectedMember.last_payment) {
      const lastPaymentDate = `${selectedMember.last_payment.month} ${selectedMember.last_payment.year}`;
      const lastPaymentColor = selectedMember.last_payment.color;
      setLastPaymentDate(lastPaymentDate);
      setLastPaymentColor(lastPaymentColor);
    } else {
      setLastPaymentDate(null);
      setLastPaymentColor(null);
    }
  };

  useEffect(() => {
    getOriginOrDestinationBanks("origin")
    getOriginOrDestinationBanks("destination")
  }, [])

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialData}
        validate={({type_of_income, no_document, bank_of_origin, bank_of_destination}) => {
          const errors = {}
          if (requiredDoc.includes(type_of_income) && !no_document) {
            errors.no_document = "Campo requerido"
          }
          if (requiredDoc.includes(type_of_income) && !bank_of_origin) {
            errors.bank_of_origin = "Campo requerido"
          }
          if (requiredDoc.includes(type_of_income) && !bank_of_destination) {
            errors.bank_of_destination = "Campo requerido"
          }
          return errors
        }}
        render={({handleSubmit, values: {type_of_income, member_id}, form: {change}}) => {
          return (
            <form onSubmit={handleSubmit}>
              <FinalFormConnection form={"CashRegister"}/>
              <div className="col-xl-6 col-lg-6 col-md-9 col-sm-12 card bg-card-cofaqui br-20px pb-4 pt-5 pl-3">
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="transaction_date">Fecha de ingreso</label>
                      <Field
                        withTime
                        typeInput='text'
                        minDate={minDate}
                        maxDate={new Date()}
                        name="transaction_date"
                        validate={fieldRequired}
                        component={renderDatePicker}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="member_id">Agremiado</label>
                      <Field
                        valueKey='id'
                        name="member_id"
                        labelKey='full_name'
                        // customAction={setMember}
                        customAction={(selectedMember) => {
                          setMember(selectedMember);
                          handleMemberChange(selectedMember);
                        }}
                        validate={fieldRequired}
                        loadOptions={loadMembers}
                        component={AsyncSelectField}
                        defaultOptions={defaultOptionMembers}
                      />
                    </div>               
                  </div>
                  {lastPaymentDate && (
                    <div className="form-group mx-xl-8 mx-md-8 mx-sm-8-end d-flex " style={{ marginLeft: '11cm' }}>
                      <label>Último pago: </label>
                      <span className="material-icons" style={{color:lastPaymentColor}}>fiber_manual_record</span>
                      {lastPaymentDate}
                    </div>
                  )}

                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="type_of_income">Forma de pago</label>
                      <Field
                        name="type_of_income"
                        options={TYPE_OF_INCOME}
                        validate={fieldRequired}
                        customAction={(item) => {
                          if (item === 1) {
                            change("no_document", null)
                            setRemoveValue(true)
                          }
                        }}
                        component={selectField}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="no_document">
                        No. {type_of_income === 10 ? "cheque" : type_of_income === 15 ? "transferencia" : "documento"}
                      </label>
                      <Field
                        name="no_document"
                        disabled={!requiredDoc.includes(type_of_income)}
                        component={simpleRenderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="bank_of_origin">Banco de origen</label>
                      <Field
                        name="bank_of_origin"
                        defaultOptions={originBanksList.results}
                        component={AsyncSelectField}
                        type="select"
                        placeholder="Banco"
                        labelKey="name"
                        valueKey="id"
                        loadOptions={(value) => getBanks(value, "origin")}
                        disabled={!requiredDoc.includes(type_of_income)}
                        removeValue={removeValue}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="bank_of_destination">
                        Banco de destino
                      </label>
                      <Field
                        name="bank_of_destination"
                        defaultOptions={destinationBanksList.results}
                        component={AsyncSelectField}
                        extendInformation={true}
                        identify="BOD"
                        extraData1="name"
                        extraData2="account_number"
                        type="select"
                        placeholder="Banco"
                        labelKey="name_account_number"
                        valueKey="id"
                        loadOptions={(value) => getBanks(value, "destination")}
                        disabled={!requiredDoc.includes(type_of_income)}
                        removeValue={removeValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="cashier_id">Usuario</label>
                      <Field
                        name="cashier_id"
                        disabled={true}
                        component={selectField}
                        validate={fieldRequired}
                        options={cashier}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="amount">Total</label>
                      <RenderCurrency
                        className="d-block pt-2"
                        value={total}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card bg-card-cofaqui mt-4">
                  <AddMembers
                    valueKey="id"
                    labelKey="name"
                    data={dataProducts}
                    lockBtnAdd={lockBtnAdd}
                    disabledSelect={!member_id}
                    valueSelect={itemSelected}
                    setValueSelect={setItemSel}
                    promiseAction={loadProducts}
                    defaultOption={defaultOptionProd}
                    actionBntAdd={actionBntAdd}
                    classContainerGrid="px-1 pt-2"
                    labelSearch="Busqueda de productos y/o timbres"
                    placeholderSearch="Nombre del producto o timbre"
                    columnsToShow={
                      [
                        {
                          title: "No.",
                          tableHeaderProps: {
                            isKey: true,
                            dataField: "order",
                            dataAlign: "center",
                            width: "200px"
                          }
                        },
                        {
                          title: "Nombre",
                          tableHeaderProps: {
                            dataField: "name",
                            dataAlign: "center",
                            width: "200px"
                          }
                        },
                        {
                          title: "Monto",
                          tableHeaderProps: {
                            dataField: "amount",
                            dataAlign: "center",
                            width: "200px",
                            dataFormat: (cell) => <RenderCurrency value={cell ? cell : 0}/>
                          }
                        },
                        {
                          title: "Cantidad",
                          tableHeaderProps: {
                            dataField: "quantity",
                            dataAlign: "center",
                            width: "235px",
                            dataFormat: (cell, row) => {
                              const disabled = row.under_approved
                              return (
                                <RenderQuantity
                                  callBackChange={(value) => changeQuantityItem(row, value)}
                                  initialValue={1}
                                  disabled={disabled}
                                  minValue={1}
                                />
                              )
                            }
                          }
                        },
                        {
                          title: "Acciones",
                          tableHeaderProps: {
                            dataField: "order",
                            dataAlign: "center",
                            width: "235px",
                            dataFormat: standardActions({eliminarSinConfirmacion: actionDelete})
                          }
                        }
                      ]
                    }
                    classNameContainer="col-xl-12 col-lg-12 col-md-11 col-sm-12 mt-1"
                    classNameCard="mb-4 py-3 col-12 px-lg-3 px-md-0 px-sm-0 br-10px"
                    classNameContainerSearch="d-lg-flex d-md-flex d-sm-block flex-wrap col-lg-6 col-md-6 col-sm-12 px-0 mb-2"
                  />
                </div>
              </div>
              <BtnSaveAndCancel
                renderBtnSubmit
                renderLinkCancel
                redirectToLink="/my-cash-register"
                customContainer="container justify-content-center w-100 mb-3 mt-4 row"
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default TransactionForm
