import React, { useEffect } from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn, BootstrapTable} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import FinalFormFilter from "../../../../components/Utils/FinalFormFilter";

const ExpanderComponent = ({
  details_with_stamps=[],
}) => {

  return (
    <div className="container-fluid">
      <BootstrapTable
        data={details_with_stamps}
        hover={false}
        tableHeaderClass="table-header-cofaqui-details"
      >
        <TableHeaderColumn
          isKey
          width='150px'
          dataField='order'
          dataAlign="center"
        >
          No.
        </TableHeaderColumn>
        <TableHeaderColumn
          width='150px'
          dataField='stamp_name'
          dataAlign="center"
        >
          Nombre del Timbre
        </TableHeaderColumn>
        <TableHeaderColumn
          width='150px'
          dataField='quantity'
          dataAlign="center"
        >
          Cantidad de Timbres
        </TableHeaderColumn>
        <TableHeaderColumn
          width='150px'
          dataField='amount'
          dataAlign="center"
        >
          Monto
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

const StampsIssuedList = ({
  getStampsIssued,
  stampsIssuedPage,
  loader,
  stampsIssuedList,
  match: { params: { noCollegiate } },
  updateFiltersStampsIssuedDetails,
  filtersStampsIssuedDetails,
  // getDeatailsWithStamps,
}) => {

  useEffect(() => {
    getStampsIssued(stampsIssuedPage, noCollegiate);
  }, []);

  return (
    <div className="container-fluid">
      <PageTitle
        title={`Colegiado ${noCollegiate}`}
      />
      <div className="card bg-card-cofaqui br-20px py-5 px-4">
        <FinalFormFilter
          withSearchFilter
          withStartDateFilter
          withEndDateFilter
          withSeparator
          action={(values)=>updateFiltersStampsIssuedDetails(values, noCollegiate)}
          initialValues={filtersStampsIssuedDetails}
          searchTitle="Buscar por No. Recibo"
          searchPlaceholder="No. Recibo"
          startDateTitle="Fecha de transacción Inicial"
          endDateTitle="Fecha de transacción Final"
        />
        <Grid
          hover
          expandableRow={() => true}
          expandComponent={ExpanderComponent}
          data={stampsIssuedList}
          page={stampsIssuedPage}
          loading={loader}
          onPageChange={(page) => getStampsIssued(page, noCollegiate)}
        >
          <TableHeaderColumn
            width="60px"
            dataField="order"
            dataAlign="center"
            isKey
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="transaction_date"
          >
            Fecha de Transacción
          </TableHeaderColumn>
          <TableHeaderColumn
            width='150px'
            dataField='receipt_number'
            dataAlign="center"
          >
            No. de Recibo
          </TableHeaderColumn>
          <TableHeaderColumn
            width='150px'
            dataField='receipt_series'
            dataAlign="center"
          >
            Serie de Recibo
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="quantity"
          >
            Timbres Comprados
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="125px"
            dataField="details_with_stamps_amount"
          >
            Monto total
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default StampsIssuedList;