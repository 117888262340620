import {handleActions} from "redux-actions";
import {createReducer} from "../../baseReducer/baseReducer";
// eslint-disable-next-line
import { api } from "api";
import { NotificationManager } from "react-notifications";

// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
  "adminCashRegister",
  "cash_register",
  "CashRegisterForm",
  "/cash-registers",
);

const deleteCashRegister = (id) => (dispatch) => {
  api
    .eliminar(`/cash_register/${id}`)
    .then((response) => {
      NotificationManager.success("Caja eliminada", "Éxito", 3000);
      dispatch(actions.listar());
    })
    .catch(() => {
      NotificationManager.error(
        "No es posible eliminar la caja, ya que tiene transacciones asociadas",
        "ERROR",
        5000
      );
    })
    .finally(() => {});
};

export const combineActions = {
  ...actions,
  deleteCashRegister,
};

export default handleActions(reducers, initialState);