import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
// import { push } from "react-router-redux";
import printJS from "print-js";
// eslint-disable-next-line
import { api } from "api";
import {addIndexToArray} from "../../../../utility/constants";

const PAYMENTS_DATA = "PAYMENTS_DATA";
const PAYMENTS_LIST = "PAYMENTS_LIST";
const START_DATE = "START_DATE";
const FILTER_YEAR = "FILTER_YEAR";
const END_DATE = "END_DATE";
const LOADER = "PAYMENTS_LOADER";
const PAGE = "PAYMENTS_PAGE";
const ORDERING = "PAYMENTS_ORDERING";
const SEARCH = "PAYMENTS_SEARCH";
const MEMBER_ID = "MEMBER_ID";

export const constants = {
  PAYMENTS_DATA,
  PAYMENTS_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = loader => ({
  type: LOADER,
  loader,
});

// const setPaymentsData = data => ({
//   type: PAYMENTS_DATA,
//   data,
// });

const setPaymentsList = data => ({
  type: PAYMENTS_LIST,
  data,
});

const setPage = page => ({
  type: PAGE,
  page,
});

// const setOrdering = ordering => ({
//   type: ORDERING,
//   ordering,
// });

// const setSearch = search => ({
//   type: SEARCH,
//   search,
// });

const setStartDate = startDate => ({
  type: START_DATE,
  startDate,
});

const setEndDate = endDate => ({
  type: END_DATE,
  endDate,
});

const setFilterYear = year => ({
  type: FILTER_YEAR,
  year,
});

const setMemberId = memberId => ({
  type: MEMBER_ID,
  memberId,
});

// ------------------------------------
// Actions
// ------------------------------------

const getPaymentsList = (page = 1) => (
  dispatch,
  getStore
) => {
  const params = { page };
  params["start_date"] = getStore().payments.startDate;
  params["end_date"] = getStore().payments.endDate;
  params["year"] = getStore().payments.year;
  params["member_id"] = getStore().payments.memberId;

  dispatch(setLoader(true));
  api.get("transaction/get_payments", { ...params })
    .then((response) => {
      if (response.results) response.results = addIndexToArray(response.results, page);
      dispatch(setPaymentsList(response));  
      dispatch(setPage(page));
      dispatch(setLoader(false));
    })
    .catch(() => {
      dispatch(setLoader(false));
    });
};

const getMyPaymentsList = (page = 1) => (
  dispatch,
  getStore
) => {
  const params = { page };
  params["start_date"] = getStore().payments.startDate;
  params["end_date"] = getStore().payments.endDate;
  params["year"] = getStore().payments.year;

  dispatch(setLoader(true));
  api.get("transaction/get_my_payments", { ...params })
    .then((response) => {
      if (response.results) response.results = addIndexToArray(response.results, page); 
      dispatch(setPaymentsList(response));
      dispatch(setPage(page));
      dispatch(setLoader(false));
    })
    .catch(() => {
      dispatch(setLoader(false));
    });
};

const printAction = url_file => () => {
  if (url_file) {
    fetch(url_file).then(function (response) {
      response.blob().then(function (blob) {
        var reader = new FileReader();
        reader.onload = function () {
          printJS({
            printable: reader.result.substring(28),
            type: "pdf",
            base64: true
          });
        };
        reader.readAsDataURL(blob);
      })
    });
  } else {
    NotificationManager.error("No existe el documento a imprimir", "Error", 4000)
  }
}

const assignStartDate = (startDate) => (
  dispatch,
) => {
  dispatch(setStartDate(startDate));
};

const assignEndDate = (endDate) => (
  dispatch,
) => {
  dispatch(setEndDate(endDate));
};

const resetPage = (page) => (
  dispatch,
) => {
  dispatch(setPage(page));
};

const assignMemberId = (memberId) => (
  dispatch,
) => {
  dispatch(setMemberId(memberId));
};

export const actions = {
  getPaymentsList,
  getMyPaymentsList,
  assignStartDate,
  assignEndDate,
  resetPage,
  printAction,
  assignMemberId,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [PAYMENTS_DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [PAYMENTS_LIST]: (state, { data }) => {
    return {
      ...state,
      paymentsList: data,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [START_DATE]: (state, { startDate }) => {
    return {
      ...state,
      startDate,
    };
  },
  [END_DATE]: (state, { endDate }) => {
    return {
      ...state,
      endDate,
    };
  },
  [FILTER_YEAR]: (state, { year }) => {
    return {
      ...state,
      year,
    };
  },
  [MEMBER_ID]: (state, { memberId }) => {
    return {
      ...state,
      memberId,
    };
  },
};

export const initialState = {
  loader: false,
  data: {},
  paymentsList: {
    results: [],
    count: 0,
  },
  page: 1,
  ordering: "",
  search: "",
  startDate: "NaN",
  endDate: "NaN",
  year: 0,
  memberId: undefined,
};

export default handleActions(reducers, initialState);