import React, { useEffect, useState } from "react";

import StampsForm from "./StampsForm";
// import SingleStampForm from "./SingleStampForm";
import TitlePageCreate from "../../../../components/layout/TitleCreate";


import "./stamps.css";

const editForm = (path, setTypeForm, setSingleForm) => {
  if (path.includes("edit") && !path.includes("stamps")) {
    setSingleForm("form-1")
    setTypeForm("edit");
    return 1;
  } else if (path.includes("required-documents")) {
    setSingleForm("form-2")
    setTypeForm("edit");
    return 2;
  } else if (path.includes("generated-documents") && !path.includes("generate-new-document")) {
    setSingleForm("form-3")
    setTypeForm("edit");
    return 4;
  } else if (path.includes("generate-new-document") && !path.includes("edit")) {
    setSingleForm("form-3")
    setTypeForm("create");
    return 4;
  }
  return "create";
};

const Stamps = ({
  formStep,
  getProfessionsPartOfUnion,
  professions,
  createStamp,
  match,
  getStamp,
  updateStamp,
  updateRequiredDocuments,
  updateGeneratedDocument,
  stampData,
  getRequiredDocuments,
  getGeneratedDocuments,
  getCategories,
  categories,
  requiredDocuments,
  getGeneratedDocument,
  generatedDocument,
  createGeneratedDocument,
  updateNumberFormSteps,
  updateNumberDocuments,
  numberDocuments,
  openCategories,
  me
  // create,
}) => {

  const { permissions } = me;

  const [form, setForm] = useState({});
  const [typeForm, setTypeForm] = useState("");
  const [title, setTitle] = useState("Datos Generales");
  const [singleForm, setSingleForm] = useState({});
  const [editOneForm, setEditOneForm] = useState({});

  const { path } = match;

  let correctForm = "";

  useEffect(() => {
    updateNumberDocuments(0);
  }, [path]);

  useEffect(() => {
    correctForm = editForm(path, setTypeForm, setSingleForm);
    setForm(correctForm);
    const { id } = match.params;
    if (form == 1) {
      getStamp(id);
      getCategories();
    } else if (form == 2) {
      getRequiredDocuments(id);
    } else if (form == 3 && !path.includes("generate-new-document") && !path.includes("edit")) {
      getGeneratedDocuments(id);
    } else {
      getCategories();
    }
  }, [form]);

  useEffect(() => {
    if (form == 1) {
      setTitle("Datos Generales");
    } else if (form == 2) {
      setTitle("Documentos Requeridos");
    } else if (form == 3) {
      setTitle("Documentos Generados");
    }
  }, [form]);

  useEffect(() => {
    setEditOneForm({
      step: form,
      type: typeForm,
      form: singleForm,
    })
  }, [form, typeForm, singleForm]);

  useEffect(() => {
    if (form == 4 && !match.path.includes("generate-new-document")) {
      const { documentId } = match.params;
      getGeneratedDocument(documentId);
    }
  }, [form]);

  return (
    <div className="mt-5">
      <TitlePageCreate
        redirectTo="/stamps"
        title={title}
      />
      {
        form == "create" && (
          <StampsForm
            formStep={formStep}
            getProfessionsPartOfUnion={getProfessionsPartOfUnion}
            openCategories={openCategories}
            professions={professions}
            type="create"
            createOrUpdateStamp={createStamp}
            setTitle={setTitle}
            updateNumberFormSteps={updateNumberFormSteps}
            updateNumberDocuments={updateNumberDocuments}
            numberDocuments={numberDocuments}
            categories={categories}
            form={editOneForm}
            match={match}
            permissions={permissions}
          />
        )
      }
      {
        form === 1 && (
          <StampsForm
            formStep={formStep}
            getProfessionsPartOfUnion={getProfessionsPartOfUnion}
            openCategories={openCategories}
            professions={professions}
            type="edit"
            createOrUpdateStamp={updateStamp}
            setTitle={setTitle}
            updateNumberFormSteps={updateNumberFormSteps}
            updateNumberDocuments={updateNumberDocuments}
            numberDocuments={numberDocuments}
            categories={categories}
            form={editOneForm}
            initialValues={stampData}
            match={match}
          />
        )
      }
      {
        form === 2 && (
          <StampsForm
            formStep={formStep}
            getProfessionsPartOfUnion={getProfessionsPartOfUnion}
            openCategories={openCategories}
            professions={professions}
            type="edit"
            createOrUpdateStamp={updateRequiredDocuments}
            setTitle={setTitle}
            updateNumberFormSteps={updateNumberFormSteps}
            updateNumberDocuments={updateNumberDocuments}
            numberDocuments={numberDocuments}
            categories={categories}
            form={editOneForm}
            initialValues={requiredDocuments}
            match={match}
          />
        )
      }
      {
        form === 4 && (
          <StampsForm
            formStep={formStep}
            getProfessionsPartOfUnion={getProfessionsPartOfUnion}
            openCategories={openCategories}
            professions={professions}
            type="edit"
            createOrUpdateStamp={
              editOneForm.type == "edit" ?
                updateGeneratedDocument :
                createGeneratedDocument
            }
            setTitle={setTitle}
            updateNumberFormSteps={updateNumberFormSteps}
            updateNumberDocuments={updateNumberDocuments}
            numberDocuments={numberDocuments}
            categories={categories}
            form={editOneForm}
            initialValues={
              typeForm === "create" ? {} : generatedDocument
            }
            match={match}
          />
        )
      }
    </div>
  );
}

export default Stamps;
