import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import CreateEditDirection from "./CreateEditDirection";
import TableList from "./TableList";
import Modal from "react-responsive-modal";
import LoadMask from "../../../components/Utils/LoadMask/LoadMask";

const ListTechnicalDirections = (props) => {
  const {
    listDirections,
    createDirection,
    updateDirection,
    deleteDirection,
    getUserBeneficiaryData,
    // STATE
    loader,
    data,
    page,
    data_item,
  } = props;

  const [is_edit, setIsEdit] = useState(false);
  
  const baseModel = {
    name_institution: "",
    direction_institution: "",
    type_institution: "",
    date_aprobation: "",
    no_stamp: "",
  }

  const [editCreate_item, setEditCreateItem] = useState(baseModel);

  const cleanData = () => {
    setEditCreateItem(baseModel);
  };
  
  const openModalEdit = (data) => {
    setIsEdit(true);
    setEditCreateItem(data);
    setOpenModal(true);
  };

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);

  const handleCreateDirection = (data) => {
    createDirection(data);
    closeModal();
    cleanData();
  };

  const handleUpdateDirection = (data) => {
    if (data && data.id) {
      updateDirection(data.id, data);
      closeModal();
    }
    closeModal();
    cleanData();
  };

  useEffect(() => {
    if (data_item && data_item.id) {
      listDirections(data_item.id);
    } else {
      getUserBeneficiaryData();
    }
  }, [data_item]);

  return (
    <div>
      <Modal
        center
        open={openModal}
        styles={{
          modal: {
            minWidth: "60%",
            borderRadius: "10px",
            backgroundColor: "#e5e1dd",
          },
        }}
        onClose={closeModal}
      >
        <LoadMask loading={loader} blur>
          <CreateEditDirection
            data={editCreate_item}
            loader={loader}
            is_edit={is_edit}
            createDirection={handleCreateDirection}
            updateDirection={handleUpdateDirection}
          />
        </LoadMask>
      </Modal>

      <div
        style={{
          position: "relative",
        }}
      >
        {/* {!!(data.count < 7) ? (
          <button
            style={{
              position: "absolute",
              right: "10px",
              top: "75px",
              zIndex: "1",
            }}
            className="btn btn-green-generic ml-2"
            onClick={() => {
              setIsEdit(false);
              setOpenModal(true);
            }}
          >
            &nbsp; Crear dirección técnica
          </button>
        ) : null} */}
        <TableList
          deleteDirection={deleteDirection}
          setDataEditDirection={openModalEdit}
          loader={loader}
          data={data}
          page={page}
          data_item={data_item}
          setIsEdit={setIsEdit}
          setOpenModal={setOpenModal}
        />
      </div>
    </div>
  );
};

export default ListTechnicalDirections;
