import _ from "lodash";
import React, {useEffect, useState} from "react";
import Select, { components } from "react-select";
import Switch from "react-switch";
import classNames from "classnames";
import DayPicker from "../DayPicker";
import Async from "react-select/async";
import DatePicker from "react-date-picker";
import Creatable from "react-select/creatable";
import NumberFormat from "react-number-format";
import FileUploader from "../FileUploader/FileUploader";
import repeatpasswordfailed from "../../../../../assets/img/icons/repeatpasswordfailed.png";
import moment from "moment";
import calendarSvg from "../../../../../assets/img/icons/calendarIcon.svg";
import {SketchPicker} from "react-color"
import MoreInfo from "../Tooltip";

export const colorPicker = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  initialColor = null,
  ...custom
}) => {
  const [color, setColor] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorPickerPosition, setColorPickerPosition] = useState({
    top: 0,
    left: 0,
  });


  const handleClick = (e) => {
    e.preventDefault();
    setDisplayColorPicker(!displayColorPicker);
    setColorPickerPosition({
      top: e.target.offsetTop + 30,
      left: e.target.offsetLeft,
    });
  };

  const handleChange = (color) => {
    setColor(color.hex);
    input.onChange(color.hex);
  };

  useEffect(() => {
    if (input.value && input.value !== color) {
      setColor(input.value);
    }
  }, [input.value]);

  useEffect(() => {
    if (custom.typeForm == "create" && initialColor) {
      initialColor("color", "#777FF3");
      setColor("#777FF3");
    }
  }, [])

  return (

    <React.Fragment>
      <button
        className="w-100"
        onClick={handleClick}
        style={{
          backgroundColor: "#FAFDF3",
          border: "1.5px solid #B0A196",
          borderRadius: "20px",
          height: "44.14px",
        }}
      >
        <div className="row justify-content-start ml-2">
          <div
            className="mr-2"
            style={{
              backgroundColor: color,
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
          <span style={{color: "#B0A196"}}>
            Color {color}
          </span>
        </div>
      </button>
      {displayColorPicker ? (
        <div
          className="color-picker__popover"
          style={{
            top: colorPickerPosition.top,
            left: colorPickerPosition.left,
            position: "absolute",
            zIndex: "999",
          }}
        >
          {/* eslint-disable-next-line */}
          {/* <div className="color-picker__cover" onClick={handleClose} onBlur={handleClose} /> */}
          <SketchPicker color={color} onChange={handleChange}/>
        </div>
      ) : null}
    </React.Fragment>

  );
};

export const renderField = ({
  input,
  placeholder,
  typeInput,
  meta,
  disabled = false,
  customClass = "",
  containerClass = "",
  showPassword = false,
  ...rest
}) => {
  const invalid = meta.touched && meta.error;
  const error = meta.error === "Las contraseñas no coinciden";
  const hasIcon = _.isEmpty(input.value); // if input is not empty, show icon

  if (showPassword) {
    typeInput = "text";
  }

  return (
    <div className={"d-flex flex-row " + containerClass}>
      {
        error ? (
          <i
            style={{
              position: "absolute",
              marginLeft: "-5%",
              marginTop: "10px"
            }}
          >
            <img src={repeatpasswordfailed} alt="repeatpasswordfailed"/>
          </i>
        ) : null
      }
      <div className="w-100">
        <input
          {...input}
          {...rest}
          placeholder={placeholder}
          type={typeInput}
          className={classNames("form-control", customClass, {"is-invalid": invalid || error}, {"icon": hasIcon})}
          disabled={disabled}
        />
        {/* </div> */}
        {invalid || error ?
          <div className="invalid-feedback">
            {meta.error}
          </div>
          : null
        }
      </div>
    </div>
  );
};

export const simpleRenderField = ({
  input,
  placeholder,
  typeInput,
  meta,
  disabled = false,
  customClass = "",
  showPassword = false,
  ...rest
}) => {
  const invalid = meta.touched && meta.error;
  const hasIcon = _.isEmpty(input.value); // if input is not empty, show icon

  if (showPassword) {
    typeInput = "text";
  }

  return (
    <div className="w-100">
      <input
        {...input}
        {...rest}
        placeholder={placeholder}
        type={typeInput}
        className={classNames("form-control", customClass, {"is-invalid": invalid}, {"icon": hasIcon})}
        disabled={disabled}
      />
      {invalid && (
        <div className="invalid-feedback">
          {meta.error}
        </div>
      )}
    </div>
  );
};

export const renderTextArea = ({input, placeholder, rows, meta: {touched, error},}) => {
  const [count, setCount] = useState(0);

  const invalid = touched && error;

  return (
    <div>
      <textarea
        {...input}
        type="text"
        placeholder={placeholder}
        style={{resize: "none"}}
        rows={rows || 3}
        maxLength={200}
        onChange={(e) => {
          setCount(e.target.value.length);
          input.onChange(e);
        }}
        className={classNames("form-control", {"is-invalid": invalid})}
      />
      <div className="d-flex flex-row justify-content-end">
        <span style={{color: "#B0A196"}}>{count}/200</span>
      </div>
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderNumber = ({
  input,
  customDecimalScale,
  placeholder,
  meta: { touched, error },
  prefix = "",
  suffix = "",
  thousandSeparator = ",",
  numberFormat,
  maxLength,
  disabled,
  className = "",
  containerClass = "",
  maxValue,
  acceptNegative = true,
}) => {

  const invalid = touched && error;
  return (
    <div className={containerClass}>
      <NumberFormat
        name={input.name}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        placeholder={placeholder}
        disabled={disabled}
        className={classNames("form-control", className, {"is-invalid": invalid})}
        decimalScale={customDecimalScale || 0}
        format={numberFormat}
        fixedDecimalScale
        value={input.value}
        maxLength={maxLength}
        prefix={prefix}
        suffix={suffix}
        thousandSeparator={thousandSeparator}
        onValueChange={(values) => {
          input.onChange(values.value);
        }}
        allowNegative={acceptNegative}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderCurrency = ({input, meta: {touched, error}, prefix = "Q ", placeholder,}) => {
  const invalid = touched && error;
  return (
    <div>
      <NumberFormat
        className={classNames("form-control", {"is-invalid": invalid})}
        decimalScale={2}
        fixedDecimalScale
        placeholder={placeholder}
        value={input.value}
        thousandSeparator
        prefix={prefix}
        onValueChange={(values) => {
          input.onChange(values.value);
        }}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export const renderSwitch = ({
  input,
  label,
  disabled,
  customAction,
  customClass = "",
  customClassCon = "",
  offColor = "#B0B0B0",
  onColor = "#0A4725",
  checkedIcon = true,
  uncheckedIcon = true,
  width = 40,
  height = 18,
  meta: { touched, error },
  customStyles = {},
}) => {
  const invalid = touched && error;
  return (
    <div className={classNames("d-flex align-items-center", customClassCon)} style={customStyles}>
      <Switch
        width={width}
        height={height}
        onColor={onColor}
        offColor={offColor}
        disabled={disabled}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        className={classNames(customClass)}
        onChange={(checked) => {
          input.onChange(checked);
          if (customAction) customAction(checked)
        }}
        checked={input.value ? input.value : false}
      // id="normal-switch"
      />
      &nbsp;{label}
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

// export const renderFieldCheck = ({input, label, value, disabled, type, meta: {touched, error}}) => {
//   const invalid = touched && error;
//   return (
//     <React.Fragment>
//       <div className="checkbox c-checkbox">
//         <label className="needsclick">
//           <input
//             type="checkbox"
//             disabled={disabled}
//             {...input}
//             className={classNames("", {"is-invalid": invalid})}
//           />
//           <span className="fa fa-check"/>
//           &nbsp;{label}
//         </label>
//       </div>
//       {invalid && (
//         <div className="invalid-feedback">
//           {error}
//         </div>
//       )}
//     </React.Fragment>
//   )
// };

export const SimpleRenderFieldCheck = ({
  input,
  label,
  value,
  disabled,
  type,
  meta: { touched, error },
  customClass = "",
}) => {
  const invalid = touched && error;
  return (
    <React.Fragment>
      <div className="checkbox c-checkbox">
        <label className="needsclick">
          <input
            {...input}
            type="checkbox"
            disabled={disabled}
            onChange={(e) => {
              input.onChange(!input.value);
            }}
            checked={input.value ? input.value : false}
            className={classNames("", customClass, { "is-invalid": invalid })}
          />
          <span className="fa fa-check" />
          &nbsp;{label}
        </label>
      </div>
      {invalid && <div className="invalid-feedback">{error}</div>}
    </React.Fragment>
  );
};

export const customRenderFieldCheck = ({
  input,
  label,
  value,
  disabled,
  type,
  meta: { touched, error },
  withPharagraph = false,
  customClass = "",
}) => {
  const invalid = touched && error;

  let label1 = "";
  let label2 = "";

  if (label.includes("-") && withPharagraph) {
    const pharagraph = label.split("-");
    label1 = pharagraph[0];
    label2 = pharagraph[1];
  }

  return (
    <React.Fragment>
      <div className="checkbox c-checkbox">
        <label className="needsclick d-flex flex-row">
          <input
            {...input}
            type="checkbox"
            disabled={disabled}
            onChange={(e) => {
              input.onChange(!input.value);
            }}
            checked={input.value ? input.value : false}
            className={classNames("", customClass, { "is-invalid": invalid })}
          />
          <span className="fa fa-check" />
          &nbsp;
          {
            withPharagraph ? (
              <div className="container-fluid d-flex flex-column" style={{ marginTop: "-1%" }}>
                <label>
                  {label1}
                </label>
                <label>
                  - {label2}
                </label>
              </div>
            )
              : (
                `${label}`
              )
          }
        </label>
      </div>
      {invalid && <div className="invalid-feedback">{error}</div>}
    </React.Fragment>
  );
};

export const renderFieldCheck = ({
  input,
  label,
  withLabel = true,
  value,
  disabled,
  type,
  meta: { touched, error },
  customClass = "",
}) => {
  const invalid = touched && error;
  return (
    <React.Fragment>
      <div className="checkbox c-checkbox">
        <label className="needsclick">
          <input
            {...input}
            type="checkbox"
            disabled={disabled}
            onChange={(e) => {
              input.onChange(!input.value);
            }}
            checked={input.value ? input.value : false}
            className={classNames("", customClass, { "is-invalid": invalid })}
          />
          <span className="fa fa-check" />
          {
            withLabel ? (
              <span>
                &nbsp;
                {label}
              </span>
            )
              : null
          }
          {/* &nbsp;{label} */}
        </label>
      </div>
      {invalid && <div className="invalid-feedback">{error}</div>}
    </React.Fragment>
  );
};

export const renderFieldRadio = ({ input, label, value, disabled, meta: { touched, error } }) => {
  const invalid = touched && error;
  return (
    <React.Fragment>
      <div className="radio c-radio c-radio-nofont d-flex">
        <label className="negro font-weight-normal">
          <input
            type="radio"
            disabled={disabled}
            {...input}
            className={classNames("", { "is-invalid": invalid })}
          />
          <span />
          &nbsp;{label}
        </label>
      </div>
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </React.Fragment>
  )
};

export const customStylesDefault = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "#959595"
        : isSelected
          ? "#E2DDD9"
          : isFocused
            ? "#EAE8E6"
            : undefined,
      color: "#4B4D58",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#E2DDD9"
            : "EAE8E6"
          : undefined,
      }
    }
  },
  menu: style => ({
    ...style,
    background: "#FAFDF3",
    fontSize: "16px !important",
    borderRadius: "8px",
    opacity: 1,
    marginTop: 1,
  }),
  control: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    "&:hover": {
      border: "2px solid #888A96 !important",
    },
    color: isDisabled ? "#959595" : "#4B4D58",
    cursor: isDisabled ? "not-allowed" : "default",
    backgroundColor: isDisabled ? "#E3E3E3" : "#FAFDF3",
    border: isFocused ? "2px solid #888A96 !important" : "1.5px solid #888A96",
    boxShadow: "2px solid #888A96 !important",
    textAlign: "left",
    borderRadius: "20px",
    fontSize: 16,
    fontWeight: 400,
    opacity: 1,
    marginTop: 0,
    paddingTop: 0,
    paddingLeft: 10,
    paddingBottom: 2,
    font: "Roboto !important",
    minHeight: 25,
    marginBottom: 0,
  }),
};
export const DropdownIndicator = props => {
  return (
    <div className="d-flex justify-content-between flex-column">
      <span className="material-icons pr-2 icon-select-indicator">expand_less</span>
      <span className="material-icons pr-2 icon-select-indicator">expand_more</span>
    </div>
  )
};

export const IndicatorSeparator = props => {
  return (
    <div className="d-none">
    </div>
  )
};
export const selectField = (
  {
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options,
    placeholder,
    customAction,
    labelKey = "label",
    valueKey = "value",
    customClass = "",
    customStyles = null,
    backspaceRemovesValue = false,
    meta: { touched, error },
    defaultSelectValue,
  }) => {
  const invalid = touched && error;
  const [value, setValue] = React.useState(null)

  let _options = [];
  options.forEach(option => {
    _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
  });

  useEffect(() => {
    setValue(null)
  }, [])

  useEffect(() => {
    if (!touched && ((input.value && (`${input.value}`.length > 0)) || Number(input.value) === 0)) {
      const newValue = _.find(_options, { value: input.value })
      setValue(newValue ? newValue : null);
    } else if ((input.value === null || input.value === undefined || input.value === "") && value) {
      setValue(null);
    }
  }, [input.value])

  useEffect(() => {
    const newOpt = options;
    if (newOpt.length && (input.value && `${input.value}`.length > 0) && value == null) {
      const find = {}
      find[valueKey] = input.value
      const finalValue = _.find(newOpt, find)
      if (finalValue !== undefined) {
        setValue({value: finalValue[valueKey], label: finalValue[labelKey]})
      }
    }
    if (options.length === 0) {
      if (input.value && value !== null) {
        setValue(null)
        input.onChange(null)
      }
    }
  }, [options])

  return (
    <React.Fragment>
      <Select
        value={value}
        onBlur={input.onBlur}
        onFocus={input.onBlur}
        noOptionsMessage={() => "sin opciones"}
        components={{DropdownIndicator, IndicatorSeparator}}
        styles={customStyles ? customStyles : customStylesDefault}
        isMulti={isMulti}
        options={_options}
        isDisabled={disabled}
        placeholder={placeholder}
        isClearable={isClearable}
        backspaceRemovesValue={backspaceRemovesValue}
        isSearchable={isSearchable}
        defaultValue={defaultSelectValue}
        onChange={(e) => {
          input.onChange(e ? e[valueKey] : null);
          setValue(e ? e : null);
          // if (setSelectedValue) {
          //   setSelectedValue(e);
          // }
          customAction && customAction(e ? e[valueKey] : null);
        }}
        className={classNames("react-select-container", customClass, { "is-invalid": invalid })}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </React.Fragment>
  )
};


export const AsyncSelectField = (
  {
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    labelKey = "label",
    valueKey = "value",
    placeholder,
    customStyles,
    defaultOptions = [],
    defaultSelectValue,
    customAction,
    customClass = "",
    removeValue=false,
    extendInformation=false,
    identify,
    extraData1,
    extraData2,
    meta: { touched, error },
  }) => {
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [withTooltip, setWithTooltip] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const invalid = touched && error;

  const Option = (props) => {
    const { data } = props;

    return (
      <MoreInfo
        anchorId={data[valueKey]+identify}
        content={
          <div className="d-flex flex-column">
            <span>
              <b>{data[extraData1]}: </b>
            </span>
            <span>
              <b>{data[extraData2]}</b>
            </span>
          </div>
        }
      >
        <components.Option {...props} />
      </MoreInfo>
    );
  };

  useEffect(() => {
    setLoading(false)
    setSelectedValue(null)
    if (extendInformation) {
      setWithTooltip({
        Option: Option,
      })
    }
  }, [])

  useEffect(() => {
    if (!!loadOptions && (input.value !== null && input.value !== undefined && input.value !== "") && !loading && selectedValue === null) {
      setLoading(true)
      loadOptions().then((data) => {
        setSelectedValue(data.find(element => element[valueKey] === input.value))
        setLoading(false)
      })
    } else if (!!loadOptions && input.value === undefined) {
      setSelectedValue(null)
    }
  }, [loadOptions, input.value])

  useEffect(() => {
    if (defaultSelectValue !== undefined && defaultSelectValue !== null) {
      setSelectedValue(defaultSelectValue)
    }
  }, [defaultSelectValue])

  useEffect(() => {
    if (removeValue) {
      setSelectedValue(null)
      input.onChange(null)
    }
  }, [removeValue])

  return (
    <React.Fragment>
      <Async
        cacheOptions
        defaultOptions={defaultOptions}
        // defaultValue={defaultSelectValue}
        components={{
          DropdownIndicator, 
          IndicatorSeparator,
          ...withTooltip
        }}
        styles={customStyles ? customStyles : customStylesDefault}
        value={selectedValue}
        // defaultValue={input.value}
        getOptionLabel={e => e[labelKey]}
        getOptionValue={e => e[valueKey]}
        isDisabled={disabled}
        loadOptions={loadOptions}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
      
        

        onChange={(e) => {
          setSelectedValue(e)
          if (customAction) {
            customAction(e)
          }
          input.onChange(e ? e[valueKey] : null);
        }}
        className={classNames("react-select-container", customClass, {"is-invalid": invalid})}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </React.Fragment>
  )
};

export const CreatableSelectField = (
  {
    input,
    disabled,
    isClearable,
    isSearchable,
    options,
    placeholder,
    labelKey = "label",
    valueKey = "value",
    backspaceRemovesValue = false,
    meta: { touched, error }
  }) => {

  const invalid = touched && error;

  return (
    <React.Fragment>
      <Creatable
        value={input.value}
        options={options}
        isDisabled={disabled}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        getOptionValue={e => e[valueKey]}
        getOptionLabel={e => e[labelKey]}
        backspaceRemovesValue={backspaceRemovesValue}
        onChange={(e) => {
          input.onChange(e ? e : null);
        }}
        className={classNames("react-select-container", { "is-invalid": invalid })}
      />
      {invalid && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </React.Fragment>
  )
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({ photo, accept = "/*", setFile, className, disabled, input, meta: { touched, error }, deleteBackground, setDeleteBackground }) => {
  const invalid = touched && error;
  return (
    <div className={classNames(`${className}`, { "is-invalid": invalid })}>
      <FileUploader
        disabled={disabled}
        deleteBackground={deleteBackground}
        setDeleteBackground={setDeleteBackground}
        accept={accept}
        img={photo ? photo : null}
        onFileChange={(e, file) => {
          file = file || e.target.files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            input.onChange(reader.result);
            if (setFile) {
              setFile(file);
            }
          };
          reader.readAsDataURL(file);
        }} />
      {invalid && <div className="invalid-feedback">
        {error}
      </div>}
    </div>
  )
};

export const renderDayPicker = ({ className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
  const invalid = touched && error;
  return (
    <div className={classNames(`${className}`, { "is-invalid": invalid })}>
      <DayPicker
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        onChange={e => input.onChange(e)}
        value={input.value}
        input={input}
      />
      {invalid && <div className="invalid-feedback">
        {error}
      </div>}
    </div>
  )
};

export const CalendarIcon = (props) => {
  return (
    <div>
      <img src={calendarSvg} alt="" width={20} height={21} />
    </div>
  )
}
export const renderDatePicker = (
  {
    classNameConn = "",
    customAction,
    customClass,
    disabled,
    withTime,
    maxDate,
    minDate,
    input,
    other,
    meta: { touched, error }
  }) => {
  let invalid = touched && error;
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (input.value && `${input.value}`.length && value === null) {
      // convert the date using new Date but do not subtract a day from it
      let date = input.value instanceof Date ? input.value : new Date(input.value + "T00:00:00")
      setValue(date)
    } else if (!touched && !input.value) {
      setValue(null)
    }
  }, [input.value])

  const changeAction = (valueDate) => {
    setValue(valueDate)
    if (valueDate) {
      let finalValueDate = ""
      if(withTime){
        valueDate.setHours(new Date().getHours())
        valueDate.setMinutes(new Date().getMinutes())
        finalValueDate = new Date(valueDate)
      } else {
        finalValueDate = moment(valueDate).format("YYYY-MM-DD")
      }
      input.onChange(finalValueDate)
      if (customAction) customAction(finalValueDate)
    } else {
      input.onChange(valueDate)
      if (customAction) customAction(valueDate)
    }
  }

  return (
    <div className={classNames(`${classNameConn}`)}>
      <DatePicker
        {...other}
        calendarIcon={<CalendarIcon />}
        format={"dd/MM/yyyy"}
        dayPlaceholder={"dd"}
        monthPlaceholder={"mm"}
        yearPlaceholder={"yyyy"}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        name={input.name}
        className={classNames(`form-control ${customClass}`, { "is-invalid": invalid })}
        onChange={changeAction}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        value={value}
      />
      {invalid && <div className="invalid-feedback mt-1">
        {error}
      </div>}
    </div>
  )
};

export const RenderQuantity = (
  {
    callBackChange,
    placeholder = "",
    initialValue=0,
    minValue=0,
    disabled,
    customClassName = "w-75 mx-auto"
  }) => {

  const [localValue, setLocalValue] = useState(initialValue)

  function decreasesValue() {
    const newValue = parseInt(localValue) - 1
    if (newValue > 0) {
      setLocalValue(newValue)
      callBackChange && callBackChange(newValue)
    } else if (newValue <= 0) {
      setLocalValue(minValue)
      callBackChange && callBackChange(minValue)
    }
  }

  function increaseValue() {
    const newValue = parseInt(localValue) + 1
    setLocalValue(newValue)
    callBackChange && callBackChange(newValue)
  }


  return (
    <div className={`d-flex justify-content-between border br-20px py-1 ${customClassName}`}>
      <div className="d-flex flex-row align-content-center">
        <button disabled={disabled} type="button" onClick={decreasesValue} className="btn hover-no-drop px-2 py-0">
          <span className="material-icons text-dark">remove</span>
        </button>
      </div>
      <div>
        <NumberFormat
          className={classNames("form-control br-0 bw-1px py-0", {"is-invalid": (localValue === "")})}
          decimalScale={0}
          fixedDecimalScale
          allowNegative={false}
          placeholder={placeholder}
          value={localValue}
          disabled={disabled}
          thousandSeparator
          onValueChange={(values) => {
            setLocalValue(values.value);
            callBackChange && callBackChange(values.value)
          }}
        />
      </div>
      <div className="d-flex flex-row align-content-center">
        <button disabled={disabled} type="button" onClick={increaseValue} className="btn hover-no-drop px-2 py-0">
          <span className="material-icons text-dark">add</span>
        </button>
      </div>
    </div>
  )
}

export const RenderField = {
  renderField,
  renderTextArea,
  renderNumber,
  renderCurrency,
  renderSwitch,
  renderFieldCheck,
  renderFieldRadio,
  selectField,
  AsyncSelectField,
  customRenderFieldCheck,
  // renderFieldPassword,
  simpleRenderField,
  SimpleRenderFieldCheck,
  colorPicker,
};
