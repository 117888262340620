import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import { addIndexToArray } from "../../../../utility/constants";

const BANK_DATA = "BANK_DATA";
const BANK_LIST = "BANK_LIST";
const ORIGIN_BANK_LIST = "ORIGIN_BANK_LIST";
const DESTINATION_BANK_LIST = "DESTINATION_BANK_LIST";
const LOADER = "BANK_LOADER";
const PAGE = "BANK_PAGE";
const ORDERING = "BANK_ORDERING";
const SEARCH = "BANK_SEARCH";

export const constants = {
  BANK_DATA,
  BANK_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setBankData = data => ({
  type: BANK_DATA,
  data,
});

export const setBankList = data => ({
  type: BANK_LIST,
  data,
});

export const setOriginBanksList = data => ({
  type: ORIGIN_BANK_LIST,
  data,
});

export const setDestinationBanksList = data => ({
  type: DESTINATION_BANK_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

const setSearch = search => ({
  type: SEARCH,
  search,
});

// ------------------------------------
// Actions
// ------------------------------------

const getBanks = (page = 1) => (dispatch, getStore) => {
  const { banks } = getStore();
  const params = { page };
  params.ordering = banks.ordering;
  params.search = banks.search;
  dispatch(setLoader(true));
  api.get("/bank", params)
    .then((response) => {
      if (response.results) {
        response.results = addIndexToArray(response.results, page);
      }
      dispatch(setBankList(response));
      dispatch(setPage(page));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const getBank = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`/bank/${id}`)
    .then((response) => {
      dispatch(setBankData(response));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const getOriginOrDestinationBanks = (type) => (dispatch) => {
  dispatch(setLoader(true));
  api.get("/bank/get_origin_or_destination_banks", { type })
    .then((response) => {
      if (type === "origin") {
        dispatch(setOriginBanksList(response));
      } else {
        dispatch(setDestinationBanksList(response));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const createBank = (data) => (dispatch) => {
  dispatch(setLoader(true));
  api.post("/bank", data)
    .then(() => {
      NotificationManager.success("Banco creado", "Éxito", 3000);
      dispatch(push("/banks"));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const updateBank = (id, data) => (dispatch) => {
  dispatch(setLoader(true));
  api.put(`/bank/${id}`, data)
    .then(() => {
      NotificationManager.success("Banco actualizado", "Éxito", 3000);
      dispatch(push("/banks"));
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const deleteBank = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.eliminar(`/bank/${id}`)
    .then(() => {
      NotificationManager.success("Banco eliminado", "Éxito", 3000);
      dispatch(getBanks());
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const onSortChange = (ordering) => (dispatch, getStore) => {
  const sort = getStore().banks.ordering;
  if (sort === ordering) {
    ordering = `-${ordering}`;
  } else {
    dispatch(setOrdering(ordering));
  }
  dispatch(getBanks());   
};

export const actions = {
  getBanks,
  getBank,
  getOriginOrDestinationBanks,
  createBank,
  updateBank,
  deleteBank,
  setOrdering,
  setSearch,
  onSortChange,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [BANK_DATA]: (state, { data }) => {
    return {
      ...state,
      bankData: data,
    };
  },
  [BANK_LIST]: (state, { data }) => {
    return {
      ...state,
      banksList: data,
    };
  },
  [ORIGIN_BANK_LIST]: (state, { data }) => {
    return {
      ...state,
      originBanksList: data,
    };
  },
  [DESTINATION_BANK_LIST]: (state, { data }) => {
    return {
      ...state,
      destinationBanksList: data,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
};

const initialState = {
  loader: false,
  bankData: {},
  banksList: {
    count: 0,
    results: [],
  },
  originBanksList: [],
  destinationBanksList: [],
  page: 1,
  ordering: "",
  search: "",
};

export default handleActions(reducers, initialState);