import {connect} from "react-redux"
import List from "./List"
import {actions} from "../../../../redux/modules/cashRegister"

const ms2p = (state)=>{
  const {me} = state.login
  return {
    ...state.cashRegister,
    me,
  }
}

const md2p = {...actions}

export default connect(ms2p, md2p)(List)
