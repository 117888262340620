import React, {useState} from "react"
import {Field, Form} from "react-final-form";
import Editor from "../../Stamps/CreateUpdate/Editor";
import {EDITOR_VARIABLE, SHEET_FORMAT} from "../../../../../utility/constants"
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel"
import {renderFilePicker} from "../../../../components/Utils/renderField/renderField"
import {selectField, simpleRenderField, SimpleRenderFieldCheck} from "../../../../components/Utils/renderField"


const FormStep2 = ({prev, onSubmit, show, data}) => {
  const [document, setDocument] = useState("");
  const [customContent, setCustomContent] = useState(false);

  const orientationY = (orientation) => {
    let sizeY = ""
    if (orientation === 1) {
      sizeY = "638px"
    } else {
      sizeY = "561px"
    }
    return sizeY
  }

  const orientationX = (orientation) => {
    let sizeX = ""
    if (orientation === 1) {
      sizeX = "561px"
    } else {
      sizeX = "638px"
    }
    return sizeX
  }

  return <div>
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({handleSubmit, values, form: {change}}) =>
        <form onSubmit={handleSubmit} className={show ? "" : "d-none"}>
          <div
            className="row justify-content-between bg-card-cofaqui br-20px px-lg-5 px-sm-1 px-md-3 py-5 col-lg-11 col-md-12 col-sm-12">
            <div className="px-0 col-sm-12 col-md-12 col-lg-6 col-lx-6">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="mb-0 px-0 mb-md-3 col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="name">Título</label>
                  <Field
                    name="title"
                    typeInput="text"
                    customClass="w-100"
                    placeholder="Certificado XYZ"
                    component={simpleRenderField}
                  />
                </div>
                <div className="px-0 col-sm-12 col-md-12 col-lg-5 col-lx-5">
                  <label htmlFor="document_orientation">Orientación de hoja</label>
                  <Field
                    customClass="w-100"
                    options={SHEET_FORMAT}
                    placeholder="Vertical"
                    component={selectField}
                    name="document_orientation"
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                {
                  EDITOR_VARIABLE.map((ele, index) => {
                    return (
                      <button
                        type="button"
                        key={index}
                        style={{
                          minWidth: "128px",
                          backgroundColor: "transparent",
                        }}
                        className="mt-3 pointer border-caffe text-14 border px-2 py-1 br-10px text-center"
                        onClick={() => {
                          let content = document
                          if (content && content.length > 2) {
                            let initialTag = content.lastIndexOf("</")
                            content = content.substring(0, initialTag) + `<span> {{${ele.value}}} </span>` + content.substring(initialTag, content.length)
                          } else {
                            content = `<span>{{${ele.value}}}</span>`
                          }
                          change("description", content)
                        }}
                        disabled={ele.value === "contenido_personalizado" && customContent}
                      >
                        {ele.label}
                      </button>
                    )
                  })
                }
              </div>
              <div className="mt-3">
                <label htmlFor="editor">Descripción</label>
                <Field
                  component={Editor}
                  name="description"
                  allowResizeY={false}
                  setDocument={setDocument}
                  setCustomContent={setCustomContent}
                />
              </div>
              <div className="d-flex flex-wrap justify-content-between mt-3">
                <div className="px-0 col mr-0 mr-lg-5">
                  <label htmlFor="background">Fondo</label>
                  <Field
                    accept="image/*"
                    name="background"
                    className="icon-picker"
                    photo={data.background}
                    component={renderFilePicker}
                  />
                </div>
                <div className="field-form mt-2 mt-lg-5 mb-0 mb-sm-3 col">
                  <div className="row check-documents">
                    <Field
                      name="print_signature"
                      withPharagraph={true}
                      component={SimpleRenderFieldCheck}
                      customClass="forms-field"
                    />
                    <label htmlFor="dos" className="forms-field-label">
                      ¿Debe llevar firmas electrónicas?
                    </label>
                  </div>
                  <div className="row justify-content-start">
                    <span
                      style={{fontSize: "10px"}}
                    >
                  Nota: debe generar un documento para completar la creación del producto. Si desea generar más documentos puede hacerlo desde la sección correspondiente.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/*--------------------------------------------------------------*/}
            {/*-------------------         PREVIOUS          ----------------*/}
            {/*--------------------------------------------------------------*/}
            <div className="col-sm-12 col-md-12 col-lg-6 col-lx-6 px-0" style={{overflowX: "auto", maxWidth: "100%"}}>
              <label htmlFor="preview" className="ml-lg-5 ml-md-3 ml-sm-1">Vista previa</label>
              <div
                className="px-0 mx-auto"
                style={{
                  background: "#FAFDF3",
                  border: "1.5px solid #B0A196",
                  borderRadius: "20px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundOrigin: "content-box",
                  wordWrap: "break-word",
                  height: `${orientationY(values.document_orientation)}`,
                  width: `${orientationX(values.document_orientation)}`,
                  backgroundImage: values.background ? `url(${values.background})` : "url(\"\")",
                }}
              >
                <div className="row justify-content-center mt-2">
                  <img
                    src={require("assets/img/logo.png")}
                    style={{
                      width: "100px",
                      height: "100px",
                      zIndex: "1",
                    }}
                    alt="..."
                  />
                  <div className="w-100">
                    <center className="my-1 font-weight-bold">{values.title ? values.title : ""}</center>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{__html: document}}
                />
              </div>
            </div>
          </div>
          <BtnSaveAndCancel
            renderBtnPrev
            renderBtnSubmit
            actionBntPrev={prev}
            customContainer="container justify-content-center w-100 my-4 row"
          />
        </form>
      }
    />
  </div>
}

export default FormStep2
