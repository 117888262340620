import React, { useEffect } from "react";
import Grid from "../../../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import { RenderCurrency } from "../../../../../components/Utils/renderField/renderReadField";
import {addIndexToArray} from "../../../../../../utility/constants"

const Table = ({ dataHistory, loader, page, listar, onSortChange, action, fecha = {title: 'Fecha', name: 'transaction_date'} }) => {
  const expandComponent = (row) => {
    return <BSTable data={addIndexToArray(row.products)} />;
  };

  const BSTable = ({ data }) => {
    if (data) {
      return (
        <div className="col-lg-9 col-md-10 col-sm-12 px-0 mx-auto pb-2 pt-3 bw-1px border-cofaqui">
          <center>
            <h5
              className="font-weight-bold mt-0 pb-2 pt-0"
              style={{ borderBottom: "1px solid #B0A196" }}
            >
              Detalle de Productos y/o timbres
            </h5>
          </center>
          <BootstrapTable
            data={data}
            hover={false}
            tableHeaderClass="table-header-cofaqui-details"
          >
            <TableHeaderColumn
              isKey
              width="150px"
              dataField="order"
              dataAlign="center"
            >
              No.
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              dataFormat={(cell, row) => {
                let name = row.product__name;
                if (!name) {
                  name = row.stamp__name;
                }
                return <span>{name}</span>;
              }}
            >
              Nombre
            </TableHeaderColumn>
            <TableHeaderColumn dataAlign="center" dataField="quantity">
              Cantidad
            </TableHeaderColumn>
            <TableHeaderColumn
              width="210px"
              dataAlgn="right"
              dataField="amount"
              dataFormat={(cell) => <RenderCurrency value={cell ? cell : 0} />}
            >
              Precio Uni.
            </TableHeaderColumn>
            <TableHeaderColumn
              width="210px"
              dataAlgn="right"
              dataField="total"
              dataFormat={(cell, row) => {
                let total = parseInt(row.quantity) * parseFloat(row.amount);
                return <RenderCurrency value={total} />;
              }}
            >
              Total
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    } else {
      return <p>?</p>;
    }
  };

  const rowClassName = (row, rowIdx) => {
    let trClassName = "td-column-even pointer"
    if (row && row.cancelled) {
      trClassName = "td-column-even item-cancelled pointer"
    }
    return trClassName;
  }

  return (
    <div style={{ borderTopLeftRadius: "0" }}>
      <div className=" ">
        <div className="px-4 pt-2">
          <Grid
            loading={loader}
            data={dataHistory}
            page={page}
            onPageChange={listar}
            onSortChange={onSortChange}
            expandableRow={() => true}
            expandComponent={expandComponent}
            trClassName={rowClassName}
            bodyContainerClass = "bc-professions-custom"
            headerContainerClass = "hc-professions-custom"
            tableContainerClass = "professions-container-table-sm"
            tableHeaderClass = "table-header-professions"
          >
            <TableHeaderColumn
              width="80px"
              dataAlign="center"
              dataField="order"
              isKey={true}
            >
              No.
            </TableHeaderColumn>

            <TableHeaderColumn
              width="200px"
              dataAlign="center"
              dataField={fecha.name}
              dataFormat={(cell, row) => {
                let dateStr = "";
                if (cell) {
                  dateStr = moment(cell).format("DD/MM/YYYY  HH:mm");
                }
                return <span>{dateStr}</span>;
              }}
            >
              {fecha.title}
            </TableHeaderColumn>

            <TableHeaderColumn
              width="330px"
              dataAlign="center"
              dataField="products"
              dataFormat={(cell) => {
                let productsName = "";
                if (cell) {
                  cell.map((item, index) => {
                    let separator = ", ";
                    if (cell.length == index + 1) {
                      separator = ".";
                    }
                    if (item.product__name) {
                      productsName += item.product__name + separator;
                    } else if (item.stamp__name) {
                      productsName += item.stamp__name + separator;
                    }
                  });
                }
                return <span>{productsName}</span>;
              }}
            >
              Descripción
            </TableHeaderColumn>

            <TableHeaderColumn
              width="200px"
              dataAlign="center"
              dataField="type_of_income"
              thStyle={{ paddingRight: "50px" }}
              tdStyle={{ paddingRight: "50px" }}
            >
              Tipo de ingreso
            </TableHeaderColumn>

            <TableHeaderColumn
              width="200px"
              dataAlign="center"
              dataField="member"
              dataFormat={(cell, row) => {
                try {
                  return cell.first_name + " " + cell.last_name;
                } catch (e) {
                  return "";
                }
              }}
              thStyle={{ paddingRight: "50px" }}
              tdStyle={{ paddingRight: "50px" }}
            >
              Agremiado
            </TableHeaderColumn>

            <TableHeaderColumn
              width="200px"
              dataAlign="center"
              dataField="amount"
              thStyle={{ paddingRight: "50px" }}
              tdStyle={{ paddingRight: "50px" }}
              dataFormat={(cell, row) => {
                return (
                  <RenderCurrency
                    className="text-right"
                    value={cell ? cell : 0}
                  />
                );
              }}
            >
              Monto
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="id"
              dataAlign="center"
              width="100px"
              dataFormat={(cell, row) => {
                return (
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      title={action.title}
                      className={action.className}
                      onClick={() => action.action(cell)}
                    >
                      <img src={action.src} alt={action.alt} />
                    </button>
                  </div>
                );
              }}
            >
              Acciones
            </TableHeaderColumn>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Table;
