import React, { useRef, useState } from "react";

const UploadButton = ({ 
  setDocument,
  withId,
  changeIcon,
  customClass, 
  input,
  isUpdate=false,
  subId=null,
  ...rest
}) => {

  const [localName, setLocalName] = useState(null);

  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFile = (value) => {
    const file = value.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // set name
      if (setDocument) {
        setDocument(file.name);
      }
      setLocalName(file.name);
      // set result
      if (withId) {
        input.onChange({
          id: rest.row.id,
          file: reader.result,
          name: file.name,
          update: isUpdate,
          sub_id: subId
        });
      } else {
        input.onChange(reader.result);
      }
    };
  };
        
  return (
    <div className="upload-btn-wrapper">
      <input 
        ref={inputRef}
        type="file" 
        name="myfile" 
        onChange={handleFile}
        style={{ display: "none" }}
        // accept word, excel, pdf
        accept=".docx,.xlsx,.pdf"
      />
      <button 
        className={`${(changeIcon && localName) ? customClass : "actions-btn btn-upload-document"}`} 
        type="button"
        onClick={handleClick}
      >
        {
          changeIcon && localName ? (
            <span
              style={{
                color: "#0A4725"
              }}
            >
              {localName}
            </span>
          )
            :
            (
              <i>
                <img src={require("assets/img/icons/uploadLila.svg")} alt={"upload"}/>
              </i>
            )
        }
      </button> 
    </div>
  );
};

export default UploadButton;