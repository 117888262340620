import React, { useEffect, useState } from "react";

import { INITIAL_STATE } from "../../../../utility/constants";

import icon_no from "../../../../../assets/img/icons/icon_no.svg";
import icon_yes from "../../../../../assets/img/icons/icon_yes.svg";

const colorRed = {
  color: "#E60F0F",
}

const colorGreen = {
  color: "#199147",
}

// funcion para validar la contraseña
const validatePasswords = (password, setValidations, setPasswordValidations) => {
  const hasNumber = /\d/;
  const hasSpecialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const hasUpperCase = /[A-Z]/;

  setValidations({
    password: {
      minLength: password.length >= 8,
      hasNumber: hasNumber.test(password),
      hasSpecialCharacter: hasSpecialCharacter.test(password),
      hasUpperCase: hasUpperCase.test(password),
    },
  })

  setPasswordValidations({
    password: {
      minLength: password.length >= 8,
      hasNumber: hasNumber.test(password),
      hasSpecialCharacter: hasSpecialCharacter.test(password),
      hasUpperCase: hasUpperCase.test(password),
    },
  })
}

// cambiar el color de los textos de acuerdo a la validacion
const changeColor = (passwordValidation) => {

  const validation = passwordValidation;

  if (validation) {
    return colorGreen;
  } else {
    return colorRed;
  }
}

// cambiar el icono de acuerdo a la validacion
const changeIcon = (passwordValidation) => {

  const validation = passwordValidation;

  if (validation) {
    return (
      <img src={icon_yes} className="icon-yes new-password-tips-card-icons" alt="ok" />
    );
  } else {
    return (
      <img src={icon_no} className="icon-no new-password-tips-card-icons" alt="ok" />
    );
  }
}

const PasswordValidationCard = ({
  formState,
  setPasswordValidations,
}) => {

  const { password = "" } = formState;

  const [validations, setValidations] = useState(INITIAL_STATE);

  useEffect(() => {
    validatePasswords(password, setValidations, setPasswordValidations);
  }, [password]);

  return (
    <React.Fragment>
      <span>
        Tu contraseña debe:
      </span>
      {/* validacion de longitud */}
      <span
        className="d-flex flex-row" 
        style={
          changeColor(validations.password.minLength)
        }
      >
        <i>
          {
            changeIcon(validations.password.minLength)
          }
        </i>
        <span>
          Tener al menos 8 caracteres.
        </span>
      </span>
      {/* validacion de mayuscula */}
      <span
        className="d-flex flex-row"
        style={
          changeColor(validations.password.hasUpperCase)
        }
      >
        <i>
          {
            changeIcon(validations.password.hasUpperCase)
          }
        </i>
        <span>
          Tener al menos una mayúscula.
        </span>
      </span>
      {/* validacion de caracter especial */}
      <span className="d-flex flex-row">
        <i>
          {
            changeIcon(validations.password.hasSpecialCharacter)
          }
        </i>
        <span style={
          changeColor(validations.password.hasSpecialCharacter)
        }>
          Tener al menos un carácter especial !&quot;#$%&/(),.
        </span>
      </span>
      {/* validacion de numero */}
      <span style={
        changeColor(validations.password.hasNumber)
      }>
        <i>
          {
            changeIcon(validations.password.hasNumber)
          }
        </i>
        Tener al menos un  número
      </span>
    </React.Fragment>
  );
};

export default PasswordValidationCard;