import {connect} from "react-redux";
import {actions} from "../../../../../redux/modules/user";
import { actions as Member } from "../../../../../redux/modules/member";
import { actions as ActionDept } from "../../../../../redux/modules/department";
const {changeCurrentStep, changeTotalStep}= Member;
import Create from "./Create";


const ms2p = (state) => {
  return {
    ...state.user,
    ...state.department,
    ...state.form,
  }
};

const md2p = {...actions, ...ActionDept, changeCurrentStep, changeTotalStep};

export default connect(ms2p, md2p)(Create);
