import React from "react";
import Modal from "react-responsive-modal"
import iconSuccess from "../../../../../assets/img/icons/iconsuccess.svg";

const ModalSuccess = ({openModal, closeModalAct, actionBtn, title = "", msg = ""}) => {

  return (
    <div>
      <Modal
        center
        open={openModal}
        styles={{
          modal: {
            width: "410px",
            maxWidth: "90%",
            padding: "2rem 0",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: '#B0A196'
          }
        }}
        closeOnOverlayClick={false}
        onClose={closeModalAct}>
        <div className="">
          <div className="col-12 text-center">
            <h5 className="text-center font-weight-bold">
            {title}
            </h5>
          </div>
          <div className="col-12 d-flex flex-row justify-content-center align-content-center py-3">
            <img src={iconSuccess} alt="icon success"/>
          </div>
          <div className="col-12 text-center px-0 my-3">
            <span className="text-center" style={{color: "#4B4D58", fontSize: "16px"}}>
            {msg}
            </span>
          </div>
          <div className="col-12 d-flex flex-row justify-content-center align-content-center">
            <button type="button" className="btn btn-create col-lg-7 col-md-7 col-sm-12" onClick={actionBtn}>
              Aceptar
            </button>
          </div>
        </div>
      </Modal>

    </div>
  )
}

export default ModalSuccess
