import React from "react";
import CustomCard from "../CustomCard";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import ReduxConnection from "../../../../../components/ReduxFormConn/FinalFormConnection";
import { Form } from "react-final-form";
import BtnSaveAndCancel from "../../../../../components/layout/BtnSaveAndCancel";
import validate from "../validations";
import Tabs, { TabPane } from "rc-tabs";

import { NotificationManager } from "react-notifications";

// import { Field } from "react-final-form";

const values = {
  documents: [
    {
      name: null,
      type: null,
    }
  ],
};

const buttonProps = (form, prevStep) => {
  if (form.type == "edit") {
    return {
      renderLinkCancel: true,
      redirectToLink: "/stamps",
      renderBtnSubmit: true,
    };
  } else {
    return {
      renderBtnNext: true,
      renderBtnPrev: true,
      actionBntPrev: prevStep,
    };
  }
};


const Step2 = ({
  // form: {
  //   mutators: { push },
  // },
  type,
  // updateNumberDocuments,
  // numberDocuments,
  nextStep,
  prevStep,
  step,
  form,
  edit,
  initialValues,
  createOrUpdateStamp,
  match,
}) => {

  // const [document, setDocument] = useState([])

  // useEffect(() => {
  //   if (type === "create" && numberDocuments < 1) {
  //     push("documents", {
  //       name: null,
  //       type: null,
  //     });
  //     updateNumberDocuments(1);
  //   }
  // }, []);

  const addDocument = (push) => {
    // setDocument([...document, { id: document.length + 1 }]);
    push("documents", {
      name: null,
      type: null,
      archive: null,
    });
  };

  const removeDocument = (id, fields, documents) => {
    // setDocument(document.filter((item) => item.id !== id));
    if (documents.length === 1) {
      return NotificationManager.error(
        "Debe tener al menos un documento",
        "Error",
        3000
      );
    }
    fields.remove(id);
  };

  const addForm = (push) => {
    // setDocument([...document, { id: document.length + 1 }]);
    push("forms", {
      name: null,
      type: null,
      archive: null,
    });
  };

  const removeForm = (id, fields, forms) => {
    // setDocument(document.filter((item) => item.id !== id));
    if (forms.length === 1) {
      return NotificationManager.error(
        "Debe tener al menos un formulario",
        "Error",
        3000
      );
    }
    fields.remove(id);
  };

  return (
    <Form
      onSubmit={
        type == "edit" ? (values) => {
          const { id } = match.params;
          createOrUpdateStamp(id, values);
        } :
          nextStep
      }
      initialValues={
        type == "edit" ?
          initialValues["documents"] :
          values
      }
      validate={(values) => validate(values, 2, edit ? "edit" : "create")}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form: { mutators: { push }, change } ,
        values: { documents, forms },
        values
      }) => (
        <form 
          onSubmit={handleSubmit} 
          className={`${step === 2 && form.step == "create" || (form.type == "edit" && form.step == 2) ? "d-block" : "d-none"} cash-container`}
        >
          <ReduxConnection form="stamps_step_2" />
          {
            type == "edit" ?
              <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ background: "transparent" }}
              >
                <TabPane tab="Documentos requeridos" key="1">
                  <div className="w-100 p-3 p-lg-5 stamps-form">
                    <h5
                      style={{
                        color: "#4B4D58",
                        fontWeight: "500",
                      }}
                    >
                      Agregar documentos
                    </h5>
                    <FieldArray
                      name="documents"
                    >
                      {
                        ({ fields }) => (
                          fields.map((name, index) => (
                            <CustomCard
                              key={name}
                              id={index}
                              name={name}
                              removeDocument={() => removeDocument(index, fields, documents)}
                            />
                          ))
                        )
                      }
                    </FieldArray>
                    <div className="row col-12 justify-content-center mt-4">
                      <button
                        type="button"
                        className="btn btn-add-document"
                        onClick={() => addDocument(push)}
                      >
                        <i className="icon-plus">
                          <img src={require("assets/img/add_circle_green.svg")} alt="icono-mas" />
                        </i>
                        <span
                          style={{
                            color: "#0A4725",
                          }}
                        >
                          &nbsp;
                          Añadir documento
                        </span>
                      </button>
                    </div>
                  </div>
                </TabPane>
                {/* Tab para formularios */}
                <TabPane tab="Formularios requeridos" key="2">
                  <div className="w-100 p-3 p-lg-5 stamps-form">
                    <h5
                      style={{
                        color: "#4B4D58",
                        fontWeight: "500",
                      }}
                    >
                      Agregar Formularios
                    </h5>
                    <FieldArray
                      name="forms"
                    >
                      {
                        ({ fields }) => (
                          fields.map((name, index) => (
                            <CustomCard
                              key={name}
                              id={index}
                              name={name}
                              withArchive={true}
                              otherTypes={true}
                              activateTitle={true}
                              removeDocument={() => removeForm(index, fields, forms)}
                              change={change}
                            />
                          ))
                        )
                      }
                    </FieldArray>
                    <div className="row col-12 justify-content-center mt-4">
                      <button
                        type="button"
                        className="btn btn-add-document"
                        onClick={() => addForm(push)}
                      >
                        <i className="icon-plus">
                          <img src={require("assets/img/add_circle_green.svg")} alt="icono-mas" />
                        </i>
                        <span
                          style={{
                            color: "#0A4725",
                          }}
                        >
                          &nbsp;
                          Añadir formulario
                        </span>
                      </button>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
              :
              // En la creacion solo se muestra esto 
              <div className="w-100 p-3 p-lg-5 stamps-form">
                <h5
                  style={{
                    color: "#4B4D58",
                    fontWeight: "500",
                  }}
                >
                Agregar documentos
                </h5>
                <FieldArray
                  name="documents"
                >
                  {
                    ({ fields }) => (
                      fields.map((name, index) => (
                        <CustomCard
                          key={name}
                          id={index}
                          name={name}
                          removeDocument={() => removeDocument(index, fields, documents)}
                        />
                      ))
                    )
                  }
                </FieldArray>
                <div className="row col-12 justify-content-center mt-4">
                  <button
                    type="button"
                    className="btn btn-add-document"
                    onClick={() => addDocument(push)}
                  >
                    <i className="icon-plus">
                      <img src={require("assets/img/add_circle_green.svg")} alt="icono-mas" />
                    </i>
                    <span
                      style={{
                        color: "#0A4725",
                      }}
                    >
                    &nbsp;
                    Añadir documento
                    </span>
                  </button>
                </div>
              </div>
          }
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          <BtnSaveAndCancel
            // renderBtnNext={true}
            // renderBtnPrev={true}
            // actionBntPrev={prevStep}
            {
            ...buttonProps(form, prevStep)
            }
          />
        </form>
      )}
    />
  );
}

export default Step2;
