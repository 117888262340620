import React from "react";
import {Form, Field} from "react-final-form";
import {renderField, AsyncSelectField} from "../../../../components/Utils/renderField";

// eslint-disable-next-line
import {api} from "api";
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";

const required = (value) => (value ? undefined : "Este campo es requerido");

const getProfessionsPartOfUnion = async (search) => {
  let options = [];
  const response = await api.get("/profession/get_professions_part_of_union", {search});
  if (response.results) options = response.results;
  return options;
};

const ManagementsForm = ({
  createOrUpdate,
  data = {},
  loader,
  type,
  professions,
}) => {
  const onSubmit = (values) => {
    if (type === "create") {
      createOrUpdate(values);
    } else {
      createOrUpdate(data.id, values);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({handleSubmit}) => (
        <form
          onSubmit={handleSubmit}
          className="py-5 px-lg-5 px-md-3 px-sm-01 px-xl-5 universities-form d-flex flex-column"
        >
          <LoadMask blur dark loading={loader} >
            <div className="row d-flex flex-row">
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="name"
                >
                Nombre de la gestión
                </label>
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder="Nombre de la universidad"
                  validate={required}
                />
              </div>
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="profession"
                >
                Profesión
                </label>
                <Field
                  name="profession"
                  defaultSelectValue={data.profession}
                  defaultOptions={professions}
                  component={AsyncSelectField}
                  placeholder="Profesión"
                  labelKey = "name"
                  valueKey = "id"
                  validate={required}
                  loadOptions={getProfessionsPartOfUnion}
                />
              </div>
            </div>
            <div className="mt-4 container-fluid">
              <BtnSaveAndCancel
                renderBtnSubmit={true}
                renderLinkCancel={true}
                redirectToLink={"/management"}
                subContainer={"px-0 col-lg-10 col-md-10 col-sm-12 row justify-content-center"}
              />
            </div>
          </LoadMask>
        </form>
      )}
    />
  );
};

export default ManagementsForm;
