import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import Grid from "../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import PageTitle from "../../../components/layout/PageTitle";
import {standardActions} from "../../../components/Utils/Grid/StandardActions";

const TableList = (props) => {
  const {
    deleteMasters,
    setDataEditMaster,
    loader,
    data,
    page,
    data_item
  } = props;

  return (
    <div className="col-12 px-1">
      {data_item && data_item.id ? (
        <Fragment>
          <PageTitle
            colTitle="col-lg-7 col-md-7 d-flex flex-row"
            title={`Maestrias de ${data_item.full_name}`}
            withReturn={true}
            returnTo={"/"}
          />
          <div className="mb-4 card card-small bg-card-cofaqui">
            <div className="px-4 py-4">
              <Grid
                hover
                page={page || 1}
                data={
                  data || {
                    results: [],
                    count: 0,
                  }
                }
                loading={loader || false}
                // onPageChange={false}
                // onSortChange={onSortChange || false}
                bodyContainerClass = "bc-professions-custom"
                headerContainerClass = "hc-professions-custom"
                tableContainerClass = "professions-container-table-sm"
                tableHeaderClass = "table-header-professions"
              >
                <TableHeaderColumn
                  dataField="name_institution"
                  dataAlign="center"
                  width="200px"
                  dataFormat={(cell, row) => {
                    return <span>{cell}</span>;
                  }}
                >
                  Nombre del establecimiento
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="name_master"
                  dataAlign="center"
                  width="200px"
                >
                  Direccion del establecimiento
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  width="100px"
                  dataField="id"
                  dataAlign="center"
                  dataFormat={standardActions({
                    eliminar: deleteMasters,
                    editarConAction: setDataEditMaster,
                  })}
                >
                  Acciones
                </TableHeaderColumn>
              </Grid>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="col-12 d-flex justify-content-center align-items-center mt-5">
          <h3 className="text-center">No hay datos para mostrar</h3>
        </div>
      )}
    </div>
  );
};

export default TableList;
