import React from 'react';
import {Form, Field} from "react-final-form";
import {AsyncSelectField} from "../../../../components/Utils/renderField";
import {renderDatePicker} from "../../../../components/Utils/renderField/renderField";


const FiltersBinnacle = ({defaultOp, getUser, changeDateStart, changeDateEnd, changeUserSelected}) => {

  return (
    <div>
      <Form
        onSubmit={() => {
        }}
        initialValues={{dateStart: new Date(), dateEnd: new Date()}}
        subscription={{submitting: true}}
        render={({handleSubmit}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-validate pt-5 pb-2 px-4 shadow-none col-lg-12 col-md-6 col-sm-12">
                <div className="d-flex flex-column flex-md-row">
                  <div className="form-group has-feedback flex-1 mx-sm-0 mx-md-3 mx-lg-5">
                    <label className="text-16 font-weight-bold" htmlFor="userId">Usuario</label>
                    <Field
                      name="userId"
                      valueKey="id"
                      labelKey="full_name"
                      loadOptions={getUser}
                      defaultOptions={defaultOp}
                      customAction={changeUserSelected}
                      component={AsyncSelectField}
                    />
                  </div>
                  <div className="form-group has-feedback flex-1 mx-sm-0 mx-md-3 mx-lg-5">
                    <label className="text-16 font-weight-bold" htmlFor="dateStart">Fecha Inical</label>
                    <Field
                      name="dateStart"
                      other={{clearIcon: null}}
                      maxDate={new Date()}
                      component={renderDatePicker}
                      customAction={changeDateStart}
                    />
                  </div>
                  <div className="form-group has-feedback flex-1 mx-sm-0 mx-md-3 mx-lg-5">
                    <label className="text-16 font-weight-bold" htmlFor="dateEnd">Fecha Final</label>
                    <Field
                      name="dateEnd"
                      maxDate={new Date()}
                      other={{clearIcon: null}}
                      customAction={changeDateEnd}
                      component={renderDatePicker}
                    />
                  </div>
                  <div className="form-group has-feedback flex-1 mx-4">
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}


export default FiltersBinnacle
