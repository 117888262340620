import React from "react";
import Modal from "react-responsive-modal";
import { Field, Form } from "react-final-form";
import { fieldRequired } from "../../../../../utility/validation";
import { renderField } from "../../../../components/Utils/renderField";
import { CUSTOM_CONTENT_VARIABLE } from "../../../../../utility/constants";

const CustomContentModal = ({
  openModal,
  closeModalAction,
  onSubmit,
  customContent,
  idDocument,
}) => {
  return(

    <div>
      <Modal
        center
        open={openModal}
        closeOnEsc={true}
        showCloseIcon={true}
        closeOnOverlayClick={false}
        styles={{
          modal: {
            width: "600px",
            padding: "2.2%",
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: "#B0A196",
          },
        }}
        onClose={closeModalAction}
      >
        <Form
          onSubmit={(values) => {
            onSubmit(idDocument, values)
            closeModalAction();
          }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {
                        customContent && customContent.map((item, index) => (
                          <div key={index}>
                            <label>
                              {
                                CUSTOM_CONTENT_VARIABLE.find((label) => label.value === item).label
                              }
                            </label>
                            <Field
                              name={`${item}`}
                              component={renderField}
                              validate={fieldRequired}
                            />
                          </div>
                        ))
                                
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={submitting}
                    >
                            Generar
                    </button>
                  </div>
                </div>
              </form>
            )
          }}
        />
      </Modal>
    </div>
  )
}

export default CustomContentModal;