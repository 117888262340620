import "chart.js/auto";
import React, { Fragment } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PieController,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, PieController);

/**
 * @param {Array} datos - Array de objetos con los datos a graficar
 * @description datos = [...{label: "label", total: 0, porcentaje: 0}]
 * @param {Array} bgColors - Array de colores para el grafico
 * @param {String} labelGrap - Titulo del grafico
 * @returns {JSX.Element} - Componente de grafico de torta
 */
const PieCustom = ({ datos = [], bgColors = [], labelGrap = "" }) => {
  const values = datos.map((item) => item.total);
  const labels = datos.map((item) => item.label[0].toUpperCase() + item.label.slice(1).toLowerCase());
  const porcentajes = datos.map((item) => item.porcentaje);

  const total = values.reduce((a, b) => a + b, 0);

  const data = {
    labels,
    datasets: [
      {
        data: total > 0 ? values : [1, 1, 1],
        backgroundColor: bgColors,
      },
    ],
  };

  const options = {
    aspectRatio: 2,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            if (total > 0 ) {

              return `${context.label}: Q${context.formattedValue} (${
                porcentajes[context.dataIndex]
              }%)`;
            }
            else {
              return `No hay datos para mostrar`;
            }
          },
        },
      },
      legend: {
        position: window.innerWidth < 425 ? "bottom" : "right",
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          padding: 20,
          font: {
            size:
              window.innerWidth < 425
                ? 8
                : window.innerWidth < 850
                ? 16
                : window.innerWidth > 1516
                ? 16
                : 10,
          },
        },
      },
    },
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <p className="title-grafica">{labelGrap}</p>
      </div>
      <div style={{ lineHeight: "10px" }}>
        <br />
      </div>
      <Pie data={data} options={options} />
    </Fragment>
  );
};

export { PieCustom };
