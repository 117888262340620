import React, { Fragment, useEffect, useState } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
//Components
import Filter from "../../../components/Utils/Filters/Filter";
import Grid from "../../../components/Utils/Grid";
import excelExportPN from "../../../../../assets/img/icons/excel.png";
import LoadMask from "../../../components/Utils/LoadMask/LoadMask";
import moment from "moment";
import { PieCustom } from "../../../components/Graphics";
import { api } from "api";

const Card = ({ children, is_loading = false }) => {
  return (
    <div className="bg-card-cofaqui-light br-10px p-1">
      <LoadMask
        loading={is_loading}
        styleCustom={{
          height: "100%",
          backgroundColor: "#FAFDF3",
          borderRadius: "10px",
        }}
        type="TailSpin"
        light
        radius
      >
        {children}
      </LoadMask>
    </div>
  );
};

const getStampsAndProductsForSelect = async (inputValue = "") => {
  const response = await api.get("income_expenses/get_stamps_and_products", { search: inputValue });
  let _options = [];
  if (response.results) {
    _options = response.results
  }
  return _options;
};

const Income = (props) => {
  //State
  const {
    income_loader,
    sedes,
    income_data,
    income_page,
    loader_excel_income,
    search_income,
    getProductsAndStamps,
    products_and_stamps,
  } = props;

  //Actions
  const {
    getReportIncome,
    setInitialDateIncome,
    setFinalDateIncome,
    setSedeIncome,
    setSearchIncome,
    setTypeIncome,
    exportReportIncomeToExcel,
  } = props;

  // Cuando se monta el componente
  useEffect(() => {
    getReportIncome(income_page || 1);
    getProductsAndStamps()
  }, []);

  return (
    <Fragment>
      <div className="card bg-card-cofaqui br-20px-NotTop p-4">
        <div className="parent m-0 p-0 mb-3">
          <Card is_loading={income_loader}>
            <PieCustom
              labelGrap="Tipos de ingresos"
              datos={income_data.data_graph}
              bgColors={["#AD6EDF", "#EFD14A", "#B7B7B7"]}
            />
          </Card>
          <div className="d-flex flex-column justify-content-between">
            <Filter
              actionFilter={getReportIncome}
              startDate={{
                show: true,
                label: "Fecha inicio",
                onChangeAction: setInitialDateIncome,
              }}
              endDate={{
                show: true,
                label: "Fecha fin",
                onChangeAction: setFinalDateIncome,
              }}
              selects={[
                {
                  show: true,
                  options: sedes,
                  label: "Sedes",
                  onChangeAction: setSedeIncome,
                  placeholder: "Seleccione una sede",
                }
              ]}
              typeOptions={{
                show: true,
                options: products_and_stamps,
                label: "Productos y timbres",
                onChangeAction: setTypeIncome,
                placeholder: "Seleccione un producto o timbre",
                loadOptions: getStampsAndProductsForSelect,
              }}
            />
            <div className="horizontal-bar-separator "></div>
            <div className="d-flex justify-content-end mb-2 align-items-end mobile-css-container">
              <div className="d-flex align-items-start justify-content-center flex-column w-50">
                <label className="mb-0 ml-2">Buscar</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="# Recibo, # Colegiado, Agremiado"
                  value={search_income}
                  onChange={(e) => setSearchIncome(e.target.value)}
                />
              </div>
              <button
                className="btn btn-green-generic ml-2 vertical-bar-separator"
                onClick={() => {
                  getReportIncome(1);
                }}
              >
                &nbsp; Buscar
              </button>
              <button
                className="btn btn-green-generic ml-2"
                onClick={exportReportIncomeToExcel}
              >
                <img src={excelExportPN} alt="" />
                &nbsp; {loader_excel_income ? "Exportando..." : "Exportar"}
              </button>
            </div>
          </div>
        </div>
        <LoadMask loading={income_loader} blur>
          <Grid
            hover
            responsive
            data={income_data}
            page={income_page}
            loading={income_loader}
            onPageChange={getReportIncome}
            bodyContainerClass = "bc-professions-custom"
            headerContainerClass = "hc-professions-custom"
            tableContainerClass = "professions-container-table-sm"
            tableHeaderClass = "table-header-professions"
          >
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="transaction_date"
              dataFormat={(cell) => {
                let dateStr = "";
                if (cell) {
                  dateStr = moment(cell).format("DD/MM/YYYY - HH:mm");
                }
                return <span>{dateStr}</span>;
              }}
            >
              Fecha y hora
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="125px"
              dataField="number_member"
            >
              # Colegiado
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="name_member"
              isKey
            >
              Agremiado
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="number_receipt"
            >
              # Recibo
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="profession_member"
            >
              Profesion
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="amount"
              dataFormat={(cell) => {
                let dateStr = "---";
                if (cell) {
                  dateStr = `Q ${cell}`;
                }
                return <span>{dateStr}</span>;
              }}
            >
              Total
            </TableHeaderColumn>
          </Grid>
        </LoadMask>
      </div>
    </Fragment>
  );
};

export { Income };
