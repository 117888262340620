import {connect} from "react-redux";
import {actions} from "../../../../../redux/modules/administration/binnacle";
import List from "./List";


const ms2p = (state) => {
  return {
    ...state.binnacle,
  }
};

const md2p = {...actions};

export default connect(ms2p, md2p)(List);
