import React from "react";
import Grid from "../../../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment/moment";

const DocsGenerateRequired = ({
  data,
  page,
  onSortChange,
  onPageChange,
  remote,
  printAction,
}) => {
  return (
    <div
      className={"col-lg-9 col-md-10 col-sm-12 card bg-card-cofaqui pt-4 pb-5"}
      style={{ borderRadius: "0 20px 20px 20px" }}
    >
      <Grid
        hover
        page={page}
        data={data}
        remote={remote}
        pagination={false}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        bodyContainerClass = "bc-professions-custom"
        headerContainerClass = "hc-professions-custom"
        tableContainerClass = "professions-container-table-sm"
        tableHeaderClass = "table-header-professions"
      >
        <TableHeaderColumn
          isKey
          width="150px"
          dataField="order"
          dataAlign="center"
        >
          No.
        </TableHeaderColumn>
        <TableHeaderColumn
          width="200px"
          dataField="stamp_name"
          dataAlign="center"
        >
          Timbre
        </TableHeaderColumn>
        <TableHeaderColumn
          width="150px"
          dataField="transaction_date"
          dataAlign="center"
          dataFormat={(cell, row) => {
            let dateStr = "";
            try {
              dateStr = moment(cell).add(6, "hours").format("L");
            } catch (e) {
              dateStr = "";
            }
            return <span>{dateStr}</span>;
          }}
        >
          Fecha de compra
        </TableHeaderColumn>
        <TableHeaderColumn
          width="150px"
          dataAlign="center"
          dataField="doc_generated"
          dataFormat={(cell, row) => {
            return <center>
              <button onClick={() => printAction(row.doc_generated)}
                className={"px-2 py-0 actions-btn btn-print"}>
                <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
              </button>
            </center>
          }}
        >
          Acciones
        </TableHeaderColumn>
      </Grid>
    </div>
  );
};

export default DocsGenerateRequired;
