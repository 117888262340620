import { connect } from "react-redux";
import { actions } from "../../../redux/modules/QRScan";
import QRScanView from "./QRScanView";

const ms2p = (state) => {
  return {
    ...state.qrScan,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(QRScanView);