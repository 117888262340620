import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import {
  renderField,
  renderNumber,
  renderSwitch,
} from "../../../components/Utils/renderField";
import {
  AsyncSelectField,
  renderDatePicker,
} from "../../../components/Utils/renderField/renderField";
import FinalFormConnection from "../../../components/ReduxFormConn/FinalFormConnection";
import {
  composeValidators,
  fieldRequired,
  fieldSelectRequired,
} from "../../../../utility/validation";
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel";
import { api } from "../../../../utility/api";
import { connect } from "react-redux";

import "./members.css";

const CollegiateForm = (props) => {
  const {
    show,
    actionBack,
    onSubmit,
    values,
    getUniversities,
    getProfession,
    subTitle,
    professionList,
    defaultUni,
    formData2,
    formData1,
    changeValueSecondaryUser,
  } = props;

  const [defaultMember, setDefaultMember] = useState([]);

  const getMembersAcounts = (profession_id, secundary_profession_id, dpi) => {
    return api
      .get("member/getMembersByMember", {
        profession_id,
        secundary_profession_id,
        dpi,
      })
      .then((resp) => {
        if (resp) return resp;
      })
      .catch(() => {})
      .finally(() => {});
  };

  const formData2Values = (formData2 && formData2.values) || {
    has_another_profession: false,
  };
  const formData1Values = (formData1 && formData1.values) || {
    dpi: "",
  };

  const [professionListSecundary, setProfessionListSecundary] = useState(
    professionList.filter((item) => item.id !== formData2Values.profession_id)
  );

  const profesionSecundary = (formData2Values && formData2Values.secundary_profession_id) || null;

  const [professionListOptions, setProfessionListOptions] =
    useState(professionList);

  useEffect(() => {
    if (formData2Values.has_another_profession && formData1Values.dpi) {
      if (
        formData2Values.profession_id &&
        formData2Values.secundary_profession_id
      ) {
        getMembersAcounts(
          formData2Values.profession_id,
          formData2Values.secundary_profession_id,
          formData1Values.dpi
        ).then((resp) => {
          if (resp) {
            setDefaultMember(resp);
          }
        });
      }
    }
    setProfessionListSecundary(
      professionList.filter((item) => item.id !== formData2Values.profession_id)
    );
    setProfessionListOptions(
      professionList.filter(
        (item) => item.id !== formData2Values.secundary_profession_id
      )
    );
  }, [formData2Values]);


  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      destroyOnUnregister={false}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={`${show ? "" : "d-none"}`}>
          <FinalFormConnection form={"MemberStep2"} />
          <div className="collegiate-spouse-container">
            {subTitle && <h5 className="roboto weight-600 ml-4">{subTitle}</h5>}
            <div className="card bg-card-cofaqui px-lg-5 py-5 px-sm-0 px-md-3 card-shadow">
              <div className="d-flex flex-column flex-md-row">
                <div className="form-group has-feedback flex-1 mx-4">
                  <label htmlFor="no_collegiate">No. de colegiado</label>
                  <Field
                    // disabled={true}
                    name={"no_collegiate"}
                    thousandSeparator={""}
                    component={renderNumber}
                    className="form-transparent"
                    validate={composeValidators(fieldRequired)}
                  />
                </div>
                <div className="form-group has-feedback flex-1 mx-4">
                  <label htmlFor="university_center_id">
                    Centro universitario
                  </label>
                  <Field
                    valueKey="id"
                    labelKey="name"
                    loadOptions={getUniversities}
                    component={AsyncSelectField}
                    validate={fieldRequired}
                    defaultOptions={defaultUni}
                    name={"university_center_id"}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row">
                <div className="form-group has-feedback flex-1 mx-4">
                  <div>
                    <label htmlFor="graduation_date">Fecha de graduación</label>
                    <Field
                      maxDate={new Date()}
                      validate={fieldRequired}
                      name={"graduation_date"}
                      component={renderDatePicker}
                    />
                  </div>
                </div>
                <div className="form-group has-feedback flex-1 mx-4">
                  <label htmlFor="college_date">Fecha de colegiación</label>
                  <Field
                    maxDate={new Date()}
                    name={"college_date"}
                    validate={fieldRequired}
                    component={renderDatePicker}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row px-0">
                <div className="form-group has-feedback flex-1 mx-4">
                  <label htmlFor="thesis_name">Nombre de tesis</label>
                  <Field
                    name="thesis_name"
                    typeInput="text"
                    component={renderField}
                    validate={fieldRequired}
                  />
                </div>
                <div className="form-group has-feedback flex-1 mx-4">
                  <label htmlFor="profession_id">Profesión</label>
                  <Field
                    valueKey="id"
                    labelKey="name"
                    name="profession_id"
                    validate={fieldRequired}
                    loadOptions={getProfession}
                    component={AsyncSelectField}
                    defaultOptions={professionListOptions}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row">
                <div className="form-group has-feedback flex-1 mx-4">
                  <label htmlFor="secundary_profession_id">
                    Profesión secundaria
                  </label>
                  <Field
                    valueKey="id"
                    labelKey="name"
                    name="secundary_profession_id"
                    loadOptions={getProfession}
                    component={AsyncSelectField}
                    isClearable={!formData2Values.has_another_profession}
                    defaultOptions={professionListSecundary}
                  />
                </div>
                <div
                  className="form-group has-feedback flex-1 mx-4 d-flex pt-4 aling-items-center"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="has_another_profession">
                    ¿Desea asociarse a un numero de colegiado existente?
                  </label>
                  <Field
                    name="has_another_profession"
                    width={40}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    component={renderSwitch}
                    customClassCon={"switch-step3 ml-3"}
                    customStyles={{
                      position: "relative",
                      top: "50%",
                      right: "0",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>
              {formData2Values.has_another_profession && (
                <div className="d-flex flex-column flex-md-row">
                  <div className="form-group has-feedback flex-1 mx-4">
                    <label htmlFor="secondary_users_id">
                      Colegiado asociado
                    </label>
                    <Field
                      valueKey="id"
                      labelKey="full_name"
                      isSearchable={false}
                      loadOptions={()=>{
                        if (
                          formData2Values.profession_id &&
                          formData2Values.secundary_profession_id
                        ) {
                          return getMembersAcounts(
                            formData2Values.profession_id,
                            formData2Values.secundary_profession_id,
                            formData1Values.dpi
                          )
                        }
                        return new Promise((resolve, reject) => {
                          resolve(defaultMember)
                        })
                      }}
                      component={AsyncSelectField}
                      validate={fieldRequired}
                      isClearable={true}
                      defaultOptions={defaultMember}
                      name={"secondary_users_id"}
                      disabled={!formData2Values.has_another_profession}
                      placeholder={
                        !formData2Values.has_another_profession
                          ? "No aplica"
                          : "Seleccione un colegiado"
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <BtnSaveAndCancel
            renderBtnPrev
            renderBtnNext
            idBtnNext="btn-step-2"
            actionBntPrev={actionBack}
            customContainer="container justify-content-center w-100 mb-3 mt-5 row"
          />
          <FinalFormConnection form={"MemberStep2"} />
        </form>
      )}
    />
  );
};

// connectar con redux
const ms2p = (state) => {
  return {
    formData1: state.form.MemberStep1,
    formData2: state.form.MemberStep2,
  };
};

export default connect(ms2p)(CollegiateForm);
