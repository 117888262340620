import React, {useEffect} from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";
import {Modal} from "react-responsive-modal";

import "react-responsive-modal/styles.css";
import "./modal.css";
import {addIndexToArray} from "../../../../../utility/constants";

const ListMembers = (props) => {
  const {
    data, loader, onSortChange,
    page, listar, eliminar, me, clearValueMun,
    generateTemporaryPassword, openModal, closeModal, temporaryPassword
  } = props;

  useEffect(() => {
    listar(page);
    clearValueMun()
  }, []);


  const {permissions} = me;

  const rolePermissionsManagement = permissions.role_permissions_management;

  return (
    <div className="">
      <Modal
        classNames={{
          modal: "custom-modal",
          overlay: "custom-overlay",
        }}
        open={openModal}
        onClose={() => closeModal(false)}
        center
      >
        <div className="container-fluid">
          <h3 className="mb-4">Contraseña temporal</h3>
          <div className="d-flex flex-row justify-content-center p-2"
               style={{background: "#E2DDD9", borderRadius: "8px"}}>
            <h4 style={{marginTop: "3%"}}>{temporaryPassword}</h4>
          </div>
        </div>
      </Modal>
      {
        rolePermissionsManagement ?
          <PageTitle
            title={"Usuarios"}
            withLink={true}
            linkTo={"new-user"}
            linkLabel={"Nuevo usuario"}
          />
          :
          <PageTitle
            title={"Usuarios"}
          />
      }
      <div>
        <div className="col-12 px-1">
          <div className="mb-4 card card-small bg-card-cofaqui">
            <div className="px-4 pt-4">
              <Grid
                hover
                data={data}
                page={page}
                loading={loader}
                onPageChange={listar}
                onSortChange={onSortChange}>
                <TableHeaderColumn
                  isKey
                  width="125px"
                  dataField="order"
                  dataAlign="center"
                >
                  No.
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200px"
                  dataAlign="center"
                  dataField="first_name"
                >
                  Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200px"
                  dataAlign="center"
                  dataField="last_name"
                >
                  Apellido
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="dpi"
                  width="250px"
                >
                  DPI
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="200px"
                  dataField="id"
                  dataAlign="center"
                  dataFormat={standardActions({
                    editar: "user",
                    eliminar: eliminar,
                    generateTemporaryPassword: generateTemporaryPassword,
                  })}
                >
                  Acciones
                </TableHeaderColumn>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListMembers;
