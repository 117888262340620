import React from "react"
import Async from "react-select/async";
import Grid from "../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table"
import {
  customStylesDefault,
  DropdownIndicator,
  IndicatorSeparator
} from "../../../../components/Utils/renderField/renderField"


const AddMembers = (
  {
    data = [],
    cellEdit={},
    lockBtnAdd,
    valueSelect,
    disabledSelect,
    setValueSelect,
    defaultOption,
    promiseAction,
    actionBntAdd,
    labelKey = "full_name",
    valueKey = "id",
    labelSearch = "Busqueda de Usuario",
    columnsToShow = [
      {
        title: "id",
        tableHeaderProps: {
          isKey: true,
          dataField: "id",
          dataAlign: "center",
        }
      }
    ],
    classContainerGrid = "px-3 pt-2",
    placeholderSearch = "Número o nombre de colegiado",
    classNameContainer = "col-xl-10 col-lg-10 col-md-11 col-sm-12 px-0",
    classNameCard = "mb-4 card card-small bg-card-cofaqui py-5 col-12 px-lg-3 px-md-0 px-sm-0",
    classNameContainerSearch = "d-lg-flex d-md-flex d-sm-block flex-wrap col-lg-6 col-md-6 col-sm-12 px-0",
    changeContainerSearch,
    classNameCustomContainerSearch,
    classNameSearchButton="d-flex flex-wrap col-lg-4 col-md-5 col-sm-12 mx-lg-4 mx-0 mb-lg-0 mb-md-2 mb-sm-3 px-0"
  }) => {
  return (
    <div className={classNameContainer}>
      <div className={classNameCard}>
        <div className={`${changeContainerSearch ? classNameCustomContainerSearch : classNameContainerSearch}`}>
          <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-1">
            <label className="text-16 font-weight-bold" htmlFor="members">{labelSearch}</label>
            <Async
              cacheOptions
              value={valueSelect}
              defaultOptions={defaultOption}
              isDisabled={disabledSelect || lockBtnAdd}
              components={{DropdownIndicator, IndicatorSeparator}}
              styles={customStylesDefault}
              getOptionLabel={e => e[labelKey]}
              getOptionValue={e => e[valueKey]}
              loadOptions={promiseAction}
              placeholder={placeholderSearch}
              isClearable={false}
              isSearchable={true}
              onChange={(e) => {
                setValueSelect(e)
              }}
              className={"react-select-container"}
            />
          </div>
          <div className={`${classNameSearchButton}`}>
            <div className="align-self-center w-100 mt-lg-2 mt-md-2 mt-sm-0">
              <button
                type="button"
                disabled={lockBtnAdd}
                className="btn btn-create w-100 mt-lg-2 mt-md-2 mt-sm-0"
                onClick={actionBntAdd}>
                Agregar
              </button>
            </div>
          </div>
        </div>
        <div className={classContainerGrid}>
          <Grid
            hover
            remote={false}
            loading={false}
            cellEditProp={cellEdit}
            data={{results: data}}
          >
            {
              columnsToShow.map((col, index) => {
                return (
                  <TableHeaderColumn
                    key={index}
                    {...col.tableHeaderProps}
                  >
                    {col.title}
                  </TableHeaderColumn>
                )
              })
            }
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default AddMembers
