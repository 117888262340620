import React, {useEffect} from "react";
import "./customStyles.css";

const StepsCFQ = ({current, clickAction, totalSteps}) => {

  useEffect(() => {
    setCurrentItem(current);
  }, [current])

  const validateAction = (item) => {
    !!(clickAction) && clickAction(item)
  }

  const rows = [];
  for (let i = 0; i < totalSteps; i++) {
    const index = i + 1;
    const idStep = `steps-item-${index}`
    const classNameStep = `steps-item ${(current === index) && "finished"}`
    const actionStep = () => validateAction(index)

    rows.push(
      <React.Fragment key={index}>
        <div id={idStep} className={classNameStep} onClick={actionStep}>
          <span className="steps-label">{index}</span>
        </div>
        {totalSteps !== index &&
        <div className="line-bar w-100"/>
        }
      </React.Fragment>
    )
  }

  return (
    <div className="w-100 mb-4 bg-card-cofaqui py-4 px-lg-5 px-md-3 px-sm-1 br-20px card-shadow">
      <div className="px-2 py-2">
        <div className="d-flex flex-row justify-content-between">
          {rows}
        </div>
      </div>
    </div>
  )
}

function setCurrentItem(current) {
  const stepsItem = document.getElementById(`steps-item-${current}`);
  if (stepsItem) {
    stepsItem.classList.add("finished")
  }
}

export default StepsCFQ;
