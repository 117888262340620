import React, { useEffect } from "react";
import OutgoingCashReceivedForm from "./OutgoingCashReceivedForm";
import PageTitle from "../../../components/layout/PageTitle";

import "./pettyCash.css";

const OutgoingCashReceived = (props) => {
  const {
    loader,
    crear,
    me,
    cashRegister,
    validateCurrentCash,
    dateOpeningCR,
    typeCashRegister,
    openCategories,
    categories,
    getCategories,
    createOutgoingCashReceived,
    getOriginOrDestinationBanks,
    destinationBanksList,
  } = props;

  useEffect(() => {
    validateCurrentCash();
  }, []);

  const onSubmit = (data) => {
    createOutgoingCashReceived({...data, cash_register: cashRegister});
  };
        
  return (
    <div className="">
      <PageTitle
        title={`Caja (${me && me.full_name})`}
        colTitle="col-lg-4 col-xl-3"
      />
      <OutgoingCashReceivedForm
        loader={loader}
        crear={crear}
        me={me}
        validateCurrentCash={validateCurrentCash}
        dateOpeningCR={dateOpeningCR}
        typeCashRegister={typeCashRegister}
        openCategories={openCategories}
        categories={categories}
        getCategories={getCategories}
        createOutgoingCashReceived={onSubmit}
        getOriginOrDestinationBanks={getOriginOrDestinationBanks}
        destinationBanksList={destinationBanksList}
      />
    </div>
  );
}

export default OutgoingCashReceived;