import React, {useState, useEffect} from "react"
import RequestForm from "./Form";
import PageTitle from "../../../../components/layout/PageTitle";
import {api} from "../../../../../utility/api";
import {NotificationManager} from "react-notifications";
import FormModal from "./Modal/FormModal";


const Create = (props) => {
  const {
    me,
    loader,
    formsData,
    docsGenerate,
    itemSelected,
    saveProgress,
    documentsData,
    addFileToStore,
    requestApproval,
    getDocsAndForms,
    declineAction,
    approvalAction,
    match: {params, path},
    changeItemSelected,
    readPurchaseRequest,
    setInitialValueRequest,
    setInitialValDocsAndForms,
    printAction,
    stampUnderApproval,
    changeTypeOfManagement,
    typeOfManagement,
    changeUnderApproval,
    addFormsToStore,
  } = props;
  const [defaultOpt, setDefaultOpt] = useState([])
  const [defaultManagements, setDefaultManagements] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [openFormModal, setOpenFormModal] = useState(false)
  const [dataFormModal, setDataFormModal] = useState({})
  const [isDetailView, setIsDetailView] = useState(false)
  const [isApprovedView, setIsApprovedView] = useState(false)
  const [isRejectedView, setIsRejectedView] = useState(false)

  const loadManagements = (search = "") => {
    const params = {search, profession_id: me.profession_id}
    return api.get("management/get_managements_of_profession", params).then((resp) => {
      let _options = [];
      if (resp) _options = resp.results
      return _options
    }).catch((err) => {
      if (err && err.details) {
        NotificationManager.error(err.details, "Error", 8000)
      }
    }).finally(() => {
    });
  }

  useEffect(() => {
    changeUnderApproval(false)
    setIsApprovedView(false)
    setIsDetailView(false)
    // changeTypeOfManagement(null)
    if (params && params.id) {
      const update = true
      readPurchaseRequest(params.id, update)
      setIsUpdate(update)
    } else {
      loadProducts().then((resp) => {
        setDefaultOpt(resp)
      }).catch(() => {
      })

      loadManagements().then((resp) => {
        setDefaultManagements(resp)
      }).catch(() => {
      })

      setIsUpdate(false)
      setInitialValueRequest()
    }
  }, [])

  useEffect(() => {
    if (path === "/purchase-request/:id/approved") {
      setIsApprovedView(true)
    }
    if (path === "/purchase-request/:id/details") {
      setIsDetailView(true)
    }
    if (path === "/purchase-request/:id/rejected") {
      setIsRejectedView(true)
    }
  }, [path])

  const loadProducts = (search = "") => {
    const params = {search, profession_id: me.profession_id}
    return api.get("product/products_under_approved", params).then((resp) => {
      let _options = [];
      if (resp && resp.length) _options = resp
      return _options
    }).catch((err) => {
      if (err && err.details) {
        NotificationManager.error(err.details, "Error", 8000)
      }
      console.log("Error to load products", err)
    }).finally(() => {
    });
  }

  const toggleModalAction = (clickType = "open", row) => {
    if (clickType === "open") {
      setDataFormModal(row)
      setOpenFormModal(true)
    } else {
      setOpenFormModal(false)
      setDataFormModal({})
    }
  }

  const finalDeclineAction = (values) => {
    declineAction(params.id, values)
  }
  const finalApprovalAction = () => {
    approvalAction(params.id)
  }


  return (
    <div className="pt-3">
      <PageTitle
        title={"Solicitud de compra"}
        colTitle={"col-12"}
      />
      {dataFormModal.id &&
        <FormModal
          data={dataFormModal}
          openModal={openFormModal}
          closeModalAction={() => toggleModalAction("close")}
        />
      }
      <RequestForm
        loader={loader}
        isUpdate={isUpdate}
        formsData={formsData}
        docsGenerate={docsGenerate}
        valueSelect={itemSelected}
        defaultOption={defaultOpt}
        saveProgress={saveProgress}
        promiseAction={loadProducts}
        printAction={printAction}
        documentsData={documentsData}
        addFileToStore={addFileToStore}
        addFormsToStore={addFormsToStore}
        requestApproval={requestApproval}
        getDocsAndForms={getDocsAndForms}
        setValueSelect={changeItemSelected}
        toggleModalAction={toggleModalAction}
        onChaneProd={setInitialValDocsAndForms}
        isDetailView={isDetailView}
        isApprovedView={isApprovedView}
        isRejectedView={isRejectedView}
        approvalAction={finalApprovalAction}
        declineAction={finalDeclineAction}
        stampUnderApproval={stampUnderApproval}
        loadManagements={loadManagements}
        defaultManagements={defaultManagements}
        typeOfManagement={typeOfManagement}
        changeTypeOfManagement={changeTypeOfManagement}
        params={params}
        path={path}
      />

    </div>
  )
}

export default Create
