import React, {useState} from "react"
import Modal from "react-responsive-modal";
import {Field, Form} from "react-final-form";
import {renderField, renderNumber} from "../../../components/Utils/renderField";
import eyeBlock from "../../../../../assets/img/icons/eyeblock.png";
import {fieldRequired} from "../../../../utility/validation";


const ConfirmationModal = ({openModal, closeModalAction, onSubmit, item = {name: ""}}) => {
  let [showPass, setShowPass] = useState(false)

  function finalSubmit(data) {
    onSubmit(data, closeModalAction)
  }

  return (
    <div>
      <Modal
        center
        open={openModal}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        styles={{
          modal: {
            width: "600px",
            padding: "2.5%",
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: "#B0A196"
          }
        }}
        onClose={closeModalAction}
      >
        <Form
          onSubmit={finalSubmit}
          render={({handleSubmit}) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <center>
                    <h4 className="font-weight-bold mb-3">
                      Cierre de Caja
                    </h4>
                  </center>
                </div>
                <div className="form-group px-lg-5 px-md-3 px-sm-0">
                  <label htmlFor="initial_balance">Saldo Final</label>
                  <Field
                    name="closing_balance"
                    prefix="Q "
                    placeholder="Q 100"
                    customDecimalScale={2}
                    validate={fieldRequired}
                    component={renderNumber}
                  />
                </div>
                <div className="form-group px-lg-5 px-md-3 px-sm-0">
                  <label htmlFor="password">Contraseña</label>
                  <div className="input-password">
                    <i style={{position: "absolute", marginTop: ".5rem", alignSelf: "flex-end", paddingRight: "10px"}}>
                      <div
                        role="button"
                        onClick={() => setShowPass(!showPass)}
                      >
                        <img
                          src={eyeBlock}
                          className="eye"
                          alt="icono-ocultar"
                        />
                      </div>
                    </i>
                    <Field
                      name="password"
                      typeInput={showPass ? "text" : "password"}
                      customClass="password-field"
                      validate={fieldRequired}
                      component={renderField}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center mt-5">
                  <button
                    type="button"
                    onClick={closeModalAction}
                    className="btn btn-red col-sm-12 col-lg-5 col-md-5"
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-success col-sm-12 col-lg-5 col-md-5 ml-lg-3 ml-ms-1 ml-sm-0">
                    Cerrar
                  </button>
                </div>
              </form>
            )
          }}
        />
      </Modal>

    </div>
  )
}

export default ConfirmationModal
