import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
// eslint-disable-next-line
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import printJS from "print-js";

const DOCUMENT_CONSULTATION_DATA = "DOCUMENT_CONSULTATION_DATA";
const DOCUMENT_CONSULTATION_LOADER = "DOCUMENT_CONSULTATION_LOADER";

export const constants = {
  DOCUMENT_CONSULTATION_DATA,
  DOCUMENT_CONSULTATION_LOADER,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: DOCUMENT_CONSULTATION_LOADER,
  loader,
});

export const setDocumentConsultationData = data => ({
  type: DOCUMENT_CONSULTATION_DATA,
  data,
});

// ------------------------------------
// Actions
// ------------------------------------

const printPDF = archive => () => {
  // if (archive.base64) {
  //   const dataURI = `data:application/pdf;base64,${archive}`;
  //   const win = window.open();
  //   win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
  // } else {
  fetch(archive.url).then(function (response) {
    response.blob().then(function (blob) {
      var reader = new FileReader();
      reader.onload = function () {
        // printJS({
        //   printable: reader.result.substring(28),
        //   type: "pdf",
        //   base64: true,
        // });
        const dataURI = `data:application/pdf;base64,${reader.result.substring(28)}`;
        const win = window.open();
        if (win) {
          win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
        }
      }
      reader.readAsDataURL(blob);
    });
  });
  // }
};

const generatePDF = (id) => (
  dispatch,
) => {
  const params = { id };
  dispatch(setLoader(true));

  api.get("inventory_documents/generate_pdf", params).then(response => {
    if (response) {
      setTimeout(() => {
        printPDF(response)();
      }, 500);
    }
  }).catch(() => {
    NotificationManager.error(
      "Error al generar el PDF",
      "ERROR",
      5000
    );
  }).finally(() => {
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 500);
  });
};

export const getDocumentConsultationData = (data) => (dispatch) => {

  const params = {
    ...data,
  };

  dispatch(setLoader(true));
  api.get("/document_consultation/get_member_inventory_report", params).then((response) => {
    dispatch(setDocumentConsultationData(response));
  }).catch((err) => {
    if (err && err.body && err.body.detail) {
      NotificationManager.error(err.body.detail, "ERROR", 5000);
    } else {
      NotificationManager.error("Error al obtener los datos o ", "ERROR", 5000);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const actions = {
  getDocumentConsultationData,
  generatePDF,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [DOCUMENT_CONSULTATION_DATA]: (state, { data }) => {
    return {
      ...state,
      reportData: data,
    };
  },
  [DOCUMENT_CONSULTATION_LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  }
};

export const initialState = {
  reportData: {},
  loader: false,
};

export default handleActions(reducers, initialState);