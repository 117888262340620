import React from "react";
import {
  Route,
  Switch,
} from "react-router-dom";
import { NotificationContainer } from "react-notifications";

import { Login, Profile, Register } from "./common/components/LoginRegister";
import QRScanView from "./common/components/QRScanView";

import { ForgotMyPassword } from "./common/components/PasswordRecovery";
import { PasswordRecovery, ChangePassword } from "./common/components/PasswordRecovery";

import {ListMember, CreateMember, TechnicalDirections, ListMastersBeneficiary} from "./common/app/Members";
import {UserList, CreateUser} from "./common/app/Administration/Users";
import {CommissionList, CreateCommission} from "./common/app/Administration/Commisions";
import {ExpenseCategoryList} from "./common/app/Administration/ExpenseCategories";
import {ListFloatingPayments} from "./common/app/Administration/FloatingPayments";
import {BinnacleList} from "./common/app/Administration/Binnacles";
import {ProductList, CreateProduct} from "./common/app/Administration/Products";
import {ListManagement} from "./common/app/MyManagements"
import {CreateTransaction, ClosingCashRegister, ListCashRegister} from "./common/app/CashRegister";
import {CreateAdminCashRegister, ListAdminCashRegister} from "./common/app/Administration/AdminCashRegister";
import {ListPurchaseRequest, CreatePurchaseRequest} from "./common/app/MyManagements/PurchaseRequest";
import {DocsGeneratedList, DocumentsAvailableList, MyDocumentsList} from "./common/app/MyManagements/MyDocuments";
import PaymentsList from "./common/app/MyManagements/MyPayments";
import PettyCashEntry from "./common/app/CashRegister/PettyCashEntry";
import OutgoingCashReceived from "./common/app/CashRegister/OutgoingCashReceived";
import DirectPurchase from  "./common/app/MyManagements/DirectPurchases";
import MyTransactionDetail from  "./common/app/MyManagements/DirectPurchases/CheckTransaction";
import Dashboard from "./common/app/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import ValidateTokenRoute from "./ValidateTokenRoute";

import Examples from "./common/components/Examples/Basic";
import NotFound from "./common/components/layout/NotFound/NotFound";
import FinalForm from "./common/components/Examples/FinalForm";
import RoleList from "./common/app/Administration/PermissiosAndRoles";
import PermissionsAndRoles from "./common/app/Administration/PermissiosAndRoles/CreateUpdate";
import UniversityList from "./common/app/Administration/Universities";
import University from "./common/app/Administration/Universities/CreateUpdate";
import ProfessionList from "./common/app/Administration/Professions";
import Profession from "./common/app/Administration/Professions/CreateUpdate";
import BanksList from "./common/app/Administration/Banks";
import Bank from "./common/app/Administration/Banks/CreateUpdate";
import ManagementsList from "./common/app/Administration/managements";
import Managements from "./common/app/Administration/managements/CreateUpdate";
import Contributions from "./common/app/Reports/Contributions";
import incomeAndExpense from "./common/app/Reports/incomeAndExpense";
import MemberInventoryList from "./common/app/MyManagements/MyDocuments/TransactionDetail/MemberDocumentInventory";
import MembersForReportList from "./common/app/Reports/StampsIssued";
import StampsIssuedList from "./common/app/Reports/StampsIssued/StampsIssuedList";
import ReportStampListSold from "./common/app/Reports/StampsSold/List"
import ReportStampListUsed from "./common/app/Reports/StampsUsed/List"

import DocumentConsultationReport from "./common/app/Reports/DocumentConsultation";
// import StampsIssuedList as DocumentConsultationList from "./common/app/Reports/StampsIssued/StampsIssuedList";

// Stamps
import StampsList from "./common/app/Administration/Stamps";
import Stamps from "./common/app/Administration/Stamps/CreateUpdate";
import DocumentsList from "./common/app/Administration/Stamps/ListDocuments/List/";

import "../assets/fonts/fonts.css";

require("../../node_modules/font-awesome/css/font-awesome.css");
require("../../node_modules/bootstrap/dist/css/bootstrap.css");
import "bootstrap/dist/css/bootstrap.min.css";
import Grids from "./common/components/Examples/Grids";
import ExampleTabs from "./common/components/Examples/Tabs/Tabs";

// constants
import { VIEW_PERMISSIONS } from "./utility/constants";

require("../style/index.css");

module.exports = (
  <div>
    <div className="container__content">
      <Switch>
        {/* Login, register and forgot my password */}
        <Route exact path="/login" component={() => <Login />} />
        <Route exact path="/register" component={() => <Register />} />
        <Route exact path="/forgot-password" component={() => <ForgotMyPassword />} />
        <Route exact path="/qr/:id" component={(props) => <QRScanView {...props} />} />
        <ValidateTokenRoute exact path="/first-login/:token" component={ChangePassword} />
        <ValidateTokenRoute exact path="/new-password/:token" component={ChangePassword} />
        <ValidateTokenRoute exact path="/password-recovery/:token/:code" component={PasswordRecovery} />
        <ValidateTokenRoute exact path="/change-password/:token" component={ChangePassword} />
        {/* ---------------------- */}

        {/* Users */}
        <ProtectedRoute exact path="/users" permissions={VIEW_PERMISSIONS.permissionsUsers} component={UserList} />
        <ProtectedRoute exact path="/new-user" permissions={VIEW_PERMISSIONS.permissionsCreateUsers} component={CreateUser} />
        <ProtectedRoute exact path="/user/:id/edit" permissions={VIEW_PERMISSIONS.permissionsUpdateUsers} component={CreateUser} />
        {/* ---------------------- */}

        {/* Members */}
        <ProtectedRoute exact path="/" permissions={VIEW_PERMISSIONS.permissionsMembers} component={ListMember} />
        <ProtectedRoute exact path="/new-member" permissions={VIEW_PERMISSIONS.permissionsMembers} component={CreateMember} />
        <ProtectedRoute exact path="/member/:id/edit" permissions={VIEW_PERMISSIONS.permissionsMembers} component={CreateMember} />
        <ProtectedRoute exact path="/member/:memberId/documents" permissions={VIEW_PERMISSIONS.paymentsDocumentsForMember} component={(props) => <MyDocumentsList { ...props } admin={true} />} />
        <ProtectedRoute exact path="/available-documents/:memberId/:id" permissions={VIEW_PERMISSIONS.paymentsDocumentsForMember} component={(props) => <DocumentsAvailableList { ...props } admin={true} />} />
        <ProtectedRoute exact path="/documents-generated/:memberId/:id" permissions={VIEW_PERMISSIONS.paymentsDocumentsForMember} component={(props) => <DocsGeneratedList { ...props } admin={true} />} />
        <ProtectedRoute exact path="/member/:memberId/confirmed-payments" permissions={VIEW_PERMISSIONS.paymentsDocumentsForMember} component={(props) => <PaymentsList {...props} admin={true} />} />
        <ProtectedRoute exact path="/member/:memberId/technical_directions" permissions={VIEW_PERMISSIONS.permissionsTechnicalAddresses} component={(props) => <TechnicalDirections {...props} admin={true} />} />
        <ProtectedRoute exact path="/member/:memberId/masters" permissions={VIEW_PERMISSIONS.permissionsMembers} component={(props) => <ListMastersBeneficiary {...props} admin={true} />} />
        <ProtectedRoute exact path="/member/:memberId/member-inventory-detail/:id/documents" permissions={VIEW_PERMISSIONS.permissionsMembers} component={MemberInventoryList} />
        {/* ---------------------- */}

        {/* others */}
        <ProtectedRoute exact path="/page2" permissions={"only_authenticated"} component={Examples} />
        <ProtectedRoute exact path="/user-profile" permissions={"only_authenticated"} component={Profile} />
        <ProtectedRoute exact path="/grids" permissions={"only_authenticated"} component={Grids} />
        <ProtectedRoute exact path="/tabs" permissions={"only_authenticated"} component={ExampleTabs} />
        <ProtectedRoute exact path="/finalform" permissions={"only_authenticated"} component={FinalForm} />
        {/* ---------------------- */}

        {/* Permissions and Roles */}
        <ProtectedRoute exact path="/permissions-roles" permissions={VIEW_PERMISSIONS.permissionsRoles} component={RoleList} />
        <ProtectedRoute exact path="/new-role" permissions={VIEW_PERMISSIONS.permissionsRoles} component={PermissionsAndRoles} />
        <ProtectedRoute exact path="/role/:id/edit" permissions={VIEW_PERMISSIONS.permissionsRoles} component={PermissionsAndRoles} />
        {/* ---------------------- */}

        {/* Universities */}
        <ProtectedRoute exact path="/universities" permissions={VIEW_PERMISSIONS.permissionsUniversities} component={UniversityList} />
        <ProtectedRoute exact path="/new-university" permissions={VIEW_PERMISSIONS.permissionsUniversities} component={University} />
        <ProtectedRoute exact path="/university/:id/edit" permissions={VIEW_PERMISSIONS.permissionsUniversities} component={University} />
        {/* ---------------------- */}

        {/* Professions */}
        <ProtectedRoute exact path="/professions" permissions={VIEW_PERMISSIONS.permissionsProfessions} component={ProfessionList} />
        <ProtectedRoute exact path="/new-profession" permissions={VIEW_PERMISSIONS.permissionsProfessions} component={Profession} />
        <ProtectedRoute exact path="/profession/:id/edit" permissions={VIEW_PERMISSIONS.permissionsProfessions} component={Profession} />
        {/* ---------------------- */}

        {/* Commissions */}
        <ProtectedRoute exact path="/commissions" permissions={VIEW_PERMISSIONS.permissionsComissions} component={CommissionList} />
        <ProtectedRoute exact path="/new-commission" permissions={VIEW_PERMISSIONS.permissionsComissions} component={CreateCommission} />
        <ProtectedRoute exact path="/commission/:id/edit" permissions={VIEW_PERMISSIONS.permissionsComissions} component={CreateCommission} />
        {/* ---------------------- */}

        {/* EXPENSE CATEGORIES */}
        <ProtectedRoute exact path="/expense-categories" permissions={VIEW_PERMISSIONS.permissionsExpenseCategories} component={ExpenseCategoryList} />
        {/* ---------------------- */}

        {/* Stamps */}
        <ProtectedRoute exact path="/stamps" permissions={"only_authenticated"} component={StampsList} />
        <ProtectedRoute exact path="/new-stamp" permissions={VIEW_PERMISSIONS.permissionsStamps} component={Stamps} />
        <ProtectedRoute exact path="/stamp/:id/edit" permissions={VIEW_PERMISSIONS.permissionsStamps} component={Stamps} />
        <ProtectedRoute exact path="/stamps/required-documents/:id" permissions={VIEW_PERMISSIONS.permissionsDocuments} component={Stamps} />
        <ProtectedRoute exact path="/stamps/generated-documents/:id" permissions={VIEW_PERMISSIONS.permissionsDocuments} component={DocumentsList} />
        <ProtectedRoute exact path="/stamps/generated-documents/:id/generate-new-document" permissions={VIEW_PERMISSIONS.permissionsDocuments} component={Stamps} />
        <ProtectedRoute exact path="/stamps/generated-documents/:id/generated-document/:documentId/edit" permissions={VIEW_PERMISSIONS.permissionsDocuments} component={Stamps} />
        {/* ---------------------- */}

        {/* DASHBOARD */}
        <ProtectedRoute exact path="/dashboard" permissions={VIEW_PERMISSIONS.permissionDashboard} component={Dashboard} />
        {/* ---------------------- */}

        {/* BINNACLE */}
        <ProtectedRoute exact path="/binnacle" permissions={VIEW_PERMISSIONS.permissionsBinnacle} component={BinnacleList} />
        {/* ---------------------- */}

        {/* PRODUCTS */}
        <ProtectedRoute exact path="/products" permissions={VIEW_PERMISSIONS.permissionsProducts} component={ProductList} />
        <ProtectedRoute exact path="/new-product" permissions={VIEW_PERMISSIONS.permissionsProducts} component={CreateProduct} />
        <ProtectedRoute exact path="/product/:id/edit" permissions={VIEW_PERMISSIONS.permissionsProducts} component={CreateProduct} />
        {/* ---------------------- */}

        {/* MY MANAGEMENTS */}
        <ProtectedRoute exact path="/managements" permissions={VIEW_PERMISSIONS.managements} component={ListManagement} />
        <ProtectedRoute exact path="/managements/update-my-information" permissions={VIEW_PERMISSIONS.managements} component={CreateMember} />
        <ProtectedRoute exact path="/managements/my-payments" permissions={VIEW_PERMISSIONS.managements} component={PaymentsList} />
        {/* ---------------------- */}

        {/* PURCHASE REQUEST */}
        <ProtectedRoute exact path="/managements/purchase-request" permissions={VIEW_PERMISSIONS.managements} component={ListPurchaseRequest} />
        <ProtectedRoute exact path="/managements/new-purchase-request" permissions={VIEW_PERMISSIONS.managements} component={CreatePurchaseRequest} />
        <ProtectedRoute exact path="/managements/purchase-request/:id/edit" permissions={VIEW_PERMISSIONS.managements} component={CreatePurchaseRequest} />
        <ProtectedRoute exact path="/purchase-request/:id/approved" permissions={"only_authenticated"} component={CreatePurchaseRequest} />
        <ProtectedRoute exact path="/purchase-request/:id/rejected" permissions={"only_authenticated"} component={CreatePurchaseRequest} />
        <ProtectedRoute exact path="/purchase-request/:id/details" permissions={"only_authenticated"} component={CreatePurchaseRequest} />
        {/* ---------------------- */}

        {/* MY DOCUMENTS */}
        <ProtectedRoute exact path="/managements/my-documents" permissions={VIEW_PERMISSIONS.managements} component={MyDocumentsList} />
        <ProtectedRoute exact path="/managements/my-documents/:id/documents-available" permissions={VIEW_PERMISSIONS.managements} component={DocumentsAvailableList} />
        <ProtectedRoute exact path="/managements/my-documents/:id/documents-generated" permissions={VIEW_PERMISSIONS.managements} component={DocsGeneratedList} />
        <ProtectedRoute exact path="/managements/my-documents/:id/member-inventory-detail" permissions={VIEW_PERMISSIONS.managements} component={MemberInventoryList} />
        {/* ---------------------- */}

        {/* Direct Purchases */}
        <ProtectedRoute exact path="/managements/direct-purchases" permissions={"only_authenticated"} component={DirectPurchase} />
        <ProtectedRoute exact path="/managements/direct-purchases/transaction/:authorization" permissions={"only_authenticated"} component={MyTransactionDetail} />
        <ProtectedRoute exact path="/managements/direct-purchases/transaction/failed" permissions={"only_authenticated"} component={(props) => <MyTransactionDetail {...props} failed={true} />} />
        {/* ---------------------- */}

        {/* ADMINISTRATION - CASH REGISTERS */}
        <ProtectedRoute exact path="/cash-registers" permissions={VIEW_PERMISSIONS.permissionsAdministrativeCashRegister} component={ListAdminCashRegister}/>
        <ProtectedRoute exact path="/create-cash-register" permissions={VIEW_PERMISSIONS.permissionsAdministrativeCashRegister} component={CreateAdminCashRegister} />
        <ProtectedRoute exact path="/cash-register/:id/edit" permissions={VIEW_PERMISSIONS.permissionsAdministrativeCashRegister} component={CreateAdminCashRegister} />
        {/* ---------------------- */}

        {/* ADMINISTRATION - CASH REGISTERS */}
        <ProtectedRoute exact path="/floating_payment" permissions={VIEW_PERMISSIONS.permissionsFloatingPayments} component={ListFloatingPayments}/>
        {/* ---------------------- */}

        {/* MY CASH REGISTER */}
        <ProtectedRoute exact path="/my-cash-register" permissions={VIEW_PERMISSIONS.cashRegister} component={ListCashRegister} />
        <ProtectedRoute exact path="/my-cash-register/create-transaction" permissions={VIEW_PERMISSIONS.cashRegister} component={CreateTransaction} />
        <ProtectedRoute exact path="/my-cash-register/closing" permissions={VIEW_PERMISSIONS.cashRegister} component={ClosingCashRegister} />
        <ProtectedRoute exact path="/my-cash-register/petty-cash-entry" permissions={VIEW_PERMISSIONS.cashRegister} component={PettyCashEntry} />
        <ProtectedRoute exact path="/my-cash-register/cash-outflow" permissions={VIEW_PERMISSIONS.cashRegister} component={OutgoingCashReceived} />
        {/* ---------------------- */}

        {/* ADMINISTRATION - BANKS */}
        <ProtectedRoute exact path="/banks" permissions={VIEW_PERMISSIONS.permissionsForBanks} component={BanksList} />
        <ProtectedRoute exact path="/banks/new-bank" permissions={VIEW_PERMISSIONS.permissionsForBanks} component={Bank} />
        <ProtectedRoute exact path="/banks/:id/edit" permissions={VIEW_PERMISSIONS.permissionsForBanks} component={Bank} />
        {/* ---------------------- */}

        {/* ADMINISTRATION - MANAGEMENTS */}
        <ProtectedRoute exact path="/management" permissions={VIEW_PERMISSIONS.permissionsForTypeOfManagements} component={ManagementsList} />
        <ProtectedRoute exact path="/new-management" permissions={VIEW_PERMISSIONS.permissionsForTypeOfManagements} component={Managements} />
        <ProtectedRoute exact path="/management/:id/edit" permissions={VIEW_PERMISSIONS.permissionsForTypeOfManagements} component={Managements} />
        {/* ---------------------- */}

        {/* REPORTS - CONTRIBUTIONS*/}
        <ProtectedRoute exact path="/contributions-report" permissions={VIEW_PERMISSIONS.contributionReport} component={Contributions} />
        {/* ---------------------- */}

        {/* REPORTS - INCOME AND EXPENSES*/}
        <ProtectedRoute exact path="/incomeandexpenses-report" permissions={VIEW_PERMISSIONS.incomeExpenses} component={incomeAndExpense} />
        {/* ---------------------- */}

        {/* REPORTS - STAMPS ISSUED */}
        <ProtectedRoute exact path="/stamps-issued-report" permissions={VIEW_PERMISSIONS.stampsIssueReport} component={MembersForReportList} />
        <ProtectedRoute exact path="/stamps-issued/:noCollegiate/detail" permissions={"only_authenticated"} component={StampsIssuedList} />
        {/* ---------------------- */}

        {/* REPORTS - STAMPS SOLD */}
        <ProtectedRoute exact path="/stamps-sold-report" permissions={VIEW_PERMISSIONS.stampsSoldReport} component={ReportStampListSold} />
        {/* ---------------------- */}


        {/* REPORTS - STAMPS SOLD */}
        <ProtectedRoute exact path="/stamps-used-report" permissions={VIEW_PERMISSIONS.stampsIssueReport} component={ReportStampListUsed} />
        {/* ---------------------- */}
        {/* REPORTS - DOCUMENT CONSULTATION */}
        <ProtectedRoute exact path="/document-consultation-report" permissions={VIEW_PERMISSIONS.documentConsultation} component={DocumentConsultationReport} />
        {/* ---------------------- */}

        <Route component={NotFound} />
      </Switch>
    </div>
    <NotificationContainer />
  </div>
);
