import React, { useEffect } from "react";
import ManagementsForm from "./ManagementsForm";
import TitlePageCreate from "../../../../components/layout/TitleCreate";

import "./managements.css";

const Managements = ({
  createManagement,
  getManagement,
  updateManagement,
  match,
  loader,
  managementData,
  getProfessionsPartOfUnion,
  professions,
}) => {

  const createOrUpdate = match.path.includes("new-management") ? "createManagement" : "updateManagement";

  useEffect(() => {
    if (createOrUpdate === "updateManagement") {
      const { id } = match.params;
      getManagement(id);
    }
    getProfessionsPartOfUnion();
  }, []);

  return (
    <div className="pt-5 container-fluid">
      <TitlePageCreate
        redirectTo="/management"
        title={createOrUpdate === "createManagement" ? "Nueva Gestión" : "Editar Gestión"}
      />
      {
        createOrUpdate === "createManagement" ?
          <ManagementsForm
            createOrUpdate={createManagement}
            loader={loader}
            type="create"
            professions={professions}
          />
          :
          <ManagementsForm
            createOrUpdate={updateManagement}
            data={managementData}
            loader={loader}
            type="update"
            professions={professions}
          />
      }
    </div>
  );
}

export default Managements;
