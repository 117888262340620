import React, { Fragment, useEffect, useState } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
//Components
import Filter from "../../../components/Utils/Filters/Filter";
import Grid from "../../../components/Utils/Grid";
import excelExportPN from "../../../../../assets/img/icons/excel.png";
import LoadMask from "../../../components/Utils/LoadMask/LoadMask";
import moment from "moment";
import { PieCustom } from "../../../components/Graphics";
import { STAMPS_PRODUCTS } from "../../../../utility/constants";

const Card = ({ children, is_loading = false }) => {
  return (
    <div className="bg-card-cofaqui-light br-10px p-1">
      <LoadMask
        loading={is_loading}
        styleCustom={{
          height: "100%",
          backgroundColor: "#FAFDF3",
          borderRadius: "10px",
        }}
        type="TailSpin"
        light
        radius
      >
        {children}
      </LoadMask>
    </div>
  );
};

const Expense = (props) => {
  //State
  const {
    expense_loader,
    sedes,
    expense_data,
    expense_page,
    loader_excel_expense,
    search_expense,
    setTypeExpense,
  } = props;

  //Actions
  const {
    setSearchExpense,
    setInitialDateExpense,
    setFinalDateExpense,
    setSedeExpense,
    getReportExpense,
    exportReportExpenseToExcel,
  } = props;

  // Cuando se monta el componente
  useEffect(() => {
    getReportExpense(expense_page || 1);
  }, []);

  return (
    <Fragment>
      <div className="card bg-card-cofaqui br-20px-NotTop p-4">
        <div className="parent m-0 p-0 mb-3">
          <Card is_loading={expense_loader}>
            <PieCustom
              labelGrap="Tipos de egresos"
              datos={expense_data.data_graph}
              bgColors={["#36A2EB", "#AD6EDF", "#B7B7B7", "#FF6384", "#FFCE56"]}
            />
          </Card>
          <div className="d-flex flex-column justify-content-between flex-wrap ">
            <Filter
              actionFilter={getReportExpense}
              classNameContainerDate="d-flex flex-wrap date-filter"
              startDate={{
                show: true,
                label: "Fecha inicio",
                onChangeAction: setInitialDateExpense,
              }}
              endDate={{
                show: true,
                label: "Fecha fin",
                onChangeAction: setFinalDateExpense,
              }}
              selects={[
                {
                  show: true,
                  options: sedes,
                  label: "Sedes",
                  onChangeAction: setSedeExpense,
                  placeholder: "Seleccione una sede",
                },
              ]}
            />
            <div className="horizontal-bar-separator "></div>
            <div className="d-flex justify-content-end mb-2 align-items-end ">
              <div className="d-flex align-items-start justify-content-center flex-column w-50">
                <label className="mb-0 ml-2">Buscar</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="# Documento, Usuario"
                  value={search_expense}
                  onChange={(e) => setSearchExpense(e.target.value)}
                />
              </div>
              <button
                className="btn btn-green-generic ml-2 vertical-bar-separator"
                onClick={() => {
                  getReportExpense(1);
                }}
              >
                &nbsp; Buscar
              </button>
              <button
                className="btn btn-green-generic ml-2"
                onClick={exportReportExpenseToExcel}
              >
                <img src={excelExportPN} alt="" />
                &nbsp; {loader_excel_expense ? "Exportando..." : "Exportar"}
              </button>
            </div>
          </div>
        </div>
        <LoadMask loading={expense_loader} blur>
          <Grid
            hover
            responsive
            data={expense_data}
            page={expense_page}
            loading={expense_loader}
            onPageChange={getReportExpense}
            bodyContainerClass = "bc-professions-custom"
            headerContainerClass = "hc-professions-custom"
            tableContainerClass = "professions-container-table-sm"
            tableHeaderClass = "table-header-professions"
          >
            <TableHeaderColumn
              dataAlign="center"
              width="180px"
              dataField="transaction_date"
              dataFormat={(cell) => {
                let dateStr = "";
                if (cell) {
                  dateStr = moment(cell).format("DD/MM/YYYY - HH:mm");
                }
                return <span>{dateStr}</span>;
              }}
            >
              Fecha y hora
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="125px"
              dataField="number_document"
            >
              # Documento
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="name_user"
              isKey
            >
              Usuario
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="180px"
              dataField="amount"
              dataFormat={(cell) => {
                let dateStr = "---";
                if (cell) {
                  dateStr = `Q ${cell}`;
                }
                return <span>{dateStr}</span>;
              }}
            >
              Total
            </TableHeaderColumn>
          </Grid>
        </LoadMask>
      </div>
    </Fragment>
  );
};

export { Expense };
