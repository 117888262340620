import React, { Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { renderDatePicker, AsyncSelectField } from "../../../components/Utils/renderField/renderField";
import SelectFilterForm from "./SelectFilterForm";
import "./styleFilters.css";

/**
 * @param {Object} startDate - Object with the following properties: show, label
 * @param {endDate} endDate - Object with the following properties: show, label
 * @param {Array} selects - Array of objects with the following properties: show, options, defaultValue, label
 * @returns {JSX.Element} - Filter component
 **/
const Filter = ({
  stylesFilterContainer,
  classNameContainerDate,
  startDate,
  endDate,
  selects,
  typeOptions={
    show: false,
  },
  actionFilter,
}) => {

  return (
    <div className={`filter-container ${stylesFilterContainer}`}>
      {/* <div className={classNameContainerDate} style={{
        textOverflow: "ellipsis",
        // whiteSpace: "nowrap",
      }}> */}
      <div className="first-container-filter">
        {startDate.show && (
          <div className="">
            <label htmlFor="initial_date" className="">
              {startDate.label}
            </label>
            <Field
              name="initial_date"
              maxDate={new Date()}
              label={startDate.label}
              component={renderDatePicker}
              onChange={(e, newValue) => {
                startDate.onChangeAction(newValue);
              }}
            />
          </div>
        )}
        {endDate.show && (
          <div className="">
            <label htmlFor="final_date" className="">
              {endDate.label}
            </label>
            <Field
              name="final_date"
              maxDate={new Date()}
              label={endDate.label}
              component={renderDatePicker}
              onChange={(e, newValue) => {
                endDate.onChangeAction(newValue);
              }}
            />
          </div>
        )}
      </div>
      <div className="second-container-filter">
        {selects.map((select, index) => {
          return (
            select.show && (
              <SelectFilterForm
                key={index}
                options={select.options}
                label={select.label}
                onChangeAction={select.onChangeAction}
                placeholder={select.placeholder}
              />
            )
          );
        })}
        {
          typeOptions.show && (
            <div>
              <label htmlFor="type" className="">
                {typeOptions.label}
              </label>
              <Field
                name="type"
                component={AsyncSelectField}
                defaultOptions={typeOptions.options}
                loadOptions={typeOptions.loadOptions}
                customAction={typeOptions.onChangeAction}
                placeholder={typeOptions.placeholder}
              />
            </div>
          )
        }
      </div>
      {/* </div> */}
      <button
        className="btn btn-green-generic w-100 mb-4"
        style={{
          height: "40px",
          // width: "110px",
        }}
        onClick={() => {
          actionFilter();
        }}
      >
        Filtrar
      </button>
    </div>
  );
};

export default reduxForm({
  form: "formFilterReports",
})(Filter);
