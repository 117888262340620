import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/myManagements/myPayments";
import PaymentsList from "./List";

const ms2p = (state) => {
  return {
    ...state.payments,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(PaymentsList);