import React, { useState, useEffect } from "react";

import { simpleRenderField, selectField, renderFieldCheck } from "../../../../../components/Utils/renderField";
import UploadButton from "./UploadButton";
import { RenderName } from "../../../../../components/Utils/RenderName";

import { Field } from "react-final-form";

import { DOCUMENT_TYPE, FORM_TYPE } from "../../../../../../utility/constants";

import "./card.css"

const CustomCard = ({
  id,
  name,
  removeDocument,
  withArchive=false,
  otherTypes=false,
  activateTitle=false,
  change,
}) => {

  const [document, setDocument] = useState(null);

  useEffect(() => {
    if (document !== null) {
      change(`${name}.archive_name`, document);
    }
  }, [document]);

  return (
    <div
      className="general-card"
      style={{
        backgroundColor: "#FAFDF3",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px",
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "space-between",
        padding: "20px",
        marginBottom: "20px",
        minHeight: "85px",
        // maxWidth: "1198px",
      }}
    >
      {
        activateTitle && (
          <div className="row justify-content-center mb-3">
            <span
              className="text-green text-center"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              <Field
                name={`${name}.archive_name`}
                component={RenderName}
                typeInput="text"
              />
            </span>
          </div>
        )
      }
      <div className="row">
        <div
          className="mb-0 mb-md-3 row justify-content-center align-items-center col- col-sm-12 col-md-12 col-lg-1 col-lx-1"
          style={{
            minWidth: "100px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              color: "#4B4D58",
              fontSize: "30px",
            }}
          >
            {id+1}
          </span>
        </div>
        <div className="mr-0 mr-md-2 mb-0 mb-sm-3 row align-items-center col- col-sm-12 col-md-12 col-lg-4 col-lx-4">
          <div
            className="col- col-sm-12 col-md-12 col-lg-2 col-lx-2"
            style={{
              minWidth: "60px",
            }}
          >
            <span>Nombre</span>
            {/* &nbsp;&nbsp; */}
          </div>
          <div
            className="col- col-sm-12 col-md-12 col-lg-10 col-lx-10"
            style={{
              minWidth: "215px",
            }}
          >
            <Field
              name={`${name}.name`}
              placeholder="Ingrese el nombre"
              customClass="w-100"
              component={simpleRenderField}
              typeInput="text"
            />
          </div>
        </div>
        <div className="row align-items-center mb-0 mb-sm-3 col- col-sm-12 col-md-12 col-lg-4 col-lx-4">
          <div
            className="col- col-sm-12 col-md-12 col-lg-2 col-lx-2"
            style={{
              minWidth: "60px",
            }}
          >
            <span>Tipo</span>
            {/* &nbsp;&nbsp; */}
          </div>
          <div
            className="col- col-sm-12 col-md-12 col-lg-10 col-lx-10"
            style={{
              minWidth: "215px",
            }}
          >
            <Field
              name={`${name}.${ otherTypes ? "type_form" : "type" }`}
              placeholder={otherTypes ? "TQB" : "PDF"}
              customClass="w-100"
              options={otherTypes ? FORM_TYPE : DOCUMENT_TYPE}
              component={selectField}
            />
          </div>
        </div>
        <div
          className="required-document row mb-0 mb-sm-3 col- col-sm-12 col-md-6 col-lg-2 col-lx-2"
          style={{
            minWidth: "215px",
          }}
        >
          <div
            className="col- col-sm-3 col-md-1 col-lg-2 col-lx-2"
            style={{
              marginBottom: "-2%",
            }}
          >
            <Field
              name={`${name}.is_required`}
              withLabel={false}
              component={renderFieldCheck}
            />
          </div>
          <div
            className="col- col-sm-2 col-md-2 col-lg-1 col-lx-1"
          >
            {/* &nbsp;&nbsp; */}
            <span>
              Obligatorio
            </span>
          </div>
        </div>
        <div
          className="row align-items-center ml-4 col- col-sm-6 col-md-1 col-lg-1 col-lx-1 justify-content-end"
          style={{
            marginTop: "-1%",
          }}
        >
          {
            withArchive && (
              <Field
                name={`${name}.archive`}
                component={UploadButton}
                setDocument={setDocument}
                document={document}
              />
            )
          }
          <button
            type="button"
            title="Borrar documento"
            className="actions-btn btn-delete-document"
            onClick={() => {
              removeDocument(id);
            }}
          >
            <img src={require("assets/img/icons/reddump.svg")} alt="Borrar documento"/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomCard;
