import React, { Fragment, useEffect, useState } from "react"
import {DropdownIndicator} from "../renderField/renderField"
import classNames from "classnames"
import "./tree.css"

const ItemTree = ({
  item,
  openCategories,
  selectedItem,
  setSelectedItem,
  getLabelSelectedItem,
  input,
  ...rest
}) => {

  const openAction = () => {
    openCategories(item.value)
  };

  const handleClick = () => {
    rest.onChange(item.value);
    setSelectedItem({
      value: item.value,
      label: item.title,
    });
    rest.handleOptionSelect(item);
    if(getLabelSelectedItem) {
      getLabelSelectedItem(item.title)
    }
  };

  return (
    <div className="no-padding tree d-block" style={{ marginLeft: `${item.level * 15}px` }}>
      <div className={`px-3 py-3 option ${selectedItem && (selectedItem.value === item.value) ? "active" : ""}`} style={{ height: 40 }}>
        <div className="d-flex">
          {
            (item.level == 0) ?
              !item.selectable ?
                item.seleccionado ?
                  <span className="text-16 text-icon-align-center font-weight-bold" >
                    {/* <button
                      type="button"
                      className="option-action"
                      onClick={handleClick}
                    > */}
                    {item.title}
                    {/* </button> */}
                    &nbsp;
                    <button type="button" title="Contraer" className="option-action material-symbols-outlined" onClick={openAction}>expand_less</button>
                  </span>
                  :
                  <span className="text-16 text-icon-align-center font-weight-bold">
                    {/* <button
                      type="button"
                      className="option-action"
                      onClick={handleClick}
                    > */}
                    {item.title}
                    {/* </button> */}
                    &nbsp;
                    <button type="button" onClick={openAction} title="Expandir" className="option-action material-symbols-outlined ">expand_more</button>
                  </span>
                :
                <span className="text-16 text-icon-align-center">
                  <button
                    type="button"
                    className="option-action"
                    onClick={handleClick}
                  >
                    {item.title}
                  </button>
                </span>
              :
              (item.level == 1) ?
                !item.selectable ?
                  item.seleccionado ?
                    <span className="text-16 text-icon-align-center font-weight-bold" >
                      {/* <button
                        type="button"
                        className="option-action"
                        onClick={handleClick}
                      > */}
                      {item.title}
                      {/* </button> */}
                      &nbsp;
                      <button type="button" title="Contraer" className="option-action material-symbols-outlined" onClick={openAction}>expand_less</button>
                    </span>
                    :
                    <span className="text-16 text-icon-align-center font-weight-bold">
                      {/* <button
                        type="button"
                        className="option-action"
                        onClick={handleClick}
                      > */}
                      {item.title}
                      {/* </button> */}
                      &nbsp;
                      <button type="button" onClick={openAction} title="Expandir" className="option-action material-symbols-outlined ">expand_more</button>
                    </span>
                  :
                  <span className="text-16 pl-1 text-icon-align-center">
                    <button
                      type="button"
                      className="option-action"
                      onClick={handleClick}
                    >
                      {item.title}
                    </button>
                  </span>
                :
                (item.level == 2) ?
                  !item.selectable ?
                    item.seleccionado ?
                      <span className="text-16 text-icon-align-center font-weight-bold" >
                        {/* <button
                          type="button"
                          className="option-action"
                          onClick={handleClick}
                        > */}
                        {item.title}
                        {/* </button> */}
                        &nbsp;
                        <button type="button" title="Contraer" className="option-action material-symbols-outlined" onClick={openAction}>expand_less</button>
                      </span>
                      :
                      <span className="text-16 text-icon-align-center font-weight-bold">
                        {/* <button
                          type="button"
                          className="option-action"
                          onClick={handleClick}
                        > */}
                        {item.title}
                        {/* </button> */}
                        &nbsp;
                        <button type="button" onClick={openAction} title="Expandir" className="option-action material-symbols-outlined ">expand_more</button>
                      </span>
                    :
                    <span className="text-16 text-icon-align-center">
                      <button
                        type="button"
                        className="option-action"
                        onClick={handleClick}
                      >
                        {item.title}
                      </button>
                    </span>
                  :
                  (item.level == 3) ?
                    !item.selectable ?
                      item.seleccionado ?
                        <span className="text-16 text-icon-align-center font-weight-bold" >
                          {/* <button
                            type="button"
                            className="option-action"
                            onClick={handleClick}
                          > */}
                          {item.title}
                          {/* </button> */}
                          &nbsp;
                          <button type="button" title="Contraer" className="option-action material-symbols-outlined" onClick={openAction}>expand_less</button>
                        </span>
                        :
                        <span className="text-16 text-icon-align-center font-weight-bold">
                          {/* <button
                            type="button"
                            className="option-action"
                            onClick={handleClick}
                          > */}
                          {item.title}
                          {/* </button> */}
                          &nbsp;
                          <button type="button" onClick={openAction} title="Expandir" className="option-action material-symbols-outlined ">expand_more</button>
                        </span>
                      :
                      <span className="text-16">
                        <button
                          type="button"
                          className="option-action text-select-cat"
                          onClick={handleClick}
                        >{item.title}
                        </button>
                      </span>
                    :
                    <span className="text-16 text-icon-align-center">
                      <button
                        type="button"
                        className="option-action text-select-cat"
                        onClick={handleClick}
                      >
                        {item.title}
                      </button>
                    </span>
          }
        </div>
      </div>
      {
        item.seleccionado && (
          item.children.map((item, index) => {
            const selected = selectedItem && (selectedItem.value === item.value);

            return (
              <ItemTree
                key={index}
                item={item}
                item_padre={item}
                // selectItem={props.selectItem}
                // setOpenModal={props.setOpenModal}
                openCategories={openCategories}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                selected={selected}
                getLabelSelectedItem={getLabelSelectedItem}
                input={input}
                {...rest}
              />
            )
          }))
      }

    </div>
  )
}


const Tree = ({
  options,
  open,
  placeholder,
  typeAction,
  getSubCategories,
  input,
  meta,
  getLabelSelectedItem,
  ...rest
}) => {

  const invalid = meta.touched && meta.error;

  const [isOpen, setIsOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(()=>{
    setSelectedItem(null)
  }, [])

  useEffect(() => {
    if (typeAction === "edit" && input.value && options.length > 0) {
      getSubCategories(input.value, setSelectedItem);
    }
  }, [options]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (item) => {
    setIsOpen(!isOpen);
  };
 

  return (
    <Fragment>
      <button
        type="button"
        className={classNames("w-100", { "with-error": invalid })}
        onClick={handleClick}
        // ref={menu}
        style={{
          backgroundColor: "#FAFDF3",
          border: "1.5px solid #B0A196",
          borderRadius: "20px",
          height: "44.14px",
          minWidth: "227px",
        }}
      >
        <div className="row justify-content-between ml-2 pr-1">
          {
            selectedItem ? (
              <span className="text-select-cat" style={{maxWidth: "89%"}}>
                {selectedItem.label}
              </span>
            ) : (
              <span style={{ color: "#B0A196" }}>
                {placeholder}
              </span>
            )
          }
          <div className="d-flex float-right">
            <DropdownIndicator/>
          </div>
        </div>
      </button>
      {
        invalid && (
          <span className="error text-select-error">
            {meta.error}
          </span>
        )
      }
      {
        isOpen && (
          <div className="tree" style={{
            position: "absolute",
            zIndex: 1,
            backgroundColor: "#FAFDF3",
            border: "1.5px solid #B0A196",
            borderRadius: "20px",
            maxHeight: "350px",
            boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
            overflowX: "auto",
            minWidth: "95%",
          }}>
            {
              options.map((item, index) => {
                return (
                  <ItemTree
                    input={input}
                    {...rest}
                    key={index}
                    item={item}
                    openCategories={open}
                    onChange={input.onChange}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    getLabelSelectedItem={getLabelSelectedItem}
                    handleOptionSelect={handleOptionSelect} 
                  />
                )
              })
            }
          </div>
        )
      }
    </Fragment>
  )
};

export default Tree;
