import React, { useEffect, useState } from "react"
import {Field, Form} from "react-final-form"
import {fieldRequired} from "../../../../utility/validation"
import {TYPE_OF_INCOME_DIRECT} from "../../../../utility/constants"
import AddMembers from "../../Administration/Commisions/Create/AddMembers"
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel"
import {standardActions} from "../../../components/Utils/Grid/StandardActions"
import {RenderCurrency} from "../../../components/Utils/renderField/renderReadField"
import {simpleRenderField, selectField, AsyncSelectField} from "../../../components/Utils/renderField"
import {renderDatePicker, RenderQuantity} from "../../../components/Utils/renderField/renderField"
import { renderFilePicker } from "../../../components/Utils/renderField/renderField"
import VisalinkModal from "./VisalinkModal"

import "./form.css"

// eslint-disable-next-line
import {api} from "api"

const getBanks = (search, type) => {
  const params = {
    search,
    type,
  }

  return api.get("bank/get_origin_or_destination_banks", { ...params }).then((response) => {
    let _options = []
    if (response.results) {
      _options = response.results
    }
    return _options
    
  }).catch(() => {
    return []
  });
}

const TransactionForm = (
  {
    me,
    changeQuantityItem,
    defaultOptionProd,
    directPurchases,
    setItemSel,
    itemSelected,
    loadProducts,
    initialData,
    actionBntAdd,
    actionDelete,
    dataProducts,
    lockBtnAdd,
    minDate,
    onSubmit,
    total = 0,
    getOriginOrDestinationBanks,
    originBanksList,
    destinationBanksList,
    paymentLink,
    createPaymentLink,
  }) => {
    
  const requiredDoc = [5, 15] // depósito, transferencia
  const [changeBtn, setChangeBtn] = useState({
    renderBtnSubmit: true,
    ebipay: false
  })
  const [changeOnSubmit, setChangeOnSubmit] = useState(false)

  const [removeValue, setRemoveValue] = useState(false)

  const [modalVisalink, setModalVisalink] = useState(false)

  const closeVisalinkModal = () => {
    setModalVisalink(false)
  }

  const openVisalinkModal = () => {
    setModalVisalink(true)
  }

  useEffect(() => {
    getOriginOrDestinationBanks("origin")
    getOriginOrDestinationBanks("destination")
  }, [])

  return (
    <div>
      <Form
        onSubmit={changeOnSubmit ? (values) => createPaymentLink({...values, purchase: [...dataProducts], amount: total, member_id: me.id}, openVisalinkModal) : onSubmit}
        initialValues={{unionized_name: me ? me.full_name : "", ...initialData}}
        validate={({
          type_of_income, 
          no_document,
          bank_of_origin,
          bank_of_destination,
        }) => {
          const errors = {}
          if (requiredDoc.includes(type_of_income) && !no_document) {
            errors.no_document = "Campo requerido"
          }
          if (requiredDoc.includes(type_of_income) && !bank_of_origin) {
            errors.bank_of_origin = "Campo requerido"
          }
          if (requiredDoc.includes(type_of_income) && !bank_of_destination) {
            errors.bank_of_destination = "Campo requerido"
          }
          return errors
        }}
        render={({
          handleSubmit, 
          values: {
            type_of_income,
          }, 
          form: {change},
          // values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <VisalinkModal
                openModal={modalVisalink}
                closeModalAction={closeVisalinkModal}
                paymentLink={paymentLink}
              />
              <div className="direct-purchase-form card bg-card-cofaqui br-20px pb-4 pt-5 pl-3">
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="unionized_name">Agremiado</label>
                      <Field
                        name="unionized_name"
                        disabled={true}
                        component={simpleRenderField}
                        validate={fieldRequired}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="cash_register_id">Receptor de pago</label>
                      <Field
                        disabled={true}
                        name="cash_register_id"
                        component={selectField}
                        validate={fieldRequired}
                        options={directPurchases}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="type_of_income">Forma de pago</label>
                      <Field
                        name="type_of_income"
                        options={TYPE_OF_INCOME_DIRECT}
                        validate={fieldRequired}
                        customAction={(item) => {
                          if (item === 1) {
                            change("no_document", null)
                            change("bank_of_origin", null)
                            change("bank_of_destination", null)
                            change("means_of_verification", null)
                            setRemoveValue(true)
                          }
                        }}
                        component={selectField}
                        parse={value => {
                          if (value == 30) {
                            setChangeBtn({
                              renderBtnSubmit: false,
                              ebipay: true,
                            })
                            setChangeOnSubmit(true)
                          }  else {
                            setChangeBtn({
                              renderBtnSubmit: true,
                              ebipay: false,
                            })
                            setChangeOnSubmit(false)
                          }
                          return value
                        }}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="no_document">
                        No. {type_of_income === 5 ? "boleta" : type_of_income === 15 ? "transferencia" : "documento"}
                      </label>
                      <Field
                        name="no_document"
                        disabled={!requiredDoc.includes(type_of_income)}
                        component={simpleRenderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="bank_of_origin">Banco de origen</label>
                      <Field
                        name="bank_of_origin"
                        defaultOptions={originBanksList.results}
                        component={AsyncSelectField}
                        type="select"
                        placeholder="Banco"
                        // extendInformation={true}
                        labelKey="name"
                        valueKey="id"
                        loadOptions={(value) => getBanks(value, "origin")}
                        disabled={!requiredDoc.includes(type_of_income)}
                        removeValue={removeValue}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="bank_of_destination">
                        Banco de destino
                      </label>
                      <Field
                        name="bank_of_destination"
                        defaultOptions={destinationBanksList.results}
                        extendInformation={true}
                        identify="BOD"
                        extraData1="name"
                        extraData2="account_number"
                        component={AsyncSelectField}
                        type="select"
                        placeholder="Banco"
                        labelKey="name_account_number"
                        valueKey="id"
                        loadOptions={(value) => getBanks(value, "destination")}
                        disabled={!requiredDoc.includes(type_of_income)}
                        removeValue={removeValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="transaction_date">Fecha de pago</label>
                      <Field
                        withTime
                        disabled={true}
                        typeInput='text'
                        minDate={minDate}
                        maxDate={new Date()}
                        name="transaction_date"
                        validate={fieldRequired}
                        component={renderDatePicker}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="amount">Total</label>
                      <RenderCurrency
                        className="d-block pt-2"
                        value={total}
                      />
                    </div>
                  </div>
                </div>
                {
                  requiredDoc.includes(type_of_income) && (
                    <div className="col-12">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                          <label htmlFor="means_of_verification">Medio de verificacion</label>
                          <Field
                            accept={"image/*, .pdf"}
                            name="means_of_verification"
                            options={TYPE_OF_INCOME_DIRECT}
                            validate={requiredDoc.includes(type_of_income) ? fieldRequired : null}
                            component={renderFilePicker}
                            typeInput="file"
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div>
                <div className="card bg-card-cofaqui mt-4">
                  <AddMembers
                    valueKey="id"
                    labelKey="name"
                    data={dataProducts}
                    lockBtnAdd={lockBtnAdd}
                    valueSelect={itemSelected}
                    setValueSelect={setItemSel}
                    promiseAction={loadProducts}
                    defaultOption={defaultOptionProd}
                    actionBntAdd={actionBntAdd}
                    classContainerGrid="px-1 pt-2"
                    changeContainerSearch
                    classNameCustomContainerSearch="search-product"
                    classNameSearchButton="d-flex flex-row search-product-btn"
                    labelSearch="Busqueda de productos y/o timbres"
                    placeholderSearch="Nombre del producto o timbre"
                    columnsToShow={
                      [
                        {
                          title: "No.",
                          tableHeaderProps: {
                            isKey: true,
                            dataField: "order",
                            dataAlign: "center",
                            width: "100px"
                          }
                        },
                        {
                          title: "Nombre",
                          tableHeaderProps: {
                            dataField: "name",
                            dataAlign: "center",
                            width: "200px"
                          }
                        },
                        {
                          title: "Monto",
                          tableHeaderProps: {
                            dataField: "amount",
                            dataAlign: "center",
                            width: "100px",
                            dataFormat: (cell) => <RenderCurrency value={cell ? cell : 0}/>
                          }
                        },
                        {
                          title: "Cantidad",
                          tableHeaderProps: {
                            dataField: "quantity",
                            dataAlign: "center",
                            width: "250px",
                            dataFormat: (cell, row) => {
                              const disabled = row.under_approved
                              return (
                                <RenderQuantity
                                  callBackChange={(value) => changeQuantityItem(row, value)}
                                  disabled={disabled}
                                  initialValue={1}
                                  minValue={1}
                                />
                              )
                            }
                          }
                        },
                        {
                          title: "",
                          tableHeaderProps: {
                            dataField: "",
                            dataAlign: "",
                            width: "5%",
                            // dataFormat: (cell) => <RenderCurrency value={cell ? cell : 0}/>
                          }
                        },
                        {
                          title: "Acciones",
                          tableHeaderProps: {
                            dataField: "order",
                            dataAlign: "center",
                            width: "230px",
                            dataFormat: standardActions({eliminarSinConfirmacion: actionDelete})
                          }
                        }
                      ]
                    }
                    classNameContainer="col-xl-12 col-lg-12 col-md-11 col-sm-12 mt-1"
                    classNameCard="mb-4 py-3 col-12 px-lg-3 px-md-0 px-sm-0 br-10px"
                    classNameContainerSearch="d-lg-flex d-md-flex d-sm-block flex-wrap col-lg-6 col-md-6 col-sm-12 px-0 mb-2"
                  />
                </div>
              </div>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              <BtnSaveAndCancel
                {...changeBtn}
                renderLinkCancel
                redirectToLink="/managements"
                customContainer="container justify-content-center w-100 mb-3 mt-4 row"
                openModalAction={openVisalinkModal}
                createPaymentLinkAction={createPaymentLink}
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default TransactionForm
