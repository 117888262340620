import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/reports/incomeAndExpense";
import Tabs, { TabPane } from "rc-tabs";
import { Income } from "./Income";
import { Expense } from "./Expense";
import "./IncomeAndExpense.css";

const mapStateToProps = (state) => ({
  ...state.incomeAndExpense,
});

const mapDispatchToProps = {
  ...actions,
};

const indexIncomAndExpense = (props) => {
  useEffect(() => {
    props.getReportSedes();
  }, []);

  return (
    <div className="p-0 cash-container pt-5">
      <Tabs>
        <TabPane tab="Ingresos" key="1">
          <Income
            getReportIncome={props.getReportIncome}
            setInitialDateIncome={props.setInitialDateIncome}
            setFinalDateIncome={props.setFinalDateIncome}
            setSedeIncome={props.setSedeIncome}
            setSearchIncome={props.setSearchIncome}
            exportReportIncomeToExcel={props.exportReportIncomeToExcel}
            //States
            income_loader={props.income_loader}
            sedes={props.sedes}
            income_data={props.income_data}
            income_page={props.income_page}
            loader_excel_income={props.loader_excel_income}
            search_income={props.search_income}
            setTypeIncome={props.setTypeIncome}
            getProductsAndStamps={props.getProductsAndStamps}
            products_and_stamps={props.products_and_stamps}
          />
        </TabPane>
        <TabPane tab="Egresos" key="2">
          <Expense
            setSearchExpense={props.setSearchExpense}
            setInitialDateExpense={props.setInitialDateExpense}
            setFinalDateExpense={props.setFinalDateExpense}
            setSedeExpense={props.setSedeExpense}
            getReportExpense={props.getReportExpense}
            exportReportExpenseToExcel={props.exportReportExpenseToExcel}
            //States
            expense_loader={props.expense_loader}
            sedes={props.sedes}
            expense_data={props.expense_data}
            expense_page={props.expense_page}
            loader_excel_expense={props.loader_excel_expense}
            search_expense={props.search_expense}
            setTypeExpense={props.setTypeExpense}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(indexIncomAndExpense);
