import React, { useEffect } from "react";
import UniversitiesForm from "./UniversitiesForm";
import TitlePageCreate from "../../../../components/layout/TitleCreate";

import "./universities.css";

const Universities = ({
  createUniversity,
  updateUniversity,
  getUniversity,
  universityData,
  match,
  countries,
  loader,
  getCountries,
}) => {

  const createOrUpdate = match.path.includes("new-university") ? "createUniversity" : "updateUniversity";

  useEffect(() => {
    if (createOrUpdate === "updateUniversity") {
      const { id } = match.params;
      getUniversity(id);
    }
    getCountries();
  }, []);

  return (
    <div className="pt-5 container-fluid">
      <TitlePageCreate
        redirectTo="/universities"
        title={createOrUpdate === "createUniversity" ? "Nueva Universidad" : "Editar Universidad"}
      />
      {
        createOrUpdate === "createUniversity" ?
          <UniversitiesForm
            createOrUpdate={createUniversity}
            countries={countries}
            loader={loader}
            type="create"
          />
          :
          <UniversitiesForm
            createOrUpdate={updateUniversity}
            countries={countries}
            data={universityData}
            loader={loader}
            type="update"
          />
      }
    </div>
  );
}

export default Universities;
