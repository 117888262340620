import React from "react";
import { Form, Field } from "react-final-form";
import { renderField } from "../../../../components/Utils/renderField";

const is_required = (value) => (value ? undefined : "Este campo es requerido");

const DocumentConsultationForm = ({
  action,
}) => {

  return (
    <Form
      onSubmit={action}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className="row align-items-end mt-2">
          <div className="mb-2 mb-lg-0 col- col-sm-12 col-md-12 col-lg-4">
            <Field
              name="prefix"
              typeInput="text"
              component={renderField}
              placeholder="Prefijo"
              validate={is_required}
            />
          </div>
          <div className="mb-2 mb-lg-0 col- col-sm-12 col-md-12 col-lg-4">
            <Field
              name="number"
              typeInput="text"
              component={renderField}
              placeholder="Número de documento"
              validate={is_required}
            />
          </div>
          <div className="col- col-sm-12 col-md-12 col-lg-3">
            <button
              type="submit"
              className="btn btn-green-generic"
              disabled={submitting}
            >
              Buscar
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default DocumentConsultationForm;