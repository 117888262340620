import React from "react";
import {Form, Field} from "react-final-form";
import FinalFormConnection from "../../../components/ReduxFormConn/FinalFormConnection";
import {renderField, renderNumber, renderSwitch} from "../../../components/Utils/renderField";
import {AsyncSelectField, renderDatePicker, renderFilePicker} from "../../../components/Utils/renderField/renderField";
import {composeValidators, fieldRequired, maxPercentage, minDigits} from "../../../../utility/validation";
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel";

const SpouseForm = ({show, onSubmit, actionBack, getProfession, initialValues, subTitle, professionList}) => {

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{...initialValues}}
      destroyOnUnregister={false}
      render={({handleSubmit, values}) =>
        <form onSubmit={handleSubmit} className={`${show ? "" : "d-none"}`}>
          <FinalFormConnection form={"MemberStep3"}/>
          <div className="spouse-container">
            {subTitle &&
            <h5 className="roboto weight-600 ml-4">{subTitle}</h5>
            }
            <div className={"card bg-card-cofaqui px-lg-5 py-5 px-sm-0 px-md-3 card-shadow"}>
              <div className="main-inputs-container-spouse">
                <div className="inputs-container-spouse">
                  <div className="input-item">
                    <label htmlFor="full_name">Nombre completo</label>
                    <Field
                      name="full_name"
                      typeInput='text'
                      component={renderField}
                    />
                  </div>
                  <div className="input-item">
                    <label htmlFor="date_of_marriage">Fecha de matrimonio/unión</label>
                    <Field
                      maxDate={new Date()}
                      validate={fieldRequired}
                      name={'date_of_marriage'}
                      component={renderDatePicker}
                    />
                  </div>
                  <div className="input-item">
                    <label htmlFor="dpi">DPI</label>
                    <Field
                      name={'dpi'}
                      maxLength={13}
                      thousandSeparator={""}
                      component={renderNumber}
                      validate={composeValidators(fieldRequired, minDigits(13))}
                    />
                  </div>
                </div>
                <div className="inputs-container-spouse">
                  <div className="input-item">
                    <label htmlFor="birth_date">Fecha de nacimiento</label>
                    <Field
                      maxDate={new Date()}
                      name='birth_date'
                      validate={fieldRequired}
                      component={renderDatePicker}
                    />
                  </div>
                  {values.profession_id === 0 &&
                      <div className="input-item">
                        <label htmlFor="unregistered_profession">Nombre profesión</label>
                        <Field
                          type="text"
                          component={renderField}
                          validate={fieldRequired}
                          name='unregistered_profession'
                        />
                      </div>
                  }
                  <div className="input-item">
                    <label htmlFor="profession_id">Profesión u oficio</label>
                    <Field
                      name={'profession_id'}
                      valueKey='id'
                      labelKey='name'
                      loadOptions={getProfession}
                      component={AsyncSelectField}
                      defaultOptions={professionList}
                    />
                  </div>
                  {
                    values.is_beneficiary &&
                    <div className="input-item">
                      <label htmlFor="percentage">Porcentaje de beneficio</label>
                      <Field
                        suffix={'%'}
                        maxLength={4}
                        maxValue={100}
                        name={'percentage'}
                        thousandSeparator={""}
                        component={renderNumber}
                        validate={composeValidators(fieldRequired, maxPercentage(100))}
                      />
                    </div>
                  }
                  {
                    !values.is_beneficiary && (
                      <div className="null-item" style={{height: "78.33px"}}></div>
                    )
                  }
                </div>
                <div className="inputs-container-spouse">
                  <div className="input-item">
                    <label htmlFor="is_beneficiary">¿Es beneficiario/a?</label>
                    <Field
                      name='is_beneficiary'
                      width={40}
                      checkedIcon={false}
                      unc heckedIcon={false}
                      component={renderSwitch}
                      customClassCon={"switch-step3 ml-3"}
                    />
                  </div>
                  {/* <div style={{ height: "40px" }}></div> */}
                  <div className="input-item">
                    <label htmlFor="dpi_img">Adjuntar DPI</label>
                    <Field
                      name={'dpi_img'}
                      accept={'image/*'}
                      // validate={fieldRequired}
                      component={renderFilePicker}
                      photo={values.dpi_img}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*-------------------------------------------------------------*/}
          {/*------------------------    BUTTONS    ----------------------*/}
          {/*-------------------------------------------------------------*/}
          <BtnSaveAndCancel
            renderBtnPrev
            renderBtnNext
            idBtnNext="btn-step-3"
            actionBntPrev={actionBack}
            customContainer="container justify-content-center w-100 mb-3 mt-5 row"
          />
          {/*-------------------------------------------------------------*/}
          {/*----------    CONNECT FORM STATE TO REDUX    ----------------*/}
          {/*-------------------------------------------------------------*/}
          <FinalFormConnection form={"MemberStep3"}/>
        </form>
      }
    />
  )
}

export default SpouseForm;
