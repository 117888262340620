import React from "react"
import Modal from "react-responsive-modal";
import TransactionTable from "../TransactionTable";
import TransationTablePettyCash from "../TransactionTablePettyCash";
import {RenderCurrency} from "../../../../components/Utils/renderField/renderReadField";


const Detail = ({openModal, closeModal, loader, onPageChange, data, page, item, typeCashRegister}) => {

  return (
    <div>
      <Modal
        center
        open={openModal}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={closeModal}
        styles={{
          modal: {
            width: "90%",
            padding: "2.5%",
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            // background: "#E2DDD9",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: "#B0A196"
          }
        }}
      >
        <div>
          <h4 className="font-weight-bold ml-2">
            Detalles No: {item.order} - {item.cash_register && item.cash_register ? item.cash_register.name : ""}
          </h4>
          <div className="d-flex flex-wrap ml-1 mb-2">
            <h5 className="col-lg-4 col-md-4 col-sm-12 font-weight-bold px-0">
              Saldo de Apertura: <RenderCurrency value={item.opening_balance}/>
            </h5>
            <h5 className="col-lg-4 col-md-4 col-sm-12 font-weight-bold">
              Saldo de Cierre: <RenderCurrency value={item.closing_balance}/>
            </h5>
          </div>
          <div className="card bg-card-cofaqui px-3 mx-n4 pt-3">
            {
              typeCashRegister === 5 ?
                <TransactionTable
                  data={data}
                  page={page}
                  loader={loader}
                  onPageChange={onPageChange}
                  onSortChange={() => {
                  }}
                  annulationAction={null}
                />
                :
                <TransationTablePettyCash
                  data={data}
                  page={page}
                  loader={loader}
                  onPageChange={onPageChange}
                  onSortChange={() => {
                  }}
                  annulationAction={null}
                />
            }
          </div>
        </div>
      </Modal>
    </div>
  )

}

export default Detail;
