import ResumenAgremiados from "./ResumenAgremiados";
import EgresosIngresos from "./EgresosIngresos";
import ResumenDepartamentos from "./ResumenDepartamentos";
import TimbresVendidos from "./TimbresVendidos";
import GastosSeguros from "./GastosSeguros";
import TopTransacciones from "./TopTransacciones";
import SelectDashboard from "./SelectDashboard";
import PerfilUsuario from "./PerfilUsuario";

export {
  ResumenAgremiados,
  EgresosIngresos,
  ResumenDepartamentos,
  TimbresVendidos,
  GastosSeguros,
  TopTransacciones,
  SelectDashboard,
  PerfilUsuario,
};
