import {handleActions} from "redux-actions";
import {createReducer} from "../../baseReducer/baseReducer";
import {api} from "../../../../utility/api";
import {NotificationManager} from "react-notifications";
import {addIndexToArray} from "../../../../utility/constants";
import {push} from "react-router-redux";
import {printAction} from "../../myManagements/myDocuments";

// ------------------------------------
// Constants
// ------------------------------------

const baseReducer = createReducer(
  "purchaseRequest",
  "purchase_request",
  "PurchaseRequestForm",
  "/managements/purchase-request",
);

const {setLoader} = baseReducer.actions
const DOCUMENTS_DATA_OF_LIST = "DOCUMENTS_DATA_OF_LIST"
const FORMS_DATA_OF_LIST = "FORMS_DATA_OF_LIST"
const ITEM_SELECTED = "ITEM_SELECTED_PURCHASE"
const FILES_TO_UPLOAD = "FILES_TO_UPLOAD"
const DOC_GENERATE_LIST = "DOC_GENERATE_LIST"
const STAMP_UNDER_APPROVAL = "STAMP_UNDER_APPROVAL"
const TYPE_OF_MANAGEMENT = "TYPE_OF_MANAGEMENT"
const REQUIRED_FORMS = "REQUIRED_FORMS"

const setDocuments = documentsData => ({
  type: DOCUMENTS_DATA_OF_LIST,
  documentsData,
});

const setForms = formsData => ({
  type: FORMS_DATA_OF_LIST,
  formsData,
});

const setItemSelected = itemSelected => ({
  type: ITEM_SELECTED,
  itemSelected,
});

const setFilesToUpload = files => ({
  type: FILES_TO_UPLOAD,
  files,
});

const setDocGenerateList = docsGenerate => ({
  type: DOC_GENERATE_LIST,
  docsGenerate,
});

const setIsStampUnderApproval = stampUnderApproval => ({
  type: STAMP_UNDER_APPROVAL,
  stampUnderApproval,
});

const setTypeOfManagement = typeOfManagement => ({
  type: TYPE_OF_MANAGEMENT,
  typeOfManagement,
});

const setRequiredForms = requiredForms => ({
  type: REQUIRED_FORMS,
  requiredForms,
});

const getDocuments = (isUpdate=false) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const prodSelected = getStore()["purchaseRequest"].itemSelected

  const params = {
    id: prodSelected.id,
    is_update: isUpdate,
    purchase_request: prodSelected.purchase_request,
  }

  api.get(`purchase_request/${prodSelected.id}/required_documents`, {...params}).then((resp) => {
    resp = addIndexToArray(resp)
    dispatch(setDocuments({results: resp, count: resp.length}))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    }
  }).finally(() => {
    dispatch(setLoader(false))
  });
}

const getForms = (isUpdate=false) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const prodSelected = getStore()["purchaseRequest"].itemSelected

  const params = {
    id: prodSelected.id,
    is_update: isUpdate,
    purchase_request: prodSelected.purchase_request,
  }

  api.get(`purchase_request/${prodSelected.id}/required_forms`, {...params}).then((resp) => {
    resp.results = addIndexToArray(resp.results)
    dispatch(setForms(resp))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    } else {
      NotificationManager.error("No se pudieron cargar los formularios ", "Error", 8000)
    }
    console.log("Error to load products", err)
  }).finally(() => {
    dispatch(setLoader(false))
    // dispatch(setItemSelected(null))
  });
}

const readPurchaseRequest = (id, isUpdate=false) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`purchase_request/${id}/product_details`).then((response) => {
    changeItemSelected(response)(dispatch);
    changeTypeOfManagement(response.type_of_management)(dispatch);
    setTimeout(() => {
      dispatch(getDocuments(isUpdate));
      dispatch(getForms(isUpdate));
      dispatch(getDocsGenerate());
    }, 500)
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    } else if (err && err.body && err.body.details) {
      NotificationManager.error(err.body.details, "Error", 8000)
    } else {
      NotificationManager.error("No se pudo cargar los datos de la solicutud.", "Error", 8000)
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};


const changeItemSelected = (value) => (dispatch, getStore) => {
  dispatch(setItemSelected(value))
  if (value.is_stamp === true && value.under_approved === true) {
    dispatch(setIsStampUnderApproval(true))
  } else {
    dispatch(setIsStampUnderApproval(false))
  }
}

const changeTypeOfManagement = (value) => (dispatch, getStore) => {
  dispatch(setTypeOfManagement(value))
}

const getDocsAndForms = () => (dispatch, getStore) => {
  dispatch(getDocuments())
  dispatch(getForms())
}

const saveProgress = (required_forms=[], required_documents=[], isUpdate=false) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const { itemSelected, typeOfManagement, stampUnderApproval } = getStore()["purchaseRequest"]

  const body = {
    product: itemSelected,
    // documents: files,
    type_of_management: typeOfManagement,
    stamp_under_approval: stampUnderApproval,
    required_forms,
    required_documents,
    is_update: isUpdate,
  }

  // if (files.length) {
  // const attachments = []
  // for (const filesKey in files) {
  //   const _file = files[filesKey]
  //   attachments.push({name: "doc_" + _file.id, file: _file.newFile})
  // }
  api.post("purchase_request/save_progress", body).then((resp) => {
    NotificationManager.success("Progreso guardado", "Éxito", 8000)
    changeTypeOfManagement({})
    dispatch(push("/managements/purchase-request"))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    } else {
      NotificationManager.error("Error al guardar el progreso", "Error", 8000)
    }
    // console.log("Error to load products", err)
  }).finally(() => {

    dispatch(setLoader(false))
    // dispatch(setItemSelected(null))
  });
  // } else {
  //   api.post("purchase_request/save_progress", body).then((resp) => {
  //     dispatch(push("/managements/purchase-request"))
  //     NotificationManager.success("Progreso guardado", "Éxito", 8000)
  //   }).catch((err) => {
  //     if (err && err.details) {
  //       NotificationManager.error(err.details, "Error", 8000)
  //     } else {
  //       NotificationManager.error("Error al guardar el progreso", "Error", 8000)
  //     }
  //     console.log("Error to load products", err)
  //   }).finally(() => {
  //     dispatch(setLoader(false))
  //     // dispatch(setItemSelected(null))
  //   });
  // }
}

const requestApproval = (required_forms=[], required_documents=[]) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const item = getStore()["purchaseRequest"].itemSelected

  const data = {
    required_forms,
    required_documents
  }

  api.put(`purchase_request/${item.purchase_request}/request_approval`, data).then((resp) => {
    NotificationManager.success("Aprobación Solicitada", "Éxito", 8000)
    dispatch(push("/managements/purchase-request"))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    } else {
      NotificationManager.error("Error al solicitar aprobación", "Error", 8000)
    }
    console.log("Error to load products", err)
  }).finally(() => {
    dispatch(setLoader(false))
    // dispatch(setItemSelected(null))
  });
}
const setInitialValueRequest = (value) => (dispatch, getStore) => {
  const DefaultValue = {results: [], count: 0}
  dispatch(setDocuments(DefaultValue))
  dispatch(setForms(DefaultValue))
  dispatch(setDocGenerateList(DefaultValue))
  dispatch(setFilesToUpload([]))
  dispatch(setItemSelected(null))
}

const setInitialValDocsAndForms = (value) => (dispatch, getStore) => {
  const DefaultValue = {results: [], count: 0}
  dispatch(setDocuments(DefaultValue))
  dispatch(setForms(DefaultValue))
  dispatch(setDocGenerateList(DefaultValue))
  dispatch(setFilesToUpload([]))
}

const addFileToStore = (dataFile) => (dispatch, getStore) => {
  let currentFiles = getStore()["purchaseRequest"].files
  let newFiles = []
  for (const currentFilesKey in currentFiles) {
    let item = currentFiles[currentFilesKey]
    const {id, name} = item
    if (id !== dataFile.id && name !== dataFile.name) {
      newFiles.push(item)
    }
  }
  newFiles.push(dataFile)
  dispatch(setFilesToUpload(newFiles))
}

const addFormsToStore = (dataFile) => (dispatch, getStore) => {
  // dataFile = file
  // formId = id of form
  // console.log("dataFile", dataFile)
  // let currentFiles = getStore()["purchaseRequest"].files
  // let newFiles = []
  // for (const currentFilesKey in currentFiles) {
  //   let item = currentFiles[currentFilesKey]
  //   const {id, name} = item
  //   if (id !== dataFile.id && name !== dataFile.name) {
  //     newFiles.push(item)
  //   }
  // }
  // newFiles.push(dataFile)
  console.log("dataFile", dataFile)
  dispatch(setRequiredForms(dataFile))
}


const approvalAction = (id) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const params = {status: 20}
  api.put(`purchase_request/${id}/update_status`, params).then((resp) => {
    dispatch(push("/stamps"))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    } else {
      NotificationManager.error("No se pudo aprobar la solicitud", "Error", 8000)
    }
    console.log("Error to load products", err)
  }).finally(() => {
    dispatch(setLoader(false))
  });
}

const declineAction = (id, values) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const params = {
    status: 10,
    ...values
  }
  api.put(`purchase_request/${id}/update_status`, params).then((resp) => {
    dispatch(push("/stamps"))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    } else {
      NotificationManager.error("No se pudo realizar la acción", "Error", 8000)
    }
    console.log("Error to load products", err)
  }).finally(() => {
    dispatch(setLoader(false))
  });
}

const getDocsGenerate = () => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const prodSelected = getStore()["purchaseRequest"].itemSelected
  api.get(`purchase_request/${prodSelected.id}/required_docsGenerate`, {...prodSelected}).then((resp) => {
    dispatch(setDocGenerateList({results: addIndexToArray(resp), count: resp.length}))
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 8000)
    }
  }).finally(() => {
    dispatch(setLoader(false))
  });
}

const changeUnderApproval = (value) => (dispatch, getStore) => {
  dispatch(setIsStampUnderApproval(value))
}

export const actions = {
  setInitialValDocsAndForms,
  ...baseReducer.actions,
  setInitialValueRequest,
  readPurchaseRequest,
  changeItemSelected,
  getDocsAndForms,
  requestApproval,
  addFileToStore,
  approvalAction,
  declineAction,
  getDocuments,
  saveProgress,
  getForms,
  getDocsGenerate,
  printAction,
  changeTypeOfManagement,
  changeUnderApproval,
  addFormsToStore,
}

const reducers = {
  ...baseReducer.reducers,
  [DOCUMENTS_DATA_OF_LIST]: (state, {documentsData}) => {
    return {
      ...state,
      documentsData,
    };
  },
  [FORMS_DATA_OF_LIST]: (state, {formsData}) => {
    return {
      ...state,
      formsData,
    };
  },
  [ITEM_SELECTED]: (state, {itemSelected}) => {
    return {
      ...state,
      itemSelected,
    };
  },
  [FILES_TO_UPLOAD]: (state, {files}) => {
    return {
      ...state,
      files,
    };
  },
  [DOC_GENERATE_LIST]: (state, {docsGenerate}) => {
    return {
      ...state,
      docsGenerate,
    };
  },
  [STAMP_UNDER_APPROVAL]: (state, {stampUnderApproval}) => {
    return {
      ...state,
      stampUnderApproval,
    };
  },
  [TYPE_OF_MANAGEMENT]: (state, {typeOfManagement}) => {
    return {
      ...state,
      typeOfManagement,
    };
  },
  [REQUIRED_FORMS]: (state, {requiredForms}) => {
    return {
      ...state,
      requiredForms,
    };
  }
}
const initialState = {
  ...baseReducer.initialState,
  documentsData: {results: [], count: 0},
  formsData: {results: [], count: 0},
  docsGenerate: {results: [], count: 0},
  itemSelected: null,
  files: [],
  requiredForms: [],
  stampUnderApproval: false,
  typeOfManagement: null,
}
export default handleActions(reducers, initialState);
