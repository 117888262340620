import React from "react"
import moment from "moment"
import Grid from "../../../components/Utils/Grid"
import {addIndexToArray} from "../../../../utility/constants"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import {standardActions} from "../../../components/Utils/Grid/StandardActions"
import {RenderCurrency} from "../../../components/Utils/renderField/renderReadField"

const TransactionTablePettyCash = (
  {
    data,
    page,
    loader,
    onPageChange,
    onSortChange,
    annulationAction,
    getTransactionList,
  }) => {

  const expandComponent = (row) => {
    
    const typeMovement = row.type_of_movement.type

    const title = typeMovement == 1 ? "Detalle del ingreso" : "Detalle de egresos"

    return <BSTable data={addIndexToArray(row.products)} title={title} />
  }
  const BSTable = ({ data, title="" }) => {
    if (data) {
      return (
        <div className="col-lg-9 col-md-10 col-sm-12 px-0 mx-auto pb-2 pt-3 bw-1px border-cofaqui">
          <center>
            <h5 className="font-weight-bold mt-0 pb-2 pt-0" style={{borderBottom: "1px solid #B0A196"}}>
              {title}
            </h5>
          </center>
          <BootstrapTable
            data={data}
            hover={false}
            tableHeaderClass="table-header-cofaqui-details"
          >
            <TableHeaderColumn
              isKey
              width='150px'
              dataField='order'
              dataAlign="center"
            >
              No.
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField='name'
              dataFormat={(cell, row) => {
                let name = row.product__name
                if (!name) {
                  name = row.expense_category__name
                }
                return <span>{name}</span>
              }}
            >
              Nombre
            </TableHeaderColumn>
            {/* <TableHeaderColumn
              dataAlign="center"
              dataField='quantity'
            >
              Cantidad
            </TableHeaderColumn> */}
            <TableHeaderColumn
              width="210px"
              dataAlgn="right"
              dataField='amount'
              dataFormat={(cell) => <RenderCurrency value={cell ? cell : 0}/>}
            >
              Monto
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    } else {
      return (<p>?</p>);
    }
  }
  const rowClassName = (row, rowIdx) => {
    let trClassName = "td-column-even pointer"
    if (row && row.cancelled) {
      trClassName = "td-column-even item-cancelled pointer"
    }
    return trClassName;
  }

  return (
    <div>
      <Grid
        hover
        data={data}
        page={page}
        loading={loader}
        expandableRow={() => true}
        expandComponent={expandComponent}
        trClassName={rowClassName}
        onPageChange={getTransactionList}
        onSortChange={onSortChange}
        bodyContainerClass = "bc-professions-custom"
        headerContainerClass = "hc-professions-custom"
        tableContainerClass = "professions-container-table-sm"
        tableHeaderClass = "table-header-professions"
      >
        <TableHeaderColumn
          isKey={true}
          width="130px"
          dataField="order"
          dataAlign="center"
        >
          No.
        </TableHeaderColumn>
        <TableHeaderColumn
          width="150px"
          dataAlign="center"
          dataField="transaction_date"
          dataFormat={(cell) => {
            let dateStr = ""
            if (cell) {
              try {
                dateStr = moment(cell).format("DD/MM/YYYY HH:mm")
              } catch (e) {
                console.log("Error in parse date to dateString", e)
              }
            }
            return <span className="py-3">{dateStr}</span>
          }}
        >
          Fecha
        </TableHeaderColumn>
        <TableHeaderColumn
          width="330px"
          dataAlign="center"
          dataField="products"
          dataFormat={(cell) => {
            let productsName = ""
            if (cell) {
              cell.map((item, index) => {
                let separator = ", "
                if(cell.length == (index+1)){
                  separator = "."
                }
                if (item.expense_category__name) {
                  productsName += item.expense_category__name + separator
                } else if (item.stamp__name) {
                  productsName += item.expense_category__name + separator
                }
              })
            }
            return <span>{productsName}</span>
          }}
        >
          Descripción
        </TableHeaderColumn>
        <TableHeaderColumn
          width="145px"
          dataAlign="center"
          dataField="type_of_movement"
          dataFormat={(cell) => {
            return cell.text
          }}
        >
          Tipo de movimiento
        </TableHeaderColumn>
        <TableHeaderColumn
          width="150px"
          dataAlign="center"
          dataField="amount"
          dataFormat={(cell) => {
            return (
              <RenderCurrency
                className="text-right"
                value={cell ? cell : 0}
              />
            )
          }}
        >
          Monto
        </TableHeaderColumn>
        {annulationAction &&
          <TableHeaderColumn
            width="130px"
            dataField="id"
            dataAlign="center"
            dataFormat={standardActions({annulation: annulationAction})}
          >
            Acciones
          </TableHeaderColumn>
        }
      </Grid>
    </div>

  )
}

export default TransactionTablePettyCash
