const endpointView = 'technical_directions';

import {handleActions} from "redux-actions";
import {NotificationManager} from "react-notifications";
// eslint-disable-next-line
import {api} from "api";
import {push} from "react-router-redux";
import moment from "moment";

/*Direccion tecnica cuerpo
    "id",
    "beneficiary",
    "name_institution",
    "direction_institution",
    "tipe_institution",
    "date_aprobation",
    "no_stamp",
*/

/// CONST 

const SET_LIST_DIRECTIONS = 'SET_LIST_DIRECTIONS';
const LOARDER_DIRECTIONS = 'LOARDER_DIRECTIONS';
const SET_DATA_ITEM_BENEFICIARY = 'SET_DATA_ITEM_BENEFICIARY';

/// ACTIONS

export const setListDirections = (data) => ({
    type: SET_LIST_DIRECTIONS,
    data,
});

export const setLoaderDirections = (loader) => ({
    type: LOARDER_DIRECTIONS,
    loader,
});

export const setDataItemBeneficiary = (data_item) => ({
    type: SET_DATA_ITEM_BENEFICIARY,
    data_item,
});



/// REDUCER

const initialState = {
    data:{
        results: [],
        count: 0,
    },
    loader: false,
    data_item: {},
};

export default handleActions(
    {
        [SET_LIST_DIRECTIONS]: (state, {data}) => {
            return {
                ...state,
                data,
            };
        }
        ,
        [LOARDER_DIRECTIONS]: (state, {loader}) => {
            return {
                ...state,
                loader,
            };
        }
        ,
        [SET_DATA_ITEM_BENEFICIARY]: (state, {data_item}) => {  
            return {
                ...state,
                data_item,
            };
        }
    },
    initialState
);

/// THUNK

export const listDirections = (id_beneficiary) => (dispatch, getStore) => {
    dispatch(setLoaderDirections(true));
    api.get(`${endpointView}/?beneficiary=${id_beneficiary}`)
        .then((response) => {
            dispatch(setListDirections(response));
            dispatch(setLoaderDirections(false));
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch(setLoaderDirections(false));
        });
};

export const createDirection = (data) => (dispatch, getStore) => {
    const {data_item} = getStore().technicalDirectionsReducer;
    if (data_item && data_item.id) {
        data['beneficiary'] = data_item.id;
        data['date_aprobation'] = moment(data['date_aprobation']).format('YYYY-MM-DD');
        dispatch(setLoaderDirections(true));
        api.post(`${endpointView}/`, data)
            .then((response) => {
                dispatch(listDirections(data.beneficiary));
                NotificationManager.success('Registro creado', 'Éxito', 3000);
            })
            .catch((error) => {
                let msj = "Error en la creación"
                if (error && error.message) {
                    msj = error.message;
                }
                NotificationManager.error(msj, 'ERROR', 0);
            })
            .finally(() => {
                dispatch(setLoaderDirections(false));
            });
    }
    else{
        NotificationManager.error('Debe seleccionar un agremiado', 'ERROR', 0);
    }
}  

export const updateDirection = (id, data) => (dispatch, getStore) => {
    dispatch(setLoaderDirections(true));
    api.put(`${endpointView}/${id}/`, data)
        .then((response) => {
            dispatch(listDirections(data.beneficiary));
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('Error en la edición', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(setLoaderDirections(false));
        });
}


export const deleteDirection = (id) => (dispatch, getStore) => {
    const {data_item} = getStore().technicalDirectionsReducer;
    if (data_item && data_item.id) {
        dispatch(setLoaderDirections(true));
        api.eliminar(`${endpointView}/${id}/`)
            .then((response) => {
                dispatch(listDirections(data_item.id));
                NotificationManager.success('Registro eliminado', 'Éxito', 3000);
            })
            .catch((error) => {
                console.log(error);
                NotificationManager.error('Error en la eliminación', 'ERROR', 0);
            })
            .finally(() => {
                dispatch(setLoaderDirections(false));
            });
    }
    else{
        NotificationManager.error('Debe seleccionar un agremiado', 'ERROR', 0);
    }
}

export const getUserBeneficiaryData = (id) => (dispatch, getStore) => {
    let _id = id;
    if (!id) {
        //obtener el id de la url actual
        const url = window.location.href;
        const idRegex = /\/member\/(\d+)\//;
        const match = url.match(idRegex);
        const id_url = match ? match[1] : null;
        _id = id_url;
    }
    
    api.get(`${endpointView}/getUserBeneficiaryData/?beneficiary=${_id}`)
        .then((response) => {
            dispatch(setDataItemBeneficiary(response));
            dispatch(listDirections(_id));
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
        });
};



export const actions = {
    listDirections,
    createDirection,
    updateDirection,
    deleteDirection,
    setDataItemBeneficiary,
    getUserBeneficiaryData,
}
