import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
// eslint-disable-next-line
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import {addIndexToArray} from "../../../../utility/constants";
import moment from "moment";


const MEMBER_REPORT_LIST = "MEMBER_REPORT_LIST";
const LOADER = "MEMBER_REPORT_LOADER";
const PAGE = "MEMBER_REPORT_PAGE"; 
const ORDERING = "MEMBER_REPORT_ORDERING";
const SEARCH = "MEMBER_REPORT_SEARCH";
const FILTERS_STAMPS_SOLD = "FILTERS_STAMPS_ISSUED";
const FILTERS_STAMPS_SOLD_DETAILS = "FILTERS_STAMPS_SOLD_DETAILS";
const STAMP_ID = "STAMP_ID"
const LOADER_EXCEL_STAMP_SOLD="LOADER_EXCEL_STAMP_SOLD"

export const constants = {
  MEMBER_REPORT_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
  FILTERS_STAMPS_SOLD,
  FILTERS_STAMPS_SOLD_DETAILS,
  STAMP_ID,
  LOADER_EXCEL_STAMP_SOLD
  
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setMemberReportList = data => ({
  type: MEMBER_REPORT_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

const setStampId = stampId => ({
  type: STAMP_ID,
  stampId,
});


// eslint-disable-next-line
const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

// eslint-disable-next-line
const setSearch = search => ({
  type: SEARCH,
  search,
});


const setFiltersStampsSold = data => ({
  type: FILTERS_STAMPS_SOLD,
  data,
});

const setFiltersStampsSoldDetails = data => ({
  type: FILTERS_STAMPS_SOLD_DETAILS,
  data,
});


export const setLoaderStampSold = (loader) => ({
  type: LOADER_EXCEL_STAMP_SOLD,
  loader,
});


// ------------------------------------
// Actions
// ------------------------------------

const getReport = (page = 1) => (
  dispatch,
  getStore,
) => {

  const filter = getStore().stampsSold.filtersStampsSold;
  const select_stamp= getStore().stampsSold.stampId
  const params = { page, ...filter };

  if (select_stamp){
    params.select_stamp = select_stamp;
  }

  dispatch(setLoader(true));

  api.get("/stamps_issued/stamps_sold",params)
    .then((response) => {
      if (response.results) response.results = addIndexToArray(response.results, page);
      dispatch(setMemberReportList(response));
      dispatch(setPage(page));
    })
    .catch(() => {
      NotificationManager.error(
        "Error al obtener a los agremiados",
        "ERROR",
        0,
      );
    })
    .finally(() => {
      dispatch(setLoader(false));
    }
    );
};



const updateFiltersStampsSold= (values) => (
  dispatch,
) => {
  dispatch(setFiltersStampsSold(values));
  dispatch(getReport());
};

const updateFiltersStampsSoldDetails = (values, noCollegiate) => (
  dispatch,
) => {
  dispatch(setFiltersStampsSoldDetails(values));
  dispatch(getStampsSold(1, noCollegiate));
};

const changeStamp = ({id}) => (dispatch) => {
  dispatch(setStampId(id))
  //dispatch(getReport(1))
}

const resetValueStamp = () => (dispatch) => {
  dispatch(setStampId(null))

}



export const exportReportStampSoldExcel = () => (dispatch, getStore) => {
  dispatch(setLoaderStampSold(true));

  let endpoint = "/api/stamps_issued/export_to_excel_report_stamp_sold/";

  if (
    getStore().stampsSold.filtersStampsSold.start_date &&
    getStore().stampsSold.filtersStampsSold.end_date
  ) {
    if (
      moment(getStore().stampsSold.filtersStampsSold.start_date).isAfter(
        getStore().stampsSold.filtersStampsSold.end_date
      )
    ) {
      NotificationManager.error(
        "La fecha inicial debe ser menor a la fecha final",
        "ERROR",
        0
      );
      dispatch(setLoaderStampSold(false));
      return;
    }
    endpoint += `?start_date=${moment(
      getStore().stampsSold.filtersStampsSold.start_date
    ).format("YYYY-MM-DD")}&end_date=${moment(
      getStore().stampsSold.filtersStampsSold.end_date
    ).format("YYYY-MM-DD")}`;
  } else if (
    !getStore().stampsSold.filtersStampsSold.start_date&&
    !getStore().stampsSold.filtersStampsSold.end_date
  ) {
    //pass params empty
    endpoint += "?start_date=&end_date="; //pass params empty
  } else {
    NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
    dispatch(setLoaderStampSold(false));
    return;
  }
  if (getStore().stampsSold.stampId) {
    endpoint += `&select_stamp=${getStore().stampsSold.stampId}`;
  }

  fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Reporte de Timbres Vendidos.xlsx";
          a.click();
        });
      }
    })
    .catch(() => {
      NotificationManager.error(
        "Ocurrió un error al exportar el reporte",
        "ERROR",
        0
      );
    })
    .finally(() => {
      dispatch(setLoaderStampSold(false));
    });
};


export const actions = {
  getReport,
  changeStamp,
  resetValueStamp,
  updateFiltersStampsSold,
  updateFiltersStampsSoldDetails,
  exportReportStampSoldExcel
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [MEMBER_REPORT_LIST]: (state, { data }) => {
    return {
      ...state,
      memberReportList: data,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [FILTERS_STAMPS_SOLD]: (state, { data }) => {
    return {
      ...state,
      filtersStampsSold: data,
    };
  },
  [FILTERS_STAMPS_SOLD_DETAILS]: (state, { data }) => {
    return {
      ...state,
      filtersStampsSoldDetails: data,
    };
  },
  [STAMP_ID]: (state, {stampId}) => {
    return {
      ...state,
      stampId
    }
  },
  [LOADER_EXCEL_STAMP_SOLD]: (state, { loader }) => {
    return {
      ...state,
      loader_excel_stamp_sold: loader,
    };
  },
};

export const initialState = {
  loader: false,
  stampId: null,
  memberReportList: {
    count: 0,
    results: [],
  },
  page: 1,
  ordering: "",
  search: "",
  filtersStampsSold: {
    start_date: null,
    end_date: null,
  },
  filtersStampsSoldDetails: {
    start_date: null,
    end_date: null,
  },
  loader_excel_stamp_sold:false,
};

export default handleActions(reducers, initialState);