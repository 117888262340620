import React from "react"
import Grid from "../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table"
import {standardActions} from "../../../../components/Utils/Grid/StandardActions"
import moment from "moment";

const ApprovalStampList = (
  {
    loader,
    pageApprovedReq,
    data,
    onChangePage,
    getData
  }) => {

  return (
    <div className="">
      <div className="p-4 card bg-card-cofaqui shadow" style={{borderRadius: "0 20px 20px 20px"}}>
        <Grid
          hover
          data={data}
          page={pageApprovedReq}
          loading={loader}
          onPageChange={onChangePage}
          onSortChange={() => {
          }}
          bodyContainerClass = "bc-professions-custom"
          headerContainerClass = "hc-professions-custom"
          tableContainerClass = "professions-container-table-sm"
          tableHeaderClass = "table-header-professions"
        >
          <TableHeaderColumn
            width="100px"
            dataField="order"
            dataAlign="center"
          >
            No
          </TableHeaderColumn>
          <TableHeaderColumn
            width="200px"
            dataAlign="center"
            dataField="stamp"
            dataFormat={(cell) => {
              return (
                <div>
                  {cell.name}
                </div>
              )
            }}
          >
            Timbre
          </TableHeaderColumn>
          <TableHeaderColumn
            width="200px"
            dataAlign="center"
            dataField="modified"
            dataFormat={(cell) => {
              return (
                <div>
                  {moment(cell).format("L")}
                </div>
              )
            }}
          >
            Fecha de aprobación
          </TableHeaderColumn>
          <TableHeaderColumn
            width="200px"
            dataAlign="center"
            dataField="management_code"
          >
            Código de solicitud
          </TableHeaderColumn>
          <TableHeaderColumn
            width="200px"
            dataAlign="center"
            dataField="member"
            dataFormat={(cell, row) => {
              let fullName = cell.first_name + " " + cell.last_name
              return (
                <div>
                  {fullName}
                </div>
              )
            }}
          >
            Agremiado
          </TableHeaderColumn>
          <TableHeaderColumn
            isKey
            width="100px"
            dataField="id"
            dataAlign="center"
            dataFormat={standardActions({
              ver: "purchase-request",
              verDetail: "approved",
            })}
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default ApprovalStampList;
