import React, { Component } from "react";
import "./verify_login.css";
import backgraound from "../../../../../assets/img/bg-opacity-25.png";


const VerifyLogin = ({
  // props
}) => {
  return (
    <div id="verify_login" >
      <ul style={{
        position: "absolute",
        top: "0",
        left: "0",
        margin: "0",
        padding: "0",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${backgraound})`,
        display: "flex",
      }}></ul>
      <p
        style={{
          position: "absolute",
          marginLeft: "calc(50vw - 132px)",
          marginTop: "calc(50vh - 72px)",
          fontWeight: "bold",
          fontSize: "3rem",
          zIndex: "100",
          color: "black",
        }}
      >
                Cargando...
      </p>
    </div>
  )
}

export default VerifyLogin;
