import React, { Fragment } from "react";
import SelectDashboard from "./SelectDashboard";
import PerfilUsuario from "./PerfilUsuario";

const TopTransacciones = ({
  datos,
  top_transactions_month_select,
  top_transactions_year_select,
  getTopTransactionsByYear,
  getTopTransactionsByMonth,
}) => {
  const { data_users, years, months, total_transacciones } = datos;

  return (
    <Fragment>
      <div className="d-flex justify-content-between ">
        <p className="title-grafica">Usuarios con más transacciones</p>
        <div className="d-flex justify-content-end">
          <SelectDashboard
            options={months[top_transactions_year_select['value']]}
            defaultValue={top_transactions_month_select}
            label="Mes"
            onChangeAction={getTopTransactionsByMonth}
          />
          &nbsp; &nbsp;
          <SelectDashboard
            options={years}
            defaultValue={top_transactions_year_select}
            label="Año"
            onChangeAction={getTopTransactionsByYear}
          />
        </div>
      </div>
      <div
        className="d-flex flex-column justify-content-around"
        style={{ height: "300px" }}
      >
        {data_users.map((item, index) => (
          <PerfilUsuario
            key={index}
            nombre={item.nombre}
            tipo={item.tipo}
            cantidad={item.total}
            cantidad_transacciones={item.transacciones}
            transacciones_porcentaje={item.porcentaje}
            total_transacciones={total_transacciones}
            avatar={item.avatar}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default TopTransacciones;
