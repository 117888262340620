import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { api } from "api";
import { addIndexToArray } from "../../../../utility/constants";
import { initialize as initializeForm, change } from "redux-form";
import moment from "moment";
import Swal from "sweetalert2";

const SET_REPORT_SEDES = "SET_REPORT_SEDES";
//INCOME
const LOADER_REPORT_INCOME = "LOADER_REPORT_INCOME";
const SET_REPORT_INCOM_DATA = "SET_REPORT_INCOM_DATA";
const SET_REPORT_INCOM_FILTER_INITIAL_DATE =
  "SET_REPORT_INCOM_FILTER_INITIAL_DATE";
const SET_REPORT_INCOM_FILTER_FINAL_DATE = "SET_REPORT_INCOM_FILTER_FINAL_DATE";
const SET_REPORT_INCOM_FILTER_SEDE = "SET_REPORT_INCOM_FILTER_SEDE";
const SET_REPORT_INCOM_FILTER_TYPE = "SET_REPORT_INCOM_FILTER_TYPE";
const SET_REPORT_INCOM_PAGE = "SET_REPORT_INCOM_PAGE";
const LOADER_EXCEL_INCOM = "LOADER_EXCEL_INCOM";
const SEARCH_INCOME = "SEARCH_INCOME";
const SET_PRODUCTS_AND_STAMPS = "SET_PRODUCTS_AND_STAMPS";
//EXPENSE
const LOADER_REPORT_EXPENSE = "LOADER_REPORT_EXPENSE";
const SET_REPORT_EXPENSE_DATA = "SET_REPORT_EXPENSE_DATA";
const SET_REPORT_EXPENSE_FILTER_INITIAL_DATE =
  "SET_REPORT_EXPENSE_FILTER_INITIAL_DATE";
const SET_REPORT_EXPENSE_FILTER_FINAL_DATE =
  "SET_REPORT_EXPENSE_FILTER_FINAL_DATE";
const SET_REPORT_EXPENSE_FILTER_SEDE = "SET_REPORT_EXPENSE_FILTER_SEDE";
const SET_REPORT_EXPENSE_FILTER_TYPE = "SET_REPORT_EXPENSE_FILTER_TYPE";
const SET_REPORT_EXPENSE_PAGE = "SET_REPORT_EXPENSE_PAGE";
const LOADER_EXCEL_EXPENSE = "LOADER_EXCEL_EXPENSE";
const SEARCH_EXPENSE = "SEARCH_EXPENSE";

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setReportSedes = (sedes) => ({
  type: SET_REPORT_SEDES,
  sedes,
});

// ACTIONS PURE INCOME

export const setLoaderIncome = (loader) => ({
  type: LOADER_REPORT_INCOME,
  loader,
});

export const setReportIncomeData = (data) => ({
  type: SET_REPORT_INCOM_DATA,
  data,
});

export const setLoaderReportIncomeExcel = (loader) => ({
  type: LOADER_EXCEL_INCOM,
  loader,
});

export const setReportIncomeFilterInitialDate = (filter_initial_date) => ({
  type: SET_REPORT_INCOM_FILTER_INITIAL_DATE,
  filter_initial_date,
});

export const setReportIncomeFilterFinalDate = (filter_final_date) => ({
  type: SET_REPORT_INCOM_FILTER_FINAL_DATE,
  filter_final_date,
});

export const setReportIncomeFilterSede = (filter_sede) => ({
  type: SET_REPORT_INCOM_FILTER_SEDE,
  filter_sede,
});

export const setReportIncomeFilterType = (filter_type) => ({
  type: SET_REPORT_INCOM_FILTER_TYPE,
  filter_type,
});

export const setReportIncomePage = (page) => ({
  type: SET_REPORT_INCOM_PAGE,
  page,
});

export const setSearchIncome = (search) => ({
  type: SEARCH_INCOME,
  search,
});

export const setProductsAndStamps = (products_and_stamps) => ({
  type: SET_PRODUCTS_AND_STAMPS,
  products_and_stamps,
});

// ACTIONS PURE EXPENSE

export const setLoaderExpense = (loader) => ({
  type: LOADER_REPORT_EXPENSE,
  loader,
});

export const setReportExpenseData = (data) => ({
  type: SET_REPORT_EXPENSE_DATA,
  data,
});

export const setLoaderReportExpenseExcel = (loader) => ({
  type: LOADER_EXCEL_EXPENSE,
  loader,
});

export const setReportExpenseFilterInitialDate = (filter_initial_date) => ({
  type: SET_REPORT_EXPENSE_FILTER_INITIAL_DATE,
  filter_initial_date,
});

export const setReportExpenseFilterFinalDate = (filter_final_date) => ({
  type: SET_REPORT_EXPENSE_FILTER_FINAL_DATE,
  filter_final_date,
});

export const setReportExpenseFilterSede = (filter_sede) => ({
  type: SET_REPORT_EXPENSE_FILTER_SEDE,
  filter_sede,
});

export const setReportExpenseFilterType = (filter_type_expense) => ({
  type: SET_REPORT_EXPENSE_FILTER_TYPE,
  filter_type_expense,
});

export const setReportExpensePage = (page) => ({
  type: SET_REPORT_EXPENSE_PAGE,
  page,
});

export const setSearchExpense = (search) => ({
  type: SEARCH_EXPENSE,
  search,
});


// ------------------------------------
// Actions
// ------------------------------------

export const getReportIncome =
  (page = 1) =>
    (dispatch, getStore) => {
      dispatch(setLoaderIncome(true));
      let params = {
        page: page || getStore().incomeAndExpense.income_page,
      };

      dispatch(setReportIncomePage(params.page));

      if (
        getStore().incomeAndExpense.income_filter_sede &&
      getStore().incomeAndExpense.income_filter_sede.value
      ) {
        params.sede = getStore().incomeAndExpense.income_filter_sede.value;
      }

      if (
        getStore().incomeAndExpense.income_filter_initial_date &&
      getStore().incomeAndExpense.income_filter_final_date
      ) {
        if (
          moment(getStore().incomeAndExpense.income_filter_initial_date).isAfter(
            getStore().incomeAndExpense.income_filter_final_date
          )
        ) {
          NotificationManager.error(
            "La fecha inicial debe ser menor a la fecha final",
            "ERROR",
            0
          );
          dispatch(setLoaderIncome(false));
          return;
        }
        params.initial_date = moment(
          getStore().incomeAndExpense.income_filter_initial_date
        ).format("YYYY-MM-DD");
        params.final_date = moment(
          getStore().incomeAndExpense.income_filter_final_date
        ).format("YYYY-MM-DD");
      } else if (
        !getStore().incomeAndExpense.income_filter_initial_date &&
      !getStore().incomeAndExpense.income_filter_final_date
      ) {
      //pass params empty
      } else {
        NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
        dispatch(setLoaderIncome(false));
        return;
      }

      if (getStore().incomeAndExpense.search_income) {
        params.search = getStore().incomeAndExpense.search_income;
      }

      if (getStore().incomeAndExpense.income_filter_type && getStore().incomeAndExpense.income_filter_type.value) {
        const value = getStore().incomeAndExpense.income_filter_type;
        if (value.is_stamp) {
          params.type_stamp_or_product = "stamp_";
        } else {
          params.type_stamp_or_product = "product_";
        }
        params.type_stamp_or_product += `${value.value}`;
      }

      api
        .get("/income_expenses/get_transactions_income", params)
        .then((response) => {
          if (response) {
            dispatch(setReportIncomeData(response));
          }
        })
        .catch(() => {
          NotificationManager.error(
            "Ocurrió un error al obtener los datos",
            "ERROR",
            0
          );
        })
        .finally(() => {
          dispatch(setLoaderIncome(false));
        });
      dispatch(setLoaderIncome(false));
    };

export const getReportSedes = () => (dispatch, getStore) => {
  api
    .get("/income_expenses/getSedes")
    .then((response) => {
      if (response) {
        dispatch(setReportSedes(response));
        dispatch(setReportIncomeFilterSede(response[0]));
        dispatch(setReportExpenseFilterSede(response[0]));
      }
    })
    .catch(() => {})
    .finally(() => {});
};

export const exportReportIncomeToExcel = () => (dispatch, getStore) => {
  dispatch(setLoaderReportIncomeExcel(true));

  let endpoint = "/api/income_expenses/export_to_excel_report_income/";

  if (
    getStore().incomeAndExpense.income_filter_initial_date &&
    getStore().incomeAndExpense.income_filter_final_date
  ) {
    if (
      moment(getStore().incomeAndExpense.income_filter_initial_date).isAfter(
        getStore().incomeAndExpense.income_filter_final_date
      )
    ) {
      NotificationManager.error(
        "La fecha inicial debe ser menor a la fecha final",
        "ERROR",
        0
      );
      dispatch(setLoaderReportIncomeExcel(false));
      return;
    }
    endpoint += `?initial_date=${moment(
      getStore().incomeAndExpense.income_filter_initial_date
    ).format("YYYY-MM-DD")}&final_date=${moment(
      getStore().incomeAndExpense.income_filter_final_date
    ).format("YYYY-MM-DD")}`;
  } else if (
    !getStore().incomeAndExpense.income_filter_initial_date &&
    !getStore().incomeAndExpense.income_filter_final_date
  ) {
    //pass params empty
    endpoint += "?initial_date=&final_date="; //pass params empty
  } else {
    NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
    dispatch(setLoaderReportIncomeExcel(false));
    return;
  }
  if (
    getStore().incomeAndExpense.income_filter_sede &&
    getStore().incomeAndExpense.income_filter_sede.value
  ) {
    endpoint += `&sede=${getStore().incomeAndExpense.income_filter_sede.value}`;
  }
  if (getStore().incomeAndExpense.search_income) {
    endpoint += `&search=${getStore().incomeAndExpense.search_income}`;
  }
  if (
    getStore().incomeAndExpense.income_filter_type &&
    getStore().incomeAndExpense.income_filter_type.value
  ) {
    const value = getStore().incomeAndExpense.income_filter_type;
    if (value.is_stamp) {
      endpoint += `&type_stamp_or_product=stamp_${value.value}`;
    } else {
      endpoint += `&type_stamp_or_product=product_${value.value}`;
    }
  }

  fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Reporte de Ingresos.xlsx";
          a.click();
        });
      }
    })
    .catch(() => {
      NotificationManager.error(
        "Ocurrió un error al exportar el reporte",
        "ERROR",
        0
      );
    })
    .finally(() => {
      dispatch(setLoaderReportIncomeExcel(false));
    });
};

const getProductsAndStamps = () => (dispatch) => {
  api
    .get("income_expenses/get_stamps_and_products")
    .then((response) => {
      if (response) {
        if (response.results) dispatch(setProductsAndStamps(response.results));
      }
    })
    .catch(() => {})
    .finally(() => {});
};

// Filters
export const setInitialDateIncome = (date) => (dispatch, getStore) => {
  dispatch(setReportIncomeFilterInitialDate(date));
};

export const setFinalDateIncome = (date) => (dispatch, getStore) => {
  dispatch(setReportIncomeFilterFinalDate(date));
};

export const setSedeIncome = (sede) => (dispatch, getStore) => {
  dispatch(setReportIncomeFilterSede(sede));
};

export const setTypeIncome = (type) => (dispatch, getStore) => {
  dispatch(setReportIncomeFilterType(type));
};

// Filters Expense
export const setInitialDateExpense = (date) => (dispatch, getStore) => {
  dispatch(setReportExpenseFilterInitialDate(date));
};

export const setFinalDateExpense = (date) => (dispatch, getStore) => {
  dispatch(setReportExpenseFilterFinalDate(date));
};

export const setSedeExpense = (sede) => (dispatch, getStore) => {
  dispatch(setReportExpenseFilterSede(sede));
};

export const setTypeExpense = (type) => (dispatch, getStore) => {
  dispatch(setReportExpenseFilterType(type));
};

export const getReportExpense =
  (page = 1) =>
    (dispatch, getStore) => {
      dispatch(setLoaderExpense(true));
      let params = {
        page: page || getStore().incomeAndExpense.expense_page,
      };

      dispatch(setReportIncomePage(params.page));

      if (
        getStore().incomeAndExpense.expense_filter_sede &&
        getStore().incomeAndExpense.expense_filter_sede.value
      ) {
        params.sede = getStore().incomeAndExpense.expense_filter_sede.value;
      }
      if (
        getStore().incomeAndExpense.expense_filter_initial_date &&
      getStore().incomeAndExpense.expense_filter_final_date
      ) {
        if (
          moment(getStore().incomeAndExpense.expense_filter_initial_date).isAfter(
            getStore().incomeAndExpense.expense_filter_final_date
          )
        ) {
          NotificationManager.error(
            "La fecha inicial debe ser menor a la fecha final",
            "ERROR",
            0
          );
          dispatch(setLoaderExpense(false));
          return;
        }
        params.initial_date = moment(
          getStore().incomeAndExpense.expense_filter_initial_date
        ).format("YYYY-MM-DD");
        params.final_date = moment(
          getStore().incomeAndExpense.expense_filter_final_date
        ).format("YYYY-MM-DD");
      } else if (
        !getStore().incomeAndExpense.expense_filter_initial_date &&
      !getStore().incomeAndExpense.expense_filter_final_date
      ) {
      //pass params empty
      } else {
        NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
        dispatch(setLoaderExpense(false));
        return;
      }

      if (getStore().incomeAndExpense.search_expense) {
        params.search = getStore().incomeAndExpense.search_expense;
      }
      if (
        getStore().incomeAndExpense.expense_filter_type &&
        getStore().incomeAndExpense.expense_filter_type.value
      ) {
        params.type_stamp_or_product = getStore().incomeAndExpense.expense_filter_type.value;
      }

      api
        .get("/income_expenses/get_transactions_expense", params)
        .then((response) => {
          if (response) {
            dispatch(setReportExpenseData(response));
          }
        })
        .catch(() => {
          NotificationManager.error(
            "Ocurrió un error al obtener los datos",
            "ERROR",
            0
          );
        })
        .finally(() => {
          dispatch(setLoaderExpense(false));
        });
      dispatch(setLoaderExpense(false));
    };


export const exportReportExpenseToExcel = () => (dispatch, getStore) => {
  dispatch(setLoaderReportExpenseExcel(true));
  
  let endpoint = "/api/income_expenses/export_to_excel_report_expenes/";
  
  if (
    getStore().incomeAndExpense.expense_filter_initial_date &&
      getStore().incomeAndExpense.expense_filter_final_date
  ) {
    if (
      moment(getStore().incomeAndExpense.expense_filter_initial_date).isAfter(
        getStore().incomeAndExpense.expense_filter_final_date
      )
    ) {
      NotificationManager.error(
        "La fecha inicial debe ser menor a la fecha final",
        "ERROR",
        0
      );
      dispatch(setLoaderReportExpenseExcel(false));
      return;
    }
    endpoint += `?initial_date=${moment(
      getStore().incomeAndExpense.expense_filter_initial_date
    ).format("YYYY-MM-DD")}&final_date=${moment(
      getStore().incomeAndExpense.expense_filter_final_date
    ).format("YYYY-MM-DD")}`;
  } else if (
    !getStore().incomeAndExpense.expense_filter_initial_date &&
      !getStore().incomeAndExpense.expense_filter_final_date
  ) {
    //pass params empty
    endpoint += "?initial_date=&final_date="; //pass params empty
  } else {
    NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
    dispatch(setLoaderReportExpenseExcel(false));
    return;
  }
  if (
    getStore().incomeAndExpense.expense_filter_sede &&
      getStore().incomeAndExpense.expense_filter_sede.value
  ) {
    endpoint += `&sede=${getStore().incomeAndExpense.expense_filter_sede.value}`;
  }
  if (getStore().incomeAndExpense.search_expense) {
    endpoint += `&search=${getStore().incomeAndExpense.search_expense}`;
  }
  if (
    getStore().incomeAndExpense.expense_filter_type &&
    getStore().incomeAndExpense.expense_filter_type.value
  ) {
    endpoint += `&type_stamp_or_product=${getStore().incomeAndExpense.expense_filter_type.value}`;
  }
  
  fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Reporte de Egresos.xlsx";
          a.click();
        });
      }
    })
    .catch(() => {
      NotificationManager.error(
        "Ocurrió un error al exportar el reporte",
        "ERROR",
        0
      );
    })
    .finally(() => {
      dispatch(setLoaderReportExpenseExcel(false));
    });
};

export const actions = {
  getReportSedes,
  //INCOME
  getReportIncome,
  setInitialDateIncome,
  setFinalDateIncome,
  setSedeIncome,
  setTypeIncome,
  exportReportIncomeToExcel,
  setSearchIncome,
  getProductsAndStamps,
  //EXPENSE
  setSearchExpense,
  setInitialDateExpense,
  setFinalDateExpense,
  setSedeExpense,
  setTypeExpense,
  getReportExpense,
  exportReportExpenseToExcel,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  // INCOME
  [LOADER_REPORT_INCOME]: (state, { loader }) => {
    return {
      ...state,
      income_loader: loader,
    };
  },
  [SET_REPORT_INCOM_DATA]: (state, { data }) => {
    return {
      ...state,
      income_data: data,
    };
  },
  [SET_REPORT_INCOM_FILTER_INITIAL_DATE]: (state, { filter_initial_date }) => {
    return {
      ...state,
      income_filter_initial_date: filter_initial_date,
    };
  },
  [SET_REPORT_INCOM_FILTER_FINAL_DATE]: (state, { filter_final_date }) => {
    return {
      ...state,
      income_filter_final_date: filter_final_date,
    };
  },
  [SET_REPORT_INCOM_FILTER_SEDE]: (state, { filter_sede }) => {
    return {
      ...state,
      income_filter_sede: filter_sede,
    };
  },
  [SET_REPORT_INCOM_FILTER_TYPE]: (state, { filter_type }) => {
    return {
      ...state,
      income_filter_type: filter_type,
    };
  },
  [SET_REPORT_INCOM_PAGE]: (state, { page }) => {
    return {
      ...state,
      income_page: page,
    };
  },
  [SET_REPORT_SEDES]: (state, { sedes }) => {
    return {
      ...state,
      sedes,
    };
  },
  [LOADER_EXCEL_INCOM]: (state, { loader }) => {
    return {
      ...state,
      loader_excel_income: loader,
    };
  },
  //EXPENSES
  [LOADER_REPORT_EXPENSE]: (state, { loader }) => {
    return {
      ...state,
      expense_loader: loader,
    };
  },
  [SET_REPORT_EXPENSE_DATA]: (state, { data }) => {
    return {
      ...state,
      expense_data: data,
    };
  },
  [SET_REPORT_EXPENSE_FILTER_INITIAL_DATE]: (
    state,
    { filter_initial_date }
  ) => {
    return {
      ...state,
      expense_filter_initial_date: filter_initial_date,
    };
  },
  [SET_REPORT_EXPENSE_FILTER_FINAL_DATE]: (state, { filter_final_date }) => {
    return {
      ...state,
      expense_filter_final_date: filter_final_date,
    };
  },
  [SET_REPORT_EXPENSE_FILTER_SEDE]: (state, { filter_sede }) => {
    return {
      ...state,
      expense_filter_sede: filter_sede,
    };
  },
  [SET_REPORT_EXPENSE_FILTER_TYPE]: (state, { filter_type_expense }) => {
    return {
      ...state,
      expense_filter_type: filter_type_expense,
    };
  },
  [SET_REPORT_EXPENSE_PAGE]: (state, { page }) => {
    return {
      ...state,
      expense_page: page,
    };
  },
  [LOADER_EXCEL_EXPENSE]: (state, { loader }) => {
    return {
      ...state,
      loader_excel_expense: loader,
    };
  },
  [SEARCH_EXPENSE]: (state, { search }) => {
    return {
      ...state,
      search_expense: search,
    };
  },
  [SEARCH_INCOME]: (state, { search }) => {
    return {
      ...state,
      search_income: search,
    };
  },
  [SET_PRODUCTS_AND_STAMPS]: (state, { products_and_stamps }) => {
    return {
      ...state,
      products_and_stamps,
    };
  }
};

const initialState = {
  //global
  sedes: [],
  //income
  income_loader: false,
  income_filter_initial_date: "",
  income_filter_final_date: "",
  income_filter_sede: {},
  income_filter_type: {},
  income_data: {
    count: 0,
    results: [],
    data_graph: [],
  },
  search_income: "",
  income_page: 1,
  loader_excel_income: false,
  products_and_stamps: [],
  //expenses
  expense_loader: false,
  expense_filter_initial_date: "",
  expense_filter_final_date: "",
  expense_filter_sede: {},
  expense_filter_type: {},
  expense_data: {
    count: 0,
    results: [],
    data_graph: [],
  },
  expense_page: 1,
  search_expense: "",
  loader_excel_expense: false,
};

export default handleActions(reducers, initialState);
