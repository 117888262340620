import React, {Component} from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

const optionStyle = {maxWidth: "35px", position: "absolute", left: "89%", marginTop: "-14px"}
const btnOption = {
  height: "28px",
  width: "28px",
  background: "#FFFFFF",
  border: "1px #9B8E84 solid",
  borderRadius: "5px"
}
const sameSweetAlertProps = {
  focusCancel: false,
  focusConfirm: false,
  reverseButtons: true,
  background: "#FAFDF3",
  showCancelButton: true,
  customClass: {
    cancelButton: "btn btn-gray br-20px",
    confirmButton: "btn btn-red br-20px",
    input: "form-control col-10 mx-auto"
  }
}

class Acciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  eliminar = (id) => {
    return () => {
      Swal.fire({
        title: "¿Eliminar?",
        text: "¡No podrá revertir esta acción!",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, eliminar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.props.eliminar(id);
        }
      });
    }
  };

  editarConAction = (row) => {
    return () => {
      this.props.editarConAction(row);
    }
  };

  annulation = (id) => {
    return () => {
      Swal.fire({
        title: "Anular",
        icon: "warning",
        text: "Motivo de anulacion",
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        cancelButtonText: "<span class=\"material-icons float-left lh-18px ml-2\">close</span>&nbsp;Cancelar&nbsp;&nbsp;",
        confirmButtonText: "<span class=\"material-icons float-left lh-18px ml-2\">done</span>&nbsp;Anular&nbsp;&nbsp;&nbsp;&nbsp;",
        showLoaderOnConfirm: true,
        ...sameSweetAlertProps,
        preConfirm: (inputValue) => {
          if (inputValue && inputValue.length > 3) {
            return {cancellation_description: inputValue}
          } else {
            Swal.showValidationMessage("Campo requerido")
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("DATA IN SWEET", result)
          const reason = result.value.cancellation_description
          Swal.fire({
            title: "¿Anular?",
            text: "¡No podrá revertir esta acción!",
            icon: "question",
            cancelButtonText: "<span class=\"material-icons float-left lh-18px\">close</span> No, cancelar",
            confirmButtonText: "<span class=\"material-icons float-left lh-18px ml-2\">done</span>Si, anular&nbsp;&nbsp;&nbsp;&nbsp;",
            ...sameSweetAlertProps,
          }).then((result) => {
            if (result.value) {
              this.props.annulation(id, reason)
            }
          })
        }
      })
    }
  }

  generateTemporaryPassword = (id) => {
    return () => {
      Swal.fire({
        title: "¿Generar contraseña temporal?",
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, generar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.props.generateTemporaryPassword(id);
        }
      });
    }
  };
  toggleOption = () => this.setState({isOpen: !this.state.isOpen})

  render() {
    const {
      id,
      setMemberData,setMemberDataMaster,
      editarConAction,
      memberId,
      ver,
      row,
      editar,
      eliminar,
      details,
      annulation,
      printAction,
      viewOrPrint,
      viewOrPrintAction,
      verDetail = "",
      docsGenerated,
      confirmedPayments,
      eliminarSinConfirmacion,
      generateTemporaryPassword,
      requiredDocuments = {},
      generatedDocuments = {},
      documents = undefined,
      availableDocuments = undefined,
      documentsGenerated = undefined,
      technical_directions = undefined,
      masters = undefined,
      memberInventoryDetail,
      otherMemberInventoryDetail,
      printActionPayment,
      printActionPaymentId,
      floatingPaymentAction = {},
    } = this.props;
    const {isOpen} = this.state;

    return (
      <React.Fragment>
        <div className="d-flex justify-content-center option-web">
          {(ver !== undefined) && (
            <Link to={`${ver}/${id}/${verDetail}`} className="px-2"><i
              className="material-icons">remove_red_eye</i></Link>
          )}
          {/* {(viewOrPrint !== undefined && row.available === "N/A") && (
            <button
              type="button"
              title="Imprimir"
              onClick={() => viewOrPrintAction(row)}
              className="px-2 py-0 actions-btn btn-print"
            >
              <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
            </button>
          )} */}
          {(viewOrPrint !== undefined && row.generate_documents === true) && (
            <Link to={`${viewOrPrint}/${row.member_inventory.id}/${memberInventoryDetail}`} className="px-2">
              <i className="material-icons">remove_red_eye</i>
            </Link>
          )}
          {(otherMemberInventoryDetail !== undefined && row.generate_documents === true) && (
            <Link to={`${otherMemberInventoryDetail}/${row.member_inventory.id}/${memberInventoryDetail}`} className="px-2">
              <i className="material-icons">remove_red_eye</i>
            </Link>
          )}
          {(viewOrPrint !== undefined && row.available !== "N/A" && row.generate_documents === false) && (
            <Link to={`${viewOrPrint}/${id}/${verDetail}`} className="px-2">
              <i className="material-icons">remove_red_eye</i>
            </Link>
          )}
          {(editar !== undefined) && (
            <Link className="actions-btn bg-btn-edit px-2 py-0" to={`${editar}/${id}/edit`} title="editar">
              <img src={require("assets/img/icons/blueedit.svg")} alt="editor"/>
            </Link>
          )}
          {(printAction !== undefined && (row.generated !== row.quantity)) && (
            <button
              type="button"
              title="Imprimir"
              onClick={() => printAction(row)}
              className="px-2 py-0 actions-btn btn-print"
            >
              <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
            </button>
          )}
          {(docsGenerated !== undefined) && (
            <Link
              title="Docuemntos Impresos"
              className="py-0 actions-btn btn-docs-printed"
              to={`${docsGenerated}/${id}/documents-generated`}
            >
              <img src={require("assets/img/icons/documents.svg")} alt="Docuemntos Impresos"/>
            </Link>
          )}
          {(details !== undefined) && (
            <button
              type="button"
              title="detalle"
              onClick={() => details(row)}
              className="px-2 py-0 actions-btn btn-detail"
            >
              <img src={require("assets/img/icons/bluedetails.svg")} alt="detalle"/>
            </button>
          )}
          {(eliminar !== undefined) && (
            <button
              type="button"
              title="eliminar"
              className="px-2 py-0 actions-btn btn-dump"
              onClick={this.eliminar(id)}
            >
              <img src={require("assets/img/icons/reddump.svg")} alt="dump"/>
            </button>
          )}
          {(editarConAction !== undefined) && (
            <button
              type="button"
              title="Editar"
              className="px-2 py-0 actions-btn bg-btn-edit"
              onClick={this.editarConAction(row)}
            >
              <img src={require("assets/img/icons/blueedit.svg")} alt="Editar"/>
            </button>
          )}
          {(annulation !== undefined && (row && !row.cancelled)) && (
            <button
              type="button"
              title="eliminar"
              className="px-2 py-0 actions-btn btn-annulation"
              onClick={this.annulation(id)}
            >
              <img src={require("assets/img/icons/redannulation.svg")} alt="annulation"/>
            </button>
          )}
          {(eliminarSinConfirmacion !== undefined) && (
            <button
              type="button"
              title="eliminar"
              className="px-2 py-0 actions-btn btn-dump"
              onClick={() => eliminarSinConfirmacion(id)}
            >
              <img src={require("assets/img/icons/reddump.svg")} alt="dump"/>
            </button>
          )}
          {(generateTemporaryPassword !== undefined) && (
            <button
              type="button"
              title="Restablecer Contraseña"
              className="px-2 py-0 actions-btn btn-resetPass"
              onClick={this.generateTemporaryPassword(id)}
            >
              <img src={require("assets/img/icons/blueresetPass.svg")} alt="temporary password"/>
            </button>
          )}
          {(requiredDocuments.to !== undefined && requiredDocuments.for !== undefined) && (
            <Link className="actions-btn bg-btn-required-documents px-2 py-0"
              to={`${requiredDocuments.for}/${requiredDocuments.to}/${id}/`} title="Documentos requeridos">
              <img src={require("assets/img/icons/bluedetails.svg")} alt="Documentos requeridos"/>
            </Link>
          )}
          {(generatedDocuments.to !== undefined && generatedDocuments.for !== undefined) && (
            <Link className="custom-actions-btn bg-btn-generated-documents px-2 py-0"
              to={`${generatedDocuments.for}/${generatedDocuments.to}/${id}/`} title="Documentos generados">
              <img src={require("assets/img/icons/document.svg")} alt="Documentos"/>
            </Link>
          )}
          {(documents !== undefined) && (
            <Link
              title="Docuemntos Impresos"
              className="py-0 actions-btn btn-docs-printed"
              to={`member/${id}/${documents}`}
            >
              <img src={require("assets/img/icons/documents.svg")} alt="Docuemntos"/>
            </Link>
          )}
          {(confirmedPayments !== undefined) && (
            <Link
              title="Pagos confirmados"
              className="py-0 actions-btn btn-confirmed-payments"
              to={`member/${id}/${confirmedPayments}`}
            >
              <img src={require("assets/img/icons/confirmedpayments.svg")} alt="Pagos confirmados"/>
            </Link>
          )}
          {(technical_directions !== undefined) && (
            <Link
              title="Direcciones tecnicas"
              className="py-0 custom-actions-btn btn-technical-directions"
              to={`member/${id}/${technical_directions}`}
              onClick={() => setMemberData(row)}
            >
              <img src={require("assets/img/icons/technical-directions-icon.svg")} alt="Direcciones"/>
            </Link>
          )}

          {(masters !== undefined) && (
            <Link
              title="Maestrias"
              className="py-0 custom-actions-btn btn-masters"
              to={`member/${id}/${masters}`}
              onClick={() => setMemberDataMaster(row)}
            >
              <img src={require("assets/img/icons/masters-icon.svg")} alt="Maestrias"/>
            </Link>
          )}

          {(availableDocuments !== undefined && row.available !== "N/A" && row.generate_documents === false) && (
            <Link
              title="Docuemntos"
              className="py-0 actions-btn btn-docs-printed"
              to={`${availableDocuments}/${memberId}/${id}`}
            >
              <i className="material-icons">remove_red_eye</i>
            </Link>
          )}
          {(documentsGenerated !== undefined) && (
            <Link
              title="Docuemntos Impresos"
              className="py-0 actions-btn btn-docs-printed"
              to={`${documentsGenerated}/${memberId}/${id}`}
            >
              <img src={require("assets/img/icons/documents.svg")} alt="Docuemntos Impresos"/>
            </Link>
          )}
          {(printActionPayment !== undefined) && (
            <button onClick={() => printActionPayment(printActionPaymentId)}
              className={"px-2 py-0 actions-btn btn-print"}>
              <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
            </button>
          )}
        </div>
        {/*------------------------------------------------------------------*/}
        {/*--------------------       OPTION MOBILE        ------------------*/}
        {/*------------------------------------------------------------------*/}
        <div className="option-mobile px-0 " style={optionStyle}>
          <Dropdown
            isOpen={isOpen}
            direction="left"
            toggle={this.toggleOption}
            className="custom-dropdown">
            <DropdownToggle style={btnOption}>
              <span className="material-symbols-outlined text-center text-dark">more_vert</span>
            </DropdownToggle>
            <DropdownMenu>
              {(ver !== undefined && verDetail != undefined) && (
                <Link to={`${ver}/${id}/${verDetail}`} className="px-2">
                  <i className="material-icons">
                    remove_red_eye
                  </i>
                  <span>&nbsp;&nbsp;Ver</span>
                </Link>
              )}
              {(ver !== undefined && verDetail == undefined) && (
                <DropdownItem>
                  <Link className="icon-action" to={`${ver}/${id}/`}>
                    <i className="material-icons">remove_red_eye</i>
                    <span>&nbsp;&nbsp;Ver</span>
                  </Link>
                </DropdownItem>
              )}
              {/* {(viewOrPrint !== undefined && row.available === "N/A") && (
                <DropdownItem>
                  <a onClick={() => viewOrPrintAction(row)} className="icon-action">
                    <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
                    <span>&nbsp;&nbsp;Imprimir</span>
                  </a>
                </DropdownItem>
              )} */}
              {(viewOrPrint !== undefined && row.available !== "N/A") && (
                <DropdownItem>
                  <Link className="icon-action" to={`${viewOrPrint}/${id}/${verDetail}`}>
                    <i className="material-icons">remove_red_eye</i>
                    <span>&nbsp;&nbsp;Ver Detalle</span>
                  </Link>
                </DropdownItem>
              )}
              {(editar !== undefined) && (
                <DropdownItem>
                  <Link className="icon-action" to={`${editar}/${id}/edit`} style={{color: "#16181b"}}>
                    <img src={require("assets/img/icons/blueedit.svg")} alt={"edit"}/>
                    <span>&nbsp;&nbsp;Editar</span>
                  </Link>
                </DropdownItem>
              )}
              {/* {(printAction !== undefined && (row.generated !== row.quantity)) && (
                <DropdownItem>
                  <a onClick={() => printAction(row)} className="icon-action">
                    <img src={require("assets/img/icons/bluePrint.svg")} alt={"Imprimir"}/>
                    <span>&nbsp;&nbsp;Imprimir</span>
                  </a>
                </DropdownItem>
              )} */}
              {(docsGenerated !== undefined) && (
                <DropdownItem>
                  <Link
                    className="icon-action" to={`${docsGenerated}/${id}/documents-generated`}
                    style={{color: "#16181b"}}>
                    <img src={require("assets/img/icons/document.svg")} alt={"edit"}/>
                    <span>&nbsp;&nbsp;Documentos Generados</span>
                  </Link>
                </DropdownItem>
              )}
              {(details !== undefined) && (
                <DropdownItem>
                  <a onClick={() => details(row)} className="icon-action">
                    <img src={require("assets/img/icons/bluedetails.svg")} alt="detalle"/>
                    <span>&nbsp;&nbsp;Detalle</span>
                  </a>
                </DropdownItem>
              )}
              {(eliminar !== undefined) && (
                <DropdownItem>
                  <a className="icon-action" onClick={this.eliminar(id)}>
                    <img src={require("assets/img/icons/reddump.svg")} alt={"delete"}/>
                    <span>&nbsp;&nbsp;Eliminar</span>
                  </a>
                </DropdownItem>
              )}
              {(annulation !== undefined && (row && !row.cancelled)) && (
                <DropdownItem>
                  <a className="icon-action" onClick={this.annulation(id)}>
                    <img src={require("assets/img/icons/redannulation.svg")} alt="annulation"/>
                    <span>&nbsp;&nbsp;Anular</span>
                  </a>
                </DropdownItem>
              )
              }
              {(eliminarSinConfirmacion !== undefined) && (
                <DropdownItem>
                  <a className="icon-action" onClick={() => eliminarSinConfirmacion(id)}>
                    <img src={require("assets/img/icons/reddump.svg")} alt={"delete"}/>
                    <span>&nbsp;&nbsp;Eliminar</span>
                  </a>
                </DropdownItem>
              )}
              {(generateTemporaryPassword !== undefined) && (
                <DropdownItem>
                  <a className="icon-action" onClick={this.generateTemporaryPassword(id)}>
                    <img src={require("assets/img/icons/blueresetPass.svg")} alt="temporary password"/>
                    <span>&nbsp;&nbsp;Restablecer Contraseña</span>
                  </a>
                </DropdownItem>
              )}
              {(documents !== undefined) && (
                <DropdownItem>
                  <Link
                    className="icon-action" to={`member/${id}/${documents}`}
                    style={{color: "#16181b"}}>
                    <img src={require("assets/img/icons/document.svg")} alt={"Documentos"}/>
                    <span>&nbsp;&nbsp;Documentos Generados</span>
                  </Link>
                </DropdownItem>
              )}
              {(masters !== undefined) && (
                <DropdownItem>
                  <Link
                    className="icon-action" to={`member/${id}/${masters}`}
                    style={{color: "#16181b"}}>
                    <img src={require("assets/img/icons/masters-icon.svg")} alt={"Documentos"}/>
                    <span>&nbsp;&nbsp;Maestrias</span>
                  </Link>
                </DropdownItem>
              )}
              {(technical_directions !== undefined) && (
                <DropdownItem>
                  <Link
                    className="icon-action" to={`member/${id}/${technical_directions}`}
                    style={{color: "#16181b"}}>
                    <img src={require("assets/img/icons/technical-directions-icon.svg")} alt="Direcciones"/>
                    <span>&nbsp;&nbsp;Direcciones Técnicas</span>
                  </Link>
                </DropdownItem>
              )}
              {(viewOrPrint !== undefined && row.generate_documents === true) && (
                <DropdownItem>
                  <Link to={`${viewOrPrint}/${row.member_inventory.id}/${memberInventoryDetail}`} className="px-2">
                    <i 
                      className="material-icons"
                    >
                      remove_red_eye
                    </i>
                    <span>&nbsp;&nbsp;Ver Detalle</span>
                  </Link>
                </DropdownItem>
              )}
              {(printActionPayment !== undefined) && (
                <DropdownItem>
                  <a 
                    onClick={() => printActionPayment(printActionPaymentId)} 
                    className="icon-action"
                    style={{
                      background: "none",
                      border: "none",
                    }}
                  >
                    <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
                    <span>&nbsp;&nbsp;Imprimir</span>
                  </a>
                </DropdownItem>
              )}
              {(generatedDocuments.to !== undefined) && (
                <DropdownItem>
                  <Link
                    className="icon-action" 
                    to={`${generatedDocuments.for}/${generatedDocuments.to}/${id}/`}
                    style={{color: "#16181b"}}>
                    <img src={require("assets/img/icons/document.svg")} alt={"Documentos"}/>
                    <span>&nbsp;&nbsp;Documentos Generados</span>
                  </Link>
                </DropdownItem>
              )}
              {(requiredDocuments.to !== undefined) && (
                <DropdownItem>
                  <Link
                    className="icon-action" 
                    to={`${requiredDocuments.for}/${requiredDocuments.to}/${id}/`}
                    style={{color: "#16181b"}}>
                    <img src={require("assets/img/icons/bluedetails.svg")} alt="Documentos requeridos"/>
                    <span>&nbsp;&nbsp;Documentos Requeridos</span>
                  </Link>
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      </React.Fragment>
    );
  }
}

Acciones.propTypes = {};

export function standardActions(acciones) {
  return (cell, row) => {
    return (<Acciones id={cell} {...acciones} row={row}/>)
  };
}
