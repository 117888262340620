import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reports/stampsSold";
import ReportStampListSold from "./List";

const ms2p = (state) => {
  return {
    ...state.stampsSold,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReportStampListSold);