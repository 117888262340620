import {handleActions} from "redux-actions";
import {createReducer} from "../../baseReducer/baseReducer";
import {addIndexToArray} from "../../../../utility/constants";
import {NotificationManager} from "react-notifications";
import {api} from "../../../../utility/api";
import printJS from "print-js";
import {push} from "react-router-redux";

// ------------------------------------
// Constants
// ------------------------------------

const baseReducer = createReducer(
  "myDocuments",
  "my_document",
  "MyDocumentsForms",
  "/managements/my-documents",
);

const LOADER_PDF = "LOADER_PDF";
const DETAIL_DATA = "DETAIL_DATA_OF_MY_DOCS_";
const DETAIL_PAGE = "DETAIL_PAGE_OF_MY_DOCS";
const CURR_ID_TRANS_DOC = "CURRENT_ID_OF_TRANSACTION_DOCS";
const ID_DETAIL_TRANSACTION = "ID_DETAIL_TRANSACTION_MY_DOCS";
const GENERATED_DOC_PAGE = "_GENERATED_DOC_PAGE";
const GENERATED_DOC_DATA = "_GENERATED_DOC_DATA";
const MEMBER_ID = "MEMBER_ID";
// const ADMIN_VIEW = "ADMIN_VIEW";

const setDetailData = detailData => ({
  type: DETAIL_DATA,
  detailData,
});

const setDetailPage = detailPage => ({
  type: DETAIL_PAGE,
  detailPage,
});

const setLoaderPDF = loaderPDF => ({
  type: LOADER_PDF,
  loaderPDF,
});
const setIdDetailTrans = transactionDetailId => ({
  type: ID_DETAIL_TRANSACTION,
  transactionDetailId,
});
const setIdTrans = currentIdTrans => ({
  type: CURR_ID_TRANS_DOC,
  currentIdTrans,
});

const setGeneratedDocPage = generatedDocPage => ({
  type: GENERATED_DOC_PAGE,
  generatedDocPage,
});

const setGeneratedDocData = generatedDocData => ({
  type: GENERATED_DOC_DATA,
  generatedDocData,
});

const setMemberId = memberId => ({
  type: MEMBER_ID,
  memberId,
});

// const setAdminView = adminView => ({
//   type: ADMIN_VIEW,
//   adminView,
// });

const listTransactions = (page = 1, id) => (dispatch) => {
  const params = {page};
  params.ordering = "";
  params.search = "";

  const memberId = id;

  dispatch(baseReducer.actions.setLoader(true));
  api.get(`my_document/${memberId}/get_for_member`, params).then((response) => {
    if (response.results) {
      response.results = addIndexToArray(response.results, page)
    }
    dispatch(baseReducer.actions.setData(response));
    dispatch(baseReducer.actions.setPage(page));
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("Error al listar", "Error", 300)
    }
  }).finally(() => {
    dispatch(baseReducer.actions.setLoader(false));
  });
};

const listDetail = (page = 1, admin, memberId) => (dispatch, getStore) => {
  const storeValues = getStore()["myDocuments"]
  const params = {page};
  // const memberId = getStore().myDocuments.memberId;
  // const adminView = getStore().myDocuments.adminView;

  // params.admin_view = adminView;
  // params.member_id = memberId;

  params.admin_view = (admin && memberId) ? true : false;
  if (memberId && params.admin_view) {
    params.member_id = memberId;
  }

  params.ordering = "";
  params.search = "";
  dispatch(baseReducer.actions.setLoader(true));
  api.get(`my_document/${storeValues.currentIdTrans}/details`, params).then((response) => {
    if (response.results) {
      response.results = addIndexToArray(response.results, page)
    }
    dispatch(setDetailData(response));
    dispatch(setDetailPage(page));
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("Error al listar", "Error", 300)
    }
  }).finally(() => {
    dispatch(baseReducer.actions.setLoader(false));
    // dispatch(isAdminView(false));
  });
};

const listDocGenerated = (page = 1, admin, memberId) => (dispatch, getStore) => {
  const storeValues = getStore()["myDocuments"]
  const params = {page};
  params.ordering = "";
  params.search = "";

  params.admin_view = (admin && memberId) ? true : false;
  if (memberId && params.admin_view) {
    params.member_id = memberId;
  }

  dispatch(baseReducer.actions.setLoader(true));
  api.get(`my_document/${storeValues.transactionDetailId}/doc_generated`, params).then((response) => {
    if (response.item) {
      dispatch(baseReducer.actions.setItemValues(response.item))
      delete response.item
    }
    if (response.results) {
      response.results = addIndexToArray(response.results, page)
    }
    dispatch(setGeneratedDocData(response));
    dispatch(setGeneratedDocPage(page));
  }).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("Error al listar", "Error", 300)
    }
  }).finally(() => {
    dispatch(baseReducer.actions.setLoader(false));
  });
};

export const printAction = url_file => () => {
  if (url_file) {
    fetch(url_file).then(function (response) {
      response.blob().then(function (blob) {
        var reader = new FileReader();
        reader.onload = function () {
          printJS({
            printable: reader.result.substring(28),
            type: "pdf",
            base64: true
          });
        };
        reader.readAsDataURL(blob);
      })
    });
  } else {
    NotificationManager.error("No existe el documento a imprimir", "Error", 4000)
  }
}

const printPDF = archive => () => {
  // function isBase64(str) {
  //   try {
  //     return btoa(atob(str)) === str;
  //   } catch (err) {
  //     return false;
  //   }
  // }

  // if (isBase64(archive)) {
  //   console.log("es base64")
  // } else {
  //   console.log("no es base64")
  // }


  if (archive) {
    // print base64 with react to pr
    // printJS({
    //   printable: archive,
    //   type: "pdf",
    //   base64: true
    // });
    const dataURI = `data:application/pdf;base64,${archive}`;
    const win = window.open();
    win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
  }
};

const generatePDF = (item, onFinish, redirect = false) => (dispatch) => {
  dispatch(setLoaderPDF(true));
  dispatch(baseReducer.actions.setLoader(true));
  const transDetailId = item.id
  api.put(`my_document/${transDetailId}/generate_pdf`, {...item}).then((response) => {
    dispatch(printAction(response.doc_generated))
  }
  ).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("Error al listar", "Error", 3000)
    }
  }).finally(() => {
    dispatch(baseReducer.actions.setLoader(false));
    dispatch(setLoaderPDF(false));
    redirect && dispatch(push(`/managements/my-documents/${item.id}/documents-generated`))
    onFinish && onFinish()
  });
};

const generateFirstPDF = (memberInventory) => (dispatch) => {
  dispatch(setLoaderPDF(true));
  dispatch(baseReducer.actions.setLoader(true));
  let localItem = {
    id: memberInventory.detail_transaction,
    member_inventory_id: memberInventory.id,
    product: memberInventory.product,
    stamp: memberInventory.stamp,
  }
  api.put(`my_document/${localItem.id}/generate_pdf`, {...localItem}).then((response) => {
    dispatch(printAction(response.doc_generated))
  }
  ).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else if (err && err.body && err.body.details) {
      NotificationManager.error(err.body.details, "Error", 3000)
    } else {
      NotificationManager.error("Error al imprimir el documento", "Error", 3000)
    }
  }).finally(() => {
    dispatch(baseReducer.actions.setLoader(false));
    dispatch(setLoaderPDF(false));
  });
}
;

const getPdfDocumetAndPrint = (row) => (dispatch) => {
  dispatch(setLoaderPDF(true));
  dispatch(baseReducer.actions.setLoader(true));
  api.get(`my_document/${row.id}/get_document`).then((response) => {
    dispatch(printPDF(response.content))
  }
  ).catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else if (err && err.body && err.body.details) {
      NotificationManager.error(err.body.details, "Error", 3000)
    } else {
      console.log(err)
      NotificationManager.error("Error al imprimir el documento", "Error", 3000)
    }
  }).finally(() => {
    dispatch(baseReducer.actions.setLoader(false));
    dispatch(setLoaderPDF(false));
  });
};

const changeIdTrans = (id, page, admin, memberId) => (dispatch) => {
  dispatch(setIdTrans(id))
  setTimeout(() => {
    dispatch(listDetail(1, admin, memberId))
  }, 400)
}

const changeTransDetailId = (id, page, admin, memberId) => (dispatch) => {
  dispatch(setIdDetailTrans(id))
  setTimeout(() => {
    dispatch(listDocGenerated(1, admin, memberId))
  }, 400)
}

const initialValueTransDetail = (id) => (dispatch) => {
  dispatch(setIdTrans(null))
  dispatch(setDetailData({results: [], count: 0}))
  dispatch(setDetailPage(1))
}

const initialValueDocGenerated = (id) => (dispatch) => {
  dispatch(setIdDetailTrans(null))
  dispatch(setGeneratedDocData({results: [], count: 0}))
  dispatch(setGeneratedDocPage(1))
}

const assignMemberId = (memberId) => (dispatch) => {
  dispatch(setMemberId(memberId))
}

// const isAdminView = (isAdmin) => (dispatch) => {
//   dispatch(setAdminView(isAdmin))
// }

export const actions = {
  ...baseReducer.actions,
  initialValueDocGenerated,
  initialValueTransDetail,
  changeTransDetailId,
  generateFirstPDF,
  listDocGenerated,
  changeIdTrans,
  generatePDF,
  printAction,
  listDetail,
  listTransactions,
  assignMemberId,
  getPdfDocumetAndPrint,
  // isAdminView,
}

const reducers = {
  ...baseReducer.reducers,
  [DETAIL_DATA]: (status, {detailData}) => {
    return {
      ...status,
      detailData,
    }
  },
  [DETAIL_PAGE]: (status, {detailPage}) => {
    return {
      ...status,
      detailPage,
    }
  },
  [LOADER_PDF]: (status, {loaderPDF}) => {
    return {
      ...status,
      loaderPDF,
    }
  },
  [CURR_ID_TRANS_DOC]: (status, {currentIdTrans}) => {
    return {
      ...status,
      currentIdTrans,
    }
  },
  [ID_DETAIL_TRANSACTION]: (status, {transactionDetailId}) => {
    return {
      ...status,
      transactionDetailId,
    }
  },
  [GENERATED_DOC_PAGE]: (status, {generatedDocPage}) => {
    return {
      ...status,
      generatedDocPage,
    }
  },
  [GENERATED_DOC_DATA]: (status, {generatedDocData}) => {
    return {
      ...status,
      generatedDocData,
    }
  },
  [MEMBER_ID]: (status, {memberId}) => {
    return {
      ...status,
      memberId,
    }
  },
  // [ADMIN_VIEW]: (status, {adminView}) => {
  //   return {
  //     ...status,
  //     adminView,
  //   }
  // },
}

const initialState = {
  ...baseReducer.initialState,
  detailData: {
    results: [],
    count: 0,
  },
  generatedDocData: {
    results: [],
    count: 0,
  },
  loaderPDF: false,
  detailPage: 1,
  generatedDocPage: 1,
  currentIdTrans: null,
  transactionDetailId: null,
  memberId: null,
  // adminView: false,
}
export default handleActions(reducers, initialState);
