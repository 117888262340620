import "chart.js/auto";
import React, { Fragment } from "react";
import { Bar } from "react-chartjs-2";
import SelectDashboard from "./SelectDashboard";

export const GastosSeguros = ({
  datos,
  getExpencesInsurancesByYear,
  getExpencesInsurancesByMonth,
  expenses_insurance_year_select,
  expenses_insurance_month_select,
}) => {
  const { insurance_data, years, months, total} = datos;

  const labels = insurance_data.map((option) => option.label);
  const data_values = insurance_data.map((option) => option.value);

  const data = {
    labels,
    datasets: [
      {
        label: "Total de gastos por seguros",
        data: data_values,
        backgroundColor: [
          "#36A2EB",
          "#AD6EDF",
          "#B7B7B7",
          "#FF6384",
          "#FFCE56",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    barThickness: 30,
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <p className="title-grafica">Gastos por seguros</p>
        Total: Q{total}
        <div className="d-flex justify-content-end">
          <SelectDashboard
            options={months[expenses_insurance_year_select['value']]}
            defaultValue={expenses_insurance_month_select}
            label="Mes"
            onChangeAction={getExpencesInsurancesByMonth}
          />
          &nbsp; &nbsp;
          <SelectDashboard
            options={years}
            defaultValue={expenses_insurance_year_select}
            label="Año"
            onChangeAction={getExpencesInsurancesByYear}
          />
        </div>
      </div>
      <div style={{ lineHeight: "10px" }}>
        <br />
      </div>
      <Bar data={data} options={options} height="120" />
    </Fragment>
  );
};
export default GastosSeguros;
