import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
// eslint-disable-next-line
import { api } from "api";

const LOADER = "PASSWORD_LOADER";
const EMAIL = "EMAIL";

export const constants = {
  LOADER,
  EMAIL,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setEmail = email => ({
  type: EMAIL,
  email,
});

// ------------------------------------
// Actions
// ------------------------------------

const createPassword = (data = {}) => (dispatch) => {
  dispatch(setLoader(true));
  api.put("user/create_password", data).then(() => {
    dispatch(push("/login"));
    NotificationManager.success("Contraseña creada con éxito, puedes iniciar sesión", "Éxito", 3000);
  }).catch(() => {
    NotificationManager.error("Error, vuelva a intentar", "ERROR", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const changePassword = (data = {}) => (dispatch) => {
  dispatch(setLoader(true));
  api.put("user/change_password", data).then(() => {
    dispatch(push("/login"));
    NotificationManager.success("Contraseña cambiada con éxito, puedes iniciar sesión", "Éxito", 3000);
  }).catch((error) => {
    if (error == "invalid code") {
      NotificationManager.error("Error, el código es incorrecto", "ERROR", 4000);
    } else {
      NotificationManager.error("Error, vuelva a intentar", "ERROR", 4000);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const forgotMyPassword = (data = {}) => (dispatch) => {
  dispatch(setLoader(true));
  api.post("user/forgot_password", data).then(() => {
    dispatch(push("/login"));
    NotificationManager.success("Se ha enviado un correo para recuperar la contraseña", "Éxito", 3000);
  }).catch(() => {
    NotificationManager.error("Error, vuelva a intentar", "ERROR", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const resendTemporaryCode = (token) => (dispatch) => {
  dispatch(setLoader(true));
  const data = { token };
  api.post("user/resend_temporary_code", data).then(() => {
    NotificationManager.success("Se ha enviado un nuevo código temporal", "Éxito", 3000);
  }).catch(() => {
    NotificationManager.error("Error, vuelva a intentar", "ERROR", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getEmail = (token) => (dispatch) => {
  dispatch(setLoader(true));
  const data = { token };
  api.post("user/get_email", data).then((response) => {
    dispatch(setEmail(response));
  }).catch(() => {
    NotificationManager.error("Error, Error al obtener el correo", "ERROR", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const actions = {
  createPassword,
  changePassword,
  forgotMyPassword,
  resendTemporaryCode,
  getEmail,
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [EMAIL]: (state, { email }) => {
    return {
      ...state,
      email,
    };
  }
};

export const initialState = {
  loader: false,
  email: "",
};

export default handleActions(reducers, initialState);