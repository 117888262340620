import React from "react";
import {renderField, renderTextArea, renderSwitch} from "../../../../components/Utils/renderField";
import {Form, Field} from "react-final-form";
import BtnSaveAndCancel from "../../../../components/layout/BtnSaveAndCancel";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";

const required = (value) => (value ? undefined : "Este campo es requerido");

const ProfessionsForm = ({
  createOrUpdate,
  data = {},
  loader,
  type,
}) => {
  const onSubmit = (values) => {
    if (type === "create") {
      createOrUpdate(values);
    } else {
      createOrUpdate(data.id, values);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({handleSubmit, submitting, values}) => (
        <form
          onSubmit={handleSubmit}
          className="py-5 px-lg-5 px-md-3 px-sm-4 universities-form d-flex flex-column"
        >
          <LoadMask blur dark loading={loader}>
            <div className="row d-flex flex-row">
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="name"
                >
                Nombre de la profesión
                </label>
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  placeholder="Nombre de la universidad"
                  validate={required}
                />
              </div>
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="country"
                >
                Siglas
                </label>
                <Field
                  name="acronym"
                  component={renderField}
                  type="text"
                  placeholder="Siglas"
                  validate={required}
                />
              </div>
            </div>
            <div className="row d-flex flex-row mt-4">
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="description"
                >
                Descripción
                </label>
                <Field
                  name="description"
                  component={renderTextArea}
                  placeholder="Descripción"
                  validate={required}
                />
              </div>
              <div className="col- col-sm-12 col-md-6 col-lg-6 col-lx-6">
                <label
                  htmlFor="is_part_of_union"
                >
                ¿Es parte del colegio?
                </label>
                <div className="d-flex flex-row">
                  <Field
                    name="is_part_of_union"
                    component={renderSwitch}
                  />
                  <span>
                    {values.is_part_of_union ? "Si" : "No"}
                  </span>
                </div>
              </div>
            </div>
            <BtnSaveAndCancel
              renderBtnSubmit={true}
              renderLinkCancel={true}
              redirectToLink={"/professions"}
              subContainer={"px-0 col-lg-10 col-md-10 col-sm-12 row justify-content-center"}
            />
          </LoadMask>
        </form>
      )}
    />
  );
};

export default ProfessionsForm;
