import {connect} from "react-redux";
import {actions} from "../../../../../redux/modules/user";
import {actions as ActionsDep} from "../../../../../redux/modules/department";
const {clearValueMun} = ActionsDep
import List from "./List";


const ms2p = (state) => {
  return {
    ...state.user,
  }
};

const md2p = {...actions, clearValueMun};

export default connect(ms2p, md2p)(List);
