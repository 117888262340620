import React from "react"
import { Link } from "react-router-dom";

const nullAction = () => {
}

const BtnSaveAndCancel = (
  {
    actionBntPrev = nullAction,
    renderBtnPrev = false,
    labelBtnPre = "Anterior",
    idBtnNext = "btn-next",
    renderBtnNext = false,
    labelBtnNext = "Siguiente",
    renderLinkCancel = false,
    redirectToLink = "/",
    labelLinkCancel = "Cancelar",
    renderBtnSubmit = false,
    labelBtnSave = "Guardar",
    customContainer = "container justify-content-center w-100 mb-3 mt-3 row",
    subContainer = "col-lg-9 col-md-9 col-sm-12 row justify-content-center",
    ebipay = false,
    labelBtnPay = "Pagar",
    openModalAction,
    createPaymentLinkAction,
  }) => {
  return (
    <div className={customContainer}>
      <div className={subContainer}>
        {renderBtnPrev &&
          <button
            type="button"
            onClick={actionBntPrev}
            className="btn btn-secondary col-lg-4 col-md-4 col-sm-12 mt-3">
            <span className="material-icons" style={{ fontSize: "20px", weight: 200 }}>arrow_back_ios</span>
            &nbsp;{labelBtnPre}
          </button>
        }
        {renderLinkCancel &&
          <Link
            to={redirectToLink}
            className="btn btn-secondary col-lg-4 col-md-4 col-sm-12 mt-3">
            <span className="material-icons" style={{ fontSize: "20px" }}>close</span>
            &nbsp;{labelLinkCancel}
          </Link>
        }
        {renderBtnNext &&
          <button
            id={idBtnNext}
            type={"submit"}
            className="btn btn-primary col-lg-4 col-md-4 col-sm-12 ml-xl-5 ml-lg-5 ml-md-4 ml-sm-0 mt-3">
            {labelBtnNext}&nbsp;
            <span className="material-icons" style={{ fontSize: "20px", weight: 200 }}>arrow_forward_ios</span>
          </button>
        }
        {renderBtnSubmit &&
          <button
            type="submit"
            className="btn btn-success col-lg-4 col-md-4 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
            <span className="icon-save" style={{ width: "20px", height: "20px" }}>&nbsp;</span>
            <span className="ml-2">{labelBtnSave}</span>
          </button>
        }
        {
          ebipay && (
            <button
              type="submit"
              // onClick={() => {
              //   createPaymentLinkAction(openModalAction)
              // }}
              className="btn btn-success col-lg-4 col-md-4 col-sm-12 ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 mt-3">
              {/* <span className="icon-save" style={{ width: "20px", height: "20px" }}>&nbsp;</span> */}
              <span className="ml-2">{labelBtnPay}</span>
            </button>
          )
        }
      </div>
    </div>
  )
}


export default BtnSaveAndCancel
