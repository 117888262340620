import React, {useState} from "react"
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import "./stylesTree.css";


const ItemTree = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const {item, openCategories} = props;

  const openAction = () => openCategories(item.value);

  return (
    <div className={"no-padding tree"} style={{marginLeft: `${item.level * 15}px`}}>
      <div className="px-3 py-3 category-option" style={{height: 40}}>
        <div className="d-flex">
          {
            (item.level == 0) ?
              !item.selectable ?
                item.seleccionado ?
                  <span className="text-16 text-icon-align-center pointer font-weight-bold" onClick={openAction}> {item.title}
                    &nbsp;
                    <span title="Cerrar" className="material-symbols-outlined">expand_less</span>
                  </span>
                  :
                  <span className="text-16 text-icon-align-center pointer font-weight-bold" onClick={openAction}> {item.title}
                    &nbsp;
                    <span title="Cerrar" className="material-symbols-outlined ">expand_more</span>
                  </span>
                :
                <span className="text-16 text-icon-align-center">{item.title}</span>
              :
              (item.level == 1) ?
                !item.selectable ?
                  item.seleccionado ?
                    <span className="text-16 text-icon-align-center pointer pl-1" onClick={openAction}> {item.title}
                      &nbsp;
                      <span title="Cerrar" className="material-symbols-outlined">expand_less</span>
                    </span>
                    :
                    <span className="text-16 text-icon-align-center pointer pl-1" onClick={openAction}> {item.title}
                      &nbsp;
                      <span title="Cerrar" className="material-symbols-outlined">expand_more</span>
                    </span>
                  :
                  <span className="text-16 pl-1 text-icon-align-center">{item.title}</span>
                :
                (item.level == 2) ?
                  !item.selectable ?
                    item.seleccionado ?
                      <span className="text-16 text-icon-align-center pointer" onClick={openAction}> {item.title}
                        &nbsp;
                        <span title="Cerrar" className="material-symbols-outlined">expand_less</span>
                      </span>
                      :
                      <span className="text-16 text-icon-align-center pointer" onClick={openAction}> {item.title}
                        &nbsp;
                        <span title="Cerrar" className="material-symbols-outlined">expand_more</span>
                      </span>
                    :
                    <span className="text-16 text-icon-align-center">{item.title}</span>
                  :
                  (item.level == 3) ?
                    !item.selectable ?
                      item.seleccionado ?
                        <span className="text-16 text-icon-align-center pointer" onClick={openAction}> {item.title}
                          &nbsp;
                          <span title="Cerrar" className="material-symbols-outlined">expand_less</span>
                        </span>
                        :
                        <span className="text-16 text-icon-align-center pointer" onClick={openAction}> {item.title}
                          &nbsp;
                          <span title="Cerrar" className="material-symbols-outlined">expand_more</span>
                        </span>
                      :
                      <span className="text-16">{item.title}</span>
                    :
                    <span className="text-16 text-icon-align-center">
                      {item.title}
                    </span>
          }
        </div>
        {
          !item.es_interno && (
            <div className="container-more-event">
              <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle style={{width: "10px", background: "none", border: "none", marginTop: "-8px"}}>
                  <span className="material-symbols-outlined text-center text-dark">more_vert</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      props.selectItem(item, 1)
                      props.setOpenModal(true);
                    }}>
                    Agregar subcategoría
                  </DropdownItem>

                  <DropdownItem onClick={() => {
                    props.selectItem(item, 2);
                    props.setOpenModal(true);
                  }}>
                    Editar
                  </DropdownItem>
                  <DropdownItem onClick={() => {
                    props.selectItem(item, 3);
                  }}>
                    Eliminar
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          )
        }
      </div>
      {
        item.seleccionado && (
          item.children.map((item, index) => {
            return (
              <ItemTree
                key={index}
                item={item}
                item_padre={props.item}
                selectItem={props.selectItem}
                setOpenModal={props.setOpenModal}
                openCategories={props.openCategories}
              />
            )
          }))
      }

    </div>
  )
}
export default ItemTree;
