import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../../../assets/img/logo.png";

import "./changePassword.css"

const copyContent = async (text) => {
  try{
    await navigator.clipboard.writeText(text);
  } catch (err) {
    // eslint-disable-next-line
    console.error("Failed to copy: ", err);
  }
}

const PasswordRecovery = ({
  match,
}) => {
  

  const { params } = match;
  const { token, code } = params;

  return (
    <div className="container-fluid new-password-container">
      <div className="row vh-100 d-flex flex-row justify-content-center align-items-center">
        <div className="" style={{ maxWidth: "fit-content" }}>
          <div className="card" style={{ background: "#E2DDD9", borderRadius: "10px", minHeight: "70%", minWidth: "55%", zIndex: 11 }}>
            <div className="d-flex flex-column p-5">
              <img src={logo} className="card-img-top" alt="logo" style={{ height: "35%", width: "35%", alignSelf: "center" }} />
              <div className="card-body mt-4 d-flex flex-column align-items-center">
                <h4 className="card-title mb-4" style={{ fontWeight: "bold" }}>Contraseña nueva</h4>
                <div className="text-center" style={{ maxWidth: "60%", color: "#4B4D58" }}>
                    Se te ha generado un enlace en donde podras 
                    restablecer tu contraseña, haz click en el boton
                    para ingresar.
                </div>
                <div className="mt-4 d-flex flex-row justify-content-center mb-3">
                  <Link
                    to={`/change-password/${token}`}
                  >
                    <button type="submit" className="btn new-password-button">
                      Crear nueva contraseña
                    </button>
                  </Link>
                </div>
                <div className="text-center mt-3">
                  <p style={{ color: "#4B4D58" }}>
                    Tu codigo de verificacion es:
                  </p>
                  <p id="code" style={{ color: "#4B4D58", fontWeight: "bold" }}>
                    {code}
                  </p>
                  <p 
                    // eslint-disable-next-line
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    style={{ color: "#0A4725", fontWeight: "bold", cursor: "pointer" }}
                    value={code}
                    onClick={
                      () => copyContent(code)
                    }
                  >
                    Copiar codigo
                  </p>
                </div>
              </div>
            </div>
            <div className="p-2 text-center" style={{ color: "#4B4D58" }}>
                  Enviado desde REPORTS APP, Guatemala - Guatemala
            </div>
            <div className="card-footer text-center" style={{ background: "#0A4725", color: "#FAFDF3", fontWeight: "bold" }}>
              Copyrigth ©MARCA REPORTES, todos los derechos reservados.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;