import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/administration/permissionsAndRoles";
import RoleList from "./List";

const ms2p = (state) => {
  return {
    ...state.permissionsAndRoles,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(RoleList);
