import "chart.js/auto";
import React, { Fragment } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";
import { Bar } from "react-chartjs-2";

//Bar
export const ResumenDepartamentos = ({ datos }) => {
  const values = datos.map((item) => item.total);
  const labels = datos.map((item) => {
    return item.name.substring(0, 5) + ".";
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Agremiados por departamento",
        data: values,
        backgroundColor: "#36A2EB",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
  };

  return (
    <Fragment>
      <Fragment>
        <div className="d-flex justify-content-between">
          <p className="title-grafica">
            Resumen de agremiados por departamento
          </p>
        </div>
        <div style={{ lineHeight: "10px" }}>
          <br />
        </div>
        <Bar data={data} options={options} height="50" />
      </Fragment>
    </Fragment>
  );
};

export default ResumenDepartamentos;
