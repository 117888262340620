import React, { useEffect } from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";

import "./list.css";

const ManagementsList = ({
  getManagements,
  page,
  loader,
  onSortChange,
  managementsList,
  deleteManagement,
}) => {

  useEffect(() => {
    getManagements(page);
  }, []);

  return (
    <div className="container-fluid">
      <PageTitle
        title={"Gestiones"}
        withLink={true}
        linkTo={"new-management"}
        linkLabel={"Nueva Gestión"}
      />
      <div className="card bg-card-cofaqui br-20px py-5 px-4">
        <Grid
          hover
          data={managementsList}
          page={page}
          loading={loader}
          onPageChange={getManagements}
          onSortChange={onSortChange}
          bodyContainerClass = "bc-professions-custom"
          headerContainerClass = "hc-professions-custom"
          tableContainerClass = "professions-container-table-sm"
          tableHeaderClass = "table-header-professions"
        >
          <TableHeaderColumn
            width="100px"
            dataField="order"
            dataAlign="center"
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="200px"
            dataField="name"
          >
            Nombre de la Gestión
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="200px"
            dataField="profession"
          >
            Profesión
          </TableHeaderColumn>
          <TableHeaderColumn
            isKey
            dataField="id"
            width="100px"
            dataAlign="center"
            dataFormat={standardActions({
              editar: "management",
              eliminar: deleteManagement,
            })}
          >
            Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default ManagementsList;
