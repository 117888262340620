import React from 'react'
import "./BinnacleDetails.css"

const DetailsViewer = ({dataPost, dataPre = {}, marginLeft = "", mark = false}) => {

  return <div>
    {
      (dataPost && (typeof dataPost) === 'object') &&
      Object.keys(dataPost).map((ele, index) => {
        let name = ele
        let localPrev = dataPre
        let colorText = ""
        let renderName = ""
        let value = dataPost[ele]
        let valueStatus = null
        if (name === "active" || name === "is_active") {
          name = "Estado"
          valueStatus = value ? "Activo" : "Inactivo"
        } else if (name === "amount") {
          valueStatus = `Q. ${value}`
        }
        try {
          if (mark) {
            if (isObj(dataPre) && dataPre[ele] !== undefined && dataPre[ele] !== value) {
              colorText = "text-remark"
            } else if (isObj(dataPre) && dataPre[ele] === undefined) {
              colorText = "text-success"
            }
            localPrev = dataPre[ele] && isObj(dataPre[ele]) ? dataPre[ele] : {}
          }
        } catch (e) {
          console.log('Error', e)
        }
        let renderValue = ""
        if (value && typeof (value) === "object") {
          renderName = <label className="key-detail">{name}&nbsp;:&nbsp;</label>
          renderValue = <DetailsViewer dataPost={value} dataPre={localPrev} marginLeft="ml-4" mark={mark}/>
        } else {
          renderName = <label className={"key-detail " + colorText}>{name}&nbsp;:&nbsp;</label>
          renderValue =
            <span className={validateStyle(value, colorText)}>{`${valueStatus ? valueStatus : value}`}</span>
        }
        return <div className={"d-block line-height-13 " + marginLeft} key={index}>
          {renderName}
          {renderValue}
        </div>
      })
    }
  </div>
}

const validateStyle = (value, colorText) => {
  const typeVal = typeof value
  return `value-detail  ${colorText}`
}

const isObj = (data) => {
  return (data && typeof data === "object")
}

export default DetailsViewer
