import { api } from "api";
import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { createReducer } from "../../baseReducer/baseReducer";
import { addIndexToArray } from "../../../../utility/constants";
import Swal from "sweetalert2";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------
const DATA_HISTORY = "DATA_CASH_REGISTER_HISTORY";
const TOTAL = "DATA_CASH_TOTAL";
const LOADER_HISTORY = "LOADER_CASH_REGISTER_HISTORY";
const DATE_START_FLOATING_PAYMENT = "DATE_START_FLOATING_PAYMENT";
const DATE_END_FLOATING_PAYMENT = "DATE_END_FLOATING_PAYMENT";

const baseReducer = createReducer(
  "floatingPayments",
  "transaction",
  "TransactionForm",
  "/my-cash-register"
);
const { setLoader, setData, setPage } = baseReducer.actions;
// ------------------------------------
// Pure action
// ------------------------------------
const setDateStart = (dateStart) => ({
  type: DATE_START_FLOATING_PAYMENT,
  dateStart,
});

const setDateEnd = (dateEnd) => ({
  type: DATE_END_FLOATING_PAYMENT,
  dateEnd,
});

const setLoaderHistory = (loaderHistory) => ({
  type: LOADER_HISTORY,
  loaderHistory,
});

const setDataHistory = (dataHistory) => ({
  type: DATA_HISTORY,
  dataHistory,
});

const setTotal = (total) => ({
  type: TOTAL,
  total,
});

// ------------------------------------
// Actions
// ------------------------------------

// list pagos flotantes
const cashRegisterHistoryList =
  (page = 1) =>
  (dispatch) => {
    const params = { page };
    dispatch(setLoaderHistory(true));
    api
      .get(`transaction/floating_payments`, params)
      .then((resp) => {
        resp.results = addIndexToArray(resp.results, page);
        dispatch(setDataHistory(resp));
        dispatch(setPage(page));
      })
      .catch((err) => {
        if (err && err.details) {
          NotificationManager.error(err.details, "Error", 3000);
        } else {
          NotificationManager.error("", "Error", 3000);
        }
      })
      .finally(() => {
        dispatch(setLoaderHistory(false));
      });
  };

// list pagos confirmados
const confirmedFloatingPayments =
  (page = 1) =>
  (dispatch, getStore) => {
    const resource = getStore()["floatingPayments"];
    const params = { page };

    params.dateEnd = resource.dateEnd;
    params.dateStart = resource.dateStart;

    dispatch(setLoaderHistory(true));
    api
      .get(`transaction/confirmed_floating_payments`, params)
      .then((resp) => {
        resp.results = addIndexToArray(resp.results, page);
        dispatch(setDataHistory(resp));
        dispatch(setPage(page));
      })
      .catch((err) => {
        if (err && err.details) {
          NotificationManager.error(err.details, "Error", 3000);
        } else {
          NotificationManager.error("", "Error", 3000);
        }
      })
      .finally(() => {
        dispatch(setLoaderHistory(false));
      });
  };

const confirmed_payment = (id) => (dispatch) => {
  Swal.fire({
    title: "¿Esta seguro de confirmar el pago?",
    text: "¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "¡Sí, continuar!",
    cancelButtonText: "No, cancelar",
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      dispatch(setLoader(true));
      api
        .post(`transaction/${id}/confirmed_payment/`)
        .then(() => {
          NotificationManager.success(
            "Pago confirmado exitosamente",
            "Exito",
            3000
          );
          dispatch(cashRegisterHistoryList());
          dispatch(get_total_floating_payment());
        })
        .catch(() => {
          NotificationManager.error(
            "Error en la confirmación del pago",
            "ERROR"
          );
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  });
};

const get_total_floating_payment = () => (dispatch, getStore) => {
  api
    .get("transaction/get_total_floating_payment/")
    .then((resp) => {
      dispatch(setTotal(resp.total));
    })
    .catch(() => {
      NotificationManager.error("Error al obtener el total", "ERROR");
    });
};

const annulation_payment = (id) => (dispatch, getStore) => {
  Swal.fire({
    title: "¿Desea anular el pago?",
    text: "Devolvera el pago a pagos flotantes, ¡No podrá revertir esta acción!",
    showCancelButton: true,
    confirmButtonText: "¡Sí, contiunar!",
    cancelButtonText: "No, cancelar",
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      dispatch(setLoader(true));
      api
        .post(`transaction/${id}/annulation_payment/`)
        .then(() => {
          NotificationManager.success(
            "Pago anulado exitosamente",
            "Exito",
            3000
          );
          dispatch(confirmedFloatingPayments());
          dispatch(get_total_floating_payment());
        })
        .catch((err) => {
          console.log(err, '-----------------');
          if (err && err.details) {
            NotificationManager.error(err.details, "Error")
          }else{
            NotificationManager.error("Error en la anulación del pago", "ERROR");
          }
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  });
};

const changeDateStart = (date) => (dispatch, getStore) => {
  const resource = getStore()["floatingPayments"];
  dispatch(setDateStart(date));
  dispatch(confirmedFloatingPayments(resource.page));
};
const changeDateEnd = (date) => (dispatch, getStore) => {
  const resource = getStore()["floatingPayments"];
  dispatch(setDateEnd(date));
  dispatch(confirmedFloatingPayments(resource.page));
};

export const actions = {
  ...baseReducer.actions,
  cashRegisterHistoryList,
  confirmedFloatingPayments,
  confirmed_payment,
  annulation_payment,
  get_total_floating_payment,
  changeDateStart,
  changeDateEnd,
  setPage,
};

// ----------------------------------
// Reducer
// ----------------------------------
const reducers = {
  ...baseReducer.reducers,
  [DATA_HISTORY]: (state, { dataHistory }) => {
    return {
      ...state,
      dataHistory,
    };
  },
  [LOADER_HISTORY]: (state, { loaderHistory }) => {
    return {
      ...state,
      loaderHistory,
    };
  },
  [TOTAL]: (state, { total }) => {
    return {
      ...state,
      total,
    };
  },
  [DATE_START_FLOATING_PAYMENT]: (state, { dateStart }) => {
    return {
      ...state,
      dateStart,
    };
  },
  [DATE_END_FLOATING_PAYMENT]: (state, { dateEnd }) => {
    return {
      ...state,
      dateEnd,
    };
  },
};

const initialState = {
  ...baseReducer.initialState,
  dataHistory: {
    results: [],
    count: 0,
  },
  total: 0,
  dateStart: "",
  dateEnd: "",
};

export default handleActions(reducers, initialState);
