import React from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-date-picker";
import { ErrorMessage } from "@hookform/error-message";

const CreateEditMasters = ({ createMasters, data, loader, updateMasters, is_edit }) => {
  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name_institution: data.name_institution,
      name_master: data.name_master,
    },
  });
  const onSubmit = (data_submit) => {
    if (is_edit) {
      data_submit['id'] = data.id;
      data_submit['beneficiary'] = data.beneficiary;
      updateMasters(data_submit);
    }
    else {
      createMasters(data_submit);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Nombre del establecimiento</label>
          <input
            type="text"
            className="form-control"
            name="name_institution"
            {...register("name_institution", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name_institution"
            render={({ message }) => (
              <p className="text-danger p-0 m-0 pl-2">{message}</p>
            )}
          />
        </div>
        <div className="form-group">
          <label>Direccion del establecimiento</label>
          <input
            type="text"
            className="form-control"
            name="name_master"
            {...register("name_master", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="name_master"
            render={({ message }) => (
              <p className="text-danger p-0 m-0 pl-2">{message}</p>
            )}
          />
        </div>
        <button
          type="submit"
          className="btn btn-success w-100"
          disabled={loader}
        >
          Guardar
        </button>
      </form>
    </div>
  );
};

export default CreateEditMasters;
