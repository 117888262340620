import moment from "moment/moment"
import React, {useEffect, useState} from "react"
import ConfirmationModal from "./ConfirmationModal"
import Grid from "../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table"
import PageTitle from "../../../../components/layout/PageTitle"
import {standardActions} from "../../../../components/Utils/Grid/StandardActions"

const List = (props) => {
  const {
    detailPage,
    detailData,
    loader,
    loaderPDF,
    generatePDF,
    setItemValues,
    match: {params},
    match: { params: { memberId } },
    initialValueDocGenerated,
    admin,
    // isAdminView,
    // assignMemberId,
    // adminView,
    // match: {url},
    // me: { permissions: { member_management } },
  } = props

  function openModalAction(values) {
    setItem(values)
    if (values.stamp && values.stamp.type == 5) {
      // Open modal for stamp under approval
      setOpenModal(true)
    } else {
      let redirect = true
      if (admin && admin === true) redirect = false
      generatePDF({...values}, onFinishSubmit, redirect)
    }
  }

  const [openModal, setOpenModal] = useState(false)
  const [item, setItem] = useState(false)
  const [actions, setActions] = useState({
    printAction: openModalAction,
    docsGenerated: "/managements/my-documents",
  })


  useEffect(() => {
    // if (admin && admin === true && member_management) {
    //   // Set memberId if admin is true
    //   const {memberId} = params
    //   assignMemberId(memberId)
    //   // isAdminView(true)
    // }

    const {id} = params
    props.changeIdTrans(id, 1, admin, memberId)
    // set initial values the next view
    setItemValues({})
    initialValueDocGenerated()
  }, []);

  useEffect(() => {
    if (admin && memberId) {
      setActions({
        printAction: openModalAction,
        documentsGenerated: "/documents-generated",
        memberId: memberId,
      })
    }
  }, [admin, memberId])

  const onFinishSubmit = () => {
    setOpenModal(false)
    setItem({})
  }

  function onSubmitAction(formData) {
    let redirect = true
    if (admin && admin === true) redirect = false
    generatePDF({...item, ...formData}, onFinishSubmit, redirect)
  }

  return (
    <div>
      <ConfirmationModal
        loader={loaderPDF}
        openModal={openModal}
        onSubmit={onSubmitAction}
        closeModalAction={() => setOpenModal(false)}
      />
      <div className="col-12 px-1">
        <PageTitle
          withReturn
          title={"Mis Documentos - Disponibles"}
          colTitle="col-lg-7 col-md-7 d-flex flex-row"
          returnTo={"/managements/my-documents"}
        />
        <div className="mb-4 card card-small bg-card-cofaqui">
          <div className="px-4 py-4">
            <Grid
              hover
              page={detailPage}
              data={detailData}
              loading={loader}
              onPageChange={() => {
              }}
              onSortChange={() => {
              }}
            >
              <TableHeaderColumn
                width="200px"
                dataField="order"
                dataAlign="center"
              >
                No.
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="stamp"
                dataAlign="center"
                dataFormat={(cell, row) => {
                  let name = ""
                  if (cell) {
                    name = cell.name
                  } else if (row.product) {
                    name = row.product.name
                  }
                  return (
                    <span>
                      {name}
                    </span>
                  )
                }}
              >
                Timbre / Producto
              </TableHeaderColumn>
              <TableHeaderColumn
                width="210px"
                dataAlign="center"
                dataField="quantity"
                dataFormat={(cell, row) => {
                  return <span>
                    {parseInt(cell) - parseInt(row.generated)}/{cell}
                  </span>
                }}
              >
                Disponibles
              </TableHeaderColumn>
              <TableHeaderColumn
                width={"200px"}
                dataAlign="center"
                dataField="created"
                dataFormat={(cell, row) => {
                  let dateStr = ""
                  try {
                    dateStr = moment(cell).add(6, "hours").format("L")
                  } catch (e) {
                    console.log("error in date", e)
                  }
                  return <span>{dateStr}</span>
                }}
              >
                Fecha de compra
              </TableHeaderColumn>
              <TableHeaderColumn
                isKey
                dataField="id"
                dataAlign="center"
                dataFormat={standardActions({
                  ...actions,
                })}
              >
                Acciones
              </TableHeaderColumn>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}


export default List
