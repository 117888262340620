import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/cuenta/forgotPasswordManagement";

import PasswordRecovery from "./PasswordRecovery";
import ChangePassword from "./ChangePassword";

const ms2p = (state) => {
  return {
    ...state.forgotPasswordManagement,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ChangePassword);

export { PasswordRecovery };