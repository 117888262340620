import {connect} from "react-redux";
import {actions} from "../../../../redux/modules/member";
import {actions as Dep} from "../../../../redux/modules/department";
const {clearValueMun} = Dep;
import {actions as actionsDirections} from "../../../../redux/modules/technical_directions";
import {actions as actionsMasters} from "../../../../redux/modules/beneficiary_masters";

import List from "./List";


const ms2p = (state) => {
  return {
    ...state.member,
  }
};

const md2p = {...actions, clearValueMun, setMemberData: actionsDirections.setDataItemBeneficiary, setMemberDataMaster: actionsMasters.setDataItemBeneficiaryMasters};

export default connect(ms2p, md2p)(List);
