import React, { Fragment, useEffect } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
//Components
import PageTitle from "../../../components/layout/PageTitle";
import Filter from "../../../components/Utils/Filters/Filter";
import Grid from "../../../components/Utils/Grid";
import excelExportPN from "../../../../../assets/img/icons/excel.png";
import LoadMask from "../../../components/Utils/LoadMask/LoadMask";
import moment from "moment";

const Contributions = (props) => {
  //State
  const { loader, institutions, contributions_data, page, loader_report} = props;

  //Actions
  const {
    getReportContributions,
    getReportContributionsInstitutions,
    setInitialDate,
    setFinalDate,
    setInstitution,
    exportReportContributionsToExcel,
  } = props;

  // Cuando se monta el componente
  useEffect(() => {
    getReportContributionsInstitutions();
    getReportContributions(page || 1);
  }, []);
  return (
    <Fragment>
      <PageTitle
        title="Aportaciones"
        customContainer="w-100 flex-wrap justify-content-between mb-3 pt-2"
      />
      <div className="card bg-card-cofaqui br-20px p-4">
        <Filter
          stylesFilterContainer="d-flex flex-wrap "
          actionFilter={getReportContributions}
          classNameContainerDate="d-flex flex-wrap  vertical-bar-separator "
          startDate={{
            show: true,
            label: "Fecha inicio",
            onChangeAction: setInitialDate,
          }}
          endDate={{
            show: true,
            label: "Fecha fin",
            onChangeAction: setFinalDate,
          }}
          selects={[
            {
              show: true,
              options: institutions,
              label: "Instituciones",
              onChangeAction: setInstitution,
              placeholder: "Seleccione una institución",
            },
          ]}
        />

        <div className="d-flex justify-content-between flex-wrap mb-2 align-items-center">
          <h4 className="m-0 p-0">Total: Q {contributions_data.total || 0}</h4>
          <button className="btn btn-green-generic" onClick={exportReportContributionsToExcel}>
            <img src={excelExportPN} alt="" />
            &nbsp; {
              loader_report ? ( "Exportando..." ) : ( "Exportar" )
            } 
          </button>
        </div>
        <LoadMask loading={loader} blur>
          <Grid
            hover
            responsive
            data={contributions_data}
            page={page}
            loading={loader}
            onPageChange={getReportContributions}
          >
            <TableHeaderColumn
              dataAlign="start"
              width="100px"
              isKey
              dataField="order"
            >
              No.
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="created"
              dataFormat={(cell) => {
                let dateStr = ""
                if (cell) {
                  dateStr = moment(cell).format("DD/MM/YYYY - HH:mm")
                }
                return <span>{dateStr}</span>
              }}
            >
              Fecha y hora
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="name_member"
            >
              Agremiado
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="name_product"
            >
              Producto
            </TableHeaderColumn>
            {/* <TableHeaderColumn
              dataAlign="center"
              width="100px"
              dataField="quantity"
            >
              Cantidad
            </TableHeaderColumn> */}
            <TableHeaderColumn
              dataAlign="center"
              width="150px"
              dataField="institution_name"
            >
              Institución
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="end"
              width="150px"
              dataField="amount"
              dataFormat={(cell) => {
                let dateStr = "---"
                if (cell) {
                  dateStr = `Q ${cell}`
                }
                return <span>{dateStr}</span>
              }}
            >
              Total
            </TableHeaderColumn>
          </Grid>
        </LoadMask>
      </div>
    </Fragment>
  );
};

export default Contributions;
