import React, { useState } from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import PasswordValidationCard from "./PasswordValidationCard";

import womanscientist from "../../../../../assets/img/womanscientist.png";

import "./changePassword.css";

const NewPassword = ({
  createPassword,
  changePassword,
  resendTemporaryCode,
  getEmail,
  email,
  match,
}) => {

  const location = window.location.href.includes("new-password") || window.location.href.includes("first-login");

  const type = location ? "create" : "change";

  const [formState, setFormState] = useState({});

  const [passwordValidations, setPasswordValidations] = useState({})

  const { params } = match;
  const { token } = params;

  return (
    <div className="h-100">
      {/* <LoadMask loading={false} light> */}
      <div className="container-fluid" style={{ color: "#4B4D58" }}>
        <div className="row">
          <div className="d-none d-md-block d-lg-block d-xl-block col-lg-7 col-md-6">
            <div className="row h-100">
              <img src={womanscientist} className="img-fluid" style={{ minHeight: "100%", objectFit: "cover" }} alt="woman-scientist" />
            </div>
          </div>
          <div 
            className="col- col-sm-12 col-md-6 col-lg-5 d-flex flex-row align-items-center justify-content-center forgot-password-form" 
            style={{ 
              minHeight: "100vh",
            }}
          >
            <div
              className="card change-password-card"
              style={{
                background: "#E2DDD9",
                minHeight: "70%",
                zIndex: 11,
                borderRadius: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.25)"
              }}
            >
              <div className="d-flex flex-column">
                <div 
                  className="card-body d-flex flex-column align-items-center" 
                >
                  <h4 className="card-title mb-4" style={{ fontWeight: "bold" }}>Nueva contraseña</h4>
                  <div className="p-4 d-flex flex-column" style={{ background: "#FAFDF3", borderRadius: "8px", minWidth: "96%" }}>
                    <PasswordValidationCard formState={formState} setPasswordValidations={setPasswordValidations} />
                  </div>
                  {
                    type === "create" ? (
                      <ChangePasswordForm
                        createOrChange={createPassword}
                        token={token}
                        type={type}
                        setFormState={setFormState}
                        passwordValidations={passwordValidations}
                      />
                    ) : (
                      <ChangePasswordForm
                        createOrChange={changePassword}
                        token={token}
                        type={type}
                        resendTemporaryCode={resendTemporaryCode}
                        getEmail={getEmail}
                        email={email}
                        setFormState={setFormState}
                        passwordValidations={passwordValidations}
                      />
                    )
                  }
                  <span>
                    {/* <Link to="/login">
                      <span style={{ color: "#0A4725" }}>
                        Regresar
                      </span>
                    </Link> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </LoadMask> */}
    </div>
  );
};

export default NewPassword;