import React, { useEffect, useState } from "react";
import {Form, Field} from "react-final-form";
import {
  renderField,
  renderNumber,
  renderSwitch,
  selectField
} from "../../../components/Utils/renderField";
import {GENDER_OPTION, MARITAL_STATUS_OPTION, STATE} from "../../../../utility/constants";
import {renderDatePicker} from "../../../components/Utils/renderField/renderField";
import FinalFormConnection from "../../../components/ReduxFormConn/FinalFormConnection";
import {composeValidators, fieldRequired, fieldSelectRequired, minDigits} from "../../../../utility/validation";
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel";

import "./members.css";

const EMPTY_OPTION = []

const MemberForm = (
  {
    show,
    onSubmit,
    initialValues,
    subTitle,
    useButtonSave = false,
    dataDep,
    dataMunBirth,
    dataMunResi,
    getMunicipalities,
    roleSelectList,
    permissions = {},
    countriesList,
    actionIsMember,
    actionMaritialStatus,
    redirectTo = "/",
    path,
  }) => {

  const rolePermissionsManagement = permissions.role_permissions_management;
  const memberManagement = permissions.member_management;

  const [deactivated, setDeactivated] = useState(false);

  useEffect(() => {
    if (path.includes("managements/update-my-information")) {
      setDeactivated(true)
    }
  }, [path])

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        const errors = {}
        if (values.country_of_residence_id === "GT" && !values.department_of_residence_id) {
          errors.department_of_residence_id = "Campo requerido"
        }
        if (values.department_of_residence_id && !values.municipality_of_residence_id) {
          errors.municipality_of_residence_id = "Campo requerido"
        }
        if (values.country_of_birth_id === "GT" && !values.birth_department_id) {
          errors.birth_department_id = "Campo requerido"
        }
        if (values.birth_department_id && !values.municipality_of_birth_id) {
          errors.municipality_of_birth_id = "Campo requerido"
        }
        return errors
      }}
      initialValues={initialValues}
      subscription={{submitting: true, values: true}}
      render={({
        handleSubmit,
        values: {country_of_residence_id, country_of_birth_id, birth_department_id, department_of_residence_id}
      }) =>
        <form onSubmit={handleSubmit} className={`${show ? "" : "d-none"}`}>
          <FinalFormConnection form={"MemberStep1"}/>
          <div className={"card bg-card-cofaqui form-validate p-3"}>
            {subTitle &&
            <h5 className="roboto weight-600 ml-4">{subTitle}</h5>
            }
            <div className="inputs-container">
              <div className="">
                <label htmlFor="first_name">Nombre(s)</label>
                <Field
                  validate={fieldRequired}
                  typeInput='text'
                  name={"first_name"}
                  component={renderField}
                />
              </div>
              <div className="">
                <label htmlFor="last_name">Apellido(s)</label>
                <Field
                  validate={fieldRequired}
                  typeInput='text'
                  name={"last_name"}
                  component={renderField}
                />
              </div>
              <div className="">
                <label htmlFor="country_of_birth_id">País de nacimiento</label>
                <Field
                  valueKey={"id"}
                  labelKey={"name"}
                  options={countriesList}
                  component={selectField}
                  validate={fieldRequired}
                  name={"country_of_birth_id"}
                />
              </div>
              <div className="">
                <label htmlFor="country_of_residence_id">País de residencia</label>
                <Field
                  valueKey={"id"}
                  labelKey={"name"}
                  options={countriesList}
                  component={selectField}
                  validate={fieldRequired}
                  name={"country_of_residence_id"}
                />
              </div>
            </div>
            <div className="inputs-container">
              <div className="">
                <div>
                  <label htmlFor="birth_date">Fecha de nacimiento</label>
                  <Field
                    name={"birth_date"}
                    validate={fieldRequired}
                    maxDate={new Date()}
                    customClass={"form-control"}
                    component={renderDatePicker}
                  />
                </div>
              </div>
              <div className="">
                <label htmlFor="dpi">DPI</label>
                <Field
                  name={"dpi"}
                  maxLength={13}
                  thousandSeparator={""}
                  component={renderNumber}
                  validate={composeValidators(fieldRequired, minDigits(13))}
                />
              </div>
              <div className="">
                <label htmlFor="birth_department_id">Departamento de nacimiento</label>
                <Field
                  valueKey={"id"}
                  labelKey={"name"}
                  options={country_of_birth_id !== "GT" ? EMPTY_OPTION : dataDep}
                  component={selectField}
                  name={"birth_department_id"}
                  customAction={(ele) => getMunicipalities(ele, 2)}
                  disabled={country_of_birth_id !== "GT"}
                />
              </div>
              <div className="">
                <label htmlFor="municipality_of_birth_id">Municipio de nacimiento</label>
                <Field
                  valueKey={"id"}
                  labelKey={"name"}
                  options={!birth_department_id ? EMPTY_OPTION : [...dataMunBirth]}
                  component={selectField}
                  name={"municipality_of_birth_id"}
                  disabled={country_of_birth_id !== "GT"}
                />
              </div>

            </div>
            <div className="inputs-container">
              <div className="">
                <label htmlFor="email">Correo electrónico</label>
                <Field
                  validate={fieldRequired}
                  name={"email"}
                  typeInput='text'
                  component={renderField}
                />
              </div>
              <div className="">
                <label htmlFor="role_id">Rol</label>
                <Field
                  name={"role_id"}
                  valueKey={"id"}
                  labelKey={"name"}
                  options={roleSelectList}
                  disabled={!rolePermissionsManagement || deactivated}
                  validate={fieldRequired}
                  component={selectField}
                />
              </div>
              <div className="">
                <label htmlFor="department_of_residence_id">Departamento de residencia</label>
                <Field
                  name='department_of_residence_id'
                  valueKey={"id"}
                  labelKey={"name"}
                  component={selectField}
                  options={country_of_residence_id !== "GT" ? EMPTY_OPTION : dataDep}
                  customAction={(ele) => getMunicipalities(ele, 1)}
                  disabled={country_of_residence_id !== "GT"}
                />
              </div>
              <div className="">
                <label htmlFor="municipality_of_residence_id">Municipio de residencia</label>
                <Field
                  valueKey={"id"}
                  labelKey={"name"}
                  options={!department_of_residence_id ? EMPTY_OPTION : dataMunResi}
                  component={selectField}
                  name={"municipality_of_residence_id"}
                  disabled={country_of_residence_id !== "GT"}
                />
              </div>
            </div>
            <div className="inputs-container">
              <div className="">
                <label htmlFor="phone">Teléfono Fijo</label>
                <Field
                  maxLength={8}
                  name={"phone"}
                  thousandSeparator={""}
                  component={renderNumber}
                  validate={composeValidators(fieldRequired, minDigits(8))}
                />
              </div>
              <div className="">
                <label htmlFor="mobile_phone">Teléfono Movil</label>
                <Field
                  maxLength={8}
                  name={"mobile_phone"}
                  thousandSeparator={""}
                  component={renderNumber}
                  validate={composeValidators(fieldRequired, minDigits(8))}
                />
              </div>
              <div className="">
                <label htmlFor="address">Dirección de residencia (línea 1)</label>
                <Field
                  validate={fieldRequired}
                  typeInput='text'
                  name={"address"}
                  component={renderField}
                />
              </div>
              <div className="">
                <label htmlFor="address_line_2">Dirección de residencia (línea 2)</label>
                <Field
                  typeInput='text'
                  name={"address_line_2"}
                  component={renderField}
                />
              </div>
            </div>
            <div className="inputs-container">
              <div className="">
                <label htmlFor="gender">Género</label>
                <Field
                  name={"gender"}
                  options={GENDER_OPTION}
                  component={selectField}
                  validate={fieldSelectRequired}
                />
              </div>
              <div className="">
                <label htmlFor="marital_status">Estado civil</label>
                <Field
                  name="marital_status"
                  component={selectField}
                  validate={fieldRequired}
                  options={MARITAL_STATUS_OPTION}
                  customAction={actionMaritialStatus}
                />
              </div>
              {!rolePermissionsManagement || deactivated ? (<div className=""></div>) : (
                <div className="">
                <label htmlFor="state">Estado</label>
                <Field
                  name={"state"}
                  options={STATE}
                  disabled={!rolePermissionsManagement || deactivated}
                  validate={fieldRequired}
                  component={selectField}
                />
              </div>)}
              {
                subTitle && memberManagement ? (
                  <div className="">
                    <label htmlFor="is_member">Es agremiado</label>
                    <Field
                      name='is_member'
                      width={40}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      component={renderSwitch}
                      customAction={actionIsMember}
                      customClassCon={"switch-step3 ml-3"}
                    />
                  </div>
                ) : (<div className="form-group has-feedback flex-1 mx-4"></div>)
              }
            </div>
          </div>
          <BtnSaveAndCancel
            idBtnNext="btn-step-1"
            renderLinkCancel={true}
            redirectToLink={
              deactivated ? "/managements" : redirectTo
            }
            renderBtnNext={!useButtonSave}
            renderBtnSubmit={useButtonSave}
            customContainer="container justify-content-center w-100 my-5 row"
          />
        </form>
      }/>
  )
}

export default MemberForm;
