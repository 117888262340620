import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { api } from "api";
import { addIndexToArray } from "../../../../utility/constants";
import { initialize as initializeForm, change } from "redux-form";
import moment from "moment";
import Swal from "sweetalert2";

const LOADER_REPORT_CONTRIBUTIONS = "LOADER_REPORT_CONTRIBUTIONS";
const SET_REPORT_CONTRIBUTIONS_DATA = "SET_REPORT_CONTRIBUTIONS_DATA";
const SET_REPORT_CONTRIBUTIONS_FILTER_INITIAL_DATE =
  "SET_REPORT_CONTRIBUTIONS_FILTER_INITIAL_DATE";
const SET_REPORT_CONTRIBUTIONS_FILTER_FINAL_DATE =
  "SET_REPORT_CONTRIBUTIONS_FILTER_FINAL_DATE";
const SET_REPORT_CONTRIBUTIONS_FILTER_INSTITUTION =
  "SET_REPORT_CONTRIBUTIONS_FILTER_INSTITUTION";
const SET_REPORT_CONTRIBUTIONS_PAGE = "SET_REPORT_CONTRIBUTIONS_PAGE";
const SET_REPORT_CONTRIBUTIONS_INSTITUTIONS =
  "SET_REPORT_CONTRIBUTIONS_INSTITUTIONS";
const LOADER_REPORT_CONTRIBUTIONS_EXPORT = "LOADER_REPORT_CONTRIBUTIONS_EXPORT";
// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
  type: LOADER_REPORT_CONTRIBUTIONS,
  loader,
});

export const setLoaderExport = (loader) => ({
  type: LOADER_REPORT_CONTRIBUTIONS_EXPORT,
  loader,
});


export const setReportContributionsData = (contributions_data) => ({
  type: SET_REPORT_CONTRIBUTIONS_DATA,
  contributions_data,
});

export const setReportContributionsFilterInitialDate = (
  filter_initial_date
) => ({
  type: SET_REPORT_CONTRIBUTIONS_FILTER_INITIAL_DATE,
  filter_initial_date,
});

export const setReportContributionsFilterFinalDate = (filter_final_date) => ({
  type: SET_REPORT_CONTRIBUTIONS_FILTER_FINAL_DATE,
  filter_final_date,
});

export const setReportContributionsFilterInstitution = (
  filter_institution
) => ({
  type: SET_REPORT_CONTRIBUTIONS_FILTER_INSTITUTION,
  filter_institution,
});

export const setReportContributionsPage = (page) => ({
  type: SET_REPORT_CONTRIBUTIONS_PAGE,
  page,
});

export const setReportContributionsInstitutions = (institutions) => ({
  type: SET_REPORT_CONTRIBUTIONS_INSTITUTIONS,
  institutions,
});

// ------------------------------------
// Actions
// ------------------------------------

export const getReportContributions =
  (page = 1) =>
    (dispatch, getStore) => {
      dispatch(setLoader(true));
      let params = {
        page: page || getStore().contributions.page,
      };

      dispatch(setReportContributionsPage(params.page));

      if (
        getStore().contributions.filter_institution &&
      getStore().contributions.filter_institution.value
      ) {
        params.institution = getStore().contributions.filter_institution.value;
      }

      if (
        getStore().contributions.filter_initial_date &&
      getStore().contributions.filter_final_date
      ) {
        if (
          moment(getStore().contributions.filter_initial_date).isAfter(
            getStore().contributions.filter_final_date
          )
        ) {
          NotificationManager.error(
            "La fecha inicial debe ser menor a la fecha final",
            "ERROR",
            0
          );
          dispatch(setLoader(false));
          return;
        }
        params.initial_date = moment(
          getStore().contributions.filter_initial_date
        ).format("YYYY-MM-DD");
        params.final_date = moment(
          getStore().contributions.filter_final_date
        ).format("YYYY-MM-DD");
      } else if (
        !getStore().contributions.filter_initial_date &&
      !getStore().contributions.filter_final_date
      ) {
      //pass params empty
      } else {
        NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
        dispatch(setLoader(false));
        return;
      }

      api
        .get("/contributions", params)
        .then((response) => {
          if (response.results) response.results = addIndexToArray(response.results, page);
          dispatch(setReportContributionsData(response));
        })
        .catch(() => {
          NotificationManager.error(
            "Ocurrió un error al obtener los datos",
            "ERROR",
            0
          );
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
      dispatch(setLoader(false));
    };

export const getReportContributionsInstitutions =
  () => (dispatch, getStore) => {
    api
      .get("/contributions/getInstitucions")
      .then((response) => {
        if (response) {
          dispatch(setReportContributionsInstitutions(response));
          dispatch(setReportContributionsFilterInstitution(response[0]));
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

export const exportReportContributionsToExcel = () => (dispatch, getStore) => {
  dispatch(setLoaderExport(true));

  let endpoint = "/api/contributions/export_to_excel_report_contributions/";

  if (
    getStore().contributions.filter_initial_date &&
    getStore().contributions.filter_final_date
  ) {
    if (
      moment(getStore().contributions.filter_initial_date).isAfter(
        getStore().contributions.filter_final_date
      )
    ) {
      NotificationManager.error(
        "La fecha inicial debe ser menor a la fecha final",
        "ERROR",
        0
      );
      dispatch(setLoader(false));
      return;
    }
    endpoint += `?initial_date=${moment(
      getStore().contributions.filter_initial_date
    ).format("YYYY-MM-DD")}&final_date=${moment(
      getStore().contributions.filter_final_date
    ).format("YYYY-MM-DD")}`;
  } else if (
    !getStore().contributions.filter_initial_date &&
    !getStore().contributions.filter_final_date
  ) {
    //pass params empty
    endpoint += "?initial_date=&final_date="; //pass params empty
  } else {
    NotificationManager.error("Debe seleccionar ambas fechas", "ERROR", 0);
    dispatch(setLoader(false));
    return;
  }
  if (
    getStore().contributions.filter_institution &&
    getStore().contributions.filter_institution.value
  ) {
    endpoint += `&institution=${
      getStore().contributions.filter_institution.value
    }`;
  }

  fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Reporte de contribuciones.xlsx";
          a.click();
        });
      }
    })
    .catch(() => {
      NotificationManager.error(
        "Ocurrió un error al exportar el reporte",
        "ERROR",
        0
      );
    })
    .finally(() => {
      dispatch(setLoaderExport(false));
    });
};

// Filters
export const setInitialDate = (date) => (dispatch, getStore) => {
  dispatch(setReportContributionsFilterInitialDate(date));
};

export const setFinalDate = (date) => (dispatch, getStore) => {
  dispatch(setReportContributionsFilterFinalDate(date));
};

export const setInstitution = (institution) => (dispatch, getStore) => {
  dispatch(setReportContributionsFilterInstitution(institution));
};

export const actions = {
  getReportContributions,
  getReportContributionsInstitutions,
  setInitialDate,
  setFinalDate,
  setInstitution,
  exportReportContributionsToExcel,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER_REPORT_CONTRIBUTIONS]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [SET_REPORT_CONTRIBUTIONS_DATA]: (state, { contributions_data }) => {
    return {
      ...state,
      contributions_data,
    };
  },
  [SET_REPORT_CONTRIBUTIONS_FILTER_INITIAL_DATE]: (
    state,
    { filter_initial_date }
  ) => {
    return {
      ...state,
      filter_initial_date,
    };
  },
  [SET_REPORT_CONTRIBUTIONS_FILTER_FINAL_DATE]: (
    state,
    { filter_final_date }
  ) => {
    return {
      ...state,
      filter_final_date,
    };
  },
  [SET_REPORT_CONTRIBUTIONS_FILTER_INSTITUTION]: (
    state,
    { filter_institution }
  ) => {
    return {
      ...state,
      filter_institution,
    };
  },
  [SET_REPORT_CONTRIBUTIONS_PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [SET_REPORT_CONTRIBUTIONS_INSTITUTIONS]: (state, { institutions }) => {
    return {
      ...state,
      institutions,
    };
  },
  [LOADER_REPORT_CONTRIBUTIONS_EXPORT]: (state, { loader }) => {
    return {
      ...state,
      loader_report: loader,
    };
  }
};

const initialState = {
  loader: false,
  filter_initial_date: "",
  filter_final_date: "",
  filter_institution: {},
  institutions: [],
  contributions_data: {
    count: 0,
    results: [],
    total: 0,
  },
  page: 1,
  loader_report: false,
};

export default handleActions(reducers, initialState);
