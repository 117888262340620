import {handleActions} from "redux-actions";
import {api} from "api";

// ------------------------------------
// Constants
// ------------------------------------
const storeId = 'department'
const constants = {
  LOADER: `${storeId.toUpperCase()}_LOADER`,
  DATA_DEP: `${storeId.toUpperCase()}_DATA_DEP`,
  DATA_MUN_RESI: `${storeId.toUpperCase()}_DATA_MUN1`,
  DATA_MUN_BIRTH: `${storeId.toUpperCase()}_DATA_MUN2`,
};

const setDataMunRes = dataMunResi => ({
  type: constants.DATA_MUN_RESI,
  dataMunResi,
});
const setDataMunBirth = dataMunBirth => ({
  type: constants.DATA_MUN_BIRTH,
  dataMunBirth,
});
const setDataDep = dataDep => ({
  type: constants.DATA_DEP,
  dataDep,
});

const getDepartments = () => (dispatch) => {
  api.get('department/list_option',).then((data) => {
    dispatch(setDataDep(data));
  }).catch(() => {
  }).finally(() => {
  });
}

const getMunicipalities = (id, numDataToSet = 1) => (dispatch) => {
  if (id) {
    if(numDataToSet === 1){
      dispatch(setDataMunRes([]))
    } else if(numDataToSet === 2){
      dispatch(setDataMunBirth([]))
    }
    api.get(`department/municipalities/${id}`, {}).then((data) => {
      if (numDataToSet === 1) {
        dispatch(setDataMunRes(data));
      } else if (numDataToSet === 2) {
        dispatch(setDataMunBirth(data));
      }
    }).catch(() => {
    }).finally(() => {
    });
  }
}

const clearValueMun = ()=>(dispatch)=>{
  dispatch(setDataMunBirth([]))
  dispatch(setDataMunRes([]))
}

export const actions = {
  clearValueMun,
  getDepartments,
  getMunicipalities,
}

const reducers = {
  [constants.DATA_MUN_BIRTH]: (state, {dataMunBirth}) => {
    return {
      ...state,
      dataMunBirth,
    };
  }, [constants.DATA_MUN_RESI]: (state, {dataMunResi}) => {
    return {
      ...state,
      dataMunResi,
    };
  },
  [constants.DATA_DEP]: (state, {dataDep}) => {
    return {
      ...state,
      dataDep,
    };
  },
}
const initialState = {
  dataDep: [],
  dataMunResi: [],
  dataMunBirth: [],
}

export default handleActions(reducers, initialState);
