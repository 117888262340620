import React from "react";
import { renderField } from "../../Utils/renderField/renderField";
import { Form, Field } from "react-final-form";

const ExampleForm = ({
  createExample,
  getDataExample,
  data, // initial data
  arrayData, // initial array data
}) => {

  return (
    <React.Fragment>
            ejemplo de formulario de creacion
      <Form
        onSubmit={createExample}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="title">title</label>
              <Field
                name="title"
                component={renderField}
                type="text"
                placeholder="title"
              />
            </div>
            <div>
              <label htmlFor="body">body</label>
              <Field
                name="body"
                component={renderField}
                type="text"
                placeholder="body"
              />
            </div>
            <div>
              <label htmlFor="userId">userId</label>
              <Field
                name="userId"
                component={renderField}
                type="text"
                placeholder="userId"
              />
            </div>
            <br />
            <div className="buttons">
              <button type="submit" disabled={submitting}>
                Submit
              </button>
              <button
                type="button"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
    
                Reset
              </button>
            </div>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
      <hr />
            ejemplo autorrellenando el formulario con los datos ingresados en el anterior
      <Form
        onSubmit={() => {}}
        initialValues={data} // initial data
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="title">title</label>
              <Field
                name="title"
                component={renderField}
                type="text"
                placeholder="title"
                disabled={true}
              />
            </div>
            <div>
              <label htmlFor="body">body</label>
              <Field
                name="body"
                component={renderField}
                type="text"
                placeholder="body"
                disabled={true}
              />
            </div>
            <div>
              <label htmlFor="userId">userId</label>
              <Field
                name="userId"
                component={renderField}
                type="text"
                placeholder="userId"
                disabled={true}
              />
            </div>
            <br />
            {/* <div className="buttons">
                            <button type="submit" disabled={submitting}>
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={form.reset}
                                disabled={submitting || pristine}
                            >

                                Reset
                            </button>
                        </div>
                        <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
      <hr />
            ejemplo de formulario de consulta
      <Form
        onSubmit={(params) => getDataExample(params)}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="postId">Post id</label>
              <Field
                name="postId"
                component={renderField}
                type="text"
                placeholder="postId"
              />
            </div>
            <br />
            <div className="buttons">
              <button type="submit" disabled={submitting}>
                                Submit
              </button>
              <button
                type="button"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                                Reset
              </button>
            </div>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
      {
        arrayData && arrayData.length > 0 ?
          arrayData.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div key={index + "postId"}>
                  <label htmlFor={item.postId}>postId</label>
                  <p id={item.postId}>{item.postId}</p>
                </div>
                <div key={index + "title"}>
                  <label htmlFor={item.postId+"title"}>title</label>
                  <p id={item.postId+"title"}>{item.title}</p>
                </div>
                <div key={index + "body"}>
                  <label htmlFor={item.postId+"body"}>body</label>
                  <p id={item.postId+"body"}>{item.body}</p>
                </div>
              </React.Fragment>
            )
          }
          )
          : null
      }
    </React.Fragment>
  );
};

export default ExampleForm;