import {connect} from "react-redux";
import ListFloatingPayments from "./List";
import {actions} from "../../../../../redux/modules/administration/floatingPayments";


const ms2p = (state) => {
  return {
    ...state.floatingPayments,
  }
};

const md2p = {...actions};

export default connect(ms2p, md2p)(ListFloatingPayments);
