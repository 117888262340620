import React from "react"
import Modal from "react-responsive-modal";
import {Form, Field} from "react-final-form";
import {renderField} from "../../../../../components/Utils/renderField";
import {fieldRequired} from "../../../../../../utility/validation";


const FormModal = ({openModal, closeModalAction, data, actionOk}) => {

  return (
    <div>
      <Modal
        center
        open={openModal}
        closeOnEsc={false}
        onClose={closeModalAction}
        closeOnOverlayClick={false}
        styles={{
          modal: {
            width: "40%",
            padding: "2.5%",
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: "#B0A196"
          }
        }}
      >
        <div>
          <Form
            onSubmit={() => {
            }}
            render={({handleSubmit}) => {
              return (
                <form onSubmit={handleSubmit}>
                  <center>
                    <h4>
                      {data.name}
                    </h4>
                  </center>
                  <div>
                    <div className="col-12">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                          <label htmlFor="field1">Campo 1</label>
                          <Field
                            name="field1"
                            component={renderField}
                            validate={fieldRequired}
                          />
                        </div>
                        <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                          <label htmlFor="field2">Campo 2</label>
                          <Field
                            name="field2"
                            component={renderField}
                            // validate={fieldRequired}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                          <label htmlFor="field3">Campo 1</label>
                          <Field
                            name="field3"
                            component={renderField}
                            // validate={fieldRequired}
                          />
                        </div>
                        <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                          <label htmlFor="field24">Campo 2</label>
                          <Field
                            name="field24"
                            component={renderField}
                            // validate={fieldRequired}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                          <label htmlFor="cashier_id">Campo 1</label>
                          <Field
                            name="cashier_id"
                            component={renderField}
                            // validate={fieldRequired}
                          />
                        </div>
                        <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                          <label htmlFor="cashier_id">Campo 2</label>
                          <Field
                            name="cashier_id"
                            component={renderField}
                            // validate={fieldRequired}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap justify-content-between col-lg-9 col-md-9 col-sm-12 mt-4 mr-1 mx-auto'>
                    <button
                      type='button'
                      onClick={actionOk}
                      className={"btn btn-success col mr-1"}>
                      Enviar
                    </button>
                    <button
                      type='button'
                      onClick={closeModalAction}
                      className={"btn btn-danger col"}>
                      Cancelar
                    </button>
                  </div>
                </form>
              )
            }}
          />

        </div>

      </Modal>

    </div>
  )
}


export default FormModal
