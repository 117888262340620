import React, { useEffect } from "react";
import { Form, Field } from "react-final-form";
import { RenderText } from "../Utils/RenderName";
import logo from "../../../../assets/img/logo.png";
import Grid from "../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import "./qrScanView.css"

const QRScanView = ({
  data,
  getQRScan,
  match: { params: { id } },
}) => {

  useEffect(() => {
    getQRScan(id);
  }, []);

  return (
    <div className="background-img">
      <Form
        onSubmit={() => {}}
        initialValues={data}
        render={({ handleSubmit, values: { documents } }) => (
          <form onSubmit={handleSubmit} className="qr-main-content" style={{ overflow: "auto" }}>
            <div className="other-content">
              <h1 style={{ fontWeight: "700"}}>
              Pantalla de verificación
              </h1>
              <div className="bg-card-cofaqui shadow qr-border px-3 py-4">
                <div className="qr-first-item">
                  <div
                    className="mr-0 mr-md-4"
                    style={{
                      background: "#FAFDF3",
                      padding: "10px",
                      borderRadius: "10px",
                      width: "157px",
                      height: "157px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={logo} alt="Logo-Cofaqui" style={{ width: "138px", height: "138px" }}/>
                  </div>
                  <div className="w-100 row-qr-container">
                    <label
                      htmlFor="member_data"
                    >
                      Agremiado
                    </label>
                    <div className="row-qr-data border-card-qr py-2" style={{ background: "#FAFDF3" }}>
                      <div className="row-qr-item">
                        <label 
                          className="form-label"
                          htmlFor="user_data.first_name"
                        >
                          <i>
                            <img src={require("assets/img/icons/qr-view/member-icon.svg")} alt="agremiado"/>
                          </i>
                          &nbsp;Nombre
                        </label>
                        <Field
                          name="user_data.first_name"
                          CustomClassName="qr-text"
                          component={RenderText}
                        />
                      </div>
                      <div className="row-qr-item">
                        <label 
                          className="form-label"
                          htmlFor="user_data.last_name"
                        >
                          <i>
                            <img src={require("assets/img/icons/qr-view/member-icon.svg")} alt="agremiado"/>
                          </i>
                          &nbsp;Apellido
                        </label>
                        <Field
                          name="user_data.last_name"
                          CustomClassName="qr-text"
                          component={RenderText}
                        />
                      </div>
                      <div className="row-qr-item">
                        <label 
                          className="form-label"
                          htmlFor="user_data.state"
                        >
                          <i>
                            <img src={require("assets/img/icons/qr-view/member-icon.svg")} alt="agremiado"/>
                          </i>
                          &nbsp;Estado
                        </label>
                        <Field
                          name="user_data.state"
                          CustomClassName="qr-text"
                          component={RenderText}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row-qr-container">
                  <label
                    htmlFor="member_data"
                  >
                    Colegiado
                  </label>
                  <div className="row-qr-data py-2 border-card-qr" style={{ background: "#FAFDF3" }}>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="user_data.no_collegiate"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/no-colegiado.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;No. Colegiado
                      </label>
                      <Field
                        name="user_data.no_collegiate"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="user_data.university"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/universidad.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Universidad
                      </label>
                      <Field
                        name="user_data.university" 
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="user_data.profession"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/profesion.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Profesión
                      </label>
                      <Field
                        name="user_data.profession" 
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="user_data.college_date"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/fecha.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Fecha de colegiación
                      </label>
                      <Field
                        name="user_data.college_date"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="user_data.graduation_date"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/fecha.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Fecha de graduación
                      </label>
                      <Field
                        name="user_data.graduation_date"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                  </div>
                </div>
                <div className="row-qr-container">
                  <label
                    htmlFor="member_data"
                  >
                  Timbre
                  </label>
                  <div className="row-qr-data py-2 border-card-qr" style={{ background: "#FAFDF3" }}>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="transaction_data.receipt_number"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/numero.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;No. recibo
                      </label>
                      <Field
                        name="transaction_data.receipt_number"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="transaction_data.receipt_series"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/numero.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Serie de recibo
                      </label>
                      <Field
                        name="transaction_data.receipt_series"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="user_data.stamp_price"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/valor.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Valor del timbre
                      </label>
                      <Field
                        name="amount"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="transaction_data.transaction_date"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/fecha.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Fecha de compra
                      </label>
                      <Field
                        name="transaction_data.transaction_date"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                    <div className="row-qr-item">
                      <label 
                        className="form-label"
                        htmlFor="correlative"
                      >
                        <i>
                          <img src={require("assets/img/icons/qr-view/numero.svg")} alt="agremiado"/>
                        </i>
                        &nbsp;Correlativo del timbre
                      </label>
                      <Field
                        name="correlative"
                        CustomClassName="qr-text"
                        component={RenderText}
                      />
                    </div>
                  </div>
                </div>
                {
                  (documents && documents.length > 0) && (
                    <div className="container-fluid mt-4">
                      <Grid
                        hover
                        responsive
                        remote={false}
                        loading={false}
                        data={{
                          results: documents
                        }}
                        bodyContainerClass = "bc-professions-custom"
                        headerContainerClass = "hc-professions-custom"
                        tableContainerClass = "professions-container-table-sm"
                        tableHeaderClass = "table-header-professions"
                      >
                        <TableHeaderColumn
                          dataField="order"
                          isKey
                          dataAlign="center"
                          width="100px"
                        >
                          No.
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="stamp_product"
                          dataAlign="center"
                          width="250px"
                        >
                          Nombre del timbre o producto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="title"
                          dataAlign="center"
                          width="250px"
                        >
                          Título del documento
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="signature_required"
                          dataAlign="center"
                          width="100px"
                          dataFormat={(cell) => {
                            return (
                              <div>
                                {cell ? "Si" : "No"}
                              </div>
                            );
                          }}
                        >
                          Requiere firma
                        </TableHeaderColumn>
                      </Grid>
                    </div>
                  )
                }
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default QRScanView;
