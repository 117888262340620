import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import {addIndexToArray} from "../../../../utility/constants";

const MANAGEMENT_DATA = "MANAGEMENT_DATA";
const MANAGEMENT_LIST = "MANAGEMENT_LIST";
const LOADER = "MANAGEMENT_LOADER";
const PAGE = "MANAGEMENT_PAGE";
const ORDERING = "MANAGEMENT_ORDERING";
const SEARCH = "MANAGEMENT_SEARCH";
const PROFESSIONS = "PROFESSIONS";

export const constants = {
  MANAGEMENT_DATA,
  MANAGEMENT_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
  PROFESSIONS,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setManagementData = data => ({
  type: MANAGEMENT_DATA,
  data,
});

export const setManagementList = data => ({
  type: MANAGEMENT_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

const setSearch = search => ({
  type: SEARCH,
  search,
});

const setProfessions = professions => ({
  type: PROFESSIONS,
  professions,
});

// ------------------------------------
// Actions
// ------------------------------------

const getManagements = (page = 1) => (dispatch) => {

  dispatch(setLoader(true));
  api.get("management", page)
    .then(response => {
      dispatch(setLoader(false));
      if (response.results) {
        response.results = addIndexToArray(response.results, page);
      }
      dispatch(setManagementList(response));
      dispatch(setPage(page));
    })
    .catch(() => {
      dispatch(setLoader(false));
    });
};

const getManagement = id => dispatch => {
  dispatch(setLoader(true));
  api.get(`management/${id}`)
    .then(response => {
      dispatch(setLoader(false));
      dispatch(setManagementData(response));
    })
    .catch(() => {
      dispatch(setLoader(false));
    });
};

const getProfessionsPartOfUnion = (search = "") => (dispatch) => {
  dispatch(setLoader(true));
  api.get("profession/get_professions_part_of_union", {search}).then(response => {
    dispatch(setProfessions(response.results));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener las profesiones", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const createManagement = data => dispatch => {
  dispatch(setLoader(true));
  api.post("management", data)
    .then(() => {
      dispatch(setLoader(false));
      NotificationManager.success("Management created", "Success", 3000);
      dispatch(push("/management"));
    })
    .catch(() => {
      dispatch(setLoader(false));
    });
};

const updateManagement = (id, data) => dispatch => {
  dispatch(setLoader(true));
  api.put(`management/${id}`, data)
    .then(() => {
      dispatch(setLoader(false));
      NotificationManager.success("Management updated", "Success", 3000);
      dispatch(push("/management"));
    })
    .catch(() => {
      dispatch(setLoader(false));
    });
};

const deleteManagement = id => dispatch => {
  dispatch(setLoader(true));
  api.eliminar(`management/${id}`)
    .then(() => {
      dispatch(setLoader(false));
      NotificationManager.success("Management deleted", "Success", 3000);
      dispatch(getManagements());
    })
    .catch(() => {
      dispatch(setLoader(false));
    });
};

export const actions = {
  getManagements,
  getManagement,
  getProfessionsPartOfUnion,
  createManagement,
  updateManagement,
  deleteManagement,
  setPage,
  setOrdering,
  setSearch,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [MANAGEMENT_DATA]: (state, { data }) => {
    return {
      ...state,
      managementData: data,
    };
  },
  [MANAGEMENT_LIST]: (state, { data }) => {
    return {
      ...state,
      managementsList: data,
    };
  },
  [PAGE]: (state, { page }) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, { ordering }) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, { search }) => {
    return {
      ...state,
      search,
    };
  },
  [PROFESSIONS]: (state, { professions }) => {
    return {
      ...state,
      professions,
    };
  }
};

export const initialState = {
  loader: false,
  managementData: {},
  managementsList: {
    count: 0,
    results: [],
  },
  page: 1,
  ordering: "",
  search: "",
  professions: [],
};

export default handleActions(reducers, initialState);