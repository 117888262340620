import React, {useEffect, useState} from "react"
import FormStep1 from "./FormStep1"
import FormStep2 from "./FormStep2"
import {api} from "../../../../../utility/api"
import {NotificationManager} from "react-notifications"
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask"
import TitlePageCreate from "../../../../components/layout/TitleCreate"


const CreateProduct = (props) => {
  const {
    crear,
    leer,
    editar,
    loader,
    categories,
    setItemValues,
    getCategories,
    match: {params},
    changeCurrentStep,
    currentStepProduct,
    openSelectCategories,
    item,
    updateOnlyGeneralData,
    me: { permissions }
  } = props
  const [dataStep1, setDataStep1] = useState({})
  const [document, setDocument] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [professionOpt, setProfessionOpt] = useState([])
  const [generalData, setGeneralData] = useState({break_down: []})

  const documentManagement = permissions.document_management

  useEffect(() => {
    changeCurrentStep(1)
    // if is update
    if (params && params.id) {
      setIsUpdate(true)
      leer(params.id)
    } else{
      setItemValues({})
    }
    getProfession().then((opts) => {
      setProfessionOpt(opts)
    })
    getCategories()
  }, [])

  useEffect(() => {
    if(item && item.general_data){
      setGeneralData({...item.general_data, is_collegiate_payment: item.is_collegiate_payment})
    }
    if(item && item.document){
      setDocument({...item.document})
    }
  }, [item])

  const onSubmit = (data) => {
    if (validateBreakDown(dataStep1.break_down)) {
      if (isUpdate) {
        editar(params.id, {general_data: dataStep1, documents: data})
      } else {
        crear({general_data: dataStep1, documents: data})
      }
    }
  }

  const prevStep = () => {
    changeCurrentStep(currentStepProduct - 1)
  }
  const nextStep = (data) => {
    if (data.require_document) {
      setDataStep1(data)
      changeCurrentStep(currentStepProduct + 1)
    } else {
      if (validateBreakDown(data.break_down)) {
        if (isUpdate) {
          editar(params.id, {general_data: data, documents: null})
        } else {
          crear({general_data: data, documents: null})
        }
      }

    }
  }

  function validateBreakDown(array) {
    if (!array.length) {
      NotificationManager.error("Ingrese el desglose del precio del producto.","Error", 8000)
      return false
    }
    return true
  }
  const getProfession = (search = "") => {
    return api.get("profession/get_professions_part_of_union", {search}).then((resp) => {
      let _options = [];
      if (resp && resp.results) _options = resp.results
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }

  return (
    <div>
      <div className="container-fluid pt-5">
        <TitlePageCreate
          redirectTo="/products"
          title={currentStepProduct === 1 ? "Datos Generales" : "Generación de documentos"}
        />
        <LoadMask dark loading={loader} blur>
          <FormStep1
            isUpdate={isUpdate}
            onSubmit={documentManagement ? nextStep : updateOnlyGeneralData}
            categories={categories}
            defaultProf={professionOpt}
            searchProfession={getProfession}
            show={currentStepProduct === 1}
            openSelectCategories={openSelectCategories}
            data={generalData}
            permissions={permissions}
          />
          <FormStep2
            prev={prevStep}
            onSubmit={onSubmit}
            data={document}
            show={currentStepProduct === 2}
          />
        </LoadMask>
      </div>
    </div>
  )
}

export default CreateProduct
