import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";
import { addIndexToArray, STATE } from "../../../utility/constants";

const LOADER_STATUS_MEMBERS = "LOADER_STATUS_MEMBERS";
const STATUS_MEMBERS = "STATUS_MEMBERS";
const LOADER_MEMBERS_DEPARTMENTS = "LOADER_MEMBERS_DEPARTMENTS";
const MEMBERS_DEPARTMENTS = "MEMBERS_DEPARTMENTS";
const ICOME_EXPENSES = "ICOME_EXPENSES";
const LOADER_ICOME_EXPENSES = "LOADER_ICOME_EXPENSES";
const AGE_ICOME_EXPENSES_SELECT = "AGE_ICOME_EXPENSES_SELECT";
const STAMPS_SOLD = "STAMPS_SOLD";
const LOADER_STAMPS_SOLD = "LOADER_STAMPS_SOLD";
const STAMPS_SOLD_MONTH_SELECT = "STAMPS_SOLD_MONTH_SELECT";
const STAMPS_SOLD_YEAR_SELECT = "STAMPS_SOLD_YEAR_SELECT";
const LOADER_EXPENSES_INSURANCE = "LOADER_EXPENSES_INSURANCE";
const EXPENSES_INSURANCE = "EXPENSES_INSURANCE";
const EXPENSES_INSURANCE_MONTH_SELECT = "LOADER_EXPENSES_MONTH_SELECT";
const EXPENSES_INSURANCE_YEAR_SELECT = "LOADER_EXPENSES_YEAR_SELECT";
const LOADER_TOP_TRANSACTIONS = "LOADER_TOP_TRANSACTIONS";
const TOP_TRANSACTIONS = "TOP_TRANSACTIONS";
const TOP_TRANSACTIONS_MONTH_SELECT = "TOP_TRANSACTIONS_MONTH_SELECT";
const TOP_TRANSACTIONS_YEAR_SELECT = "TOP_TRANSACTIONS_YEAR_SELECT";

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoaderTopTransactions = (is_loader_top_transactions) => ({
  type: LOADER_TOP_TRANSACTIONS,
  is_loader_top_transactions,
});

export const setTopTransactions = (top_transactions) => ({
  type: TOP_TRANSACTIONS,
  top_transactions,
});

export const setTopTransactionsMonthSelect = (
  top_transactions_month_select
) => ({
  type: TOP_TRANSACTIONS_MONTH_SELECT,
  top_transactions_month_select,
});

export const setTopTransactionsYearSelect = (top_transactions_year_select) => ({
  type: TOP_TRANSACTIONS_YEAR_SELECT,
  top_transactions_year_select,
});

export const setLoaderExpensesInsurance = (is_loader_expenses_insurance) => ({
  type: LOADER_EXPENSES_INSURANCE,
  is_loader_expenses_insurance,
});

export const setExpensesInsurance = (expenses_insurance) => ({
  type: EXPENSES_INSURANCE,
  expenses_insurance,
});

export const setExpensesInsuranceMonthSelect = (
  expenses_insurance_month_select
) => ({
  type: EXPENSES_INSURANCE_MONTH_SELECT,
  expenses_insurance_month_select,
});

export const setExpensesInsuranceYearSelect = (
  expenses_insurance_year_select
) => ({
  type: EXPENSES_INSURANCE_YEAR_SELECT,
  expenses_insurance_year_select,
});

export const setLoaderMeStatus = (is_loader_status_members) => ({
  type: LOADER_STATUS_MEMBERS,
  is_loader_status_members,
});

export const setLoaderMeDep = (is_loader_members_departments) => ({
  type: LOADER_MEMBERS_DEPARTMENTS,
  is_loader_members_departments,
});

export const setStatusMemData = (status_members) => ({
  type: STATUS_MEMBERS,
  status_members,
});

export const setMemDep = (members_departments) => ({
  type: MEMBERS_DEPARTMENTS,
  members_departments,
});

export const setIcomeExpenses = (icome_expenses) => ({
  type: ICOME_EXPENSES,
  icome_expenses,
});

export const setLoaderIcomeExpenses = (is_loader_icome_expenses) => ({
  type: LOADER_ICOME_EXPENSES,
  is_loader_icome_expenses,
});

export const setAgeIcomeExpenseSelect = (age_icome_expenses_select) => ({
  type: AGE_ICOME_EXPENSES_SELECT,
  age_icome_expenses_select,
});

export const setStampsSold = (stamps_sold) => ({
  type: STAMPS_SOLD,
  stamps_sold,
});

export const setLoaderStampsSold = (is_loader_stamps_sold) => ({
  type: LOADER_STAMPS_SOLD,
  is_loader_stamps_sold,
});

export const setStampsSoldMonthSelect = (stamps_sold_month_select) => ({
  type: STAMPS_SOLD_MONTH_SELECT,
  stamps_sold_month_select,
});

export const setStampsSoldYearSelect = (stamps_sold_year_select) => ({
  type: STAMPS_SOLD_YEAR_SELECT,
  stamps_sold_year_select,
});

// ------------------------------------
// Actions
// ------------------------------------

const getMembersStatus = () => (dispatch) => {
  dispatch(setLoaderMeStatus(true));
  api
    .get("/dashboard/get_members_by_status")
    .then((response) => {
      if (response) {
        dispatch(setStatusMemData(response));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoaderMeStatus(false));
    });
};
const getMembersDepartments = () => (dispatch) => {
  dispatch(setLoaderMeDep(true));
  api
    .get("/dashboard/get_members_by_department")
    .then((response) => {
      if (response) {
        dispatch(setMemDep(response));
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(setLoaderMeDep(false));
    });
};

const getIcomeExpenses =
  ({ value }) =>
    (dispatch) => {
      dispatch(setLoaderIcomeExpenses(true));
      api
        .get("/dashboard/get_income_expenses", {
          age: value || new Date().getFullYear(),
        })
        .then((response) => {
          if (response) {
            dispatch(setIcomeExpenses(response));
            dispatch(setAgeIcomeExpenseSelect({ value, label: value }));
          }
        })
        .catch((e) => {
          let msj = "Error al cargar los datos";
          NotificationManager.error(msj, "ERROR", 0);
        })
        .finally(() => {
          dispatch(setLoaderIcomeExpenses(false));
        });
    };

const getIcomeExpensesByYear = (year_option) => (dispatch) => {
  dispatch(setAgeIcomeExpenseSelect(year_option));
  getIcomeExpenses(year_option)(dispatch);
};

const getStampsSold = (year_option, month_option) => async (dispatch) => {
  dispatch(setLoaderStampsSold(true));

  let params = {
    age: year_option.value || new Date().getFullYear(),
    month: month_option.value || new Date().getMonth() + 1,
  };
  api
    .get("/dashboard/get_stamps_sold", params)
    .then(async (response) => {
      if (response) {
        dispatch(setStampsSold(response));
        dispatch(setStampsSoldMonthSelect(month_option));
        dispatch(setStampsSoldYearSelect(year_option));
      }
    })
    .catch((e) => {
      let msj = "Error al cargar los datos";
      NotificationManager.error(msj, "ERROR", 0);
    })
    .finally(() => {
      dispatch(setLoaderStampsSold(false));
    });
};

const getStampsSoldByMonth = (month_option) => (dispatch, getStore) => {
  let { stamps_sold_year_select } = getStore().dashboard;
  dispatch(setStampsSoldMonthSelect(month_option));
  getStampsSold(stamps_sold_year_select, month_option)(dispatch);
};

const getStampsSoldByYear = (year_option) => async (dispatch, getStore) => {
  let { months } = getStore().dashboard.stamps_sold;
  await dispatch(setStampsSoldYearSelect(year_option));
  let months_by_year = months[year_option["value"]];
  await dispatch(setStampsSoldMonthSelect(months_by_year[0]));
  getStampsSold(year_option, months_by_year[0])(dispatch);
};

const getExpencesInsurances = (year_option, month_option) => (dispatch) => {
  dispatch(setLoaderExpensesInsurance(true));
  let params = {
    age: year_option.value || new Date().getFullYear(),
    month: month_option.value || new Date().getMonth() + 1,
  };

  api
    .get("/dashboard/get_insurance_expenses", params)
    .then((response) => {
      if (response) {
        dispatch(setExpensesInsurance(response));
        dispatch(setExpensesInsuranceMonthSelect(month_option));
        dispatch(setExpensesInsuranceYearSelect(year_option));
      }
    })
    .catch((e) => {
      let msj = "Error al cargar los datos";
      NotificationManager.error(msj, "ERROR", 0);
    })
    .finally(() => {
      dispatch(setLoaderExpensesInsurance(false));
    });
};

const getExpencesInsurancesByMonth = (month_option) => (dispatch, getStore) => {
  let { expenses_insurance_year_select } = getStore().dashboard;
  dispatch(setExpensesInsuranceMonthSelect(month_option));
  getExpencesInsurances(expenses_insurance_year_select, month_option)(dispatch);
};

const getExpencesInsurancesByYear =
  (year_option) => async (dispatch, getStore) => {
    let { months } = getStore().dashboard.expenses_insurance;
    await dispatch(setExpensesInsuranceYearSelect(year_option));
    let months_by_year = months[year_option["value"]];
    await dispatch(setExpensesInsuranceMonthSelect(months_by_year[0]));
    getExpencesInsurances(year_option, months_by_year[0])(dispatch);
  };

const getTopTransactions = (year_option, month_option) => (dispatch) => {
  dispatch(setLoaderTopTransactions(true));
  let params = {
    age: year_option.value || new Date().getFullYear(),
    month: month_option.value || new Date().getMonth() + 1,
  };
  
  api
    .get("/dashboard/get_top_users_transactions", params)
    .then((response) => {
      if (response) {
        dispatch(setTopTransactions(response));
        dispatch(setTopTransactionsMonthSelect(month_option));
        dispatch(setTopTransactionsYearSelect(year_option));
      }
    })
    .catch((e) => {
      let msj = "Error al cargar los datos";
      NotificationManager.error(msj, "ERROR", 0);
    })
    .finally(() => {
      dispatch(setLoaderTopTransactions(false));
    });
};

const getTopTransactionsByMonth = (month_option) => (dispatch, getStore) => {
  let { top_transactions_year_select } = getStore().dashboard;
  dispatch(setTopTransactionsMonthSelect(month_option));
  getTopTransactions(top_transactions_year_select, month_option)(dispatch);
};

const getTopTransactionsByYear = (year_option) => async (dispatch, getStore) => {
  let { months } = getStore().dashboard.top_transactions;
  await dispatch(setTopTransactionsYearSelect(year_option));
  let months_by_year = months[year_option["value"]];
  await dispatch(setTopTransactionsMonthSelect(months_by_year[0]));
  getTopTransactions(year_option, months_by_year[0])(dispatch);
};







export const actions = {
  getMembersStatus,
  getMembersDepartments,
  getIcomeExpenses,
  getIcomeExpensesByYear,
  getStampsSold,
  getStampsSoldByYear,
  getStampsSoldByMonth,
  getExpencesInsurances,
  getExpencesInsurancesByYear,
  getExpencesInsurancesByMonth,
  getTopTransactions,
  getTopTransactionsByYear,
  getTopTransactionsByMonth,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER_STATUS_MEMBERS]: (state, { is_loader_status_members }) => {
    return {
      ...state,
      is_loader_status_members,
    };
  },
  [LOADER_MEMBERS_DEPARTMENTS]: (state, { is_loader_members_departments }) => {
    return {
      ...state,
      is_loader_members_departments,
    };
  },
  [STATUS_MEMBERS]: (state, { status_members }) => {
    return {
      ...state,
      status_members,
    };
  },
  [MEMBERS_DEPARTMENTS]: (state, { members_departments }) => {
    return {
      ...state,
      members_departments,
    };
  },
  [ICOME_EXPENSES]: (state, { icome_expenses }) => {
    return {
      ...state,
      icome_expenses,
    };
  },
  [LOADER_ICOME_EXPENSES]: (state, { is_loader_icome_expenses }) => {
    return {
      ...state,
      is_loader_icome_expenses,
    };
  },
  [AGE_ICOME_EXPENSES_SELECT]: (state, { age_icome_expenses_select }) => {
    return {
      ...state,
      age_icome_expenses_select,
    };
  },
  [STAMPS_SOLD]: (state, { stamps_sold }) => {
    return {
      ...state,
      stamps_sold,
    };
  },
  [LOADER_STAMPS_SOLD]: (state, { is_loader_stamps_sold }) => {
    return {
      ...state,
      is_loader_stamps_sold,
    };
  },
  [STAMPS_SOLD_MONTH_SELECT]: (state, { stamps_sold_month_select }) => {
    return {
      ...state,
      stamps_sold_month_select,
    };
  },
  [STAMPS_SOLD_YEAR_SELECT]: (state, { stamps_sold_year_select }) => {
    return {
      ...state,
      stamps_sold_year_select,
    };
  },
  [LOADER_EXPENSES_INSURANCE]: (state, { is_loader_expenses_insurance }) => {
    return {
      ...state,
      is_loader_expenses_insurance,
    };
  },
  [EXPENSES_INSURANCE]: (state, { expenses_insurance }) => {
    return {
      ...state,
      expenses_insurance,
    };
  },
  [EXPENSES_INSURANCE_YEAR_SELECT]: (
    state,
    { expenses_insurance_year_select }
  ) => {
    return {
      ...state,
      expenses_insurance_year_select,
    };
  },
  [EXPENSES_INSURANCE_MONTH_SELECT]: (
    state,
    { expenses_insurance_month_select }
  ) => {
    return {
      ...state,
      expenses_insurance_month_select,
    };
  },
  [TOP_TRANSACTIONS]: (state, { top_transactions }) => {
    return {
      ...state,
      top_transactions,
    };
  },
  [LOADER_TOP_TRANSACTIONS]: (state, { is_loader_top_transactions }) => {
    return {
      ...state,
      is_loader_top_transactions,
    };
  },
  [TOP_TRANSACTIONS_YEAR_SELECT]: (state, { top_transactions_year_select }) => {
    return {
      ...state,
      top_transactions_year_select,
    };
  },
  [TOP_TRANSACTIONS_MONTH_SELECT]: (
    state,
    { top_transactions_month_select }
  ) => {
    return {
      ...state,
      top_transactions_month_select,
    };
  },
};

const initialState = {
  is_loader_status_members: false,
  status_members: [],
  is_loader_members_departments: false,
  members_departments: [],
  icome_expenses: {
    ingresos: [],
    egresos: [],
    years: [],
  },
  is_loader_icome_expenses: false,
  age_icome_expenses_select: {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  },
  stamps_sold: {
    options_stamps: [],
    years: [],
    months: {},
  },
  is_loader_stamps_sold: false,
  stamps_sold_month_select: {
    value: new Date().getMonth() + 1,
    label:
      new Date()
        .toLocaleString("es-ES", { month: "long" })
        .charAt(0)
        .toUpperCase() +
      new Date().toLocaleString("es-ES", { month: "long" }).slice(1),
  },
  stamps_sold_year_select: {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  },
  is_loader_expenses_insurance: false,
  expenses_insurance: {
    insurance_data: [],
    years: [],
    months: {},
    total: 0,
  },
  expenses_insurance_year_select: {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  },
  expenses_insurance_month_select: {
    value: new Date().getMonth() + 1,
    label:
      new Date()
        .toLocaleString("es-ES", { month: "long" })
        .charAt(0)
        .toUpperCase() +
      new Date().toLocaleString("es-ES", { month: "long" }).slice(1),
  },
  top_transactions: {
    data_users: [],
    years: [],
    months: {},
    total_transacciones: 0,
  },
  is_loader_top_transactions: false,
  top_transactions_year_select: {
    value: new Date().getFullYear(),
    label: new Date().getFullYear(),
  },
  top_transactions_month_select: {
    value: new Date().getMonth() + 1,
    label:
      new Date()
        .toLocaleString("es-ES", { month: "long" })
        .charAt(0)
        .toUpperCase() +
      new Date().toLocaleString("es-ES", { month: "long" }).slice(1),
  },
};

export default handleActions(reducers, initialState);
