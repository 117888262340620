import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import { MONTHS } from "../../../../../utility/constants";
import { standardActions } from "../../../../components/Utils/Grid/StandardActions";

import "./list.css";

const PaymentsList = ({
//   getPaymentsList,
  getMyPaymentsList,
  page,
  loader,
  onSortChange,
  paymentsList,
  assignStartDate,
  assignEndDate,
  resetPage,
  startDate,
  endDate,
  printAction,
  assignMemberId,
  admin,
  match: { path, params: { memberId } },
  getPaymentsList,
}) => {

  const [returnToMemers, setReturnToMemers] = useState(false);

  useEffect(() => {
    if (path == "/member/:memberId/confirmed-payments") {
      setReturnToMemers(true);
    } else {
      setReturnToMemers(false);
    }
  }, []);

  useEffect(() => {
    if (admin) {
      assignMemberId(memberId);
      getPaymentsList(page);
    } else {
      getMyPaymentsList(page);
    }
  }, [startDate, endDate]);

  return (
    <div className="container-fluid">
      <PageTitle
        title={"Mis Pagos"}
        withStartDateFilter
        withEndDateFilter
        months={MONTHS}
        setStartDate={assignStartDate}
        setEndDate={assignEndDate}
        resetPage={resetPage}
        colTitle="row col-lg-3 col-md-12 col-sm-12"
        removeCols=""
        withReturn
        returnTo={returnToMemers ? "/" : "/managements"}
        // startDate={startDate}
        // endDate={endDate}
      />
      <div className="card bg-card-cofaqui br-20px py-5 px-4">
        <Grid
          hover
          data={paymentsList}
          page={page}
          loading={loader}
          onPageChange={admin ? getPaymentsList : getMyPaymentsList}
          onSortChange={onSortChange}
          bodyContainerClass = "bc-payments-custom"
          headerContainerClass = "hc-payments-custom"
          tableContainerClass = "payments-container-table-sm"
          tableHeaderClass = "table-header-payments"
        >
          <TableHeaderColumn
            isKey
            width="100px"
            dataField="order"
            dataAlign="center"
          >
            No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="200px"
            dataField="transaction_date"
          >
            Fecha de la transacción
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="150px"
            dataField="amount"
          >
            Monto total de la transacción
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="150px"
            dataField="type_of_income"
          >
            Forma de pago
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="receipt"
            dataAlign="center"
            width="100px"
            dataFormat={(cell) => {
              return standardActions({
                printActionPayment: printAction,
                printActionPaymentId: cell,
              }) (cell);
            }}
          >
                Acciones
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}

export default PaymentsList;
