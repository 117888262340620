import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import arrowicon from "../../../../../assets/img/icons/arrowicon.png";
import "./navbar.css";

const Navigation = ({singleLink, singleLocation, link1, level1, level2, level3, text1, text2, text3, level4, text4}) => {
  return (
    <Fragment>
      {singleLink && <Link to={`${singleLocation}`} className="breadcrumbs-text">{singleLink}</Link>}
      {level1 && <span className="breadcrumbs-text">{text1}</span>}
      {link1 && <Link to={`${link1}`} className="breadcrumbs-text">{text1}</Link>}
      {level2 && <Fragment>
        <img src={arrowicon} alt="" className="arrow-nav"/>
        <Link to={`${level2}`} className="breadcrumbs-text">{text2}</Link>
      </Fragment>
      }
      {level3 && <Fragment>
        <img src={arrowicon} alt="" className="arrow-nav"/>
        <Link to={`${level3}`} className="breadcrumbs-text">{text3}</Link>
      </Fragment>
      }
      {level4 && <Fragment>
        <img src={arrowicon} alt="" className="arrow-nav"/>
        <Link to={`${level4}`} className="breadcrumbs-text">{text4}</Link>
      </Fragment>
      }
    </Fragment>
  )
}

export default Navigation;
