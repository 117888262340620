import React from "react"
import {Link} from "react-router-dom";
import iconReturn from "../../../../../assets/img/icons/iconreturn.svg";

const TitlePageCreate = ({redirectTo, title}) => {
  return (
    <div className="d-flex flex-row mb-1">
      <h3 className="pl-lg-4 pl-md-4 pl-sm-0 font-weight-bold">
        {
          title
        }
      </h3>
      <Link to={redirectTo} className="ml-2">
        <i>
          <img src={iconReturn} alt="Regresar" className="ml-2 mt-n1"/>
        </i>
      </Link>
    </div>
  )
}

export default TitlePageCreate
