import React, { useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { logOut, getMe } from "./redux/modules/cuenta/login";
// base component
import { VerifyLogin } from "./common/components/layout";
import Navbar from "./common/components/layout/Navbar/Navbar";
import SideBar from "./common/components/layout/Sidebar/SideBar";

// function que válida que el usuario cuente con los permisos necesarios
const userPermissions = (permissions, validatePermissions) => {
  if (validatePermissions) {
    const userPermissions = permissions.map(permission => {
      if (validatePermissions[permission] === true) {
        return validatePermissions[permission];
      }
      else if (validatePermissions[permission] === false || validatePermissions[permission] === undefined) {
        return validatePermissions[permission] || false;
      }
    });
    return userPermissions.includes(false) ? false : true;
  }

  return false;
}

// validar permisos
const validatePermissions = (permissions = [], validatePermissions) => {

  // esto se quitara despues cuando ya se tengan todos los permisos
  if (permissions === "only_authenticated") {
    return true;
  }

  else if (permissions.length > 0) {
    return userPermissions(permissions, validatePermissions);
  }

  return false;

}

const PrivateRouteBase = (props) => {
  const [toggleOpen, setToggleOpen] = useState(true)

  const navToggle = () => {
    // validacion para el menu en version mobile
    if (screen.width <= 915 && toggleOpen) {
      let element = document.getElementsByTagName("body")
      element[0].style.overflow = "hidden";
    } else {
      let element = document.getElementsByTagName("body")
      element[0].style.overflow = "initial";
    }
    setToggleOpen(!toggleOpen);
  };

  // Validar autenticacion
  const validateIsAuthenticated = () => {
    const { getMe, login: { me } } = props;

    const token = localStorage.getItem("token");
    const appBody = document.getElementById("app-body");
    if (!!token && !!me.dpi) {
      appBody.classList.add("body-layer-1");
      return true;
    } else if (token) {
      getMe();
      return "Verifying"
    }
    appBody.classList.remove("body-layer-1");
    return false;
  };

  const { component: Component, logOut, login: { me }, permissions, computedMatch: { path, url, params }, member, form, user, myDocuments: {item}, ...rest } = props;
  const isAuthenticated = validateIsAuthenticated();

  const hasPermissions = validatePermissions(permissions, me.permissions);

  // Validacion de autenticacion
  if (isAuthenticated) {

    // Pantalla de carga
    if (!me.permissions) {
      return (
        <VerifyLogin />
      )
    }


    // Validacion de permisos
    else if (hasPermissions) {
      return (
        <Route
          {...rest}
          render={props => (
            <div className="d-flex">
              <SideBar toggleOpen={toggleOpen} navToggle={navToggle} setToggleOpen={setToggleOpen} me={me} logOut={logOut} location={path} />
              <main id="con-main" className="main-content px-0 col-sm-12 col-md-v9 offset-md-new-3 col-lg-new-10 offset-lg-new-1">
                <div className="main-navbar bg-nav-bar sticky-top">
                  <div className="px-3 w-100">
                    <Navbar navToggle={navToggle} logOut={logOut} user={me} userStorage={user} member={member} pathname={path} url={url} itemMyDoc={item} params={params}/>
                  </div>
                </div>
                <div className="main-content-container px-lg-4v2 px-md-4 px-md-0 container-fluid">
                  <Component {...props} me={me} />
                </div>
              </main>
            </div>
          )}
        />
      );
    }

    // No cuenta con los permisos necesarios
    else {
      return (
        <Redirect
        to={{
          pathname: me.permissions.startup_screen,
          state: { from: props.location }
        }}
      />
      )
    }
  }

  // redireccionar al login
  else {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )}
      />
    )
  }
};

const mstp = state => ({ ...state });
const mdtp = { logOut, getMe };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBase);
export default ProtectedRoute;
