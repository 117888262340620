import {connect} from "react-redux";
import Create from "./Create"
import {actions} from "../../../../../redux/modules/myManagements/purchaseRequest"


const ms2p = (state) => {
  return {
    ...state.purchaseRequest,
    me: state.login.me,
  }
}

const md2p = {...actions}

export default connect(ms2p, md2p)(Create)
