import React from "react";
import { Form, Field } from "react-final-form";
import { RenderName } from  "../../../../components/Utils/RenderName";
import Grid from "../../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table"

import "./reports.css"

const ReportForm = ({
  initialValues,
  generatePDF,
}) => {

  return (
    <Form
      onSubmit={()=>{}}
      initialValues={initialValues}
      render={({ handleSubmit, values: { purchase_request_data, documents } }) => (
        <form onSubmit={handleSubmit} className="mt-4">
          <div className={`row ${purchase_request_data.management_code !== "" ? "justify-content-between" : ""} mt-2 mb-3 px-2`}>
            {/* Detalles del agremiado */}
            <div className="col- col-sm-12 col-md-12 col-lg-3 mb-2 mb-lg-0">
              <div className="d-flex flex-row">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#0A4725",
                  }}
                >
                  Agremiado&nbsp;
                  <Field
                    name="user_data.no_collegiate"
                    component={RenderName}
                  />
                  &nbsp;-&nbsp;
                  <Field
                    name="user_data.state"
                    component={RenderName}
                  />
                </span>
              </div>
              <div className="report-member-details mt-2 px-2 py-3">
                <div className="d-flex flex-column px-5 py-3">
                  <div className="row">
                    <label 
                      className="form-label"
                      htmlFor="user_data.first_name"
                    >
                      Nombres:&nbsp;
                    </label>
                    <Field
                      name="user_data.first_name"
                      component={RenderName}
                    />
                  </div>
                  <div className="row">
                    <label
                      className="form-label"
                      htmlFor="user_data.last_name"
                    >
                      Apellidos:&nbsp;
                    </label>
                    <Field
                      name="user_data.last_name"
                      component={RenderName}
                    />
                  </div>
                  <div className="row">
                    <label 
                      className="form-label"
                      htmlFor="user_data.profession"
                    >
                      Profesión:&nbsp;
                    </label>
                    <Field
                      name="user_data.profession"
                      component={RenderName}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Detalle de compra */}
            <div className="col- col-sm-12 col-md-12 col-lg-3 mb-2 mb-lg-0">
              <div className="d-flex flex-row mt-2">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#0A4725",
                  }}
                >
                Detalle de compra
                </span>
              </div>
              <div className="report-member-details mt-2 px-2 py-3">
                <div className="d-flex flex-column px-5 py-3">
                  {/* <div className="d-flex flex-row mt-2"> */}
                  <div className="row">
                    <label 
                      className="form-label"
                      htmlFor="transaction_data.transaction_date"
                    >
                      Fecha de compra:&nbsp;
                    </label>
                    <Field
                      name="transaction_data.transaction_date"
                      component={RenderName}
                    />
                  </div>
                  <div className="row">
                    <label
                      className="form-label"
                      htmlFor="transaction_data.receipt_number"
                    >
                      Número de recibo:&nbsp;
                    </label>
                    <Field
                      name="transaction_data.receipt_number"
                      component={RenderName}
                    />
                  </div>
                  <div className="row">
                    <label 
                      className="form-label"
                      htmlFor="transaction_data.receipt_series"
                    >
                      Serie de recibo:&nbsp;
                    </label>
                    <Field
                      name="transaction_data.receipt_series"
                      component={RenderName}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Tipo de gestion */}
            {
              (purchase_request_data.management_code !== "") && (
                <div className="col- col-sm-12 col-md-12 col-lg-3 mb-2 mb-lg-0">
                  <div className="d-flex flex-row mt-2">
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "#0A4725",
                      }}
                    >
                    Detalles de la gestion&nbsp;
                      <Field
                        name="purchase_request_data.management_code"
                        component={RenderName}
                      />
                    </span>
                  </div>
                  <div className="report-member-details mt-2 px-2 py-3">
                    <div className="d-flex flex-column px-5 py-3">
                      {/* <div className="d-flex flex-row mt-2"> */}
                      <div className="row">
                        <label 
                          className="form-label"
                          htmlFor="purchase_request_data.type_of_management_data.name"
                        >
                      Tipo de gestión:&nbsp;
                        </label>
                        <Field
                          name="purchase_request_data.type_of_management_data.name"
                          component={RenderName}
                        />
                      </div>
                      <div className="row">
                        <label
                          className="form-label"
                          htmlFor="purchase_request_data.type_of_management_data.profession"
                        >
                      Profesión:&nbsp;
                        </label>
                        <Field
                          name="purchase_request_data.type_of_management_data.profession"
                          component={RenderName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          {
            (documents.length > 0) && (
              <div className="d-flex flex-row mt-2">
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#0A4725",
                  }}
                >
              Documentos
                </span>
              </div>
            )
          }
          {
            (documents.length > 0) && (
              <div className="container-fluid">
                <Grid
                  hover
                  responsive
                  remote={false}
                  loading={false}
                  data={{
                    results: initialValues.documents,
                  }}
                  bodyContainerClass = "bc-professions-custom"
                  headerContainerClass = "hc-professions-custom"
                  tableContainerClass = "professions-container-table-sm"
                  tableHeaderClass = "table-header-professions"
                >
                  <TableHeaderColumn
                    dataField="order"
                    isKey
                    dataAlign="center"
                    width="100px"
                  >
                    No.
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="stamp_product"
                    dataAlign="center"
                    width="150px"
                  >
                    Nombre del timbre o producto
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="title"
                    dataAlign="center"
                    width="150px"
                  >
                    Título del documento
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="signature_required"
                    dataAlign="center"
                    width="150px"
                    dataFormat={(cell) => {
                      return (
                        <div>
                          {cell ? "Si" : "No"}
                        </div>
                      );
                    }}
                  >
                    Requiere firma
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="320px"
                    dataFormat={(cell, row) => {
                      return (
                        <center>
                          {
                            (row && row.is_printed) ? (
                              <button
                                type="button"
                                title="Imprimir"
                                onClick={() => generatePDF(cell)}
                                className="px-2 py-0 actions-btn btn-print"
                              >
                                <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
                              </button>
                            ) :
                              (
                                <span>
                                  Aún no generado por el agremiado
                                </span>
                              )
                          }
                        </center>
                      );                    
                    }}
                  >
                    Imprimir documento
                  </TableHeaderColumn>
                </Grid>
              </div>
            )
          }
        </form>
      )}
    />
  );
}

export default ReportForm;