import {handleActions} from "redux-actions";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
// eslint-disable-next-line
import {api} from "api";
import {addIndexToArray} from "../../../utility/constants";

const STAMP_DATA = "STAMP_DATA";
const STAMP_LIST = "STAMP_LIST";
const LOADER = "STAMP_LOADER";
const PAGE = "STAMP_PAGE";
const ORDERING = "STAMP_ORDERING";
const SEARCH = "STAMP_SEARCH";
const PROFESSIONS = "PROFESSIONS_FOR_STAMPS";
const GENERATED_DOCUMENTS = "GENERATED_DOCUMENTS";
const GENERATED_DOCUMENT = "GENERATED_DOCUMENT";
const REQUIRED_DOCUMENTS = "REQUIRED_DOCUMENTS";
const NUMBER_DOCUMENTS = "NUMBER_DOCUMENTS";
const STAMP_TITLE = "STAMP_TITLE";
const CATEGORIES = "CATEGORIES_FOR_STAMPS";
const PAGE_PENDING_REQ = "PAGE_PENDING_REQ1";
const DATA_PENDING_REQ = "DATA_PENDING_REQ1";
const PAGE_APPROVAL_REQ = "PAGE_APPROVAL_REQ1";
const DATA_APPROVAL_REQ = "DATA_APPROVAL_REQ1";
const PAGE_REJECTED_REQ = "PAGE_REJECTED_REQ1";
const DATA_REJECTED_REQ = "DATA_REJECTED_REQ1";

export const constants = {
  STAMP_DATA,
  STAMP_LIST,
  LOADER,
  PAGE,
  ORDERING,
  SEARCH,
  PROFESSIONS,
  GENERATED_DOCUMENTS,
  REQUIRED_DOCUMENTS,
  GENERATED_DOCUMENT,
  NUMBER_DOCUMENTS,
  STAMP_TITLE,
  CATEGORIES,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setStampData = data => ({
  type: STAMP_DATA,
  data,
});

export const setStampList = data => ({
  type: STAMP_LIST,
  data,
});

export const setPage = page => ({
  type: PAGE,
  page,
});

const setOrdering = ordering => ({
  type: ORDERING,
  ordering,
});

const setSearch = search => ({
  type: SEARCH,
  search,
});

const setProfessionsPartOfUnion = data => ({
  type: PROFESSIONS,
  data,
});

const setGeneratedDocuments = data => ({
  type: GENERATED_DOCUMENTS,
  data,
});

const setRequiredDocuments = data => ({
  type: REQUIRED_DOCUMENTS,
  data,
});

const setGeneratedDocument = data => ({
  type: GENERATED_DOCUMENT,
  data,
});

const setNumberDocuments = data => ({
  type: NUMBER_DOCUMENTS,
  data,
});

const setStampTitle = data => ({
  type: STAMP_TITLE,
  data,
});

const setCategories = data => ({
  type: CATEGORIES,
  data,
});

const setPageApprovedReq = pageApprovedReq => ({
  type: PAGE_APPROVAL_REQ,
  pageApprovedReq,
});

const setDataApprovedReq = dataApprovedReq => ({
  type: DATA_APPROVAL_REQ,
  dataApprovedReq,
});

const setPagePendingReq = pagePendingReq => ({
  type: PAGE_PENDING_REQ,
  pagePendingReq,
});

const setDataPendingReq = dataPendingReq => ({
  type: DATA_PENDING_REQ,
  dataPendingReq,
});

const setPageRejectedReq = pageRejectedReq => ({
  type: PAGE_REJECTED_REQ,
  pageRejectedReq,
});

const setDataRejectedReq = dataRejectedReq => ({
  type: DATA_REJECTED_REQ,
  dataRejectedReq,
});

// ------------------------------------
// Actions
// ------------------------------------

const createStamp = () => (dispatch, getStore) => {
  const forms = getStore()["form"]
  let form1 = forms.stamps_step_1.values;
  let form2 = forms.stamps_step_2.values;
  let form2_3 = forms.stamps_step_2_3.values;
  let form3 = forms.stamps_step_3.values;
  let data = {...form1, ...form2, ...form3, ...form2_3}
  dispatch(setLoader(true));
  api.post("stamp", data).then(() => {
    NotificationManager.success("Timbre creado exitosamente", "Success", 3000);
    dispatch(push("/stamps"));
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 3000)
    } else {
      NotificationManager.error("Error en la creación", "ERROR", 300);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const createGeneratedDocument = (id, data) => (dispatch) => {
  dispatch(setLoader(true));
  // eslint-disable-next-line
  api.post(`document/${id}/create_generated_document`, data).then(response => {
    NotificationManager.success("Documento generado exitosamente", "Success", 3000);
    dispatch(push(`/stamps/generated-documents/${id}`));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al generar el documento", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getStamps = (page = 1) => (dispatch, getStore) => {
  const resource = getStore().stamps;
  const params = {page};
  if (resource.ordering) {
    params.ordering = resource.ordering;
  }
  if (resource.search) {
    params.search = resource.search;
  }

  dispatch(setLoader(true));
  api.get("stamp", params).then(response => {
    dispatch(setStampList(response));
    dispatch(setPage(page));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener los timbres", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getStamp = id => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`stamp/${id}`).then(response => {
    dispatch(setStampData(response));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener el timbre", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getGeneratedDocuments = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`document/${id}/get_generated_documents`).then(response => {
    NotificationManager.success("Documentos obtenidos exitosamente", "Success", 3000);
    dispatch(setGeneratedDocuments(response));
    dispatch(setStampTitle(response.results[0].stamp_title));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener los documentos generados", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getGeneratedDocument = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`document/${id}/get_generated_document`).then(response => {
    // NotificationManager.success("Documentos obtenidos exitosamente", "Success", 3000);
    dispatch(setGeneratedDocument(response));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener los documentos generados", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getRequiredDocuments = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`document/${id}/get_required_documents`).then(response => {
    dispatch(setRequiredDocuments(response));
    NotificationManager.success("Documentos obtenidos exitosamente", "Success", 3000);
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener los documentos requeridos", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getCategories = (page = 1) => (dispatch) => {
  const params = {page};
  dispatch(setLoader(true));
  api.get("expense_category", params).then(response => {
    dispatch(setCategories(response));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener las categorias", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const updateStamp = (id, data) => (dispatch) => {
  dispatch(setLoader(true));
  // eslint-disable-next-line
  api.put(`stamp/${id}`, data).then(response => {
    NotificationManager.success("Timbre actualizado exitosamente", "Success", 3000);
    dispatch(push("/stamps"));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al actualizar el timbre", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const updateRequiredDocuments = (id, data) => (dispatch) => {
  dispatch(setLoader(true));
  // eslint-disable-next-line
  api.put(`document/${id}/update_required_documents`, data).then(response => {
    NotificationManager.success("Documentos requeridos actualizados exitosamente", "Success", 3000);
    dispatch(push("/stamps"));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al actualizar los documentos requeridos", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const updateGeneratedDocument = (id, data) => (dispatch) => {
  dispatch(setLoader(true));
  // eslint-disable-next-line
  api.put(`document/${id}/update_generated_document`, data).then(response => {
    NotificationManager.success("Documento generado actualizado exitosamente", "Success", 3000);
    dispatch(push("/stamps"));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al actualizar el documento generado", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const deleteGeneratedDocument = id => (dispatch) => {
  dispatch(setLoader(true));
  // eslint-disable-next-line
  api.eliminar(`document/${id}/delete_generated_document`).then(response => {
    NotificationManager.success("Documento eliminado exitosamente", "Success", 3000);
    dispatch(push("/stamps"));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al eliminar el documento", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getProfessionsPartOfUnion = (search = "") => (dispatch) => {
  dispatch(setLoader(true));
  api.get("profession/get_professions_part_of_union", {search}).then(response => {
    dispatch(setProfessionsPartOfUnion(response.results));
    // eslint-disable-next-line
  }).catch(error => {
    NotificationManager.error("Error al obtener las profesiones", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const searchStamp = search => (dispatch) => {
  dispatch(setSearch(search));
  dispatch(getStamps());
};

const onSortChange = ordering => (dispatch, getStore) => {
  const sort = getStore.stamps.ordering;
  if (ordering === sort) {
    ordering = `-${ordering}`;
  } else {
    dispatch(setOrdering(ordering));
  }
  dispatch(getStamps());
};

const updateNumberDocuments = number => (dispatch) => {
  dispatch(setNumberDocuments(number));
};

const openCategories = (value) => (dispatch, getStore) => {
  const data = getStore().stamps.categories;
  data.forEach(category => {
    if (value == category.value) {
      category.seleccionado = !category.seleccionado
    } else {
      category.children.forEach(sub => {
        if (value == sub.value) {
          sub.seleccionado = !sub.seleccionado
        } else {
          sub.children.forEach(sub1 => {
            if (value == sub1.value) {
              sub1.seleccionado = !sub1.seleccionado
            } else {
              sub1.children.forEach(sub12 => {
                if (value == sub12.value) {
                  sub12.seleccionado = !sub12.seleccionado
                } else {
                  sub12.children.forEach(sub2 => {
                    if (value == sub12.value) {
                      sub12.seleccionado = !sub12.seleccionado
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
  })
  dispatch(setCategories(data))
}

const ListApprovalReq = (page = 1) => (dispatch) => {
  const params = {page, approved: true};
  dispatch(setLoader(true));
  api.get("purchase_request", params).then(response => {
    if (response.results) {
      response.results = addIndexToArray(response.results, page)
    }
    dispatch(setDataApprovedReq(response));
    dispatch(setPageApprovedReq(page));
  }).catch(error => {
    NotificationManager.error("Error al obtener las solicitudes aprobadas", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const ListPendingReq = (page = 1) => (dispatch) => {
  const params = {page, pending: true};
  dispatch(setLoader(true));
  api.get("purchase_request", params).then(response => {
    if (response.results) {
      response.results = addIndexToArray(response.results, page)
    }
    dispatch(setDataPendingReq(response));
    dispatch(setPagePendingReq(page));
  }).catch(error => {
    NotificationManager.error("Error al obtener las solicitudes pendientes", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const ListRejectedReq = (page = 1) => (dispatch) => {
  const params = {page, rejected: true};
  dispatch(setLoader(true));
  api.get("purchase_request", params).then(response => {
    if (response.results) {
      response.results = addIndexToArray(response.results, page)
    }
    dispatch(setDataRejectedReq(response));
    dispatch(setPageRejectedReq(page));
  }).catch(error => {
    NotificationManager.error("Error al obtener las solicitudes rechazadas", "Error", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

export const actions = {
  createStamp,
  createGeneratedDocument,
  getStamps,
  getStamp,
  getProfessionsPartOfUnion,
  getGeneratedDocuments,
  getRequiredDocuments,
  getGeneratedDocument,
  getCategories,
  ListPendingReq,
  ListApprovalReq,
  ListRejectedReq,
  updateRequiredDocuments,
  updateStamp,
  updateGeneratedDocument,
  deleteGeneratedDocument,
  searchStamp,
  onSortChange,
  updateNumberDocuments,
  openCategories,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [STAMP_DATA]: (state, {data}) => {
    return {
      ...state,
      stampData: data,
    };
  },
  [STAMP_LIST]: (state, {data}) => {
    return {
      ...state,
      stampList: data,
    };
  },
  [LOADER]: (state, {loader}) => {
    return {
      ...state,
      loader,
    };
  },
  [PAGE]: (state, {page}) => {
    return {
      ...state,
      page,
    };
  },
  [ORDERING]: (state, {ordering}) => {
    return {
      ...state,
      ordering,
    };
  },
  [SEARCH]: (state, {search}) => {
    return {
      ...state,
      search,
    };
  },
  [PROFESSIONS]: (state, {data}) => {
    return {
      ...state,
      professions: data,
    };
  },
  [GENERATED_DOCUMENTS]: (state, {data}) => {
    return {
      ...state,
      generatedDocuments: data,
    };
  },
  [REQUIRED_DOCUMENTS]: (state, {data}) => {
    return {
      ...state,
      requiredDocuments: {
        documents: data,
      }
    };
  },
  [GENERATED_DOCUMENT]: (state, {data}) => {
    return {
      ...state,
      generatedDocument: data,
    };
  },
  [NUMBER_DOCUMENTS]: (state, {data}) => {
    return {
      ...state,
      numberDocuments: data,
    };
  },
  [STAMP_TITLE]: (state, {data}) => {
    return {
      ...state,
      stampTitle: data,
    };
  },
  [CATEGORIES]: (state, {data}) => {
    return {
      ...state,
      categories: data,
    };
  },
  [PAGE_PENDING_REQ]: (state, {pagePendingReq}) => {
    return {
      ...state,
      pagePendingReq,
    };
  },
  [DATA_PENDING_REQ]: (state, {dataPendingReq}) => {
    return {
      ...state,
      dataPendingReq,
    };
  },
  [PAGE_APPROVAL_REQ]: (state, {pageApprovedReq}) => {
    return {
      ...state,
      pageApprovedReq,
    };
  },
  [DATA_APPROVAL_REQ]: (state, {dataApprovedReq}) => {
    return {
      ...state,
      dataApprovedReq,
    };
  },
  [PAGE_REJECTED_REQ]: (state, {pageRejectedReq}) => {
    return {
      ...state,
      pageRejectedReq,
    };
  },
  [DATA_REJECTED_REQ]: (state, {dataRejectedReq}) => {
    return {
      ...state,
      dataRejectedReq,
    };
  },
};

export const initialState = {
  loader: false,
  stampData: {},
  stampList: {
    count: 0,
    results: [],
  },
  page: 1,
  ordering: "",
  search: "",
  professions: [],
  generatedDocuments: {
    count: 0,
    results: [],
  },
  requiredDocuments: {
    documents: [],
  },
  generatedDocument: {},
  numberDocuments: 0,
  stampTitle: "",
  categories: [],
  dataApprovedReq: {
    count: 0,
    results: [],
  },
  pageApprovedReq: 1,
  dataPendingReq: {
    count: 0,
    results: [],
  },
  pagePendingReq: 1,
  dataRejectedReq: {
    count: 0,
    results: [],
  },
  pageRejectedReq: 1,
};

export default handleActions(reducers, initialState);
