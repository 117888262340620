import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import FinalFormFilter from "../../../../components/Utils/FinalFormFilter";
import Filter from "../../../../components/Utils/Filters/Filter";
import { api } from "../../../../../utility/api";
import excelExportPN from "../../../../../../assets/img/icons/excel.png";
import "./list.css";
const ReportStampListUsed = ({
  getReport,
  page,
  loader,
  memberReportList,
  updateFiltersStampsUsed,
  filtersStampsUsed,
  changeStamp,
  resetValueStamp,
  exportReportStampUsedExcel,
  loader_excel_stamp_used
}) => {
  const [defOptionStamp, setOptionStamp] = useState([])
  useEffect(() => {
    getReport(page);
  }, []);
  useEffect(() => {
    resetValueStamp()
    getStamp().then((opts) => {
      setOptionStamp(opts)
    })
  }, [])
  const getStamp = (search = "") => {
    return api.get("stamp/all_stamp", { search }).then((resp) => {
      let _options = [];
      if (resp) {
        _options = resp
        _options.push({ id: null, name: 'Todos' })
      }
      return _options
    }).catch(() => {
    }).finally(() => {
    })
  }
  return (
    <div className="container-fluid">
      <PageTitle
        title={"Timbres Usados"}
        colTitle="col-12"
      />
      <div className="card bg-card-cofaqui br-20px py-5 px-4">
        <FinalFormFilter
          getStamp={getStamp}
          defaultOp={defOptionStamp}
          withStartDateFilter
          withEndDateFilter
          withSelectStampFilter
          action={updateFiltersStampsUsed}
          initialValues={filtersStampsUsed}
          startDateTitle="Fecha de inicio de uso"
          endDateTitle="Fecha de fin de uso"
          changeStampSelected={changeStamp}
        />
        <div className="align-self-end d-flex mb-3">
          <button
            className="btn btn-green-generic align-self-end mr-3"
            onClick={exportReportStampUsedExcel}
            >
              <img src={excelExportPN} alt="" />
              &nbsp; {loader_excel_stamp_used ? "Exportando..." : "Exportar"}
          </button>
        </div>
        <Grid
          hover
          data={memberReportList}
          page={page}
          loading={loader}
          onPageChange={getReport}
          bodyContainerClass="bc-professions-custom"
          headerContainerClass="hc-professions-custom"
          tableContainerClass="professions-container-table-sm"
          tableHeaderClass="table-header-professions"
        >
          <TableHeaderColumn
            width="100px"
            dataField="correlative"
            dataAlign="center"
            isKey
          >
            Correlativo
          </TableHeaderColumn>
          <TableHeaderColumn
            width="100px"
            dataField="no_document"
            dataAlign="center"
          >
            No. Recibo
          </TableHeaderColumn>
          <TableHeaderColumn
            width="100px"
            dataField="date_used"
            dataAlign="center"
          >
            Fecha de uso 
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="100"
            dataField="no_collegiate"
          >
            No. Colegiado
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="175px"
            dataField="full_name"
          >
            Nombre del Agremiado
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="90px"
            dataField="stamp_name"
          >
            Timbre
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            width="90px"
            dataField="amount"
          >
            Monto
          </TableHeaderColumn>
        </Grid>
      </div>
    </div>
  );
}
export default ReportStampListUsed;














