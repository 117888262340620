import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
const superagent = require("superagent");

const DATA = "EXAMPLE_DATA";
const ARRAY_DATA = "EXAMPLE_ARRAY_DATA";
const LOADER = "EXAMPLE_LOADER";

export const constants = {
  DATA,
  ARRAY_DATA,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setData = data => ({
  type: DATA,
  data,
});

export const setArrayData = data => ({
  type: ARRAY_DATA,
  data,
});

// ------------------------------------
// Actions
// ------------------------------------

const createExample = (data) => (dispatch) => {
  dispatch(setLoader(true));
  superagent.post("https://jsonplaceholder.typicode.com/posts", {
    title: data.title,
    body: data.body,
    userId: data.userId,
  })
    .then((response) => {
      dispatch(setLoader(false));
      dispatch(setData(response.body));
      NotificationManager.success("Example created", "Success");
    }
    ).catch((error) => {
      dispatch(setLoader(false));
      NotificationManager.error("Error creating example", error);
    });
};

const getDataExample = (params) => (dispatch) => {
  dispatch(setLoader(true));
  superagent.get("https://jsonplaceholder.typicode.com/comments", params)
    .then((response) => {
      dispatch(setLoader(false));
      dispatch(setArrayData(response.body));
      NotificationManager.success("Example loaded", "Success");
    }
    ).catch((error) => {
      dispatch(setLoader(false));
      NotificationManager.error("Error getting example",error);
    });
};

export const actions = {
  createExample,
  getDataExample,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [ARRAY_DATA]: (state, { data }) => {
    return {
      ...state,
      arrayData: data,
    };
  }
};

export const initialState = {
  loader: false,
  data: {},
  arrayData: [],
};

export default handleActions(reducers, initialState);