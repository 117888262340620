import "./login.css";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import womanscientist from "../../../../../assets/img/womanscientist.png";
import logo from "../../../../../assets/img/logo.png";

const Login = (props) => {
  const { onSubmit, loader } = props;
  if (localStorage.getItem("token")) {
    return (<Redirect to="/" />);
  }
  return (
    <div className="h-100">
      <LoadMask loading={loader} light>
        <div className="container-fluid" style={{ color: "#4B4D58" }}>
          <div className="row d-flex flex-row">
            <div className="col- col-sm-12 col-md-6 col-lg-5 d-flex flex-row align-items-center justify-content-center login-form" style={{ minHeight: "100vh" }}>
              <div className="card" style={{ background: "#E2DDD9", borderRadius: "10px", minHeight: "70%", minWidth: "55%", zIndex: 11 }}>
                <div className="d-flex flex-column p-5">
                  <img src={logo} className="card-img-top" alt="logo" style={{ height: "70%", width: "70%", alignSelf: "center" }} />
                  <div className="card-body d-flex flex-column align-items-center">
                    <h4 className="card-title" style={{ fontWeight: "bold" }}>Iniciar Sesión</h4>
                    <LoginForm onSubmit={onSubmit} />
                    <span>
                      <Link to="/forgot-password">
                        <span style={{ color: "#0A4725", fontWeight: "bold" }}>
                          Olvidé mi contraseña
                        </span>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none d-md-block d-lg-block d-xl-block col-lg-7 col-md-6">
              <div className="row h-100">
                <img src={womanscientist} className="img-fluid" style={{ minHeight: "100%", objectFit: "cover" }} alt="woman-scientist"/>
              </div>
            </div>
          </div>
        </div>
      </LoadMask>
    </div>
  );
};


export default Login;
