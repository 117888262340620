import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/administration/managements";
import Managements from "./Managements";

const ms2p = (state) => {
  return {
    ...state.managements,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Managements);
