import React, { useEffect, useState } from "react"
import {Field, Form} from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"
import {fieldRequired} from "../../../../utility/validation"
import {TYPE_OF_DISCHARGE, TYPE_OF_INCOME} from "../../../../utility/constants"
import AddMembers from "../../Administration/Commisions/Create/AddMembers"
import BtnSaveAndCancel from "../../../components/layout/BtnSaveAndCancel"
import {standardActions} from "../../../components/Utils/Grid/StandardActions"
import {RenderCurrency} from "../../../components/Utils/renderField/renderReadField"
import {renderDatePicker, RenderQuantity, renderNumber} from "../../../components/Utils/renderField/renderField"
import {simpleRenderField, selectField} from "../../../components/Utils/renderField"
import tree from "../../../components/Utils/TreeComponent"
import Grid from "../../../components/Utils/Grid"
import { TableHeaderColumn } from "react-bootstrap-table"
import { NotificationManager } from "react-notifications"
import reddump from "../../../../../assets/img/icons/reddump.svg"

import validate from "./validations"

const PettyCashTransactionForm = (
  {
    // defaultOptionMembers,
    changeQuantityItem,
    defaultOptionProd,
    // loadMembers,
    setItemSel,
    itemSelected,
    loadProducts,
    initialData,
    actionBntAdd,
    actionDelete,
    dataProducts,
    // setMember,
    minDate,
    onSubmit,
    cashier,
    openCategories,
    categories,
    getCategories,
  }) => {

  const [selectedItem, setSelectedItem] = useState(null)

  const [totalAmount, setTotalAmount] = useState(0)

  const requiredDoc = [5, 10, 15, 20] // deposito, cheque, transferencia

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialData}
        validate={(values, requiredDoc=[10, 15, 20]) => validate(values, requiredDoc)}
        mutators={{
          ...arrayMutators
        }}
        render={({handleSubmit, values: { type_of_discharge, cash_outflows, expense_category }, form: { change, mutators: { push } }}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="col-xl-6 col-lg-6 col-md-9 col-sm-12 card bg-card-cofaqui br-20px pb-4 pt-5 pl-3">
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="transaction_date">Fecha de egreso</label>
                      <Field
                        withTime
                        typeInput='text'
                        minDate={minDate}
                        maxDate={new Date()}
                        name="transaction_date"
                        validate={fieldRequired}
                        component={renderDatePicker}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="type_of_discharge">Tipo de egreso</label>
                      <Field
                        name="type_of_discharge"
                        options={TYPE_OF_DISCHARGE}
                        validate={fieldRequired}
                        customAction={(item) => {
                          if (item === 1) {
                            change("no_document", null)
                          }
                        }}
                        component={selectField}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="no_document">
                        No. {type_of_discharge === 10 ? "Cheque" : type_of_discharge === 20 ? "Banco" : "Documento"}
                      </label>
                      <Field
                        name="no_document"
                        disabled={!requiredDoc.includes(type_of_discharge)}
                        component={simpleRenderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row">
                    <div className="form-group has-feedback flex-1 mr-lg-5 mr-xl-5 mr-md-3 mr-sm-0">
                      <label htmlFor="cashier_id">Usuario</label>
                      <Field
                        name="cashier_id"
                        disabled={true}
                        component={selectField}
                        validate={fieldRequired}
                        options={cashier}
                      />
                    </div>
                    <div className="form-group has-feedback flex-1 mx-lg-4 mx-xl-4 mx-md-2 mx-sm-0">
                      <label htmlFor="amount">Total</label>
                      <RenderCurrency
                        className="d-block pt-2"
                        value={totalAmount}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 p-4 card bg-card-cofaqui">
                <div className="row mb-4">
                  <div className="col- col-sm-12 col-md-12 col-lg-3 col-lx-3 mb-2 mb-lg-0 mr-0 mr-md-4">
                    <label htmlFor="amount">Categoría de gasto</label>
                    <Field
                      name="expense_category"
                      component={tree}
                      options={categories}
                      open={openCategories}
                      placeholder="Seleccione una categoría"
                      getLabelSelectedItem={setSelectedItem}
                    />
                  </div>
                  <div className="align-self-center w-100 ml-0 ml-md-2 mb-sm-0 mt-lg-3 mt-md-2 mt-sm-0 col- col-sm-12 col-md-12 col-lg-2 col-lx-2">
                    <button
                      type="button"
                      className="btn btn-create w-100 mt-lg-2 mt-md-2 mt-sm-0"
                      onClick={() => {
                        if (!expense_category) return

                        if (cash_outflows && cash_outflows.some((item) => item.expense_category === expense_category)) {
                          return NotificationManager.error("Ya existe una categoría con el mismo nombre", "Error", 5000)
                        }

                        push("cash_outflows", {
                          no: cash_outflows ? cash_outflows.length + 1 : 1,
                          expense_category: expense_category,
                          amount: null,
                          name: selectedItem,
                        })
                      }}
                    >
                    Agregar
                    </button>
                  </div>
                </div>
                <Grid
                  hover
                  responsive
                  remote={false}
                  loading={false}
                  // cellEditProp={cellEdit}
                  // keyField="no"
                  data={{
                    results: cash_outflows,
                  }}
                >
                  <TableHeaderColumn
                    width="5%"
                    isKey
                    dataField="no"
                    dataAlign="center"
                    // dataFormat={(cell, row, enumObject, index) => index + 1}
                  >
                    No.
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="name"
                    dataAlign="center"
                    width="30%"
                  >
                    Categoría
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="amount"
                    dataAlign="center"
                    width="30%"
                    dataFormat={(cell, row) => {
                      return (
                        <Field
                          name={`cash_outflows[${row.no - 1}].amount`}
                          component={renderNumber}
                          minLenght={0}
                          prefix="Q "
                          placeholder="0.00"
                          customDecimalScale={2}
                          typeInput="text"
                          acceptNegative={false}
                          parse={
                            (value) => {
                              const totalAmount = cash_outflows.reduce((acc, item) => {
                                if (row.no === item.no) {
                                  return acc + parseFloat(value)
                                }
                                return acc + parseFloat(item.amount || 0)
                              }
                              , 0)
                              setTotalAmount(totalAmount)
                              return value
                            }
                          }
                          validate={fieldRequired}
                        />
                      )
                    }}
                  >
                    Monto
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="no"
                    dataAlign="center"
                    width="8%"
                    dataFormat={(cell, row) => {
                      return (
                        <button
                          type="button"
                          className="btn w-100"
                          onClick={() => {
                            const data = cash_outflows
                            const filtered = data.filter((item) => item.no !== row.no)
                            const reIndex = filtered.map((item, index) => {
                              return {
                                ...item,
                                no: index + 1,
                              }
                            })
                            
                            const totalAmount = reIndex.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0)
                            setTotalAmount(totalAmount)

                            change("cash_outflows", reIndex)
                          }}
                        >
                          <i className="d-flex flex-row justify-content-center align-items-center">
                            <img src={reddump} alt="remover" />
                          </i>
                        </button>
                      )
                    }}
                  >
                    Acción
                  </TableHeaderColumn>
                </Grid>
              </div>
              <BtnSaveAndCancel
                renderBtnSubmit
                renderLinkCancel
                redirectToLink="/my-cash-register"
                customContainer="container justify-content-center w-100 mb-3 mt-4 row"
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default PettyCashTransactionForm