import React from "react";
import { Form, Field } from "react-final-form";
import { renderField } from "../../../components/Utils/renderField";
import { renderDatePicker } from "../../../components/Utils/renderField/renderField";
import {AsyncSelectField} from "../../../components/Utils/renderField";

import "./filter.css";

/***
 * @param action: function to be executed when the form is submitted
 * @param withStartDateFilter: boolean to indicate if the start date filter is to be displayed
 * @param withEndDateFilter: boolean to indicate if the end date filter is to be displayed
 * @param withSearchFilter: boolean to indicate if the search filter is to be displayed
 * @param withSeparator: boolean to indicate if the separator is to be displayed
 * @param initialValues: object with the initial values of the form
 * @param searchTitle: string with the title of the search filter
 * @param startDateTitle: string with the title of the start date filter
 * @param endDateTitle: string with the title of the end date filter
 * @param searchPlaceholder: string with the placeholder of the search filter
 * @param withSelectStampFilter: boolean to indicate if the select stamp is to be displayed
 * @returns update your state with the new values of the filters
 * @param withSelectStampFilter: boolean to indicate if the select stamp is to be displayed
***/

const FinalFormFilter = ({
  action,
  withStartDateFilter,
  withEndDateFilter,
  withSearchFilter,
  withSelectStampFilter,
  withSeparator,
  initialValues,
  searchTitle,
  startDateTitle,
  endDateTitle,
  searchPlaceholder,
  getStamp,
  changeStampSelected,
  defaultOp
}) => {

  return (
    <Form
      onSubmit={action}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className="d-flex flex-wrap mb-4 filter-container">
          {withStartDateFilter && (
            <div className="col- col-sm-12 col-md-12 col-lg-3 field-min-width">
              <label htmlFor="start_date" className="pl-1">
                {startDateTitle}
              </label>
              <Field
                name="start_date"
                component={renderDatePicker}
                type="text"
                placeholder="Fecha de inicio"
              />
            </div>
          )}
          {withEndDateFilter && (
            <div className="col- col-sm-12 col-md-12 col-lg-3 field-min-width">
              <label htmlFor="end_date" className="pl-1">
                {endDateTitle}
              </label>
              <Field
                name="end_date"
                component={renderDatePicker}
                type="text"
                placeholder="Fecha de fin"
              />
            </div>
          )}
          {withSeparator && (
            <div className="separator" />
          )}
          {withSearchFilter && (
            <div className="col- col-sm-12 col-md-12 col-lg-3">
              <label htmlFor="search" className="pl-1">
                {searchTitle}
              </label>
              <Field
                name="search"
                component={renderField}
                type="text"
                placeholder={searchPlaceholder}
                className="form-control"
              />
            </div>
          )}
          {  withSelectStampFilter && (
            <div className="form-group has-feedback flex-1 mx-sm-0 mx-md-3 mx-lg-5">
              <label className="text-16 font-weight-bold" htmlFor="userId">Timbre</label>
              <Field
                name="userId"
                valueKey="id"
                labelKey="name"
                loadOptions={getStamp}
                defaultOptions={defaultOp}
                customAction={changeStampSelected}
                component={AsyncSelectField}
              />
            </div>
          )}
          <div className="col- col-sm-12 col-md-12 col-lg-2 align-self-end">
            <button
              type="submit"
              className="btn btn-green-generic w-100"
              disabled={submitting}
            >
              Filtrar
            </button>
          </div>
        </form>
      )}
    />
  )
}

export default FinalFormFilter;