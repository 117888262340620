const isEmpty = value => value === undefined || value === null || value === "";
function join(rules) {
  return (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];
}

export function email(value) {
  // Let's not start a debate on email regex! This one is quite standard
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return "Invalid email address";
  }

  return null;
}

export function phone(value) {
  // Let's not start a debate on phone regex! This one is the best I can find, the best way to
  // do it correctly is utilizing a third party verification, but for our use case, it is
  // just overkill
  if (!isEmpty(value) && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g.test(value)) {
    return "Invalid phone number";
  }

  return null;
}

export function required(value) {
  if (isEmpty(value)) {
    return "Required";
  }

  return null;
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }

    return null;
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }

    return null;
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return "Must be an integer";
  }

  return null;
}

export function oneOf(enumeration) {
  return (value) => {
    if (!enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(", ")}`;
    }

    return null;
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return "Do not match";
      }
    }

    return null;
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      // concat enables both functions and arrays of functions
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function strongPassword(password) {
  const regex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
  return regex.test(password);
}

export const fieldRequired = value => (value ? undefined : "Este campo es requerido")
export const fieldSelectRequired = value => ((value != null || value != undefined )? undefined : "Campo requerido")
export const mustBeNumber = value => (isNaN(value) ? "Must be a number" : undefined)
export const minValue = min => value =>  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
export const maxValue = max => value =>  isNaN(value) || value <= max ? undefined : `Should be less than ${max}`
export const minDigits = max => value =>  isNaN(value) || `${value}`.length === max ? undefined : `El campo debe tener ${max} digitos`
export const minCharacter = max => value =>  isNaN(value) || value <= max ? undefined : `El campo debe tener ${max} caracteres`
export const maxPercentage = max => (value) => {
  return (value <= max) ? undefined : `El campo debe tener un valor menor o igual a ${max}%`
}
export const maxAmount = max => (value) => {
  return (value <= max) ? undefined : `El monto debe ser menor o igual a ${max}`
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
