import React, { useEffect } from "react";
import PermissionsAndRolesForm from "./PermissionsAndRolesForm";
import TitlePageCreate from "../../../../components/layout/TitleCreate";

import "./permissionsAndRoles.css";

const PermissionsAndRoles = ({
  createRole,
  updateRole,
  getRole,
  roleData,
  loader,
  match,
}) => {

  const createOrUpdate = match.path.includes("new-role") ? "createRole" : "updateRole";

  useEffect(() => {
    if (createOrUpdate === "updateRole") {
      const { id } = match.params;
      getRole(id);
    }
  }, []);

  return (
    <div className="pt-5 container-fluid">
      <TitlePageCreate
        redirectTo="/permissions-roles"
        title={createOrUpdate === "createRole" ? "Nuevo Rol" : "Editar Rol"}
      />
      {
        createOrUpdate === "createRole" ?
          <PermissionsAndRolesForm
            createOrUpdate={createRole}
            loader={loader}
            type="create"
          />
          :
          <PermissionsAndRolesForm
            createOrUpdate={updateRole}
            loader={loader}
            data={roleData}
            type="update"
          />
      }
    </div>
  );
}

export default PermissionsAndRoles;
