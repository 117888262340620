import {push} from "react-router-redux";
// eslint-disable-next-line
import {api} from "api";
import {NotificationManager} from "react-notifications";
import {addIndexToArray} from "../../../utility/constants";


export const createReducer = (storeId, endpoint, formName = undefined, resourceList = undefined) => {

  // ------------------------------------
  // Constants
  // ------------------------------------

  const constants = {
    LOADER: `${storeId.toUpperCase()}_LOADER`,
    DATA: `${storeId.toUpperCase()}_DATA`,
    ITEM: `${storeId.toUpperCase()}_ITEM`,
    PAGE: `${storeId.toUpperCase()}_PAGE`,
    ORDERING: `${storeId.toUpperCase()}_ORDERING`,
    SEARCH: `${storeId.toUpperCase()}_SEARCH`,
    FILTERING: `${storeId.toUpperCase()}_FILTERING`,
    DYNAMIC_FILTER_VALUE: `DYNAMIC_FILTER_VALUE`
  };

  const CUSTOM_FILTERING_VALUES = {
    "Activos" : "state",
    "Inactivos": "state",
    "Fallecidos": "state",
    "Suspención temporal": "state",
    "Miembro temporal": "state",
  }

  // -----------------------------------
  // Pure Actions
  // -----------------------------------

  const setLoader = loader => ({
    type: constants.LOADER,
    loader,
  });

  const setData = data => ({
    type: constants.DATA,
    data,
  });

  const setItem = item => ({
    type: constants.ITEM,
    item,
  });

  const setPage = page => ({
    type: constants.PAGE,
    page,
  });

  const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
  });

  const setSearch = search => ({
    type: constants.SEARCH,
    search,
  });

  const setFiltering = filtering => ({
    type: constants.FILTERING,
    filtering,
  })

  // -----------------------------------
  // Actions
  // -----------------------------------

  const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    let params = {page};
    params.ordering = resource.ordering;
    params.search = resource.search;
  
    if (!!resource.filtering) {
      Object.keys(resource.filtering).forEach(item => {
        params[item] = resource.filtering[item]
      })
    }

    dispatch(setLoader(true));
    api.get(endpoint, params).then((response) => {
      if (response.results) {
        response.results = addIndexToArray(response.results, page)
      }
      dispatch(setData(response));
      dispatch(setPage(page));
    }).catch((err) => {
      if (err && err.details) {
        NotificationManager.error(err.details, "Error", 3000)
      } else {
        NotificationManager.error("Error al listar", "Error", 300)
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };

  const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
      dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };

  const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
      NotificationManager.success("Registro creado", "Éxito", 3000);
      if (resourceList)
        dispatch(push(resourceList));
    }).catch((error) => {
      if (error && error.details) {
        NotificationManager.error(error.details, "ERROR", 4000);
      } 
      else if (error && error.detail) {
        const ERROR = error.detail;

        NotificationManager.error(ERROR, "ERROR", 4000);
      }
      else {
        NotificationManager.error("Error en la creación", "ERROR", 4000);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };

  const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
      NotificationManager.success("Registro actualizado", "Éxito", 3000);
      if (resourceList)
        dispatch(push(resourceList));
    }).catch((error) => {
      if (error && error.details) {
        NotificationManager.error(error.details, "ERROR", 0);
      } else {
        NotificationManager.error("Error en la edición", "ERROR", 0);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };

  const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
      dispatch(listar());
      NotificationManager.success("Registro eliminado", "Éxito", 3000);
    }).catch((error) => {
      if (error && error.details) {
        NotificationManager.error(error.details, "ERROR", 0);
      } else {
        NotificationManager.error("Error en la eliminación", "ERROR", 0);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };

  const setItemValues = (newValues = {}) => (dispatch) => {
    const _final = newValues
    dispatch(setItem(_final));
  };

  const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    // if (search && search.length > 2 || search.length === 0) {
    dispatch(listar());
    // }
  };

  const filteringChange = filtering => (dispatch) => {
    dispatch(setFiltering(filtering));
    dispatch(listar(1));
  }

  // const getCustomFilterValues = (values) => {
  //   let customValues = {}

  //   values.forEach(value => {
  //     customValues[CUSTOM_FILTERING_VALUES[value.label]] = value.value;
  //   });

  //   return customValues;
  // }

  const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
      dispatch(setOrdering(`-${ordering}`));
    } else {
      dispatch(setOrdering(ordering));
    }
    dispatch(listar());
  };

  const onPageChange = (page) => (dispatch) => {
    dispatch(listar(page))
  }

  const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setLoader,
    setData,
    setSearch,
    filteringChange,
    setPage,
    onPageChange,
    setItemValues,
  };

  // -----------------------------------
  // Reducers
  // -----------------------------------

  const reducers = {
    [constants.LOADER]: (state, {loader}) => {
      return {
        ...state,
        loader,
      };
    },
    [constants.DATA]: (state, {data}) => {
      return {
        ...state,
        data,
      };
    },
    [constants.ITEM]: (state, {item}) => {
      return {
        ...state,
        item,
      };
    },
    [constants.PAGE]: (state, {page}) => {
      return {
        ...state,
        page,
      };
    },
    [constants.ORDERING]: (state, {ordering}) => {
      return {
        ...state,
        ordering,
      };
    },
    [constants.SEARCH]: (state, {search}) => {
      return {
        ...state,
        search,
      };
    },
    [constants.FILTERING]: (state, {filtering}) => {
      return {
        ...state,
        filtering: Object.assign(state.filtering, {
          [CUSTOM_FILTERING_VALUES[filtering.label]]: filtering.value
        }),
      }
    }
  };

  const initialState = {
    loader: false,
    data: {
      results: [],
      count: 0,
    },
    item: {},
    page: 1,
    ordering: "",
    search: "",
    filtering: {},
  };

  return {reducers, initialState, actions};

};
