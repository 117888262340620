import "chart.js/auto";
import React, { Fragment } from "react";
import { Bar } from "react-chartjs-2";
import SelectDashboard from "./SelectDashboard";

export const EgresosIngresos = ({datos, age_icome_expenses_select, getIcomeExpensesByYear}) => {
  const {ingresos, egresos, years} = datos;

  const data = {
    labels: [
      "Ene.",
      "Feb.",
      "Mar.",
      "Abr.",
      "May.",
      "Jun.",
      "Jul.",
      "Ago.",
      "Sep.",
      "Oct.",
      "Nov.",
      "Dic.",
    ],
    datasets: [
      {
        label: "Ingresos",
        data: ingresos,
        backgroundColor: "#36A2EB",
        borderWidth: 1,
      },
      {
        label: "Egresos",
        data: egresos,
        backgroundColor: "#FF6384",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          padding: 20,
          font: {
            size:
              window.innerWidth < 850 ? 16 : window.innerWidth > 1516 ? 16 : 10,
          },
        },
      },
    },
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <p className="title-grafica">Comparativa de Ingresos y Egresos</p>
        <SelectDashboard
          options={years}
          defaultValue={age_icome_expenses_select}
          label="Año"
          onChangeAction={getIcomeExpensesByYear}
        />
      </div>
      <div style={{ lineHeight: "10px" }}>
        <br />
      </div>
      <Bar data={data} options={options} height="100" />
    </Fragment>
  );
};

export default EgresosIngresos;
