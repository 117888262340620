import {handleActions} from "redux-actions";
import {createReducer} from "../../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {api} from "api";


// ------------------------------------
// Constants
// ------------------------------------
const DATA_USER_IN_COMMISSION = 'DATA_USER_IN_COMMISSION'

const baseReducer = createReducer(
  "commission",
  "commission",
  "CommissionForm",
  "/commissions",
);

const {setLoader, setItemValues} = baseReducer.actions;
// -----------------------------------
// Pure Actions
// -----------------------------------
const setUserInCommission = userInCommission => ({
  type: DATA_USER_IN_COMMISSION,
  userInCommission,
});


// ------------------------------------
// Actions
// ------------------------------------

const readCommission = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`commission/${id}`, {}).then((res) => {
    dispatch(setItemValues({name: res.name, id: res.id}))
    dispatch(setUserInCommission(res.members ? res.members : []))
  }).catch(() => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error en la creación de la comisón", "ERROR", 0);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}
const createCommission = data => (dispatch, getStore) => {
  const dataMembers = getStore()['commission'].userInCommission
  if (!dataMembers || (dataMembers && dataMembers.length === 0)) {
    NotificationManager.error("Se require agreagar al menos un agremiado", "ERROR", 0);
  } else {
    dispatch(setLoader(true));
    api.post('commission', {...data, members: dataMembers}).then(() => {
      NotificationManager.success("Comisión creada", "Éxito", 3000);
      dispatch(push('/commissions'));
    }).catch(() => {
      if (error && error.details) {
        NotificationManager.error(error.details, "ERROR", 0);
      } else {
        NotificationManager.error("Error en la creación de la comisón", "ERROR", 0);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
  }
};
const editCommission = (id, data) => (dispatch, getStore) => {
  const dataMembers = getStore()['commission'].userInCommission
  if (!dataMembers || (dataMembers && dataMembers.length === 0)) {
    NotificationManager.error("Se require agreagar al menos un agremiado", "ERROR", 0);
  } else {
    dispatch(setLoader(true));
    api.put(`commission/${id}`, {...data, members: dataMembers}).then(() => {
      NotificationManager.success("Comisión editada", "Éxito", 3000);
      dispatch(push('/commissions'));
    }).catch(() => {
      if (error && error.details) {
        NotificationManager.error(error.details, "ERROR", 0);
      } else {
        NotificationManager.error("Error en la edición de la comisón", "ERROR", 0);
      }
    }).finally(() => {
      dispatch(setLoader(false));
    });
  }
};

const addUserInCommission = (newMember) => (dispatch, getStore) => {
  let currentData = getStore()['commission'].userInCommission
  if (currentData.find(element => element.id === newMember.id) === undefined) {
    currentData.push(newMember)
  }
  dispatch(setUserInCommission(currentData))
}
const removeUserInCommission = (id) => (dispatch, getStore) => {
  let currentData = getStore()['commission'].userInCommission
  const result = currentData.filter(member => member.id !== id);
  dispatch(setUserInCommission(result))
}
const resetUserInCommission = (data=[]) => (dispatch) => {
  dispatch(setUserInCommission(data))
}

export const actions = {
  readCommission,
  editCommission,
  createCommission,
  addUserInCommission,
  removeUserInCommission,
  resetUserInCommission,
  ...baseReducer.actions
}


// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  ...baseReducer.reducers,
  [DATA_USER_IN_COMMISSION]: (state, {userInCommission}) => {
    return {
      ...state,
      userInCommission
    }
  }
}

// ------------------------------------
// InitialState
// ------------------------------------

const initialState = {
  ...baseReducer.initialState,
  userInCommission: [],
}

export default handleActions(reducers, initialState);
