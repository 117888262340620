import {handleActions} from "redux-actions";
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
// eslint-disable-next-line
import {api} from "api";
import {push} from "react-router-redux";
import { change, initialize } from "redux-form";

// ------------------------------------
// Constants
// ------------------------------------
const STEPS = "STEP_FORM_MEMBER";
const CURRENT_STEP = "CURRENT_STEP_FORM_MEMBER";
const TEMPORARY_PASSWORD = "TEMPORARY_PASSWORD_FOR_MEMBER";
const OPEN_MODAL = "OPEN_MODAL_FOR_MEMBER";
const CORRELATIVE_NUMBER = "CORRELATIVE_NUMBER_FOR_MEMBER";
const SHOW_MODAL_SUCCESS = "SHOW_MODAL_SUCCESS_MEMBER";

const baseReducer = createReducer(
  "member",
  "member",
  "MemberStep1",
  "/",
);
const {setLoader, setData, setPage} = baseReducer.actions;
const endpoint = "member";
const resourceList = "/";

const setSteps = stepsMember => ({
  type: STEPS,
  stepsMember,
});

const setShowModalSuc = showModalSuccess => ({
  type: SHOW_MODAL_SUCCESS,
  showModalSuccess,
});

const setCurrentStep = currentStepMember => ({
  type: CURRENT_STEP,
  currentStepMember,
});

const setTemporaryPassword = password => ({
  type: TEMPORARY_PASSWORD,
  password,
});

const setOpenModal = value => ({
  type: OPEN_MODAL,
  value,
});

const setCorrelativeNumber = correlativeNumber => ({
  type: CORRELATIVE_NUMBER,
  correlativeNumber,
});

const printPDF = archive => () => {
  const dataURI = `data:application/pdf;base64,${archive}`;
  const win = window.open();
  win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
};

// Actions
const create = (data, withSpouse) => (dispatch, getStore) => {
  const forms = getStore()["form"]
  let form1 = forms.MemberStep1.values;
  let form2 = forms.MemberStep2.values;
  let step3 = forms.MemberStep3.values;
  let dataEnd = {general_data: {...form1, ...form2}, step3, step4: data.customers}
  if (!withSpouse) delete dataEnd.step3
  dispatch(setLoader(true));
  api.post(endpoint, dataEnd).then((response) => {
    dispatch(setShowModalSuc(true))
    if(response.file) {
      dispatch(printPDF(response.file))
    }
  }).catch((error) => {
    if(error && error.details){
      NotificationManager.error(error.details, "ERROR", 3000)
    } else {
      NotificationManager.error("Error en la creación", "ERROR", 300);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const update = (id, data, withSpouse, redirectTo=null) => (dispatch, getStore) => {
  const forms = getStore()["form"]
  let form1 = forms.MemberStep1.values;
  let form2 = forms.MemberStep2.values;
  let step3 = forms.MemberStep3.values;
  let dataEnd = {data_user: {...form1, ...form2}, spouse: step3, beneficiaries: data.customers}
  if (!withSpouse) delete dataEnd.spouse
  dispatch(setLoader(true));
  api.put(`${endpoint}/${id}`, dataEnd).then(() => {
    if (!redirectTo) dispatch(setShowModalSuc(true))
    if (redirectTo) dispatch(push(redirectTo))
  }).catch((error) => {
    if(error && error.details) {
      NotificationManager.error(error.details, "ERROR", 3000)
    }else {
      NotificationManager.error("Error en la actualización del agremiado", "ERROR", 3000);
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

const generateTemporaryPassword = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.put(`user/${id}/generate_temporary_password`).then((response) => {
    NotificationManager.success("Contraseña temporal generada", "Éxito", 3000);
    dispatch(setTemporaryPassword(response.password))
    dispatch(setOpenModal(true))
    dispatch(push("/"));
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error al generar la contraseña temporal", "ERROR");
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const getCorrelativeNumber = () => (dispatch) => {
  dispatch(setLoader(true));
  api.get("index_table/get_correlative_number").then((response) => {
    dispatch(setCorrelativeNumber(response.correlative_number))
  }).catch((error) => {
    if (error && error.details) {
      NotificationManager.error(error.details, "ERROR", 0);
    } else {
      NotificationManager.error("Error al obtener el correlativo", "ERROR");
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });
};

const changeTotalStep = (totalSteps) => (dispatch) => {
  dispatch(setSteps(totalSteps))
}

const changeCurrentStep = (step) => (dispatch) => {
  dispatch(setCurrentStep(step))
}

const closeModal = (value) => (dispatch) => {
  dispatch(setOpenModal(value))
}

const closeModalSuccess = () => (dispatch) => {
  dispatch(setShowModalSuc(false))
  dispatch(push(resourceList))
}

const changeValueSecondaryUser = () => (dispatch, getStore) => {
  const forms = getStore()["form"]
  if (
    forms &&
      forms.MemberStep2 &&
      forms.MemberStep2.values
  ) {
    let values = forms.MemberStep2.values 
    // removemos el valor de secondary_users_id
    values.secondary_users_id = null
    values.secondary_users_selected = null
    dispatch(initialize("MemberStep2", values))
  }
}

const createPDFReport = () => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const resource = getStore()['member']
  const params = {}
  params.state = resource.filtering['state']

  if (params.state === undefined) {
    NotificationManager.error('Debe de seleccionar un filtro para generar el reporte', 'Error', 3000)
    dispatch(setLoader(false))
    return
  }

  fetch(`api/${endpoint}/pdf_report?state=${params.state}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      'Authorization': `Token ${localStorage.getItem('token')}`
    }
  }).then (response => {

    if (response.ok) {
      return response.blob()
    } else {
      NotificationManager.error("Error al descargar", "Error", 3000)
    }

  }).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'listado_agremiados.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
  })
  .catch(error => {
    NotificationManager.error("Error al descargar", "Error", 3000)
  }).finally(()  => {
    dispatch(setLoader(false))
  })

}

const createExcelReport = () => (dispatch, getStore) => {
  dispatch(setLoader(true))
  const resource = getStore()['member']
  const params = {}
  params.state = resource.filtering['state']

  if (params.state === undefined) {
    NotificationManager.error('Debe de seleccionar un filtro para generar el reporte', 'Error', 3000)
    dispatch(setLoader(false))
    return
  }

  fetch(`api/${endpoint}/excel_report?state=${params.state}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${localStorage.getItem('token')}`,
    }
  }).then((response) => {
    if (response.ok) {
      return response.blob()
    } else {
      NotificationManager.error("Error al descargar", "Error", 3000)
    }
  })
  .then(text => {
    const url = window.URL.createObjectURL(new Blob([text]));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'reporte.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  })
  .catch((err) => {
    if (err && err.details) {
      NotificationManager.error(err.details, "Error", 3000)
    } else {
      NotificationManager.error("Error al descargar", "Error", 300)
    }
  }).finally(() => {
    dispatch(setLoader(false));
  });

}

export const actions = {
  create,
  update,
  closeModal,
  changeTotalStep,
  closeModalSuccess,
  changeCurrentStep,
  generateTemporaryPassword,
  getCorrelativeNumber,
  changeValueSecondaryUser,
  createExcelReport,
  createPDFReport,
  ...baseReducer.actions,
}

const reducers = {
  ...baseReducer.reducers,
  [STEPS]: (state, {stepsMember}) => {
    return {
      ...state,
      stepsMember,
    };
  },
  [CURRENT_STEP]: (state, {currentStepMember}) => {
    return {
      ...state,
      currentStepMember,
    };
  },
  [TEMPORARY_PASSWORD]: (state, {password}) => {
    return {
      ...state,
      temporaryPassword: password,
    };
  },
  [OPEN_MODAL]: (state, {value}) => {
    return {
      ...state,
      openModal: value,
    };
  },
  [CORRELATIVE_NUMBER]: (state, {correlativeNumber}) => {
    return {
      ...state,
      correlativeNumber,
    };
  },
  [SHOW_MODAL_SUCCESS]: (state, {showModalSuccess}) => {
    return {
      ...state,
      showModalSuccess,
    };
  },
}
const initialState = {
  ...baseReducer.initialState,
  stepsMember: 4,
  currentStepMember: 1,
  temporaryPassword: "",
  openModal: false,
  showModalSuccess: false,
  correlativeNumber: 0,
}

export default handleActions(reducers, initialState);
