import "chart.js/auto";
import React, { Fragment, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import SelectDashboard from "./SelectDashboard";

export const TimbresVendidos = ({
  datos,
  stamps_sold_month_select,
  stamps_sold_year_select,
  getStampsSoldByMonth,
  getStampsSoldByYear,
}) => {

  const { years, months, options_stamps } = datos;
  const labels = options_stamps.map((option) => option.label);
  const data_values = options_stamps.map((option) => option.value);
  const data = {
    labels,
    datasets: [
      {
        label: "Timbres vendidos por tipo",
        data: data_values,
        backgroundColor: "#36A2EB",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <p className="title-grafica">Timbres vendidos por tipo</p>
        <div className="d-flex justify-content-end">
          <SelectDashboard
            options={months[stamps_sold_year_select['value']]}
            defaultValue={stamps_sold_month_select}
            label="Mes"
            onChangeAction={getStampsSoldByMonth}
          />
          &nbsp; &nbsp;
          <SelectDashboard
            options={years}
            defaultValue={stamps_sold_year_select}
            label="Año"
            onChangeAction={getStampsSoldByYear}
          />
        </div>
      </div>
      <div style={{ lineHeight: "10px" }}>
        <br />
      </div>
      <Bar data={data} options={options} height="50" />
    </Fragment>
  );
};
export default TimbresVendidos;
