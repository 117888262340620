import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
// eslint-disable-next-line
import { api } from "api";

const LOADER = "QRSCAN_LOADER";
const DATA = "QRSCAN_DATA";

export const constants = {
  LOADER,
  DATA,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: LOADER,
  loader,
});

export const setData = data => ({
  type: DATA,
  data,
});

// ------------------------------------
// Actions
// ------------------------------------

const getQRScan = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`qr_scan/${id}/get_qr_consultation`).then((response) => {
    dispatch(setData(response));
  }).catch(() => {
    NotificationManager.error("Error, vuelva a intentar", "ERROR", 4000);
  }).finally(() => {
    dispatch(setLoader(false));
  });
}

export const actions = {
  getQRScan,
};

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
  [LOADER]: (state, { loader }) => ({
    ...state,
    loader,
  }),
  [DATA]: (state, { data }) => ({
    ...state,
    data,
  }),
};

export const initialState = {
  loader: false,
  data: null,
};

export default handleActions(reducers, initialState);