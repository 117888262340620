export const binnaclePath = ["/binnacle"]
export const userPath = ["/users", "/new-user", "/user/:id/edit"]
export const memberPath = ["/", "/new-member", "/member/:id/edit"]
export const productPath = ["/products", "/new-product", "/product/:id/edit"]
export const rolesPath = ["/permissions-roles", "/new-role", "/role/:id/edit"]
export const universityPath = ["/universities", "/new-university", "/university/:id/edit"]
export const professionPath = ["/professions", "/new-profession", "/profession/:id/edit"]
export const commissionPath = ["/commissions", "/new-commission", "/commission/:id/edit"]
export const expenseCat = ["/expense-categories"]
export const stampPath = ["/stamps", "/new-stamp", "/stamp/:id/edit", "/stamps/required-documents/:id", "/stamps/generated-document/:id",
  "/stamps/generated-documents/:id/generate-new-document", "/stamps/generated-documents/:id/generated-document/:documentId/edit"]
export const adminCashRegister = ["/cash-registers", "/create-cash-register", "/cash-register/:id/edit"]
export const myCashRegister = ["/my-cash-register", "/my-cash-register/closing", "/my-cash-register/create-transaction"]
export const floatingPayment = ["/floating_payment"]
export const banksPath = ["/banks", "/banks/new-bank", "/banks/:id/edit"]
export const managementPath = ["/management", "/new-management", "/management/:id/edit"]
export const pathsAdmin = binnaclePath.concat(
  userPath, rolesPath,
  universityPath, professionPath,
  commissionPath, productPath,
  expenseCat, adminCashRegister,
  floatingPayment, banksPath,
  stampPath, managementPath
)
export const pathsReport = ["/report", "/finalform", "/stamps-issued-report", "/stamps-used-report","/contributions-report", "/incomeandexpenses-report", "/document-consultation-report"]
