import React from "react";
import { renderField } from "../../Utils/renderField";
import { Form, Field } from "react-final-form";

const ForgotMyPasswordForm = ({
  forgotMyPassword
}) => {
  return (
    <Form
      onSubmit={forgotMyPassword}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="forgot-password-formulate">
          <div className="text-center mb-2">
              Por favor ingresa tu correo para poder enviarte
              un codigo para restablecer tu contraseña
          </div>
          <div className="mb-3">
            <label htmlFor="email">Correo</label>
            <Field
              name="email"
              component={renderField}
              typeInput="text"
              customClass="email-field"
              placeholder="     Ingresa correo"
            />
          </div>
          <div className="d-flex flex-row justify-content-center mb-3">
            <button type="submit" className="btn forgot-password-button">
              Enviar
            </button>
          </div>
        </form>
      )}
    />
  );
}

export default ForgotMyPasswordForm;