const TEXT = "Este campo es requerido";

const validate = (values, step, otherCase=false) => {
  const errors = {};

  switch (step) {
  case 1:
    if (!values.name) {
      errors.name = TEXT;
    }
    if (!values.profession) {
      errors.profession = TEXT;
    }
    if (!values.type) {
      errors.type = TEXT;
    }
    if (!values.amount) {
      errors.amount = TEXT;
    }
    if (!values.icon) {
      errors.icon = TEXT;
    }
    if (!values.stamp_prefix) {
      errors.stamp_prefix = TEXT;
    }
    if (!values.expense_category) {
      errors.expense_category = TEXT;
    }

    return errors;
  case 2:
    if (values["documents"] && values["documents"].length > 0) {
      errors.documents = []
      values["documents"].map((item, index) => {
        errors.documents[index] = {}
        if (!item.name) {
          errors.documents[index].name = TEXT;
        }
        if (!item.type) {
          errors.documents[index].type = TEXT;
        }
      });
      values["documents"].map((document, index) => {
        if (!document.name) {
          errors.documents = errors.documents || {};
          errors.documents[index] = errors.documents[index] || {};
          errors.documents[index].name = TEXT;
        }
        if (!document.type) {
          errors.documents = errors.documents || {};
          errors.documents[index] = errors.documents[index] || {};
          errors.documents[index].type = TEXT;
        }
      });
    }

    if (values["forms"] && values["forms"].length > 0 && otherCase == "edit") {
      errors.forms = []
      values["forms"].map((item, index) => {
        errors.forms[index] = {}
        if (!item.name) {
          errors.forms[index].name = TEXT;
        }
        if (!item.type_form) {
          errors.forms[index].type_form = TEXT;
        }
      });
      values["forms"].map((form, index) => {
        if (!form.name) {
          errors.forms = errors.forms || {};
          errors.forms[index] = errors.forms[index] || {};
          errors.forms[index].name = TEXT;
        }
        if (!form.type_form) {
          errors.forms = errors.forms || {};
          errors.forms[index] = errors.forms[index] || {};
          errors.forms[index].type_form = TEXT;
        }
      });
    }


    return errors;
  case 3:
    if (values["required_forms"]) {
      errors.required_forms = []
      values.required_forms.map((item, index) => {
        errors.required_forms[index] = {}
        if (!item.name) {
          errors.required_forms[index].name = TEXT;
        }
        if (!item.type_form) {
          errors.required_forms[index].type_form = TEXT;
        }
      });
      values.required_forms.map((document, index) => {
        if (!document.name) {
          errors.required_forms = errors.required_forms || {};
          errors.required_forms[index] = errors.required_forms[index] || {};
          errors.required_forms[index].name = TEXT;
        }
        if (!document.type_form) {
          errors.required_forms = errors.required_forms || {};
          errors.required_forms[index] = errors.required_forms[index] || {};
          errors.required_forms[index].type_form = TEXT;
        }
      });
    }
  
    return errors;
  case 4:
    if (!values.title) {
      errors.title = TEXT;
    }
    if (!values.description) {
      errors.description = TEXT;
    }
    if (!values.document_orientation) {
      errors.document_orientation = TEXT;
    }

    return errors;
  default:
    return undefined;
  }
};

export default validate;