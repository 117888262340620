import { connect } from "react-redux";
import { actions } from "../../../../../../redux/modules/stamps";
import DocumentsList from "./List";

const ms2p = (state) => {
  return {
    ...state.stamps,
  };
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(DocumentsList);
