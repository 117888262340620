import React, {useEffect} from "react";
import Grid from "../../../../components/Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import PageTitle from "../../../../components/layout/PageTitle";
import {standardActions} from "../../../../components/Utils/Grid/StandardActions";
import moment from "moment";
import"./list.css";

const CommissionList = (props) => {
  const {data, loader, onPageChange, onSortChange, page, listar, eliminar, me} = props;

  useEffect(() => {
    listar(page);
  }, []);

  const {permissions} = me;


  return (
    <div className="">
      <PageTitle
        withLink={true}
        title={"Comisiones"}
        linkTo={"new-commission"}
        linkLabel={"Nueva comisión"}
      />
      <div>
        <div className="col-12 px-1">
          <div className="mb-4 card card-small bg-card-cofaqui">
            <div className="px-4 pt-4">
              <Grid
                hover
                data={data}
                page={page}
                loading={loader}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                bodyContainerClass = "bc-comissions-custom"
                headerContainerClass = "hc-comissions-custom"
                tableContainerClass = "comissions-container-table-sm"
                tableHeaderClass = "table-header-comissions"
              >
                <TableHeaderColumn
                  width="100px"
                  dataField="order"
                  dataAlign="center"
                >
                  No.
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="name"
                  width="165px"
                >
                  Nombre Comisión
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="no_members"
                  width="145px"
                >
                  Total Integrantes
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  dataField="modified"
                  width="125px"
                  dataFormat={(cell) => {
                    return moment(cell).format('L')
                  }}
                >
                  Fecha Modificada
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey
                  dataField="id"
                  dataAlign="center"
                  width="100px"
                  dataFormat={standardActions({
                    editar: "commission",
                    eliminar: eliminar,
                  })}
                >
                  Acciones
                </TableHeaderColumn>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommissionList;
