import React, { useState, useEffect } from "react";
import {
  renderField, AsyncSelectField,
  customRenderFieldCheck, renderNumber,
  colorPicker, simpleRenderField,
  selectField
} from "../../../../../components/Utils/renderField";
import { renderFilePicker } from "../../../../../components/Utils/renderField/renderField";
import { Field } from "react-final-form";
import Tree from "../../../../../components/Utils/TreeComponent";
import { Form } from "react-final-form";
import ReduxConnection from "../../../../../components/ReduxFormConn/FinalFormConnection";

import { STAMP_TYPE } from "../../../../../../utility/constants";

import BtnSaveAndCancel from "../../../../../components/layout/BtnSaveAndCancel";

import validate from "../validations";

// eslint-disable-next-line
import { api } from "api"

const getProfessions = async (inputValue = "") => {
  const response = await api.get("profession/get_professions_part_of_union", { search: inputValue });
  let _options = [];
  if (response.results) {
    _options = response.results
  }
  return _options;
};

export const getSubCategories = async (id = "", setItem) => {
  const response = await api.get(`expense_category/${id}/get_children`);
  let _options = [];
  if (response && response.length > 0) {
    _options = response;
    setItem({
      value: response[0].id,
      label: response[0].name,
    });
  }
  return _options;
};


const buttonProps = (form, typeStamp) => {
  if (form.type === "edit" || typeStamp !== 1) {
    return {
      renderLinkCancel: true,
      redirectToLink: "/stamps",
      renderBtnSubmit: true,
    };
  }
  return {
    renderLinkCancel: true,
    redirectToLink: "/stamps",
    renderBtnNext: true,
  };
};

const Step1 = ({
  professions,
  photo,
  type,
  setTypeStamp,
  categories,
  openCategories,
  nextStep,
  step,
  typeStamp,
  createOrUpdateStamp,
  form,
  initialValues,
  match,
  documentManagement,
}) => {

  const [stampTypeOptions, setStampTypeOptions] = useState(STAMP_TYPE);

  useEffect(() => {
    if (type !== "edit" && !documentManagement) {
      setStampTypeOptions(STAMP_TYPE.filter((item) => item.value !== 1));
    }
  } , []);


  return (
    <Form
      onSubmit={
        type == "edit" || typeStamp !== 1 ?
          (values) => {
            const { id } = match.params;
            createOrUpdateStamp(id, values);
          } :
          nextStep
      }
      initialValues={initialValues}
      validate={(values) => validate(values, 1)}
      render={({ handleSubmit, form: { change } }) => (
        <form onSubmit={handleSubmit} className={`${(step === 1 && form.step === "create") || form.type == "edit" && form.step == 1 ? "d-block" : "d-none"}`}>
          <ReduxConnection form="stamps_step_1" />
          <div className="w-100 p-3 p-lg-5 stamps-form">
            <div className="row justify-content-between">
              <div className="col- col-sm-12 col-md-6 col-lg-5">
                <div className="mb-3">
                  <label htmlFor="name">Título del timbre</label>
                  <Field
                    name="name"
                    placeholder="Registro sanitario"
                    component={renderField}
                    typeInput="text"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Profesión</label>
                  <Field
                    // options={[]}
                    valueKey="id"
                    labelKey="name"
                    name="profession"
                    // defaultSelectValue={stampData.profession}
                    loadOptions={getProfessions}
                    placeholder="Químico biólogo"
                    component={AsyncSelectField}
                    defaultOptions={professions}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="name">Icono o logo</label>
                  <div className="d-flex flex-column">
                    <Field
                      accept={"image/*"}
                      name="icon"
                      className="icon-picker"
                      component={renderFilePicker}
                      photo={photo}
                      typeInput="file"
                    />
                    <div className="row p-3">
                      <span
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        Nota: solo se permite formato PNG, a una resolución
                        máxima de 400x400 pixeles.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Valor del timbre</label>
                  <Field
                    name="amount"
                    component={renderNumber}
                    prefix="Q "
                    placeholder="1.00"
                    customDecimalScale={2}
                    typeInput="text"
                  />
                </div>
              </div>
              {/* <div className="col-lg-1">
          </div> */}
              <div className="col- col-sm-12 col-md-6 col-lg-5">
                <div className="mb-3">
                  <label htmlFor="name">Asignación de color</label>
                  <Field
                    placeholder="Color 1"
                    name="color"
                    component={colorPicker}
                    typeForm={type}
                    initialColor={(color, value) => change(color, value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Prefijo de código de timbre</label>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <Field
                      name="stamp_prefix"
                      placeholder="QB"
                      customClass="w-100"
                      maxLength={3}
                      component={simpleRenderField}
                      typeInput="text"
                    />
                    <span style={{
                      minWidth: "80px",
                      marginLeft: "10px",
                    }}>
                      QB-0001
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="tree">
                    Categoría de gasto
                  </label>
                  <Field
                    name="expense_category"
                    options={categories}
                    getSubCategories={getSubCategories}
                    open={openCategories}
                    component={Tree}
                    typeAction={type}
                    placeholder="Seleccione una categoría"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name">Tipo de timbre</label>
                  <Field
                    name="type"
                    placeholder="Bajo aprobación"
                    options={stampTypeOptions}
                    component={selectField}
                    customAction={setTypeStamp}
                    disabled={type === "edit"}
                  // parse={value => {
                  //   if (type === "create") {
                  //     setTypeStamp(value);
                  //   }
                  //   return value;
                  // }}
                  // typeInput="text"
                  />
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="forms">Formularios</label>
                  <div
                    style={{
                      background: "#FAFDF3",
                      border: "1px solid #B0A196",
                      borderRadius: "15px",
                    }}
                    className="field-form container p-4"
                  >
                    <Field
                      name="tqb"
                      value={1}
                      withPharagraph={true}
                      label="Formulario TQB -Direcciones tecnicas"
                      component={customRenderFieldCheck}
                      typeInput="checkbox"
                      customClass="forms-field"
                    />
                    <Field
                      name="cayceq"
                      value={5}
                      withPharagraph={true}
                      label="Formulario CAYCEQ - Apertura Lab"
                      component={customRenderFieldCheck}
                      typeInput="checkbox"
                      customClass="forms-field"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <BtnSaveAndCancel
            {
            ...buttonProps(form, typeStamp)
            }
          />
        </form>
      )}
    />
  );
}

export default Step1;
