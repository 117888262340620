import React, { useEffect } from "react";
import ProfessionsForm from "./ProfessionsForm";
import TitlePageCreate from "../../../../components/layout/TitleCreate";

import "./universities.css";

const Professions = ({
  createProfession,
  updateProfession,
  getProfession,
  professionData,
  match,
  loader,
  countries,
}) => {

  const createOrUpdate = match.path.includes("new-profession") ? "createProfession" : "updateProfession";

  useEffect(() => {
    if (createOrUpdate === "updateProfession") {
      const { id } = match.params;
      getProfession(id);
    }
  }, []);

  return (
    <div className="pt-5 container-fluid">
      <TitlePageCreate
        redirectTo="/professions"
        title={createOrUpdate ==="createProfession" ? "Nueva Profesión" : "Editar Profesión"}
      />
      {
        createOrUpdate === "createProfession" ?
          <ProfessionsForm
            createOrUpdate={createProfession}
            countries={countries}
            loader={loader}
            type="create"
          />
          :
          <ProfessionsForm
            createOrUpdate={updateProfession}
            countries={countries}
            data={professionData}
            loader={loader}
            type="update"
          />
      }
    </div>
  );
}

export default Professions;
