import React, { Fragment } from "react";

const RenderName = ({ input }) => {
  return (
    <Fragment>
      {input.value}
    </Fragment>
  );
};

const RenderText = ({ input, CustomClassName }) => {
  return (
    <span className={CustomClassName}>
      {input.value}
    </span>
  );
};

export {
  RenderName,
  RenderText
}