import moment from "moment/moment"
import React, { useEffect, useState } from "react"
import Grid from "../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table"
import PageTitle from "../../../../components/layout/PageTitle"
import {standardActions} from "../../../../components/Utils/Grid/StandardActions"


const List = (props) => {
  const {
    listar, page, 
    data, onSortChange, 
    loader, initialValueTransDetail, 
    generateFirstPDF, listTransactions,
    admin, match: { path, params: { memberId } },
    assignMemberId, getPdfDocumetAndPrint
  } = props
  
  const localPrintAction = (row) => {
    const {member_inventory} = row
    if (typeof (member_inventory) === "object" && member_inventory.id) {
      // generateFirstPDF(member_inventory)
      getPdfDocumetAndPrint(member_inventory)
    } else {
      console.log("--- DONT HAVE A MEMBER_INVENTORY ---")
    }
  }
  
  const [actions, setActions] = useState({
    viewOrPrint: "/managements/my-documents",
    viewOrPrintAction: localPrintAction,
    verDetail: "documents-available",
    memberInventoryDetail: "member-inventory-detail",
  })

  useEffect(() => {
    if (admin && admin === true) {
      // Set memberId if admin is true and get transactions
      assignMemberId(memberId)
      listTransactions(page, memberId)
      setActions({
        // viewOrPrint: url,
        availableDocuments: "/available-documents",
        viewOrPrintAction: localPrintAction,
        otherMemberInventoryDetail: "member-inventory-detail",
        memberInventoryDetail: "documents",
        // verDetail: "documents-available",
      })
    } else {
      listar()
    }
    initialValueTransDetail()
  }, [])



  return (
    <div>
      <div className="col-12 px-1">
        <PageTitle
          colTitle="col-lg-7 col-md-7 d-flex flex-row"
          title={"Mis Documentos"}
          withReturn
          returnTo={path == "/member/:memberId/documents" ? "/" : "/managements"}
        />
        <div className="mb-4 card card-small bg-card-cofaqui">
          <div className="px-4 py-4">
            <Grid
              hover
              page={page}
              data={data}
              loading={loader}
              onPageChange={
                admin && admin === true ? (page) => listTransactions(page, memberId) : (page) => listar(page)
              }
              onSortChange={onSortChange}
            >
              <TableHeaderColumn
                width="150px"
                dataField="order"
                dataAlign="center"
              >
                No.
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="type_purchase"
                dataAlign="center"
                width="200px"
                dataFormat={(cell, row) => {
                  return (
                    <span>
                      {cell}
                    </span>
                  )
                }}
              >
                Tipo de compra
              </TableHeaderColumn>
              <TableHeaderColumn
                width="210px"
                dataField="available"
                dataAlign="center"
              >
                Disponibles
              </TableHeaderColumn>
              <TableHeaderColumn
                width={"200px"}
                dataAlign="center"
                dataField="transaction_date"
                dataFormat={(cell, row) => {
                  let dateStr = ""
                  try {
                    dateStr = moment(cell).add(6, "hours").format("L")
                  } catch (e) {
                    console.log("error in date", e)
                  }
                  return <span>{dateStr}</span>
                }}
              >
                Fecha de compra
              </TableHeaderColumn>
              <TableHeaderColumn
                isKey
                dataField="id"
                width="200px"
                dataAlign="center"
                dataFormat={standardActions({
                  ...actions,
                  memberId: memberId,
                })}
              >
                Acciones
              </TableHeaderColumn>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}


export default List

