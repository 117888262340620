import moment from "moment/moment"
import React, {useEffect} from "react"
import Grid from "../../../../components/Utils/Grid"
import {TableHeaderColumn} from "react-bootstrap-table"
import PageTitle from "../../../../components/layout/PageTitle"

const DocsGeneratedList = (props) => {
  const {
    item,
    loader,
    history,
    detailPage,
    printAction,
    generatedDocData,
    listDocGenerated,
    changeTransDetailId,
    admin,
    match: { params: {id} },
    match: { params: { memberId } },
  } = props

  useEffect(() => {
    if (id) {
      changeTransDetailId(id, 1, admin, memberId)
    }
  }, [])

  return (
    <div>

      <div className="col-12 px-1">
        <PageTitle
          returnActionBtn={history.goBack}
          colTitle="col-lg-7  col-md-7 d-flex flex-row"
          title={`${item.name ? item.name : "Nombre de timbre / producto"}`}
        />
        <div className="mb-4 card card-small bg-card-cofaqui">
          <div className="px-4 py-4">
            <Grid
              hover
              loading={loader}
              page={detailPage}
              data={generatedDocData}
              onPageChange={listDocGenerated}
              onSortChange={() => {
              }}
            >
              <TableHeaderColumn
                width="200px"
                dataField="order"
                dataAlign="center"
              >
                No.
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="reason_for_use"
                dataAlign="center"
                dataFormat={(cell)=>{
                  let reason_for_use = "No aplica"
                  if(cell){
                    reason_for_use = cell
                  }
                  return (
                    <span>
                      {reason_for_use}
                    </span>
                  )
                }}
              >
                Razón de uso
              </TableHeaderColumn>
              <TableHeaderColumn
                width={"200px"}
                dataAlign="center"
                dataField="date_used"
                dataFormat={(cell) => {
                  let dateStr = "--/--/-- "
                  if (cell) {
                    try {
                      dateStr = moment(cell).add(6, "hours").format("L")
                    } catch (e) {
                      console.log("error in date", e)
                    }
                  }
                  return <span>{dateStr}</span>
                }}
              >
                Fecha de uso
              </TableHeaderColumn>
              <TableHeaderColumn
                width={"200px"}
                dataAlign="center"
                dataField="date_doc_printed"
                dataFormat={(cell, row) => {
                  let dateStr = ""
                  try {
                    dateStr = dateStr = moment(cell).add(6, "hours").format("L")
                  } catch (e) {
                    console.log("error in date", e)
                  }
                  return <span>{dateStr}</span>
                }}
              >
                Fecha de generación
              </TableHeaderColumn>
              <TableHeaderColumn
                width="200px"
                dataField="correlative"
                dataAlign="center"
              >
                Correlativo
              </TableHeaderColumn>
              <TableHeaderColumn
                isKey
                dataField="id"
                dataAlign="center"
                dataFormat={(cell, row) => {
                  return <center>
                    <button onClick={() => printAction(row.doc_generated)}
                      className={"px-2 py-0 actions-btn btn-print"}>
                      <img src={require("assets/img/icons/bluePrint.svg")} alt={"print"}/>
                    </button>
                  </center>
                }}
              >
                Acciones
              </TableHeaderColumn>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}


export default DocsGeneratedList
