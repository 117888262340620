import React from 'react'
import Modal from "react-responsive-modal";
import {Field, Form} from "react-final-form";
import {fieldRequired} from "../../../../../utility/validation";
import {renderField} from "../../../../components/Utils/renderField";
import {renderDatePicker} from "../../../../components/Utils/renderField/renderField";
import LoadMask from "../../../../components/Utils/LoadMask/LoadMask";

const ConfirmationModal = ({openModal, closeModalAction, onSubmit, loader}) => {

  function finalSubmit(data) {
    onSubmit(data, closeModalAction)
  }

  return (
    <div>
      <Modal
        center
        open={openModal}
        closeOnEsc={false}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        styles={{
          modal: {
            width: "600px",
            padding: '2.2%',
            maxWidth: "90%",
            borderRadius: "8px",
            background: "#FAFDF3",
            border: "1px solid #B0A196",
          },
          closeIcon: {
            fill: '#B0A196'
          }
        }}
        onClose={closeModalAction}
      >
        <Form
          onSubmit={finalSubmit}
          render={({handleSubmit}) => {
            return (
              <form onSubmit={handleSubmit}>
                <LoadMask dark loading={loader} blur>
                  <React.Fragment>
                    <div>
                      <center>
                        <span className={'material-icons'} style={{fontSize: '6.3rem', color: '#777FF3'}}>info</span>
                        <h4 className="font-weight-bold mb-0">
                          Impresion de Timbre / Producto
                        </h4>
                        <br/>
                        <span>A continación, conmplete los detalles de uso </span>
                      </center>
                    </div>
                    <div className="form-group px-lg-5 px-md-3 px-sm-0 mt-4">
                      <label htmlFor="reason_for_use">Razón de uso</label>
                      <Field
                        name="reason_for_use"
                        validate={fieldRequired}
                        component={renderField}
                      />
                    </div>
                    <div className="form-group px-lg-5 px-md-3 px-sm-0">
                      <label htmlFor="date_used">Fecha de uso</label>
                      <Field
                        name="date_used"
                        validate={fieldRequired}
                        component={renderDatePicker}
                      />
                    </div>
                    <div className="d-flex flex-wrap justify-content-center mt-5">
                      <button
                        type="button"
                        onClick={closeModalAction}
                        className="btn btn-red col-sm-12 col-lg-5 col-md-5"
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success col-sm-12 col-lg-5 col-md-5 ml-lg-3 ml-ms-1 ml-sm-0">
                        Imprimir
                      </button>
                    </div>
                  </React.Fragment>
                </LoadMask>
              </form>
            )
          }}
        />
      </Modal>

    </div>
  )
}

export default ConfirmationModal
