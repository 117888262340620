import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
// eslint-disable-next-line
import { api } from "api";

const TRANSACTION_DATA = "TRANSACTION_DATA_EBIPAY";
const LOADER = "TRANSACTION_LOADER_EBIPAY";

export const constants = {
    TRANSACTION_DATA,
    LOADER,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setTransactionData = data => ({
    type: TRANSACTION_DATA,
    data,
});

// ------------------------------------
// Actions
// ------------------------------------

const getTransactionData = (authorization) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`transaction/${authorization}/check_pay`).then((response) => {
        dispatch(setTransactionData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const printPDF = archive => () => {
    // if (archive.base64) {
    //   const dataURI = `data:application/pdf;base64,${archive}`;
    //   const win = window.open();
    //   win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
    // } else {
    fetch(archive.url).then(function (response) {
      response.blob().then(function (blob) {
        var reader = new FileReader();
        reader.onload = function () {
          // printJS({
          //   printable: reader.result.substring(28),
          //   type: "pdf",
          //   base64: true,
          // });
          const dataURI = `data:application/pdf;base64,${reader.result.substring(28)}`;
          const win = window.open();
          if (win) {
            win.document.write("<iframe src=\"" + dataURI + "\" style=\"border: none; width: 100%; height: 100vh;\"></iframe>");
          }
        }
        reader.readAsDataURL(blob);
      });
    });
    // }
  };

const generateReceipt = (authorization) => (
    dispatch,
  ) => {

    dispatch(setLoader(true));
    api.get(`transaction/${authorization}/generate_receipt_ebipay`).then(response => {
        setTimeout(() => {
          printPDF(response)();
        }, 500);
    }).catch(() => {
      NotificationManager.error(
        "Error al generar el recibo",
        "ERROR",
        5000
      );
    }).finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 500);
    });
  };

export const actions = {
    getTransactionData,
    generateReceipt,
};

// ------------------------------------
// Reducer
// ------------------------------------

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [TRANSACTION_DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

const initialState = {
    loader: false,
    data: {
        details: {
            results: [],
            count: 0,
        },
    },
};

export default handleActions(reducers, initialState);